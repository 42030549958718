import React, { useState, useRef, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import InputSideCustom from 'views/pages/components/InputSideCustom'
import InputFile from 'views/pages/components/InputFile'
import {
  InputKeySelect,
  InputArraySelect,
  InputKeySideSelect,
  InputArraySideSelect,
} from 'views/pages/components/InputSelect'
import InputToggle from 'views/pages/components/InputToggle'
import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap'

export default function ProviderSelector() {
  const { id } = useParams();
  const notificationAlert = useRef(null)
  const history = useHistory()

  const [token, setToken] = useState('')

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/deal/${id}/provider-selector`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data

        if (data && data.data && data.data.division) {
          const division = data.data.division
          switch (division) {
            case "paypaplane":
              break;

            case "ezidebit":
              history.push({
                pathname: '/admin/deals/' + id + '/ezidebit/settings',
              })
              break;

            case "gocardless":
              history.push({
                pathname: '/admin/deals/' + id + '/goCardless',
              })
              break;

            default:
              break;
          }
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  })

  const goList = () => {
    history.push({
      pathname: '/admin/deals/' + id + '/edit',
    })
  }

  const goGoCardless = () => {
    history.push({
      pathname: '/admin/deals/' + id + '/goCardless',
    })
  }

  const goPaypaPlane = () => {
  }

  const goEzidebit = () => {
    history.push({
      pathname: '/admin/deals/' + id + '/ezidebit/settings',
    })
  }

  return (
    <>
      <CardsHeader
        name="Leads"
        parentName="Leads Management"
        currentName="Create"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Choose payment method</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button
                      className="w-100"
                      color="success"
                      type="button"
                      onClick={() => goList()}
                    >
                      Deals
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <h1 className="mt-3">{`Methods`}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Button
                      className="w-100"
                      color="success"
                      type="button"
                      onClick={() => goGoCardless()}
                    >
                      {`GoCardless Or Classic Reference`}
                    </Button>
                  </Col>
                  <Col md={6}>
                    <Button
                      className="w-100"
                      color="danger"
                      type="button"
                      onClick={() => goPaypaPlane()}
                    >
                      {`Paypa Plane`}
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={6}>
                    <Button
                      className="w-100"
                      color="danger"
                      type="button"
                      onClick={() => goEzidebit()}
                    >
                      {`Ezidebit`}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}