import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import NotificationAlert from "react-notification-alert";

import CardsHeader from "layouts/Headers/CardsHeader.js";

import { DealContextProvider } from "./context";

import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";

import PaymentStatus from "views/pages/deals/tabs/PaymentStatus";
import Tabs from "views/pages/deals/tabs/Tabs";
import Content from "views/pages/deals/tabs/Content";

export default function DealEdit() {
  const { id } = useParams();
  const notificationAlert = useRef(null);

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <DealContextProvider id={id} notify={notify}>
      <CardsHeader
        name="Deal Management"
        parentName="Deals Management"
        currentName="Edit"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col md={12} xl={12}>
            <Card>
              <CardHeader>
                <Tabs />
              </CardHeader>
              <CardBody>
                <PaymentStatus />
              </CardBody>
            </Card>
            <Card>
              <Content />
            </Card>
          </Col>
        </Row>
      </Container>
    </DealContextProvider>
  );
}
