import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminNavbar from 'layouts/Navbars/AdminNavbar.js';
import AdminFooter from "layouts/Footers/AdminFooter.js";

import Sidebar_ from "layouts/Sidebar/Sidebar.js";
import Sidebar_PaulAu from "layouts/Sidebar/Sidebar_au.js";

import * as constants from "constants/index";

import routes from "routes.js";
import routes_PaulAu from "routes_au.js";

import SettingsResources from 'views/pages/settings/resources/Index';
import VehicleInStock from 'views/pages/fleet/VehicleInStock';
import CategoryDashBoard from 'views/pages/fleet/CategoryDashBoard';

const RoutesComponents = {
  routes,
  routes_PaulAu
}

const SidebarComponent = {
  Sidebar_,
  Sidebar_PaulAu
}

const Sidebar = constants.SITE_CODE === 'Uk'? SidebarComponent['Sidebar_']: SidebarComponent['Sidebar_' + constants.SITE_CODE]

class Admin extends React.Component {
  state = {
    sidenavOpen: true
  };
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            exact
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  toggleSidenav = e => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen
    });
  };
  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      "admin/alternative-dashboard"
    ) === -1
      ? "dark"
      : "light";
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={constants.SITE_CODE === 'Uk' ? RoutesComponents['routes']: RoutesComponents['routes_' + constants.SITE_CODE]}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: "/",
            imgSrc: require("assets/img/RentalHub.png"),
            imgAlt: "..."
          }}
        />
        <div
          className="main-content"
          ref="mainContent"
          onClick={this.closeSidenav}
        >
          <AdminNavbar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            <Route
              path="/admin/resource-categories/:slug/docs"
              exact
              component={(props) => <SettingsResources {...props} key={window.location.pathname} />}
            />
            <Route
              path="/admin/fleet-job/inspect/:category"
              exact
              component={(props) => <VehicleInStock {...props} key={window.location.pathname} />}
            />
            <Route
              path="/admin/fleet-job/dashboard/:category"
              exact
              component={(props) => <CategoryDashBoard {...props} key={window.location.pathname} />}
            />
            {this.getRoutes(constants.SITE_CODE === 'Uk' ? RoutesComponents['routes']: RoutesComponents['routes_' + constants.SITE_CODE])}            
            <Redirect from="*" to="/admin/dashboard" />
          </Switch>
          <AdminFooter />
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    );
  }
}

export default Admin;
