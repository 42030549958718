import React, { useEffect, useRef } from 'react'

import axios from 'axios'
import moment from 'moment'

import { BryntumScheduler } from 'bryntum-react-shared'

import 'bryntum-scheduler/scheduler.stockholm.css'
import TaskStore from 'views/pages/fleet/scheduler/lib/TaskStore'
import UnplannedGridComponent from 'views/pages/fleet/scheduler/components/UnplannedGrid'
import Drag from 'views/pages/fleet/scheduler/lib/Drag'

import Cookies from 'js-cookie'
import * as constants from 'constants/index.js'

let token
let eventStore
let unassigned

const Content = props => {

  const
    scheduler = useRef(),
    grid = useRef()
  
  eventStore = new TaskStore({
    data: props.jobs.events
  })

  unassigned = props.jobs.unassigned

  useEffect(() => {
    token = Cookies.get('grs_token')

    new Drag({
      grid: grid.current.unplannedGrid,
      schedule: scheduler.current.schedulerInstance,
      constrain: false,
      outerElement: grid.current.unplannedGrid.element
    })
  }, [])

  useEffect(() => {
    eventStore.on({
      update: onEventStoreUpdate,
      add: onEventStoreAdd
    })
  }, [props.jobs.events])

  /**
   * @param {Event} record Event record
   * @param {TaskStore} eventStore Event store firing the event
   *
   * Reschedules the overlapping events if the button is pressed
   */
  const onEventStoreUpdate = ({ record, source: eventStore }) => {
    const job = record.data
    if (job) {
      bookJob({
        ...job,
        status: job.resourceId ? 1 : 0,
      })
    }
    if (props.autoReschedule.current) {
      eventStore.rescheduleOverlappingTasks(record)
    }
  }

  /**
   * @param {Event[]} records Array of Event records
   * @param {TaskStore} eventStore Event store firing the event
   *
   * Reschedules the overlapping events if the button is pressed
   */
  const onEventStoreAdd = ({ records, source: eventStore }) => {
    const job = records[0].data
    if (job) {
      bookJob({
        ...job,
        status: 1,
      })
    }
    if (props.autoReschedule.current) {
      records.forEach((eventRecord) => eventStore.rescheduleOverlappingTasks(eventRecord))
    }
  }

  const bookJob = async (job) => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${constants.API_URL}/fleet-management/${job.vehicle_id}/vehicle-jobs/${job.uuid}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          ...job,
          origin: 'jobsByDate',
          description: job.description ? job.description : '',
          job_type_id: job.job_type_id ? job.job_type_id : '',
          status: job.status,
          start_date: job.resourceId && job.startDate ? moment(job.startDate).format('YYYY-MM-DDTHH:mm:ss') : null,
          end_date: job.resourceId &&  job.endDate ? moment(job.endDate).format('YYYY-MM-DDTHH:mm:ss') : null,
          estimated_completion_at:  job.resourceId && job.endDate ? moment(job.endDate).format('YYYY-MM-DDTHH:mm:ss') : null,
          eta_hours: job.duration ? job.duration : '',
          booked_by: job.resourceId ? job.resourceId : null,
        }
      })
      console.log('response :: ', response.data)
      const data = response.data
    } catch (error) {
      console.error('error :: ', error)
    }
  }

  const completeJob = (eventRecord) => {
    eventStore.remove(eventRecord)
    const job = eventRecord.data
    if (job) {
      bookJob({
        ...job,
        status: 2,
      })
    }
  }

  return (
    <div id="main">
      <BryntumScheduler
        ref={scheduler}
        id="schedulerComponent"

        stripeFeature={true}
        timeRangesFeature={true}
        eventMenuFeature={{
          processItems: ({ eventRecord, items }) => {
            if (eventRecord.data.resizable) {
              items.unassign = {
                text: 'Unassign',
                icon: 'b-fa b-fa-user-times',
                weight: 200,
                onItem({ eventRecord, resourceRecord }) {
                  eventRecord.unassign(resourceRecord)
                }
              }
              items.completeItem = {
                text: 'Complete',
                icon: 'b-fa b-fa-fw b-fa-flag',
                onItem({ eventRecord, resourceRecord }) {
                  completeJob(eventRecord)
                }
              }
            } else {
              items.unassign = false
            }
            items.deleteEvent = false
            items.editEvent = false
          }
        }}
        rowHeight={50}
        barMargin={4}
        eventColor='indigo'

        columns={[
          {
            type: 'resourceInfo',
            text: 'Name',
            width: 200,
            showEventCount: false,
            showRole: false
          }
        ]}
        allowOverlap={false}

        viewPreset={{
          base: 'hourAndDay',
          columnLinesFor: 0,
          timeResolution: {
            unit: 'MINUTE',
            increment: 15
          },
          headers: [
            {
              unit: 'd',
              align: 'center',
              dateFormat: 'ddd DD MMM'
            },
            {
              unit: 'h',
              align: 'center',
              dateFormat: 'HH'
            }
          ]
        }}

        eventStore={eventStore}

        startDate={new Date(moment(props.date).format('YYYY'), moment(props.date).format('MM') - 1, moment(props.date).format('DD'), 9)}
        endDate={new Date(moment(props.date).format('YYYY'), moment(props.date).format('MM') - 1, moment(props.date).format('DD'), 17)}
        resources={props.jobs.resources}

        listeners={{
          eventDrop: ({ source, eventRecords, assignmentRecords, isCopy, context }) => {
            const job = eventRecords[0].data
            if (job) {
              bookJob({
                ...job,
                status: 1,
              })
            }
          },
          eventResizeEnd: ({wasChanged, eventRecord}) => {
            const job = eventRecord.data
            if (job) {
              bookJob({
                ...job,
                status: 1,
              })
            }
          }
        }}
        createEventOnDblClick={false}
        eventDragCreateFeature={false}
        eventEditFeature={false}
        scheduleMenuFeature={false}
        cellMenuFeature={false}
        cellEditFeature={false}
      />
      <UnplannedGridComponent
        ref={grid}
        eventStore={eventStore}
        data={unassigned}
      />
    </div>
  )
}

export default Content
