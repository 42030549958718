import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'
import {
  InputKeySelect,
} from 'views/pages/components/InputSelect'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap'

export default function Confirm() {
  const notificationAlert = useRef(null)
  const history = useHistory()

  const [token, setToken] = useState('')
  const [classes, setClasses] = useState('')
  const [doors, setDoors] = useState('')
  const [groups, setGroups] = useState([])
  const [item, setItem] = useState([])
  const [makes, setMakes] = useState([])
  const [model, setModel] = useState('')
  const [models, setModels] = useState([])
  const [vehicleTransmissions, setVehicleTransmissions] = useState('')
  const [vehicleTypes, setVehicleTypes] = useState('')
  const [wasSearched, setWasSearched] = useState(false)

  const [searchValue, setSearchValue] = useState('')

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/inventory/daily-rental-vehicle/create`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data

        setClasses(data.data.classes)
        setDoors(data.data.doors)
        setGroups(data.data.groups)
        setItem(data.data.item)
        setMakes(data.data.makes)
        setModel(data.data.model)
        setModels(data.data.models)
        setVehicleTransmissions(data.data.vehicleTransmissions)
        setVehicleTypes(data.data.vehicleTypes)
        setWasSearched(data.data.wasSearched)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const searchVehicle = async () => {
    console.log('searchValue :: ', searchValue)
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/inventory/daily-rental-vehicle/create`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          check_class: searchValue
        }
      })
      console.log('response :: ', response.data)
      const data = response.data

      setClasses(data.data.classes)
      setDoors(data.data.doors)
      setGroups(data.data.groups)
      setItem(data.data.item)
      setMakes(data.data.makes)
      setModel(data.data.model)
      setModels(data.data.models)
      setVehicleTransmissions(data.data.vehicleTransmissions)
      setVehicleTypes(data.data.vehicleTypes)
      setWasSearched(data.data.wasSearched)
    } catch (error) {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
    }
  }

  const updateGroup = (uuid) => {
    history.push({
      pathname: `/admin/daily-rental-vehicle/${uuid}/edit`
    })
  }

  const createGroup = () => {
    history.push({
      pathname: `/admin/daily-rental-vehicle/${searchValue}/create`
    })
  }

  return (
    <>
      <CardsHeader
        name="Leads"
        parentName="Leads Management"
        currentName="Create"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Add new daily rentals vehicle</h1>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6} sm={12}>
                    <Row className="flex" style={{alignItems: 'center'}}>
                      <Col md={8} sm={12}>
                        <InputKeySelect
                          label={`Class`}
                          options={classes}
                          onChange={(e) => {
                            setSearchValue(e.target.value);
                          }}
                          defaultOption={true}
                        />
                      </Col>
                      <Col md={4} sm={12}>
                        <Button
                          className="w-100"
                          color="success"
                          onClick={() => searchVehicle()}
                        >
                          Search
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p className="text-sm mb-0">
                      {`Search any other daily rental vehicles matching make and model criteria.`}
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {wasSearched && (
              <Card>
                <CardHeader>
                  <h1 className="mb-0">General Group Information</h1>
                </CardHeader>
                <CardBody>
                  {groups.length > 0 && (
                    <>
                      <h4>
                        Showing existing dealer matching your search criteria.
                        You can alter a group and add more vehicle items under.
                      </h4>
                      {groups.map((group, index) => {
                        return (
                          <div className="row align-items-center" key={index}>
                            <div className="col-12 col-md-10">
                              <p>
                                <span className="font-weight-700">
                                  Title:{" "}
                                  {group.title ? group.title : "NO TITLE"}
                                </span>
                                <br />
                                Daily Price: {constants.CURRENCY}{" "}
                                {constants.round2Decimals(group.daily_price)}
                                <br />
                                Doors: {group.no_of_doors}
                                <br />
                                Seats: {group.no_of_seats}
                                <br />
                                Car Type:{" "}
                                {vehicleTypes[group.type]
                                  ? vehicleTypes[group.type]
                                  : ""}
                                <br />
                                Class:{" "}
                                {classes[group.class]
                                  ? classes[group.class]
                                  : ""}
                                <br />
                                Transmission:{" "}
                                {vehicleTransmissions[group.transmission]
                                  ? vehicleTransmissions[group.transmission]
                                  : ""}
                              </p>
                            </div>
                            <div className="col-12 col-md-2">
                              <Button
                                className="w-100"
                                color="success"
                                onClick={() => updateGroup(group.uuid)}
                              >
                                Edit
                              </Button>
                            </div>
                            <hr />
                          </div>
                        );
                      })}
                    </>
                  )}
                  <h2>
                    {`Add new daily rental vehicle `}
                    <a style={{background: 'red'}} onClick={() => createGroup()} className={`cursor-pointer`}>here</a>
                  </h2>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}