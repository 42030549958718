import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';
import { Editor } from '@tinymce/tinymce-react';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import InputToggle from 'views/pages/components/InputToggle';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  FormGroup,
} from 'reactstrap';

import {
  deletee,
  hasPermission,
} from 'views/policies/EmailTemplatePolicy';

export default function EmailTemplates() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const permissions = useSelector(state => state.getState).permissions
  
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [emails, setEmails] = useState([]);

  const [email, setEmail] = useState('');
  const [emailModal, setEmailModal] = useState({
    show: false,
    header: '',
    division: '',
  });

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    onClick: null,
  });

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/settings/emails`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        
        setEmails(data.data.items)

        setTimeout(() => {
          setLoading(false)
        }, 500);
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])
  
  const addTemplate = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/settings/emails`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: email
    })
      .then(response => {
        console.log('res :: ', response.data)
        setEmailModal({
          ...emailModal,
          show: false
        })
        const data = response.data
        setEmails(data.data.items)
        setErrors('')
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          const errors = error.response.data.errors
          setErrors(errors)
          return
        }
        setEmailModal({
          ...emailModal,
          show: false
        })
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  const updateTemplate = () => {
    axios({
      method: 'PUT',
      url: `${constants.API_URL}/settings/emails/${email.id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: email
    })
      .then(response => {
        console.log('res :: ', response.data)
        setEmailModal({
          ...emailModal,
          show: false
        })
        const data = response.data
        setEmails(data.data.items)
        setErrors('')
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          const errors = error.response.data.errors
          setErrors(errors)
          return
        }
        setEmailModal({
          ...emailModal,
          show: false
        })
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  const deleteTemplate = (templateId) => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/settings/emails/${templateId}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        console.log('res :: ', response.data)
        setDeleteModal({
          ...deleteModal,
          show: false
        })
        const data = response.data
        setEmails(data.data.items)
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        console.error('error :: ', error)
        setDeleteModal({
          ...deleteModal,
          show: false
        })
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  return (
    <>
      <CardsHeader name="Templates" parentName="Templates Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Email Templates</h1>
                    <p className="text-sm mb-0">Manage application email templates</p>
                  </Col>
                  <Col xs={4} className="text-right">
                    {hasPermission(permissions, 'create-email-templates') &&
                      <Button className="w-100" color="success" type="button" onClick={() => {
                        setEmail('')
                        setErrors('')
                        setEmailModal({
                          show: true,
                          header: 'Template details'.toUpperCase(),
                          division: 'create'
                        })
                      }}>Add new</Button>}
                  </Col>
                </Row>
              </CardHeader>			  
              <CardBody>
                <TableCustom 
                  loading={loading}
                  keyField={`id`}
                  searchField={false}
                  items={emails}
                  columns={[
                    {
                      dataField: "mail_type",
                      text: "Type",
                      sort: true
                    },
                    {
                      dataField: "",
                      text: "Action",
                      style: {
                        textAlign: 'right'
                      },
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  onClick={() => {
                                    setEmail(row)
                                    setErrors('')
                                    setEmailModal({
                                      show: true,
                                      header: 'Edite template details'.toUpperCase(),
                                      division: 'update'
                                    })
                                  }}
                                >
                                  Edit
                                </DropdownItem>
                                {deletee(permissions, row) && 
                                <DropdownItem
                                  onClick={() => setDeleteModal({
                                    show: true,
                                    header: 'Delete template',
                                    onClick: () => deleteTemplate(row.id)
                                  })}
                                >
                                  Delete
                                </DropdownItem>}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* Add/Edit Template Modal */}
        <Modal
          isOpen={emailModal.show}
          toggle={() => setEmailModal({
            show: false
          })}
          size="lg"
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{emailModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setEmailModal({
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <InputCustom 
              label={`Type`}
              value={email && email.mail_type? email.mail_type: ''}
              onChange={e => setEmail({
                ...email,
                mail_type: e.target.value,
              })}
              invalid={errors && errors.mail_type? true: false}
              errorMessage={errors.mail_type}
            />
            <InputCustom 
              label={`Subject`}
              value={email && email.subject? email.subject: ''}
              onChange={e => setEmail({
                ...email,
                subject: e.target.value,
              })}
              invalid={errors && errors.subject? true: false}
              errorMessage={errors.subject}
            />
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="example3cols2Input"
              >
                Description
              </label>
              <Editor
                value={email && email.content ? email.content : ''}
                apiKey={constants.TINYMCE_KEY}
                init={{
                  height: 300,
                  menubar: true,
                  plugins: ['code', 'preview', 'pagebreak', 'image'],
                  toolbar:
                    'undo redo | formatselect | bold italic | \
                                    alignleft aligncenter alignright alignjustify | \
                                    outdent indent'
                }}
                onEditorChange={(content, editor) => setEmail({
                  ...email,
                  content: content
                })}
              />
              <div className="invalid-feedback" style={{display: errors && errors.content? 'block': 'none'}}>
                {errors.content}
              </div>
            </FormGroup>
            <InputToggle 
              label={`Is active`}
              checked={email && email.is_active == 1? true: false}
              onChange={() => setEmail({
                ...email,
                is_active: email.is_active == 1? 0: 1
              })}
              invalid={errors && errors.is_active? true: false}
              errorMessage={errors.is_active}
            />
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => emailModal.division == 'create'? addTemplate(): updateTemplate()}
            >
              {`Save`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setEmailModal({
                show: false
              })}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
        {/* Delete template */}
        <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            ...deleteModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {`Are you sure you want to delete this template?`}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
			        {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}