import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import Select from 'react-select'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import {
  InputArraySelect,
} from 'views/pages/components/InputSelect'
import InputToggle from 'views/pages/components/InputToggle'
import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  FormGroup
} from 'reactstrap'

export default function JobCreate() {
  const notificationAlert = useRef(null)
  const history = useHistory()

  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [regNos, setRegNos] = useState([])
  const [filter, setFilter] = useState('')
  const [vehicle, setVehicle] = useState('')
  const [models, setModels] = useState([])
  const [bodyShopJobs, setBodyShopJobs] = useState([])
  const [valetingJobs,setValetingJobs] = useState([])
  const [mechanicalJobs, setMechanicalJobs] = useState([])
  const [jobs, setJobs] = useState([])
  const [jobsCategories, setJobsCategories] = useState('')
  const [statusTypes, setStatusTypes] = useState('')
  const [pagination, setPagination] = useState('')
  const [jobTypes, setJobTypes] = useState([])

  const [job, setJob] = useState('')
  const [jobModal, setJobModal] = useState({
    show: false,
    header: '',
    division: ''
  })

  const [deleteJob, setDeleteJob] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null
  })

  const [errors, setErrors] = useState('')
  
  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/fleet-management/jobs/create`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data

        let _regNos = data.data.regNos;
        let _regNos_array = [];
        _regNos.map(_regNo => {
          return _regNos_array.push({
            ..._regNo,
            value: _regNo.id,
            label: _regNo.stock_no,
          })
        })
        setRegNos(_regNos_array)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const goList = () => {
    history.push({
      pathname: '/admin/vehicle-in-stock'
    })
  }

  const searchVehicle = () => {
    if (filter && filter.regNo) {
      fetchJobs(1)
    } else {
      notify('info', 'Information', 'Please select a reg no.')
    }
  }

  const fetchJobs = async (pageNumber) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/fleet-management/${filter.regNo}/vehicle-jobs`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: pageNumber
        }
      })
      console.log('response :: ', response.data)
      const data = response.data
      setVehicle(data.data.vehicle)
      setModels(data.data.models)
      setJobs(data.data.jobs.data)
      setPagination(data.data.jobs)
      setBodyShopJobs(data.data.bodyShopJobs)
      setValetingJobs(data.data.valetingJobs)
      setMechanicalJobs(data.data.mechanicalJobs)
      setJobsCategories(data.data.jobsCategories)
      let _status_types = data.data.statusTypes
      let _statusTypes = []
      _status_types.map((type, index) => {
        _statusTypes.push({
          id: index,
          name: type
        })
      })
      setStatusTypes(_statusTypes)
      setJobTypes(data.data.jobTypes)

      setTimeout(() => {
        setLoading(false)
      }, 500);
    } catch (error) {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
    }
  }

  const createJob = async () => {    
    try {
      const response = await axios({
        method: 'POST',
        url: `${constants.API_URL}/fleet-management/${filter.regNo}/vehicle-jobs`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          ...job,
          status: 0,
        },
      })
      console.log('response :: ', response.data)
      const data = response.data
      setJobs(data.data.jobs.data)
      setPagination(data.data.jobs)
      setBodyShopJobs(data.data.bodyShopJobs)
      setValetingJobs(data.data.valetingJobs)
      setMechanicalJobs(data.data.mechanicalJobs)

      notify('success', 'Success', data.message)

      setJobModal({
        ...jobModal,
        show: false
      })
    } catch (error) {
      console.error('error :: ', error)
      if (error.response && error.response.status === 422) {
        setErrors(error.response.data.errors)
        return;
      }
      setJobModal({
        ...jobModal,
        show: false
      })
      notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
    }
  }

  return (
    <>
      <CardsHeader name="Fleets" parentName="Job Management" currentName="Create" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Fleet jobs</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button className="w-100" color="success" type="button" onClick={() => goList()}>Back to list</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row>
                    <Col md={4} sm={12} xs={12}>
                      <FormGroup>
                        <label className="form-control-label">Reg No</label>
                        <Select
                          options={regNos}
                          value={filter && filter.regNo ? (regNos.find((element) => element.id == filter.regNo) || '') : ''}
                          onChange={value => setFilter({
                            ...filter,
                            regNo: value.id
                          })}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={12} xs={12} className="pt-3-4">
                      <Button className="w-100" color="success" style={{margin: 5, padding: '0.425rem 1.25rem', }} type="button" onClick={() => searchVehicle()}>Pull Info In</Button>
                    </Col>
                  </Row>
                </Container>
              </CardBody>
            </Card>
            {vehicle && 
            <Card>
              <CardHeader>
                <h1 className="title mb-0">General Vehicle Information</h1>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={4}>
                    <InputArraySelect 
                      label={`Vehicle model`}
                      value={vehicle && vehicle.vehicle_model_id ? vehicle.vehicle_model_id: ''}
                      options={models}
                      disabled={true}
                      onChange={(e) => setVehicle({
                        ...vehicle,
                        vehicle_model_id: e.target.value,
                      })}
                      valueKey={`id`}
                      labelKey={`name`}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={4}>
                    <InputCustom 
                      label={`Derivative`}
                      value={vehicle && vehicle.derivative ? vehicle.derivative: ''}
                      onChange={(e) => setVehicle({
                        ...vehicle,
                        derivative: e.target.value,
                      })}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <InputCustom 
                      label={`Colour`}
                      value={vehicle && vehicle.colour ? vehicle.colour: ''}
                      onChange={(e) => setVehicle({
                        ...vehicle,
                        colour: e.target.value,
                      })}
                    />
                  </Col>
                  <Col md={4}>
                    <InputCustom 
                      label={`Reg no`}
                      value={vehicle && vehicle.stock_no ? vehicle.stock_no: ''}
                      onChange={(e) => setVehicle({
                        ...vehicle,
                        stock_no: e.target.value,
                      })}
                    />
                  </Col>
                  <Col md={4}>
                    <InputCustom 
                      label={`Current mileage`}
                      value={vehicle && vehicle.current_mileage ? vehicle.current_mileage: ''}
                      onChange={(e) => setVehicle({
                        ...vehicle,
                        current_mileage: e.target.value,
                      })}
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardHeader>
                <h1 className="title mb-0">Vehicle Inspection</h1>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row>
                    <Col md={4} sm={12} xs={12}>
                      <InputToggle
                        label={`Need Service`}
                        checked={valetingJobs && valetingJobs.length > 0 ? true : false}
                        onChange={() => { }}
                      />
                    </Col>
                    <Col md={4} sm={12} xs={12}>
                      <InputToggle
                        label={`Need Bodywork`}
                        checked={bodyShopJobs && bodyShopJobs.length > 0 ? true : false}
                        onChange={() => { }}
                      />
                    </Col>
                    <Col md={4} sm={12} xs={12}>
                      <InputToggle
                        label={`Need Mechanical`}
                        checked={mechanicalJobs && mechanicalJobs.length > 0 ? true : false}
                        onChange={() => { }}
                      />
                    </Col>
                  </Row>
                </Container>
                <Row>
                  <Col xs={8}>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button className="w-100" color="success" type="button" onClick={() => {
                      setJob('')
                      setErrors('')
                      setJobModal({
                        ...jobModal,
                        show: true,
                        header: 'Create new job',
                        division: 'create'
                      })
                    }}>Add more work</Button>
                  </Col>
                </Row>
                <TableCustom 
                  loading={loading}
                  items={jobs}
                  pagination={pagination}
                  onChange={pageNumber => fetchJobs(pageNumber)}
                  columns={[
                    {
                      dataField: "name",
                      text: "Type of work",
                    },
                    {
                      dataField: "description",
                      text: "Work needed"
                    },
                    {
                      dataField: "eta_hours",
                      text: "ETA Hours"
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      formatter: (rowContent, row) => {
                        return statusTypes[row.status].name
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
            }
          </Col>
        </Row>
        <Modal
          isOpen={jobModal.show}
          toggle={() => setJobModal({
            ...jobModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{jobModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setJobModal({
                ...jobModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <InputArraySelect 
              label={`Type Of Work`}
              options={jobTypes}
              value={job && job.job_type_id? job.job_type_id: ''}
              onChange={e => setJob({
                ...job,
                job_type_id: e.target.value
              })}
              defaultOption={true}
              valueKey={`id`}
              labelKey={`name`}
              invalid={errors && errors.job_type_id? true: false}
              errorMessage={errors.job_type_id}
            />
            <InputCustom 
              type={`textarea`}
              rows={5}
              label={`Work Needed`}
              value={job && job.description? job.description: ''}
              onChange={e => setJob({
                ...job,
                description: e.target.value
              })}
              invalid={errors && errors.description? true: false}
              errorMessage={errors.description}
            />
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => createJob()}
            >
              {`Save`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setJobModal({
                ...jobModal,
                show: false
              })}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}