import React from 'react'

import {
  FormGroup
} from 'reactstrap'

export default function VerticalLabel({
  label,
  labelStyle = {
    fontSize: '16px',
    fontWeight: 400,
    borderBottom: '1px solid #c0c0c0',
    color: '#2d2d2d',
    lineHeight: '16px'
  },
  value,
  valueStyle = {
    fontWeight: '600',
    fontSize: '16px',
  },
  onClick = null,
  editable = false,
}) {
  return (
    <>
      <FormGroup>
        <label style={labelStyle}>{label}</label>
        <div
          style={{
            ...valueStyle,
            cursor: editable ? "pointer" : "",
            color: value ? "#414141" : "#B5B5B5",
            textDecorationLine: editable ? "underline" : "unset",
            textDecorationStyle: editable ? "dotted" : "unset",
          }}
          dangerouslySetInnerHTML={{
            __html: value ? value : editable ? `[ Editable ]` : ` `,
          }}
          onClick={onClick}
        ></div>
      </FormGroup>
    </>
  );
  
}