import React, { useRef, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import Cookies from 'js-cookie';
import axios from 'axios';
import * as constants from 'constants/index.js';
import moment from 'moment';

import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import { InputKeySelect } from 'views/pages/components/InputSelect';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  UncontrolledAlert,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

export default function Ezidebit() {
  const { id } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');
  
  const [deal, setDeal] = useState('');
  const [hasBeenGenerated, setHasBeenGenerated] = useState(true);
  const [isPaymentActive, setIsPaymentActive] = useState(true);
  const [payments, setPayments] = useState([]);
  const [minDate, setMinDate] = useState('');
  const [paymentDescriptors, setPaymentDescriptors] = useState('');
  const [paymentIntervals, setPaymentIntervals] = useState('');
  const [paymentProcessTypes, setPaymentProcessTypes] = useState('');
  const [paymentStatuses, setPaymentStatuses] = useState('');
  const [paymentStatusesGroup, setPaymentStatusesGroup] = useState('');
  const [totalCollected, setTotalCollected] = useState('');
  const [totalPayments, setTotalPayments] = useState('');
  const [isAbleToCreateDeals, setIsAbleToCreateDeals] = useState(false);
  const [upcomingPayments, setUpcomingPayments] = useState([]);

  const [paymentChangesHistory, setPaymentChangesHistory] = useState([]);
  const [preferences, setPreferences] = useState('');

  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState(false);

  const [saveButtonDisable, setSaveButtonDisable] = useState(true);
  const [confirmRescheduleModal, setConfirmRescheduleModal] = useState(false);

  const [flag, setFlag] = useState(false);

  const [errors, setErrors] = useState('');

  const [message, setMessage] = useState('');
  const [changeMinDate, setChangeMinDate] = useState('');
  const [viewChangeDetail, setViewChangeDetail] = useState(false);

  const [paymentDetailModal, setPaymentDetailModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState('');
  const [paymentStatusPaidOut, setPaymentStatusPaidOut] = useState('');
  const [paymentStatusFailed, setPaymentStatusFailed] = useState('');
  const [paymentStatusNew, setPaymentStatusNew] = useState('');
  const [paymentStatusCreated, setPaymentStatusCreated] = useState('');
  const [processTypeDirectDebit, setProcessTypeDirectDebit] = useState('');
  const [paymentEdit, setPaymentEdit] = useState('');

  const [paymentData, setPaymentData] = useState('');
  const [changePaymentModal, setChangePaymentModal] = useState({
    show: false,
  })

  const [savePaymentErrors, setSavePaymentErrors] = useState('');

  const [confirmDeleteModal, setConfirmDeleteModal] = useState({
    show: false
  })

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    let grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/deal/${id}/ezidebit/settings`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data        

        setDeal({
          ...data.data.deal,
          next_payment: data.data.deal.next_payment ? constants.formatDate(data.data.deal.next_payment) : '',
        });
        setHasBeenGenerated(data.data.hasBeenGenerated);
        setIsPaymentActive(data.data.isPaymentActive);
        setPayments(data.data.items);
        setMinDate(data.data.minDate);
        setPaymentDescriptors(data.data.paymentDescriptors);
        setPaymentIntervals(data.data.paymentIntervals);
        setPaymentProcessTypes(data.data.paymentProcessTypes);
        setPaymentStatuses(data.data.paymentStatuses);
        setPaymentStatusesGroup(data.data.paymentStatusesGroup);
        setTotalCollected(data.data.totalCollected);
        setTotalPayments(data.data.totalPayments);
        setIsAbleToCreateDeals(data.data.isAbleToCreateDeals);
        setUpcomingPayments(data.data.upcomingPayments);

        setTimeout(() => {
          setLoading(false);
          setDelay(true);
        }, 1000);
      } catch (error) {
        setLoading(false);
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const goDeal = () => {
    history.push({
      pathname: '/admin/deals/' + id + '/edit',
    })
  }

  useEffect(() => {
    if (flag) {
      let body = {
        payment_frequency: deal.payment_frequency,
        payment_frequency_interval: deal.payment_frequency_interval,
        monthly_payment: deal.monthly_payment,
        term: deal.term,
        interval: deal.interval,
        next_payment: deal.next_payment,
      }
      axios({
        method: 'POST',
        url: constants.API_URL + '/deal/' + id + '/edit-calculator',
        headers: {
          Authorization: 'Bearer ' + token
        },
        data: body,
      })
        .then(response => {
          if (response.status == 200) {
            setErrors('');
            let data = response.data;
            console.log('response data :: ', data);

            setMessage(data.data.message);
            setChangeMinDate(data.data.minDate);
            setDeal({
              ...deal,
              next_payment: constants.formatDate(data.data.nextPayment),
              last_payment: constants.formatDate(data.data.lastPayment)
            })

            setTimeout(() => {
              setViewChangeDetail(true);
              setConfirmRescheduleModal(true);
            }, 2000);
          }
        })
        .catch(error => {
          setErrors('');
          console.error('error :: ', error.response);
          if (error.response.status == 422) {
            setErrors(error.response.data.errors);
          }
          notify('warning', 'Warning', error.response.data.message? error.response.data.message: error.response.statusText);
        })
    }
  }, [
    deal.payment_frequency,
    deal.payment_frequency_interval,
    deal.term,
    deal.interval,
    deal.monthly_payment,
    deal.next_payment,
  ])

  useEffect(() => {
    if (deal.consent == 1) {
      setSaveButtonDisable(false)
    }
  }, [deal.consent])

  const savePayment = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/deal/' + id + '/save-reference',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: paymentEdit,
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setPaymentEdit('');
          setSelectedPayment('');
          setPaymentDetailModal(false);
          setPayments(data.data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status == 422) {
          let errors = error.response.data.errors;
          let err = '';
          Object.keys(errors).map(key => {
            err += errors[key];
          })
          setSavePaymentErrors(err);
        } else if (error.response && error.response.status == 406) {
          let errors = error.response.data.errors;
          let err = errors.message;
          setSavePaymentErrors(err);
        }
      }) 
  }

  const cancelPayment = (referenceId) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/cancel/' + referenceId,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setPaymentEdit('');
          setSelectedPayment('');
          setPaymentDetailModal(false);
          setPayments(data.data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status == 422) {
          let errors = error.response.data.errors;
          let err = '';
          Object.keys(errors).map(key => {
            err += errors[key];
          })
          setSavePaymentErrors(err);
        } else if (error.response && error.response.status == 406) {
          let errors = error.response.data.errors;
          let err = errors.message;
          setSavePaymentErrors(err);
        }
      }) 
  }

  const retryPayment = (referenceId) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/retry/' + referenceId,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setPaymentEdit('');
          setSelectedPayment('');
          setPaymentDetailModal(false);
          setPayments(data.data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status == 422) {
          let errors = error.response.data.errors;
          let err = '';
          Object.keys(errors).map(key => {
            err += errors[key];
          })
          setSavePaymentErrors(err);
        } else if (error.response && error.response.status == 406) {
          let errors = error.response.data.errors;
          let err = errors.message;
          setSavePaymentErrors(err);
        }
      }) 
  }

  const ezidebitPaymentSetup = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/deal/${id}/ezidebit/widget-setup`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: paymentData
      })
      console.log('response :: ', response.data)
      const data = response.data
      window.open(data?.data?.widgetUrl)
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const changePayment = async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${constants.API_URL}/deal/${id}/ezidebit/change`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: paymentData
      })
      console.log('response :: ', response.data)
      const data = response.data

      setUpcomingPayments(data.data.upcomingPayments)

      if (data.data.errorAmount === '' && data.data.errorDate === '') {
        notify('success', 'Success', 'Successfully updated')
        setChangePaymentModal({
          show: false
        })
      } else {
        notify('warning', 'Warning', data.data.errorAmount || data.data.errorDate)
        setChangePaymentModal({
          show: false
        })
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const deletePayment = async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${constants.API_URL}/deal/${id}/ezidebit/delete-payment`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: paymentData
      })
      console.log('response :: ', response.data)
      const data = response.data

      setUpcomingPayments(data.data.upcomingPayments)

      if (data.data.error === '') {
        notify('success', 'Success', 'Successfully deleted')
        setConfirmDeleteModal({
          show: false
        })
      } else {
        notify('warning', 'Warning', data.data.error)
        setChangePaymentModal({
          show: false
        })
      }

      setConfirmDeleteModal({
        show: false
      })
    } catch (error) {
      setConfirmDeleteModal({
        show: false
      })
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const editPayment = (item) => {    
    setChangePaymentModal({
      show: true,
    })
  }

  const viewPaymentDetails = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/deal/${id}/ezidebit/payer/view`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: paymentData
      })
      console.log('response :: ', response.data)
      const data = response.data
      window.open(data?.data?.actionUrl)
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const editPaymentDetails = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/deal/${id}/ezidebit/payer/edit`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: paymentData
      })
      console.log('response :: ', response.data)
      const data = response.data
      window.open(data?.data?.actionUrl)
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  return (
    <>
      <CardsHeader
        name="Deal"
        parentName="Payment management"
        currentName="List"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">{`All payments associated to current deal`}</h1>
                  </div>
                  <Button
                    className="h-50"
                    color="success"
                    type="button"
                    onClick={() => goDeal()}
                  >
                    Deal
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <Row className="py-3">
                  <Col md={3}>
                    <h2>Contract Interval</h2>
                  </Col>
                  <Col md={9}>
                    <h3 className="text-danger">
                      {deal.delivery_date
                        ? `${constants.formatDateShortGB(deal.delivery_date)} - 
                         ${constants.formatDateShortGB(
                           moment(deal.delivery_date).add(
                             deal.term,
                             deal.interval + "s"
                           )
                         )}`
                        : ""}
                    </h3>
                  </Col>
                </Row>
                <Row className="py-3">
                  <Col md={3}>
                    <small className="text-uppercase font-weight-bold">
                      Term
                    </small>
                  </Col>
                  <Col md={9}>
                    <h3 className="heading">
                      {deal.term &&
                        deal.interval &&
                        `${deal.term} ${deal.interval}s`}
                    </h3>
                  </Col>
                </Row>
                <Row className="py-3">
                  <Col md={3}>
                    <small className="text-uppercase font-weight-bold">
                      Payment Start Date
                    </small>
                  </Col>
                  <Col md={9}>
                    <h3 className="heading">
                      {deal.delivery_date &&
                        constants.formatDateShortGB(deal.delivery_date)}
                    </h3>
                  </Col>
                </Row>
              </CardBody>
              {deal && deal.deal_status === "delivered" && !hasBeenGenerated && (
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Button
                        className="w-100"
                        color="success"
                        type="button"
                        onClick={() => {
                          ezidebitPaymentSetup();
                        }}
                      >
                        {`Ezidebit Payment Setup`}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              )}
              {hasBeenGenerated && (
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Button
                        className="w-100"
                        color="success"
                        type="button"
                        onClick={() => {
                          viewPaymentDetails()
                        }}
                      >
                        {`View Payment Details`}
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={12}>
                      <Button
                        className="w-100"
                        color="secondary"
                        type="button"
                        onClick={() => {
                          editPaymentDetails()
                        }}
                      >
                        {`Edit Payment Details`}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              )}
              {isPaymentActive && hasBeenGenerated && (
                <>
                  <CardBody className="py-0">
                    <Row>
                      <Col md={3} sm={4}>
                        <h3>Total Collected</h3>
                      </Col>
                      <Col md={9} sm={8}>
                        <h4>
                          {constants.CURRENCY} {totalCollected}
                        </h4>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardBody>
                    <Row>
                      <Col md={12} className="text-center">
                        <h1>{`Collected payments`}</h1>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardBody>
                    <TableCustom
                      loading={loading}
                      delay={delay}
                      items={payments}
                      keyField={`payment_no`}
                      searchField={false}
                      columns={[
                        {
                          dataField: "payment_no",
                          text: "#",
                          sort: true,
                        },
                        {
                          dataField: "amount",
                          text: "Amount",
                          sort: true,
                        },
                        {
                          dataField: "charge_date",
                          text: "Charge At",
                          sort: true,
                          formatter: (rowContent, row) => {
                            return row.charge_date
                              ? constants.formatDateShortGB(row.charge_date)
                              : constants.formatDateShortGB(row.scheduled_at);
                          },
                        },
                        {
                          dataField: "payment_status",
                          text: "Payment status",
                          sort: true,
                          formatter: (rowContent, row) => {
                            return row.payment_status
                              ? paymentStatuses[row.payment_status]
                              : "-";
                          },
                        },
                        {
                          dataField: "process_type",
                          text: "Process type",
                          sort: true,
                          formatter: (rowContent, row) => {
                            return row.process_type
                              ? paymentProcessTypes[row.process_type]
                              : "-";
                          },
                        },
                      ]}
                    />
                  </CardBody>
                  <CardBody className="text-center">
                    <h1 className="mb-0">{`Upcoming payments`}</h1>
                    <h4 className="mb-0">{`Ezidebit scheduled payments`}</h4>
                  </CardBody>
                  <CardBody>
                    <TableCustom
                      loading={loading}
                      delay={delay}
                      items={upcomingPayments}
                      keyField={`id`}
                      searchField={false}
                      columns={[
                        {
                          dataField: "PaymentDate",
                          text: "Scheduled At",
                          sort: true,
                          formatter: (rowContent, row) => {
                            return row.PaymentDate
                              ? constants.formatDate(row.PaymentDate)
                              : "";
                          },
                        },
                        {
                          dataField: "PaymentAmount",
                          text: "Amount",
                          sort: true,
                        },
                        {
                          dataField: "",
                          text: "Action",
                          formatter: (rowContent, row) => {
                            return (
                              <>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    color=""
                                    role="button"
                                    size="sm"
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={(e) => {
                                        editPayment(row);
                                        setPaymentData({
                                          amount:
                                            row && row.PaymentAmount
                                              ? row.PaymentAmount
                                              : "",
                                          scheduled_at:
                                            row && row.PaymentDate
                                              ? constants.formatDate(
                                                  row.PaymentDate
                                                )
                                              : "",
                                          original_charge_date:
                                            row && row.PaymentDate
                                              ? constants.formatDate(
                                                  row.PaymentDate
                                                )
                                              : "",
                                          original_amount:
                                            row && row.PaymentAmount
                                              ? row.PaymentAmount
                                              : "",
                                        });
                                      }}
                                    >
                                      Edit
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={(e) => {
                                        setPaymentData({
                                          amount:
                                            row && row.PaymentAmount
                                              ? row.PaymentAmount
                                              : "",
                                          scheduled_at:
                                            row && row.PaymentDate
                                              ? constants.formatDate(
                                                  row.PaymentDate
                                                )
                                              : "",
                                          original_charge_date:
                                            row && row.PaymentDate
                                              ? constants.formatDate(
                                                  row.PaymentDate
                                                )
                                              : "",
                                          original_amount:
                                            row && row.PaymentAmount
                                              ? row.PaymentAmount
                                              : "",
                                        });
                                        setConfirmDeleteModal({
                                          show: true,
                                        });
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </>
                            );
                          },
                        },
                      ]}
                    />
                  </CardBody>
                </>
              )}
              {!isPaymentActive && (
                <CardBody className="py-0">
                  <Row>
                    <Col md={12}>
                      <div className="alert alert-danger alert-dismissible">
                        {`Warning`}!{" "}
                        {`Payments can not be generated until payment frequency and delivery date are both set`}
                        !
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              )}
              {!hasBeenGenerated && (
                <CardBody className="py-0">
                  <Row>
                    <Col md={12}>
                      <div className="alert alert-danger alert-dismissible">
                        {`Payments for this deal are not generated yet`}!
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={changePaymentModal.show}
          toggle={() =>
            setChangePaymentModal({
              ...changePaymentModal,
              show: false,
            })
          }
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{`Edit payment data`}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() =>
                setChangePaymentModal({
                  ...changePaymentModal,
                  show: false,
                })
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <InputCustom
              type={`number`}
              label={`Amount`}
              value={
                paymentData && paymentData.amount ? paymentData.amount : ""
              }
              onChange={(e) =>
                setPaymentData({
                  ...paymentData,
                  amount: e.target.value,
                })
              }
            />
            <InputCustom
              type={`date`}
              label={`Charge At`}
              value={
                paymentData && paymentData.scheduled_at
                  ? paymentData.scheduled_at
                  : ""
              }
              onChange={(e) =>
                setPaymentData({
                  ...paymentData,
                  scheduled_at: e.target.value,
                })
              }
            />
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => changePayment()}
            >
              {`Save`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() =>
                setChangePaymentModal({
                  ...changePaymentModal,
                  show: false,
                })
              }
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={confirmDeleteModal.show}
          toggle={() =>
            setConfirmDeleteModal({
              ...confirmDeleteModal,
              show: false,
            })
          }
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{`Delete payment data`}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() =>
                setConfirmDeleteModal({
                  ...confirmDeleteModal,
                  show: false,
                })
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {`Are you sure you want to delete this payment data?`}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={() => deletePayment()}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() =>
                setConfirmDeleteModal({
                  ...confirmDeleteModal,
                  show: false,
                })
              }
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={confirmRescheduleModal}
          toggle={() => setConfirmRescheduleModal(false)}
          className="modal-dialog-centered"
        >
          <div className="modal-body my-2">
            <h1 className="text-center my-5">{`Payment Schedule Change`}</h1>
            <h4 className="text-center text-info">
              Check the table below to see how are the changes applying in real
              time
            </h4>
          </div>
          <div className="text-center my-4">
            <Button
              className="w-25"
              color="primary"
              type="button"
              onClick={() => {
                setConfirmRescheduleModal(false);
              }}
            >
              {`OK!`}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={paymentDetailModal}
          toggle={() => setPaymentDetailModal(false)}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h2>{`Payment reference editor`}</h2>
          </div>
          <div className="modal-body">
            <h1 className="my-1">{`Notes`}</h1>
            <ul className="list-group mt-3 mb-5">
              <li className="list-group-item">
                Past payments can't be edited for direct debit processing
              </li>
              <li className="list-group-item">
                Direct debit payments are automatically processed
              </li>
              <li className="list-group-item">
                Cash/Card are automatically set as paid
              </li>
            </ul>
            {savePaymentErrors && (
              <UncontrolledAlert color="warning" fade={false}>
                <span className="alert-inner--text">
                  <strong>Warning!</strong> {savePaymentErrors}
                </span>
              </UncontrolledAlert>
            )}
            {selectedPayment &&
              selectedPayment.reference &&
              selectedPayment.reference.payment_status ==
                paymentStatusPaidOut && (
                <Row>
                  <Col sm={12}>
                    <h3 className="text-info heading mb-0">{`Payment successfully collected!`}</h3>
                  </Col>
                </Row>
              )}
            {selectedPayment &&
              selectedPayment.reference &&
              selectedPayment.reference.process_type ==
                processTypeDirectDebit && (
                <Row>
                  <Col sm={3}>
                    <small className="text-uppercase font-weight-bold">
                      Payment Reference
                    </small>
                  </Col>
                  <Col sm={9}>
                    <h3 className="heading mb-0">
                      {selectedPayment.reference.payment_gateway_reference_id}
                    </h3>
                  </Col>
                </Row>
              )}
            {selectedPayment &&
              selectedPayment.reference &&
              selectedPayment.reference.payment_status ==
                paymentStatusFailed && (
                <Row>
                  <Col sm={12}>
                    <h3 className="text-danger heading mb-0">{`Payment failed! You can take future actions by retrying the payment!`}</h3>
                  </Col>
                </Row>
              )}
            <InputCustom
              type={`date`}
              label={`Charge At`}
              value={
                paymentEdit && paymentEdit.scheduled_at
                  ? paymentEdit.scheduled_at
                  : ""
              }
              onChange={(e) =>
                setPaymentEdit({
                  ...paymentEdit,
                  scheduled_at: e.target.value,
                })
              }
            />
            <InputCustom
              type={`number`}
              label={`Amount`}
              value={
                paymentEdit && paymentEdit.amount ? paymentEdit.amount : ""
              }
              onChange={(e) =>
                setPaymentEdit({
                  ...paymentEdit,
                  amount: e.target.value,
                })
              }
            />
            <InputKeySelect
              label={`Process type`}
              value={
                paymentEdit && paymentEdit.process_type
                  ? paymentEdit.process_type
                  : ""
              }
              options={paymentProcessTypes}
              onChange={(e) =>
                setPaymentEdit({
                  ...paymentEdit,
                  process_type: e.target.value,
                })
              }
              defaultOption={true}
            />
            {selectedPayment.scheduled_at >= moment().format() &&
              (!selectedPayment.reference ||
                (selectedPayment.reference &&
                  selectedPayment.reference.process_type ==
                    processTypeDirectDebit &&
                  selectedPayment.reference.payment_status ==
                    paymentStatusNew)) && (
                <FormGroup>
                  <label className="form-control-label">{`Collect payment now (only for direct debit payments)`}</label>
                  <div>
                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        checked={paymentEdit.collect_now == 1 ? true : false}
                        onChange={() =>
                          setPaymentEdit({
                            ...paymentEdit,
                            collect_now: paymentEdit.collect_now == 1 ? 0 : 1,
                          })
                        }
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                      />
                    </label>
                  </div>
                </FormGroup>
              )}
          </div>
          <div className="modal-footer mb-4">
            {selectedPayment.reference &&
              selectedPayment.reference.process_type ===
                processTypeDirectDebit &&
              selectedPayment.reference.payment_status ==
                paymentStatusCreated && (
                <Button
                  className="new-event--add"
                  color="primary"
                  type="button"
                  onClick={() => cancelPayment(selectedPayment.reference.id)}
                >
                  {`Cancel Payment`}
                </Button>
              )}
            {selectedPayment.reference &&
              selectedPayment.reference.process_type ===
                processTypeDirectDebit &&
              selectedPayment.reference.payment_status ==
                paymentStatusFailed && (
                <Button
                  className="new-event--add"
                  color="primary"
                  type="button"
                  onClick={() => retryPayment(selectedPayment.reference.id)}
                >
                  {`Retry`}
                </Button>
              )}
            {!(
              selectedPayment.reference &&
              selectedPayment.reference.process_type ===
                processTypeDirectDebit &&
              selectedPayment.reference.payment_status != paymentStatusNew
            ) && (
              <Button
                className="new-event--add"
                color="primary"
                type="button"
                onClick={() => savePayment()}
              >
                {`Save`}
              </Button>
            )}
            <Button
              className="ml-auto"
              color="danger"
              type="button"
              onClick={() => {
                setSavePaymentErrors("");
                setPaymentDetailModal(false);
              }}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  );
}