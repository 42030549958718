import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import NotificationAlert from "react-notification-alert";
import Cookies from 'js-cookie';
import axios from 'axios';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import {
  InputArraySelect
} from 'views/pages/components/InputSelect';
import InputToggle from 'views/pages/components/InputToggle';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";

export default function CustomerCreate() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');
  const [businessTypes, setBusinessTypes] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [counties, setCounties] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [regions, setRegions] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [errors, setErrors] = useState('');

  const [customer, setCustomer] = useState({
    is_active: 0,
  });

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
      const grs_token = Cookies.get('grs_token')
      setToken(grs_token)

      const source = axios.CancelToken.source()
  
      const fetchData = async () => {
        try {
          const response = await axios({
            method: 'GET',
            url: `${constants.API_URL}/customer/create`,
            headers: {
              Authorization: `Bearer ${grs_token}`,
            },
            cancelToken: source.token,
          })
          console.log('response :: ', response.data)
          const data = response.data
          
          setBusinessTypes(data.data.businessTypes)
          setCompanyTypes(data.data.companyTypes)
          setCounties(data.data.counties.data)
          setLeadSources(data.data.leadSources)
          setRegions(data.data.regions)
          setCompanies(data.data.companies)
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log('Request canceled :: ', error)
          } else {
            console.error('error :: ', error)
            notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
          }
        }
      }
  
      fetchData()
  
      return () => {
        source.cancel()
      }
  }, [])

  const addCustomer = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/customer`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...customer,
        company_name: customer.company && customer.company.name? customer.company.name: null,
        business_phone_number: customer.company && customer.company.phone_number? customer.company.phone_number: null,
        cb_company_name: customer.cb_company && customer.cb_company.name? customer.cb_company.name: null,
        cb_business_phone_number: customer.cb_company && customer.cb_company.phone_number? customer.cb_company.phone_number: null
      }
    })
      .then(response => {
        if (response.status == 200) {
          const data = response.data;
          console.log('response data :: ', data);
          setErrors('');
          notify('success', 'Success', data.message);
          setTimeout(() => {
            history.push({
              pathname: '/admin/customer/' + data.data.id + '/edit',
              state: {
                customer: data.data
              }
            })
          }, 1500);
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error);
        if (error.response.status == 422) {
          const errors = error.response.data.errors;
          setErrors(errors);
          return
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const goList = () => {
    history.push('/admin/customer');
  }

  return (
    <>
      <CardsHeader name="Customers" parentName="Customer Management" currentName="Create customer" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">Add Customer</h1>
                  </div>
                  <Button className="h-50" color="success" type="button" onClick={() => goList()}>Back to list</Button>
                </div>
              </CardHeader>
            </Card>
            <Row>
              <Col xl={6}>
                <Card>
                  <CardHeader>
                    <h1 className="title mb-0">Customer Management</h1>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          label={`First Name`}
                          value={customer && customer.first_name? customer.first_name: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            first_name: e.target.value
                          })}
                          invalid={errors && errors.first_name? true: false}
                          errorMessage={errors.first_name}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          label={`Last Name`}
                          value={customer && customer.full_name? customer.full_name: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            full_name: e.target.value
                          })}
                          invalid={errors && errors.full_name? true: false}
                          errorMessage={errors.full_name}
                        />
                      </Col>
                    </Row>
                    <InputCustom 
                      type={`email`}
                      label={`Email Address`}
                      value={customer && customer.email? customer.email: ''}
                      onChange={e => setCustomer({
                        ...customer,
                        email: e.target.value
                      })}
                      invalid={errors && errors.email? true: false}
                      errorMessage={errors.email}
                    />
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          label={`Phone Number`}
                          value={customer && customer.mobile_number? customer.mobile_number: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            mobile_number: e.target.value
                          })}
                          invalid={errors && errors.mobile_number? true: false}
                          errorMessage={errors.mobile_number}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          label={`Alternate Number`}
                          value={customer && customer.land_line_number? customer.land_line_number: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            land_line_number: e.target.value
                          })}
                          invalid={errors && errors.land_line_number? true: false}
                          errorMessage={errors.land_line_number}
                        />
                      </Col>
                    </Row>
                    <InputCustom 
                      label={`Address`}
                      value={customer && customer.address? customer.address: ''}
                      onChange={e => setCustomer({
                        ...customer,
                        address: e.target.value
                      })}
                      invalid={errors && errors.address? true: false}
                      errorMessage={errors.address}
                    />
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          label={`City`}
                          value={customer && customer.city? customer.city: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            city: e.target.value
                          })}
                          invalid={errors && errors.city? true: false}
                          errorMessage={errors.city}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputArraySelect 
                          label={`State`}
                          options={regions}
                          value={customer && customer.region_id? customer.region_id: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            region_id: e.target.value
                          })}
                          valueKey={`id`}
                          labelKey={`name`}
                          defaultOption={true}
                          invalid={errors && errors.region_id? true: false}
                          errorMessage={errors.region_id}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          label={`Postal Code`}
                          value={customer && customer.postal_code? customer.postal_code: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            postal_code: e.target.value
                          })}
                          invalid={errors && errors.postal_code? true: false}
                          errorMessage={errors.postal_code}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          type={`date`}
                          label={`Date of Birth`}
                          value={customer && customer.dob? customer.dob: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            dob: e.target.value
                          })}
                          invalid={errors && errors.dob? true: false}
                          errorMessage={errors.dob}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom 
                          label={`D/L Number`}
                          value={customer && customer.driver_license_number? customer.driver_license_number: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            driver_license_number: e.target.value
                          })}
                          invalid={errors && errors.driver_license_number? true: false}
                          errorMessage={errors.driver_license_number}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputArraySelect 
                          label={`Lead Source`}
                          options={leadSources}
                          value={customer && customer.lead_source_id? customer.lead_source_id: ''}
                          onChange={e => setCustomer({
                            ...customer,
                            lead_source_id: e.target.value
                          })}
                          valueKey={`id`}
                          labelKey={`name`}
                          defaultOption={true}
                          invalid={errors && errors.lead_source_id? true: false}
                          errorMessage={errors.lead_source_id}
                        />
                      </Col>
                    </Row>
                    <InputToggle 
                      label={`Is Active`}
                      checked={customer && customer.is_active === 1? true: false}
                      onChange={() => setCustomer({
                        ...customer,
                        is_active: customer.is_active === 1? 0: 1
                      })}
                      invalid={errors && errors.is_active? true: false}
                      errorMessage={errors.is_active}
                    />
                  </CardBody>
                  <CardHeader>
                    <h1 className="title mb-0">Company Information</h1>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <p className="col-sm-12 text-center">Add a new company</p>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Company Name`}
                          value={customer && customer.company && customer.company.name ? customer.company.name : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            company: {
                              ...customer.company,
                              name: e.target.value
                            }
                          })}
                          invalid={errors && errors.company_name ? true : false}
                          errorMessage={errors.company_name}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Phone Number`}
                          value={customer && customer.company && customer.company.phone_number ? customer.company.phone_number : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            company: {
                              ...customer.company,
                              phone_number: e.target.value
                            }
                          })}
                          invalid={errors && errors.business_phone_number ? true : false}
                          errorMessage={errors.business_phone_number}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <p className="col-sm-12 text-center">Or select an existent company</p>
                      <Col md={12}>
                        <InputArraySelect
                          label={`Company`}
                          options={companies}
                          value={customer && customer.company_id ? customer.company_id : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            company_id: e.target.value
                          })}
                          valueKey={`id`}
                          labelKey={`name`}
                          defaultOption={true}
                          invalid={errors && errors.company_id ? true : false}
                          errorMessage={errors.company_id}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  <div className="d-flex flex-row justify-content-center mb-4">
                    <Button className="w-50" color="success" onClick={() => addCustomer()}>Save</Button>
                  </div>
                </Card>
              </Col>
              <Col xl={6}>
                <Card>
                  <CardHeader>
                    <h1 className="title mb-0">Co-Borrower Details</h1>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Full Name`}
                          value={customer && customer.cb_full_name ? customer.cb_full_name : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_full_name: e.target.value
                          })}
                          invalid={errors && errors.cb_full_name ? true : false}
                          errorMessage={errors.cb_full_name}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom
                          type={`date`}
                          label={`Date of Birth`}
                          value={customer && customer.cb_dob ? customer.cb_dob : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_dob: e.target.value
                          })}
                          invalid={errors && errors.cb_dob ? true : false}
                          errorMessage={errors.cb_dob}
                        />
                      </Col>
                    </Row>
                    <InputCustom 
                      type={`email`}
                      label={`Email Address`}
                      value={customer && customer.cb_email ? customer.cb_email : ''}
                      onChange={e => setCustomer({
                        ...customer,
                        cb_email: e.target.value
                      })}
                      invalid={errors && errors.cb_email ? true : false}
                      errorMessage={errors.cb_email}
                    />
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Phone Number`}
                          value={customer && customer.cb_mobile_number ? customer.cb_mobile_number : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_mobile_number: e.target.value
                          })}
                          invalid={errors && errors.cb_mobile_number ? true : false}
                          errorMessage={errors.cb_mobile_number}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Land Line Number`}
                          value={customer && customer.cb_land_line_number ? customer.cb_land_line_number : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_land_line_number: e.target.value
                          })}
                          invalid={errors && errors.cb_land_line_number ? true : false}
                          errorMessage={errors.cb_land_line_number}
                        />
                      </Col>
                    </Row>
                    <InputCustom
                      label={`Address`}
                      value={customer && customer.cb_address ? customer.cb_address : ''}
                      onChange={e => setCustomer({
                        ...customer,
                        cb_address: e.target.value
                      })}
                      invalid={errors && errors.cb_address ? true : false}
                      errorMessage={errors.cb_address}
                    />
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`City`}
                          value={customer && customer.cb_city ? customer.cb_city : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_city: e.target.value
                          })}
                          invalid={errors && errors.cb_city ? true : false}
                          errorMessage={errors.cb_city}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputArraySelect
                          label={`State`}
                          options={regions}
                          value={customer && customer.cb_region_id ? customer.cb_region_id : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_region_id: e.target.value
                          })}
                          valueKey={`id`}
                          labelKey={`name`}
                          defaultOption={true}
                          invalid={errors && errors.cb_region_id ? true : false}
                          errorMessage={errors.cb_region_id}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Postal Code`}
                          value={customer && customer.cb_postal_code ? customer.cb_postal_code : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_postal_code: e.target.value
                          })}
                          invalid={errors && errors.cb_postal_code ? true : false}
                          errorMessage={errors.cb_postal_code}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`D/L Number`}
                          value={customer && customer.cb_driver_license_number ? customer.cb_driver_license_number : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_driver_license_number: e.target.value
                          })}
                          invalid={errors && errors.cb_driver_license_number ? true : false}
                          errorMessage={errors.cb_driver_license_number}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  <CardHeader>
                    <h1 className="title mb-0">Company information</h1>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <p className="col-sm-12 text-center">Add a new company</p>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Company Name`}
                          value={customer && customer.cb_company && customer.cb_company.name ? customer.cb_company.name : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_company: {
                              ...customer.cb_company,
                              name: e.target.value
                            }
                          })}
                          invalid={errors && errors.cb_company_name ? true : false}
                          errorMessage={errors.cb_company_name}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Phone Number`}
                          value={customer && customer.cb_company && customer.cb_company.phone_number ? customer.cb_company.phone_number : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_company: {
                              ...customer.cb_company,
                              phone_number: e.target.value
                            }
                          })}
                          invalid={errors && errors.cb_business_phone_number ? true : false}
                          errorMessage={errors.cb_business_phone_number}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <p className="col-sm-12 text-center">Or select an existent company</p>
                      <Col md={12}>
                        <InputArraySelect
                          label={`Company`}
                          options={companies}
                          value={customer && customer.cb_company_id ? customer.cb_company_id : ''}
                          onChange={e => setCustomer({
                            ...customer,
                            cb_company_id: e.target.value
                          })}
                          valueKey={`id`}
                          labelKey={`name`}
                          defaultOption={true}
                          invalid={errors && errors.cb_company_id ? true : false}
                          errorMessage={errors.cb_company_id}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}