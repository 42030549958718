import React from 'react';
import { useHistory } from 'react-router-dom';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';

export default function Personalisation() {
  const history = useHistory();

  const gotoSettings = () => {
    history.push({
      pathname: `/admin/settings/personalisation/automated-reminders`
    })
  }

  return (
    <>
      <CardsHeader name="Settings" parentName="News Management" currentName="List" />
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="4">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">Automated Reminders</h1>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <p className="card-text mb-4">Set up various roles groups to be notified about automated reminders.</p>
                <Button className="w-100" color="success" type="button" onClick={() => gotoSettings()}>Go to settings</Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}