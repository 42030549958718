import React, { useEffect, useState, useRef } from 'react';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Alert,
  Input
} from 'reactstrap';

export default function Modules() {
  const notificationAlert = useRef(null);

  const [token, setToken] = useState('');
  const [modules, setModules] = useState('');
  const [paypaPlane, setPaypaPlane] = useState('');
  const [ezidebit, setEzidebit] = useState('');
  
  const [paymentErrors, setPaymentErrors] = useState('');
  const [paypaErrors, setPaypaErrors] = useState('');
  const [ezidebitErrors, setEzidebitErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/dealer/profile`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        
        setModules(data.data.modules)
        setPaypaPlane(data.data.paypaPlane)
        setEzidebit({
          ...data.data.ezidebit,
          origin_digital_key: '',
          origin_public_key: ''
        })
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const actionRequire = (url) => {
    window.open(url, '_blank');
  }

  const saveConfiguration = () => {
    let formData = new FormData();
    Object.keys(modules).map(key => {
      if (modules[key]) {
        Object.keys(modules[key]).map(key2 => {
          if (modules[key][key2] && modules[key][key2].type != 'redirect_access') {
            formData.append(`${key}[${key2}]`, modules[key][key2].value? modules[key][key2].value: '')
          }
        })
      }
    })
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    axios({
      method: 'POST',
      url: `${constants.API_URL}/dealer/profile-module-configuration`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: formData
    })
      .then(response => {
        if (response.status == 200) {
          console.log('response data :: ', response.data)
          const data = response.data;
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setPaymentErrors('');
        console.error('error :: ', error.response);
        if (error.response && error.response.data.errors) {
          const errors = error.response.data.errors;
          setPaymentErrors(errors);
          return
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  const savePaypaPlaneConfiguration = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/dealer/profile-paypa-configuration`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: paypaPlane
    })
      .then(response => {
        if (response.status == 200) {
          console.log('response data :: ', response.data)
          const data = response.data;
          notify('success', 'Success', data.message);
          setPaypaErrors('');
        }
      })
      .catch(error => {
        setPaypaErrors('');
        console.error('error :: ', error.response);
        if (error.response && error.response.data.errors) {
          const errors = error.response.data.errors;
          setPaypaErrors(errors);
          return
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  const saveEzidebitConfiguration = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/dealer/profile-ezidebit-configuration`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: ezidebit
    })
      .then(response => {
        if (response.status == 200) {
          console.log('response data :: ', response.data)
          const data = response.data;
          notify('success', 'Success', data.message);
          setEzidebitErrors('');
        }
      })
      .catch(error => {
        setEzidebitErrors('');
        console.error('error :: ', error.response);
        if (error.response && error.response.data.errors) {
          const errors = error.response.data.errors;
          setEzidebitErrors(errors);
          return
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  return (
    <>
      <CardsHeader
        name="Dealer Admin"
        parentName=""
        currentName=""
        breadcrumb={false}
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">Modules</h1>
                    <p className="text-small mb-0">
                      You can create and edit your company's profile. This is
                      informations are also going to be used in the contracts.
                    </p>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                {modules &&
                  Object.keys(modules).map((key) => {
                    return (
                      <Row key={key} className="mb-3 border-bottom">
                        <Col md={12}>
                          <Row className="align-items-center">
                            <Col md={12}>
                              <h2 className="mb-4">{`${key
                                .charAt(0)
                                .toUpperCase()}${key.slice(1)}`}</h2>
                            </Col>
                          </Row>
                          {modules[key] &&
                          Object.keys(modules[key]).length > 0 ? (
                            Object.keys(modules[key]).map((key2) => {
                              if (
                                modules[key][key2].type == "redirect_access"
                              ) {
                                return (
                                  <FormGroup key={key2}>
                                    <label className="form-control-label">
                                      {modules[key][key2].label}
                                    </label>
                                    {modules[key][key2].value.value === true ? (
                                      <Alert color="primary">
                                        <strong>Already have access!</strong>
                                        {modules[key][key2].optional
                                          .afterCheck && (
                                          <strong>
                                            {
                                              modules[key][key2].optional
                                                .afterCheck
                                            }
                                            !
                                          </strong>
                                        )}
                                      </Alert>
                                    ) : (
                                      <div>
                                        <h4 className="text-dark">
                                          {modules[key][key2].value.message}
                                        </h4>
                                        <Button
                                          className="w-50 mt-4"
                                          color="success"
                                          type="button"
                                          onClick={() =>
                                            actionRequire(
                                              modules[key][key2].value
                                                .actionRequired
                                            )
                                          }
                                        >
                                          Click Here
                                        </Button>
                                      </div>
                                    )}
                                  </FormGroup>
                                );
                              } else {
                                return (
                                  <FormGroup key={key2}>
                                    <label className="form-control-label">
                                      {modules[key][key2].label}
                                    </label>
                                    <Input
                                      type="text"
                                      placeholder={modules[key][key2].label}
                                      value={
                                        modules[key][key2].value
                                          ? modules[key][key2].value
                                          : ""
                                      }
                                      onChange={(e) =>
                                        setModules({
                                          ...modules,
                                          [key]: {
                                            ...modules[key],
                                            [key2]: {
                                              ...modules[key][key2],
                                              value: e.target.value,
                                            },
                                          },
                                        })
                                      }
                                      invalid={
                                        paymentErrors &&
                                        paymentErrors[`${key}.${key2}`]
                                          ? true
                                          : false
                                      }
                                    />
                                    <div className="invalid-feedback">
                                      {paymentErrors[`${key}.${key2}`]}
                                    </div>
                                  </FormGroup>
                                );
                              }
                            })
                          ) : (
                            <h5 class="mb-1">{`No configuration required!`}</h5>
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                {modules && Object.keys(modules).length > 0 && (
                  <Button
                    className="w-100 mt-4"
                    color="success"
                    onClick={() => saveConfiguration()}
                  >
                    Save Configuration
                  </Button>
                )}
                {modules && Object.keys(modules).length == 0 && (
                  <h3 className="mb-0">{`You have no active module!`}</h3>
                )}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">Paypa Plane Integration</h1>
                    <p className="text-small mb-0">
                      Please copy you SITE KEY generated from the paypa plane
                      dashboard in order to automate the process of payment
                      creation and automatic interactaction.
                    </p>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <InputCustom
                      label={`API KEY`}
                      value={
                        paypaPlane && paypaPlane.api_key
                          ? paypaPlane.api_key
                          : ""
                      }
                      onChange={(e) =>
                        setPaypaPlane({
                          ...paypaPlane,
                          api_key: e.target.value,
                        })
                      }
                      invalid={
                        paypaErrors && paypaErrors.api_key ? true : false
                      }
                      errorMessage={paypaErrors.api_key}
                    />
                  </Col>
                  <Col md={12}>
                    <h1 className="title">{`Identifiers`}</h1>
                  </Col>
                  <Col md={12}>
                    <InputCustom
                      label={`Account ID`}
                      value={
                        paypaPlane && paypaPlane.account_id
                          ? paypaPlane.account_id
                          : ""
                      }
                      onChange={(e) =>
                        setPaypaPlane({
                          ...paypaPlane,
                          account_id: e.target.value,
                        })
                      }
                      invalid={
                        paypaErrors && paypaErrors.account_id ? true : false
                      }
                      errorMessage={paypaErrors.account_id}
                    />
                  </Col>
                  <Col md={12}>
                    <InputCustom
                      label={`Site ID`}
                      value={
                        paypaPlane && paypaPlane.site_id
                          ? paypaPlane.site_id
                          : ""
                      }
                      onChange={(e) =>
                        setPaypaPlane({
                          ...paypaPlane,
                          site_id: e.target.value,
                        })
                      }
                      invalid={
                        paypaErrors && paypaErrors.site_id ? true : false
                      }
                      errorMessage={paypaErrors.site_id}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Button
                      className="w-100"
                      color="success"
                      onClick={() => savePaypaPlaneConfiguration()}
                    >
                      {`Save PaypaPlane Configuration`}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">Ezidebit configuration</h1>
                    <p className="text-small mb-0">
                      In order to use ezidebit please paste your Digital Key
                      sent by Ezidebit.
                    </p>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <InputCustom
                      label={`Digital Key ${
                        ezidebit &&
                        ezidebit.digital_key &&
                        ezidebit.digital_key.length > 6
                          ? constants.strPad(
                              ezidebit.digital_key.substring(
                                ezidebit.digital_key.length - 6,
                                ezidebit.digital_key.length
                              ),
                              12,
                              "*",
                              "STR_PAD_LEFT"
                            )
                          : ezidebit.digital_key
                      }`}
                      value={
                        ezidebit.origin_digital_key === ""
                          ? ""
                          : ezidebit && ezidebit.digital_key
                          ? ezidebit.digital_key
                          : ""
                      }
                      onChange={(e) =>
                        setEzidebit({
                          ...ezidebit,
                          digital_key: e.target.value,
                          origin_digital_key: e.target.value
                        })
                      }
                      invalid={
                        ezidebitErrors && ezidebitErrors.digital_key
                          ? true
                          : false
                      }
                      errorMessage={ezidebitErrors.digital_key}
                    />
                  </Col>
                  <Col md={12}>
                    <InputCustom
                      label={`Public Key ${
                        ezidebit &&
                        ezidebit.public_key &&
                        ezidebit.public_key.length > 6
                          ? constants.strPad(
                              ezidebit.public_key.substring(
                                ezidebit.public_key.length - 6,
                                ezidebit.public_key.length
                              ),
                              12,
                              "*",
                              "STR_PAD_LEFT"
                            )
                          : ezidebit.public_key
                      }`}
                      value={
                        ezidebit.origin_public_key === ""
                          ? ""
                          : ezidebit && ezidebit.public_key
                          ? ezidebit.public_key
                          : ""
                      }
                      onChange={(e) =>
                        setEzidebit({
                          ...ezidebit,
                          public_key: e.target.value,
                          origin_public_key: e.target.value,
                        })
                      }
                      invalid={
                        ezidebitErrors && ezidebitErrors.public_key
                          ? true
                          : false
                      }
                      errorMessage={ezidebitErrors.public_key}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Button
                      className="w-100"
                      color="success"
                      onClick={() => saveEzidebitConfiguration()}
                    >
                      {`Save Ezidebit Configuration`}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}