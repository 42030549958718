import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import { useDropzone } from "react-dropzone";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';
import {
  hasPermission,
} from 'views/policies/VehiclePolicy';

import TableCustom from 'views/pages/components/TableCustom';
import InputCustom from 'views/pages/components/InputCustom';
import {
  InputKeySelect,
  InputArraySelect,
} from 'views/pages/components/InputSelect';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Input,
} from 'reactstrap';

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out"
};

const activeStyle = {
  borderColor: "#2196f3"
};

const acceptStyle = {
  borderColor: "#00e676"
};

const rejectStyle = {
  borderColor: "#ff1744"
};

export default function VehicleCost() {
  const { id } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();

  const permissions = useSelector(state => state.getState).permissions;
  
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState(false);
  const [costLoading, setCostLoading] = useState(true);
  const [costTypes, setCostTypes] = useState('');
  const [costs, setCosts] = useState([]);
  const [loaners, setLoaners] = useState([]);
  const [realisedBy, setRealisedBy] = useState('');
  const [vehicle, setVehicle] = useState('');
  const [vehicleProfit, setVehicleProfit] = useState('');
  const [availableDocumentsUploadTypes, setAvailableDocumentsUploadTypes] = useState('');
  const [cost, setCost] = useState({
    payment_realised_by: 'company',
    on_rental_to: null,
    notification: null,
  });

  const [confirmShow, setConfirmShow] = useState(false);
  const [selectedCostId, setSelectedCostId] = useState('');

  const [errors, setErrors] = useState('');

  /**
   * file upload
   */
  const [fileLists, setFileLists] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    let newFileTypes = fileTypes;
    acceptedFiles.map((item, index) => {
      newFileTypes[item.name] = "photo_id";
    })
    setFileTypes(newFileTypes);
    setFileLists(prev => [...prev, ...acceptedFiles]);
  })
  
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({onDrop});

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/vehicle/${id}/cost`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        
        setCostTypes(data.data.costTypes)
        setCosts(data.data.items.data)
        setLoaners(data.data.loaners)
        setRealisedBy(data.data.realisedBy)
        setVehicle(data.data.vehicle)
        setVehicleProfit(data.data.vehicleProfit)
        setAvailableDocumentsUploadTypes(data.data.availableDocumentsUploadTypes)

        setLoading(false)
        setTimeout(() => {
          setDelay(true)
          setCostLoading(false)
        }, 500);
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])
  
  const goList = () => {
    history.push({
      pathname: `/admin/vehicle/${id}/edit`
    })
  }

  const addCost = () => {
    let formData = new FormData();
    for (let index = 0; index < fileLists.length; index++) {
      const element = fileLists[index];
      formData.append(`file[${index}]`, element);
    }
    Object.keys(fileTypes).map(key => {
      formData.append(`type[${key}]`, fileTypes[key]);
    })

    Object.keys(cost).map(key => {
      formData.append(key, cost[key] == null ? '': cost[key])
    })

    axios({
      method: 'POST',
      url: `${constants.API_URL}/vehicle/${id}/cost`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: formData
    })
      .then(response => {
        setCostLoading(true)
        setDelay(false)
        setErrors('')
        setCost('')
        console.log('res :: ', response.data)
        let data = response.data
        setCosts(data.data.data)
        notify('success', 'Success', data.message)

        setTimeout(() => {
          setDelay(true)
          setCostLoading(false)
        }, 500);
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors)
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  const deleteCost = () => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/vehicle/${id}/cost/${selectedCostId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        setCostLoading(true)
        setDelay(false)
        setConfirmShow(false)
        console.log('res :: ', response.data)
        let data = response.data
        setCosts(data.data.data)
        notify('success', 'Success', data.message)

        setTimeout(() => {
          setDelay(true)
          setCostLoading(false)
        }, 500);
      })
      .catch(error => {
        setConfirmShow(false)
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  const setSendType = (e, index) => {
    let newFileTypes = fileTypes;
    let key = fileLists[index].name;
    newFileTypes[key] = e.target.value;
    setFileTypes(newFileTypes);
  };

  return (
    <>
      <CardsHeader name="Vehicles" parentName={`Vehicle - ${vehicle.vehicle_make && vehicle.vehicle_make.name}, ${vehicle.vehicle_model && vehicle.vehicle_model.name}, ${vehicle.stock_no}`} currentName="Cost" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl={7}>
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">New cost</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button className="w-100" color="success" type="button" onClick={() => goList()}>Back to vehicle</Button>
                  </Col>
                </Row>
              </CardHeader>			  
              <CardBody>
                <div className="d-flex justify-content-around mt-5">
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      className="custom-control-input"
                      id="customRadioInline1"
                      name="customRadioInline1"
                      type="radio"
                      checked={cost && cost.payment_realised_by == 'company'? true: false}
                      onChange={() => setCost({
                        ...cost,
                        payment_realised_by: 'company',
                      })}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline1"
                      onChange={() => setCost({
                        ...cost,
                        payment_realised_by: 'company',
                      })}
                    >
                      Cost Us
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      className="custom-control-input"
                      id="customRadioInline2"
                      name="customRadioInline1"
                      type="radio"
                      checked={cost && cost.payment_realised_by == 'customer'? true: false}
                      onChange={() => setCost({
                        ...cost,
                        payment_realised_by: 'customer',
                      })}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline2"
                      onChange={() => setCost({
                        ...cost,
                        payment_realised_by: 'customer',
                      })}
                    >
                      Cost Customer
                    </label>
                  </div>
                </div>
                <Row className="mt-3">
                  <Col md={6} sm={12}>
                    <InputArraySelect
                      label={`Rented to`}
                      value={cost && cost.on_rental_to ? cost.on_rental_to : ''}
                      options={loaners}
                      onChange={e => setCost({
                        ...cost,
                        on_rental_to: e.target.value,
                      })}
                      valueKey={`id`}
                      labelKey={`full_name`}
                      defaultOption={true}
                      invalid={errors && errors.on_rental_to ? true : false}
                      errorMessage={errors.on_rental_to}
                    />
                    <InputCustom 
                      type={`textarea`}
                      label={`Notes`}
                      rows={5}
                      value={cost && cost.notification? cost.notification: ''}
                      onChange={e => setCost({
                        ...cost,
                        notification: e.target.value,
                      })}
                      invalid={errors && errors.notification ? true : false}
                      errorMessage={errors.notification}
                    />
                    <InputCustom 
                      type={`date`}
                      label={`Date the payment had been placed`}
                      value={cost && cost.realised_at? cost.realised_at: ''}
                      onChange={e => setCost({
                        ...cost,
                        realised_at: e.target.value,
                      })}
                      invalid={errors && errors.realised_at ? true : false}
                      errorMessage={errors.realised_at}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <InputKeySelect 
                      label={`Cost type`}
                      value={cost && cost.cost_type? cost.cost_type: ''}
                      options={costTypes}
                      onChange={e => setCost({
                        ...cost,
                        cost_type: e.target.value,
                      })}
                      defaultOption={true}
                      invalid={errors && errors.cost_type ? true : false}
                      errorMessage={errors.cost_type}
                    />
                    {cost && cost.cost_type && cost.cost_type === 'fine' &&
                      <>
                        <InputCustom
                          label={`Fine from`}
                          value={cost && cost.fine_from ? cost.fine_from : ''}
                          onChange={e => setCost({
                            ...cost,
                            fine_from: e.target.value,
                          })}
                          invalid={errors && errors.fine_from ? true : false}
                          errorMessage={errors.fine_from}
                        />
                        <InputCustom
                          type={`datetime-local`}
                          label={`Fine date & time`}
                          value={cost && cost.fine_datetime ? cost.fine_datetime : ''}
                          onChange={e => setCost({
                            ...cost,
                            fine_datetime: e.target.value,
                          })}
                          invalid={errors && errors.fine_datetime ? true : false}
                          errorMessage={errors.fine_datetime}
                        />
                        <InputCustom
                          label={`Fine for`}
                          value={cost && cost.fine_for ? cost.fine_for : ''}
                          onChange={e => setCost({
                            ...cost,
                            fine_for: e.target.value,
                          })}
                          invalid={errors && errors.fine_for ? true : false}
                          errorMessage={errors.fine_for}
                        />
                        <InputCustom
                          label={`PCN number`}
                          value={cost && cost.pcn_number ? cost.pcn_number : ''}
                          onChange={e => setCost({
                            ...cost,
                            pcn_number: e.target.value,
                          })}
                          invalid={errors && errors.pcn_number ? true : false}
                          errorMessage={errors.pcn_number}
                        />
                      </>
                    }
                    <InputCustom 
                      type={`number`}
                      label={`Cost`}
                      value={cost && cost.costs? cost.costs: ''}
                      onChange={e => setCost({
                        ...cost,
                        costs: e.target.value,
                      })}
                      invalid={errors && errors.costs ? true : false}
                      errorMessage={errors.costs}
                    />
                    {cost && cost.payment_realised_by && cost.payment_realised_by === 'customer' &&
                      <InputCustom
                        type={`number`}
                        label={`Admin fee`}
                        value={cost && cost.admin_fee ? cost.admin_fee : ''}
                        onChange={e => setCost({
                          ...cost,
                          admin_fee: e.target.value,
                        })}
                        invalid={errors && errors.admin_fee ? true : false}
                        errorMessage={errors.admin_fee}
                      />
                    }
                  </Col>
                  <Col md={12}>
                    <div className="">
                      <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <p>Select or drop the documents, then match each of them to the right type</p>
                      </div>
                      <aside>
                        {fileLists.map((item, index) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: '1px solid #CCC',
                                alignItems: 'center',
                                padding: 20,
                              }}
                              key={index}
                            >
                              <Col md={3}>
                                <span>{item.name}</span>
                              </Col>
                              <Col md={3}>
                                <span>{`${item.size} bytes`}</span>
                              </Col>
                              <Col md={2}>
                                {`Document type`}
                              </Col>
                              <Col md={4}>
                                <Input
                                  type='select'
                                  id={`select_${index}`}
                                  onChange={(e) => setSendType(e, index)}
                                >
                                  {Object.keys(availableDocumentsUploadTypes).map((key, index) => {
                                    return (
                                      <option value={key} key={key}>
                                        {availableDocumentsUploadTypes[key]}
                                      </option>
                                    );
                                  })}
                                </Input>
                              </Col>
                            </div>
                          )
                        })}
                      </aside>
                    </div>
                  </Col>
                </Row>
                <div className="d-flex flex-row justify-content-center mt-4">
                  <Button className="w-100" color="success" onClick={() => addCost()}>Save</Button>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={5}>
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">On rental intervals</h1>
                  </div>
                </div>
              </CardHeader>			  
              <CardBody>
                <TableCustom 
                  loading={loading}
                  items={vehicleProfit && vehicleProfit.deals? vehicleProfit.deals: []}
                  keyField={`customer_name`}
                  searchField={false}
                  columns={[
                    {
                      dataField: "customer_name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "from",
                      text: "From",
                      sort: true
                    },
                    {
                      dataField: "to",
                      text: "To",
                      sort: true
                    },
                    {
                      dataField: "additionalCostOnUs",
                      text: "Cost Us",
                      sort: true
                    },
                    {
                      dataField: "additionalCostCustomer",
                      text: "Cost Customer",
                      sort: true
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card>
          <CardHeader>
            <h1>Costs list</h1>
          </CardHeader>
          <CardBody>
            <TableCustom 
              loading={costLoading}
              delay={delay}
              items={costs}
              keyField={`id`}
              searchField={false}
              columns={[
                {
                  dataField: "type",
                  text: "Type",
                  sort: true,
                  formatter: (rowContent, row) => {
                    return costTypes[row.cost_type]
                  }
                },
                {
                  dataField: "costs",
                  text: "Cost",
                  sort: true
                },
                {
                  dataField: "realised_by",
                  text: "Payment realised by",
                  sort: true,
                  formatter: (rowContent, row) => {
                    return realisedBy[row.payment_realised_by]
                  }
                },
                {
                  dataField: "on_rental_to",
                  text: "On rental to",
                  sort: true,
                  formatter: (rowContent, row) => {
                    return row.customer? row.customer.full_name: ''
                  }
                },
                {
                  dataField: "created_at",
                  text: "Created At",
                  sort: true,
                  formatter: (rowContent, row) => {
                    return constants.formatDateFullGB(row.created_at)
                  }
                },
                {
                  dataField: "notification",
                  text: "Notes",
                  sort: true
                },
                {
                  dataField: "admin_fee",
                  text: "Admin fee",
                  sort: true
                },
                {
                  dataField: "fine_from",
                  text: "Fine from",
                  sort: true
                },
                {
                  dataField: "fine_datetime",
                  text: "Fine Date & time",
                  sort: true,
                  formatter: (rowContent, row) => {
                    return row.cost_type && row.cost_type === 'fine' && row.fine_datetime? constants.formatDateFullGB(row.fine_datetime): ''
                  }
                },
                {
                  dataField: "fine_for",
                  text: "Fine for",
                  sort: true,
                },
                {
                  dataField: "pcn_number",
                  text: "PCN number",
                  sort: true,
                },
                {
                  dataField: "",
                  text: "Action",
                  formatter: (rowContent, row) => {
                    return (
                      <>
                        { (hasPermission(permissions, 'create-vehicles') || hasPermission(permissions, 'delete-vehicles')) 
                          && <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            color=""
                            role="button"
                            size="sm"
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              onClick={e => {
                                setConfirmShow(true);
                                setSelectedCostId(row.id);
                              }}
                            >
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>}
                      </>
                    )
                  }
                }
              ]}
            />
          </CardBody>
        </Card>
        <Modal
          isOpen={confirmShow}
          toggle={() => setConfirmShow(false)}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{`Delete cost`}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setConfirmShow(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {`Are you sure to delete this cost?`}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={() => deleteCost()}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setConfirmShow(false)}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}