import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import InputSideCustom from 'views/pages/components/InputSideCustom'
import InputFile from 'views/pages/components/InputFile'
import {
  InputKeySelect,
  InputArraySelect,
  InputKeySideSelect,
  InputArraySideSelect,
} from 'views/pages/components/InputSelect'
import InputToggle from 'views/pages/components/InputToggle'
import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap'

export default function OrderTrackers() {
  const notificationAlert = useRef(null)
  const history = useHistory()

  const [token, setToken] = useState('')
  const [dealer, setDealer] = useState('')
  const [profile, setProfile] = useState('')
  const [currentUser, setCurrentUser] = useState('')
  const [trackers, setTrackers] = useState('')

  const [errors, setErrors] = useState('')

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/tracking/order-trackers`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data

        setDealer(data.data.dealer)
        setProfile(data.data.profile)
        setCurrentUser(data.data.currentUser)

        const _currentUser = data?.data?.currentUser
        const _profile = data?.data?.profile
        setTrackers({
          ...trackers,
          full_name: _currentUser?.name,
          dealer_name: _profile?.name,
          address: `${_profile?.postal_address ? _profile?.postal_address : ''}, ${_profile?.postal_city ? _profile?.postal_city : ''}, ${_profile?.postal_state ? _profile?.postal_state : ''}`,
          post_code: _profile?.postal_post_code
        })

      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const order = async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${constants.API_URL}/tracking/order-trackers`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: trackers
      })
      setErrors('')
      console.log('response :: ', response.data)
      const data = response.data

      notify("success", "Success", data.message);

    } catch (error) {
      const errors = error?.response
      console.error('errors :: ', errors?.data.errors)

      if (error?.response?.status && error?.response?.status === 422) {
        setErrors(errors?.data?.errors);
      }

      notify(
        "warning",
        "Warning",
        error.response && error.response.data
          ? error.response.data.message
          : error.response
          ? error.response.statusText
          : "Error Occurred!"
      );
    }
  }

  return (
    <>
      <CardsHeader
        name="Order Tracker"
        parentName="Tracking Management"
        currentName="List"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Order Trackers</h1>
                    <p className="text-sm mb-0">
                      Order your vehicle trackers here.
                    </p>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6} sm={12}>
                    <InputCustom
                      type={`number`}
                      label={`Number of required trackers`}
                      onChange={(e) => {
                        setTrackers({
                          ...trackers,
                          number_of_devices: e.target.value,
                        });
                      }}
                      invalid={
                        errors && errors.number_of_devices ? true : false
                      }
                      errorMessage={errors?.number_of_devices}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <InputCustom
                      type={`text`}
                      label={`Full Name`}
                      value={
                        trackers && trackers.full_name ? trackers.full_name : ""
                      }
                      onChange={(e) => {
                        setTrackers({
                          ...trackers,
                          full_name: e.target.value,
                        });
                      }}
                      invalid={errors && errors.full_name ? true : false}
                      errorMessage={errors?.full_name}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <InputCustom
                      type={`text`}
                      label={`Contact Number`}
                      onChange={(e) => {
                        setTrackers({
                          ...trackers,
                          contact_number: e.target.value,
                        });
                      }}
                      invalid={errors && errors.contact_number ? true : false}
                      errorMessage={errors?.contact_number}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <InputCustom
                      type={`text`}
                      label={`Dealer Name`}
                      value={
                        trackers && trackers.dealer_name
                          ? trackers.dealer_name
                          : ""
                      }
                      onChange={(e) => {
                        setTrackers({
                          ...trackers,
                          dealer_name: e.target.value,
                        });
                      }}
                      invalid={errors && errors.dealer_name ? true : false}
                      errorMessage={errors?.dealer_name}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <InputCustom
                      type={`text`}
                      label={`Address`}
                      value={
                        trackers && trackers.address ? trackers.address : ""
                      }
                      onChange={(e) => {
                        setTrackers({
                          ...trackers,
                          address: e.target.value,
                        });
                      }}
                      invalid={errors && errors.address ? true : false}
                      errorMessage={errors?.address}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <InputCustom
                      type={`text`}
                      label={`Post Code`}
                      value={
                        trackers && trackers.post_code ? trackers.post_code : ""
                      }
                      onChange={(e) => {
                        setTrackers({
                          ...trackers,
                          post_code: e.target.value,
                        });
                      }}
                      invalid={errors && errors.post_code ? true : false}
                      errorMessage={errors?.post_code}
                    />
                  </Col>
                  <Col md={12} sm={12}>
                    <InputCustom
                      type={`textarea`}
                      label={`Message`}
                      rows={5}
                      onChange={(e) => {
                        setTrackers({
                          ...trackers,
                          description: e.target.value,
                        });
                      }}
                      invalid={errors && errors.description ? true : false}
                      errorMessage={errors?.description}
                    />
                  </Col>
                  <Col md={12} sm={12}>
                    <Button
                      className="w-100"
                      color="success"
                      onClick={() => order()}
                    >
                      Order
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}