import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';
import { Editor } from '@tinymce/tinymce-react';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import {
  InputArraySelect
} from 'views/pages/components/InputSelect';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
} from 'reactstrap';
import InputToggle from 'views/pages/components/InputToggle';

export default function DocumentForm() {
  const { id } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();

  const authUserTeam = useSelector(state => state.getState).authUserTeam;
  
  const [token, setToken] = useState('');
  const [dealers, setDealers] = useState([]);
  const [document, setDocument] = useState([]);
  const [types, setTypes] = useState('');

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const url = id ? `${constants.API_URL}/dealer/documents/${id}/edit` : `${constants.API_URL}/dealer/documents/create`

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: url,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        
        setDealers(data.data.dealers ? data.data.dealers : [])
        setDocument(data.data.document)
        setTypes(data.data.types)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])
  
  const goList = () => {
    history.push({
      pathname: `/admin/settings/documents`
    })
  }

  const addDocument = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/dealer/documents`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: document
    })
      .then(response => {
        setErrors('')
        console.log('res :: ', response.data)
        let data = response.data
        notify('success', 'Success', data.message)

        setTimeout(() => {
          history.push({
            pathname: `/admin/settings/documents`
          })
        }, 1500);
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors)
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  const updateDocument = () => {
    axios({
      method: 'PUT',
      url: `${constants.API_URL}/dealer/documents/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: document
    })
      .then(response => {
        setErrors('')
        console.log('res :: ', response.data)
        let data = response.data
        notify('success', 'Success', data.message)

        setTimeout(() => {
          history.push({
            pathname: `/admin/settings/documents`
          })
        }, 1500);
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors)
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  return (
    <>
      <CardsHeader name="Settings" parentName="Document Management" currentName="Create" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title">Available fields</h1>
                <p className="text-sm mb-0">List of available shortcodes</p>
              </CardHeader>
              <CardBody>
                <Row>
                  { types &&
                    Object.keys(types).map(key => {
                      return (
                        <Col md={12} key={key} className="mt-3">
                          <h3 className="mb-0">{key}</h3>
                          <Col md={12}>
                            {types[key] && types[key].length > 0 &&
                              types[key].map((type, index) => {
                              return <span key={index}>{type}{" "}</span>
                              })
                            }
                          </Col>
                        </Col>
                      )
                    })
                  }
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Documents Management</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button className="w-100" color="success" type="button" onClick={() => goList()}>Back to list</Button>
                  </Col>
                </Row>
              </CardHeader>			  
              <CardBody>
                <InputCustom 
                  label={`Document name`}
                  value={document && document.document_name? document.document_name: ''}
                  onChange={e => setDocument({
                    ...document,
                    document_name: e.target.value
                  })}
                  invalid={errors && errors.document_name? true: false}
                  errorMessage={errors.document_name}
                />
                {!authUserTeam && 
                <InputArraySelect 
                  label={`Dealer`}
                  value={document && document.dealer_id? document.dealer_id: ''}
                  options={dealers}
                  onChange={e => setDocument({
                    ...document,
                    dealer_id: e.target.value
                  })}
                  valueKey={`id`}
                  labelKey={`name`}
                  defaultOption={true}
                  invalid={errors && errors.dealer_id? true: false}
                  errorMessage={errors.dealer_id}
                />}
                <InputCustom 
                  type={`textarea`}
                  label={`Description`}
                  rows={5}
                  value={document && document.description? document.description: ''}
                  onChange={e => setDocument({
                    ...document,
                    description: e.target.value
                  })}
                  invalid={errors && errors.description? true: false}
                  errorMessage={errors.description}
                />
                <FormGroup>
                  <label
                    className="form-control-label"
                  >
                    Pdf content
                  </label>
                  <Editor
                    value={document && document.content ? document.content : ''}
                    apiKey={constants.TINYMCE_KEY}
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: ['code', 'preview', 'pagebreak', 'image', 'fullpage'],
                      toolbar:
                        'undo redo | formatselect | bold italic | \
                                alignleft aligncenter alignright alignjustify | \
                                outdent indent'
                    }}
                    onEditorChange={(content, editor) => setDocument({
                      ...document,
                      content: content
                    })}
                  />
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                  >
                    Pdf content raw
                  </label>
                  <Editor
                    value={document && document.content_raw ? document.content_raw : ''}
                    apiKey={constants.TINYMCE_KEY}
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: ['code', 'preview', 'pagebreak', 'image', 'fullpage'],
                      toolbar:
                        'undo redo | formatselect | bold italic | \
                                alignleft aligncenter alignright alignjustify | \
                                outdent indent'
                    }}
                    onEditorChange={(content, editor) => setDocument({
                      ...document,
                      content_raw: content
                    })}
                  />
                </FormGroup>
                <InputCustom 
                  type={`number`}
                  label={`Sort ( position )`}
                  value={document && document.sort && {}.toString.call(document.sort) !== '[object Function]'? document.sort: ''}
                  onChange={e => setDocument({
                    ...document,
                    sort: e.target.value
                  })}
                  invalid={errors && errors.sort? true: false}
                  errorMessage={errors.sort}
                />
                <InputToggle 
                  label={`Is active`}
                  checked={document && document.is_active? true: false}
                  onChange={e => setDocument({
                    ...document,
                    is_active: document.is_active == 1? 0: 1
                  })}
                  invalid={errors && errors.is_active? true: false}
                  errorMessage={errors.is_active}
                />
                <div className="d-flex flex-row justify-content-center">
                  <Button className="w-100" color="success" onClick={() => id? updateDocument(): addDocument()}>Save</Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}