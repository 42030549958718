import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import Cookies from "js-cookie";

import actions from "actions/index.js";
import * as constants from "constants/index.js";
import CardsHeader from "layouts/Headers/CardsHeader.js";

import InputCustom from "views/pages/components/InputCustom";
import InputSideCustom from "views/pages/components/InputSideCustom";
import {
  InputKeySideSelect,
  InputArraySideSelect,
} from "views/pages/components/InputSelect";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  CardBody,
} from "reactstrap";

export default function LeadCreate() {
  const notificationAlert = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const [token, setToken] = useState("");
  const [lead, setLead] = useState([]);
  const [dealerMembers, setDealerMembers] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [leadTypes, setLeadTypes] = useState("");
  const [leadsStatuses, setLeadsStatuses] = useState("");
  const [makes, setMakes] = useState([]);
  const [makesAndModels, setMakesAndModels] = useState("");
  const [models, setModels] = useState([]);
  const [errors, setErrors] = useState("");

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get("grs_token");
    setToken(grs_token);

    const source = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const response = await axios({
          method: "GET",
          url: `${constants.API_URL}/leads/create`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        });
        console.log("response :: ", response.data);
        const data = response.data;

        setLead(data.data.item);
        setDealerMembers(data.data.dealerMembers);
        setLeadSources(data.data.leadSources);
        setLeadTypes(data.data.leadTypes);
        setLeadsStatuses(data.data.leadsStatuses);
        setMakes(data.data.makes);
        setMakesAndModels(data.data.makesAndModels);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled :: ", error);
        } else {
          console.error("error :: ", error);
          notify(
            "warning",
            "Warning",
            error.response && error.response.data
              ? error.response.data.message
              : error.response
              ? error.response.statusText
              : "Error Occurred!"
          );
        }
      }
    };

    fetchData();

    return () => {
      source.cancel();
    };
  }, []);

  const goList = () => {
    history.push({
      pathname: "/admin/leads",
    });
  };

  const addLead = () => {
    axios({
      method: "POST",
      url: `${constants.API_URL}/leads`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: lead,
    })
      .then((response) => {
        setErrors("");
        console.log("res :: ", response.data);
        let data = response.data;
        notify("success", "Success", data.message);
        dispatch(actions.getNotifications(token));
        setTimeout(() => {
          history.push({
            pathname: "/admin/leads/" + data.data.uuid + "/edit",
          });
        }, 1500);
      })
      .catch((error) => {
        setErrors("");
        console.error("error :: ", error);
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors);
          return;
        }
        notify(
          "warning",
          "Warning",
          error.response ? error.response.statusText : "Error occurred!"
        );
      });
  };

  return (
    <>
      <CardsHeader
        name="Leads"
        parentName="Leads Management"
        currentName="Create"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Leads Management</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button
                      className="w-100"
                      color="success"
                      type="button"
                      onClick={() => goList()}
                    >
                      Back to list
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <h1 className="text-center">{`Create a new lead`}</h1>
                <Row className="mt-3 px-3">
                  <Col md={6} sm={12} className="mt-4">
                    <InputSideCustom
                      label={`Name`}
                      value={lead && lead.name ? lead.name : ""}
                      onChange={(e) =>
                        setLead({
                          ...lead,
                          name: e.target.value,
                        })
                      }
                      invalid={errors && errors.name ? true : false}
                      errorMessage={errors.name}
                    />
                    <InputSideCustom
                      label={`Email`}
                      value={lead && lead.email ? lead.email : ""}
                      onChange={(e) =>
                        setLead({
                          ...lead,
                          email: e.target.value,
                        })
                      }
                      invalid={errors && errors.email ? true : false}
                      errorMessage={errors.email}
                    />
                    <InputArraySideSelect
                      label={`Model`}
                      options={models}
                      value={
                        lead && lead.vehicle_model_id
                          ? lead.vehicle_model_id
                          : ""
                      }
                      onChange={(e) =>
                        setLead({
                          ...lead,
                          vehicle_model_id: e.target.value,
                        })
                      }
                      valueKey={`id`}
                      labelKey={`name`}
                      defaultOption={true}
                      invalid={errors && errors.vehicle_model_id ? true : false}
                      errorMessage={errors.vehicle_model_id}
                    />
                    <InputArraySideSelect
                      label={`Source`}
                      options={leadSources}
                      value={
                        lead && lead.lead_source_id ? lead.lead_source_id : ""
                      }
                      onChange={(e) =>
                        setLead({
                          ...lead,
                          lead_source_id: e.target.value,
                        })
                      }
                      valueKey={`id`}
                      labelKey={`name`}
                      defaultOption={true}
                      invalid={errors && errors.lead_source_id ? true : false}
                      errorMessage={errors.lead_source_id}
                    />
                    <InputArraySideSelect
                      label={`Assignee`}
                      options={dealerMembers}
                      value={lead && lead.assigned_to ? lead.assigned_to : ""}
                      onChange={(e) =>
                        setLead({
                          ...lead,
                          assigned_to: e.target.value,
                        })
                      }
                      valueKey={`id`}
                      labelKey={`name`}
                      defaultOption={true}
                      invalid={errors && errors.assigned_to ? true : false}
                      errorMessage={errors.assigned_to}
                    />
                    <InputCustom
                      type={`textarea`}
                      rows={8}
                      label={`Additional vehicle information`}
                      value={
                        lead && lead.additional_information
                          ? lead.additional_information
                          : ""
                      }
                      onChange={(e) =>
                        setLead({
                          ...lead,
                          additional_information: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col md={6} sm={12} className="mt-4">
                    <InputSideCustom
                      label={`Mobile No`}
                      value={lead && lead.phone ? lead.phone : ""}
                      onChange={(e) =>
                        setLead({
                          ...lead,
                          phone: e.target.value,
                        })
                      }
                      invalid={errors && errors.phone ? true : false}
                      errorMessage={errors.phone}
                    />
                    <InputArraySideSelect
                      label={`Makes`}
                      options={makes}
                      value={lead && lead.lead_make ? lead.lead_make : ""}
                      onChange={(e) => {
                        setModels(makesAndModels[e.target.value]);
                        setLead({
                          ...lead,
                          lead_make: e.target.value,
                        });
                      }}
                      valueKey={`id`}
                      labelKey={`name`}
                      defaultOption={true}
                      invalid={errors && errors.lead_make ? true : false}
                      errorMessage={errors.lead_make}
                    />
                    <InputSideCustom
                      label={`Derivative`}
                      value={lead && lead.derivative ? lead.derivative : ""}
                      onChange={(e) =>
                        setLead({
                          ...lead,
                          derivative: e.target.value,
                        })
                      }
                      invalid={errors && errors.derivative ? true : false}
                      errorMessage={errors.derivative}
                    />
                    <InputKeySideSelect
                      label={`Lead Type`}
                      options={leadTypes}
                      value={lead && lead.lead_type ? lead.lead_type : ""}
                      onChange={(e) =>
                        setLead({
                          ...lead,
                          lead_type: e.target.value,
                        })
                      }
                      defaultOption={true}
                      invalid={errors && errors.lead_type ? true : false}
                      errorMessage={errors.lead_type}
                    />
                  </Col>
                </Row>
                <div className="d-flex flex-row justify-content-center mt-5 px-3">
                  <Button
                    className="w-100"
                    color="success"
                    onClick={() => addLead()}
                  >
                    Save
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
