import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';
import { Editor } from '@tinymce/tinymce-react';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import InputFile from 'views/pages/components/InputFile';
import {
  InputArraySelect,
} from 'views/pages/components/InputSelect';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Label,
  Alert,
  Input,
  FormGroup
} from 'reactstrap';

export default function DealerProfile() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [dealerProfile, setDealerProfile] = useState('');
  const [countries, setCountries] = useState([]);
  const [counties, setCounties] = useState([]);
  const [dealerLanding, setDealerLanding] = useState('');
  const [modules, setModules] = useState('');
  const [regions, setRegions] = useState([]);

  const [profileErrors, setProfileErrors] = useState('');
  const [paymentErrors, setPaymentErrors] = useState('');
  const [frontendErrors, setFrontendErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/dealer/profile`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        setCountries(data.data.countries)
        setCounties(data.data.counties)
        setDealerLanding(data.data.dealerLanding)
        setModules(data.data.modules)
        setDealerProfile({
          ...data.data.profile,
          date_of_birth: data.data.profile.date_of_birth? constants.formatDate(data.data.profile.date_of_birth): '',
        })
        setRegions(data.data.regions)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const handleChange = (files) => {
    setImageFile(files[0]);
  }

  const band = [null, 'null', undefined];

  const updateProfile = () => {
    let formData = new FormData();
    formData.append('dealer_id',                  dealerProfile.dealer_id);
    formData.append('name',                       band.includes(dealerProfile.name)                      ? '': dealerProfile.name);
    formData.append('address',                    band.includes(dealerProfile.address)                   ? '': dealerProfile.address);
    formData.append('contact_person',             band.includes(dealerProfile.contact_person)            ? '': dealerProfile.contact_person);
    formData.append('place_of_birth',             band.includes(dealerProfile.place_of_birth)            ? '': dealerProfile.place_of_birth);
    formData.append('license_no',                 band.includes(dealerProfile.license_no)                ? '': dealerProfile.license_no);
    formData.append('company_ref_number',         band.includes(dealerProfile.company_ref_number)        ? '': dealerProfile.company_ref_number);
    formData.append('business_name',              band.includes(dealerProfile.business_name)             ? '': dealerProfile.business_name);
    formData.append('office_name',                band.includes(dealerProfile.office_name)               ? '': dealerProfile.office_name);
    formData.append('office_city',                band.includes(dealerProfile.office_city)               ? '': dealerProfile.office_city);
    formData.append('location_map_url',           band.includes(dealerProfile.location_map_url)          ? '': dealerProfile.location_map_url);
    formData.append('office_post_code',           band.includes(dealerProfile.office_post_code)          ? '': dealerProfile.office_post_code);
    formData.append('postal_address',             band.includes(dealerProfile.postal_address)            ? '': dealerProfile.postal_address);
    formData.append('postal_city',                band.includes(dealerProfile.postal_city)               ? '': dealerProfile.postal_city);
    formData.append('postal_state',               band.includes(dealerProfile.postal_state)              ? '': dealerProfile.postal_state);
    formData.append('postal_post_code',           band.includes(dealerProfile.postal_post_code)          ? '': dealerProfile.postal_post_code);
    formData.append('office_contact_phone',       band.includes(dealerProfile.office_contact_phone)      ? '': dealerProfile.office_contact_phone);
    formData.append('office_fax',                 band.includes(dealerProfile.office_fax)                ? '': dealerProfile.office_fax);
    formData.append('office_mobile',              band.includes(dealerProfile.office_mobile)             ? '': dealerProfile.office_mobile);
    formData.append('office_email_for_enquiries', band.includes(dealerProfile.office_email_for_enquiries)? '': dealerProfile.office_email_for_enquiries);
    formData.append('expire_notification_email',  band.includes(dealerProfile.expire_notification_email) ? '': dealerProfile.expire_notification_email);
    formData.append('phone_number',               band.includes(dealerProfile.phone_number)              ? '': dealerProfile.phone_number);
    formData.append('email',                      band.includes(dealerProfile.email)                     ? '': dealerProfile.email);
    formData.append('fiscal_code',                band.includes(dealerProfile.fiscal_code)               ? '': dealerProfile.fiscal_code);
    formData.append('bank',                       band.includes(dealerProfile.bank)                      ? '': dealerProfile.bank);
    formData.append('bank_sort_code',             band.includes(dealerProfile.bank_sort_code)            ? '': dealerProfile.bank_sort_code);
    formData.append('account_number',             band.includes(dealerProfile.account_number)            ? '': dealerProfile.account_number);
    formData.append('account_name',               band.includes(dealerProfile.account_name)              ? '': dealerProfile.account_name);
    if (imageFile) {
      formData.append('logo', imageFile);
    }
    if (dealerProfile.date_of_birth) {
      formData.append('date_of_birth', dealerProfile.date_of_birth == 'null'? '': dealerProfile.date_of_birth);
    }
    if (dealerProfile.country_of_birth_id) {
      formData.append('country_of_birth_id', dealerProfile.country_of_birth_id == 'null'? '': dealerProfile.country_of_birth_id);
    }
    if (dealerProfile.county_id) {
      formData.append('county_id', dealerProfile.county_id == 'null'? '': dealerProfile.county_id);
    }
    if (dealerProfile.is_public_listed) {
      formData.append('is_public_listed', dealerProfile.is_public_listed == true? 1: 0);
    }

    axios({
      method: 'POST',
      url: constants.API_URL + '/dealer/profile',
      headers: {
        'accept': 'application/json',
        Authorization: 'Bearer ' + token,
        'Content-Type': `multipart/form-data`,
      },
      data: formData
    })
      .then(response => {
        if (response.status == 200) {
          setProfileErrors('');
          let data = response.data.data;
          setDealerProfile({
            ...data,
            date_of_birth: data.date_of_birth? constants.formatDate(data.date_of_birth): '',
          });
          notify('success', 'Success', response.data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        if (error.response.status == 422) {
          let errors = error.response.data.errors;
          setProfileErrors(errors);
          return
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  const actionRequire = (url) => {
    window.open(url, '_blank');
  }

  const saveConfiguration = () => {
    let formData = new FormData();
    Object.keys(modules).map(key => {
      if (modules[key]) {
        Object.keys(modules[key]).map(key2 => {
          if (modules[key][key2] && modules[key][key2].type != 'redirect_access') {
            formData.append(`${key}[${key2}]`, modules[key][key2].value? modules[key][key2].value: '')
          }
        })
      }
    })
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    axios({
      method: 'POST',
      url: constants.API_URL + '/dealer/profile-module-configuration',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: formData
    })
      .then(response => {
        if (response.status == 200) {
          console.log('response data :: ', response.data)
          let data = response.data;
          notify('success', 'Success', response.data.message);
        }
      })
      .catch(error => {
        setPaymentErrors('');
        console.error('error :: ', error.response);
        if (error.response && error.response.data.errors) {
          let errors = error.response.data.errors;
          setPaymentErrors(errors);
          return
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  const updateFront = () => {
    axios({
      method: 'PUT',
      url: constants.API_URL + '/dealer/landing',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      data: dealerLanding
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data.data;
          console.log('response financial data :: ', response.data);
          setDealerLanding(data);
          notify('success', 'Success', response.data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response.status == 422) {
          let errors = error.response.data.errors;
          setFrontendErrors(errors);
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  return (
    <>
      <CardsHeader name="Dealer" parentName="Dealer Admin" currentName="Edit" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="7">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">Edit Profile</h1>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <InputFile
                  label={`Company Logo`}
                  placeholder={`Select file`}
                  accept="image/*"
                  onChange={(e) => handleChange(e.target.files)}
                  invalid={profileErrors && profileErrors.logo ? true : false}
                  errorMessage={profileErrors.logo}
                />
                {dealerProfile.logo && <Row>
                  <div className="col-lg-4 order-lg-2 mb-2">
                    <div className="dealer-profile-image-edit-section">
                      <img src={constants.STORAGE_URL + dealerProfile.logo} className="img-thumbnail" />
                    </div>
                  </div>
                </Row>}
                <Card>
                  <CardHeader className="text-center pb-0 pt-2" style={{ backgroundColor: '#CED4DA' }}>
                    <h2 className="title" style={{ color: '#000000' }}>Personal Details</h2>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Name`}
                          value={dealerProfile && dealerProfile.contact_person ? dealerProfile.contact_person : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            contact_person: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.contact_person ? true : false}
                          errorMessage={profileErrors.contact_person}
                        />
                        <InputArraySelect
                          label={`Country of Birth`}
                          options={countries}
                          value={dealerProfile.country_of_birth_id ? dealerProfile.country_of_birth_id : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            country_of_birth_id: e.target.value
                          })}
                          defaultOption={true}
                          valueKey={`id`}
                          labelKey={`name`}
                          invalid={profileErrors && profileErrors.country_of_birth_id ? true : false}
                          errorMessage={profileErrors.country_of_birth_id}
                        />
                        <InputCustom
                          label={`Mobile No`}
                          value={dealerProfile.phone_number ? dealerProfile.phone_number : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            phone_number: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.phone_number ? true : false}
                          errorMessage={profileErrors.phone_number}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom
                          type={`date`}
                          label={`Date of Birth`}
                          value={dealerProfile.date_of_birth ? dealerProfile.date_of_birth : undefined}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            date_of_birth: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.date_of_birth ? true : false}
                          errorMessage={profileErrors.date_of_birth}
                        />
                        <InputCustom
                          label={`Place of Birth`}
                          value={dealerProfile.place_of_birth ? dealerProfile.place_of_birth : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            place_of_birth: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.place_of_birth ? true : false}
                          errorMessage={profileErrors.place_of_birth}
                        />
                        <InputCustom
                          label={`License No`}
                          value={dealerProfile.license_no ? dealerProfile.license_no : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            license_no: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.license_no ? true : false}
                          errorMessage={profileErrors.license_no}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader className="text-center pb-0 pt-2" style={{ backgroundColor: '#CED4DA' }}>
                    <h2 className="title" style={{ color: '#000000' }}>Admin Details</h2>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Dealer information`}
                          value={dealerProfile.name ? dealerProfile.name : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            name: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.name ? true : false}
                          errorMessage={profileErrors.name}
                        />
                        <InputCustom
                          label={`Business Name`}
                          value={dealerProfile.business_name ? dealerProfile.business_name : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            business_name: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.business_name ? true : false}
                          errorMessage={profileErrors.business_name}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Company Ref Number`}
                          value={dealerProfile.company_ref_number ? dealerProfile.company_ref_number : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            company_ref_number: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.company_ref_number ? true : false}
                          errorMessage={profileErrors.company_ref_number}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader className="text-center pb-0 pt-2" style={{ backgroundColor: '#CED4DA' }}>
                    <h2 className="title" style={{ color: '#000000' }}>Office Details</h2>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Office Name`}
                          value={dealerProfile.office_name ? dealerProfile.office_name : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            office_name: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.office_name ? true : false}
                          errorMessage={profileErrors.office_name}
                        />
                        <InputCustom
                          label={`City`}
                          value={dealerProfile.office_city ? dealerProfile.office_city : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            office_city: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.office_city ? true : false}
                          errorMessage={profileErrors.office_city}
                        />
                        <InputCustom
                          label={`Location Map Url`}
                          value={dealerProfile.location_map_url ? dealerProfile.location_map_url : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            location_map_url: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.location_map_url ? true : false}
                          errorMessage={profileErrors.location_map_url}
                        />
                        <Row className="mt-4">
                          <Label
                            className="form-control-label"
                            htmlFor="example-text-input"
                            md="5"
                          >
                            Is Public Listed
                    </Label>
                          <Col md="7">
                            <label className="custom-toggle mr-1 mt-2">
                              <input
                                onChange={() =>
                                  setDealerProfile({
                                    ...dealerProfile,
                                    is_public_listed: dealerProfile.is_public_listed == 1 ? 0 : 1
                                  })
                                }
                                checked={dealerProfile.is_public_listed == 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Yes"
                              />
                            </label>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Address`}
                          value={dealerProfile.address ? dealerProfile.address : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            address: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.address ? true : false}
                          errorMessage={profileErrors.address}
                        />
                        <InputCustom
                          label={`Post Code`}
                          value={dealerProfile.office_post_code ? dealerProfile.office_post_code : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            office_post_code: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.office_post_code ? true : false}
                          errorMessage={profileErrors.office_post_code}
                        />
                        <InputArraySelect
                          label={`State`}
                          options={counties}
                          value={dealerProfile.county_id ? dealerProfile.county_id : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            county_id: e.target.value
                          })}
                          valueKey={`id`}
                          labelKey={`name`}
                          defaultOption={true}
                          invalid={profileErrors && profileErrors.county_id ? true : false}
                          errorMessage={profileErrors.county_id}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader className="text-center pb-0 pt-2" style={{ backgroundColor: '#CED4DA' }}>
                    <h2 className="title" style={{ color: '#000000' }}>Financial Information</h2>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`VAT Reg`}
                          value={dealerProfile.fiscal_code ? dealerProfile.fiscal_code : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            fiscal_code: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.fiscal_code ? true : false}
                          errorMessage={profileErrors.fiscal_code}
                        />
                        <InputCustom
                          label={`Sort code`}
                          value={dealerProfile.bank_sort_code ? dealerProfile.bank_sort_code : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            bank_sort_code: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.bank_sort_code ? true : false}
                          errorMessage={profileErrors.bank_sort_code}
                        />
                        <InputCustom
                          label={`Account name`}
                          value={dealerProfile.account_name ? dealerProfile.account_name : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            account_name: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.account_name ? true : false}
                          errorMessage={profileErrors.account_name}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Bank`}
                          value={dealerProfile.bank ? dealerProfile.bank : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            bank: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.bank ? true : false}
                          errorMessage={profileErrors.bank}
                        />
                        <InputCustom
                          label={`Account number`}
                          value={dealerProfile.account_number ? dealerProfile.account_number : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            account_number: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.account_number ? true : false}
                          errorMessage={profileErrors.account_number}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader className="text-center pb-0 pt-2" style={{ backgroundColor: '#CED4DA' }}>
                    <h2 className="title" style={{ color: '#000000' }}>Postal Address</h2>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Address`}
                          value={dealerProfile.postal_address ? dealerProfile.postal_address : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            postal_address: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.postal_address ? true : false}
                          errorMessage={profileErrors.postal_address}
                        />
                        <InputCustom
                          label={`State`}
                          value={dealerProfile.postal_state ? dealerProfile.postal_state : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            postal_state: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.postal_state ? true : false}
                          errorMessage={profileErrors.postal_state}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`City`}
                          value={dealerProfile.postal_city ? dealerProfile.postal_city : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            postal_city: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.postal_city ? true : false}
                          errorMessage={profileErrors.postal_city}
                        />
                        <InputCustom
                          label={`Post Code`}
                          value={dealerProfile.postal_post_code ? dealerProfile.postal_post_code : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            postal_post_code: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.postal_post_code ? true : false}
                          errorMessage={profileErrors.postal_post_code}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader className="text-center pb-0 pt-2" style={{ backgroundColor: '#CED4DA' }}>
                    <h2 className="title" style={{ color: '#000000' }}>Contact Address</h2>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Phone`}
                          value={dealerProfile.office_contact_phone ? dealerProfile.office_contact_phone : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            office_contact_phone: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.office_contact_phone ? true : false}
                          errorMessage={profileErrors.office_contact_phone}
                        />
                        <InputCustom
                          label={`Mobile`}
                          value={dealerProfile.office_mobile ? dealerProfile.office_mobile : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            office_mobile: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.office_mobile ? true : false}
                          errorMessage={profileErrors.office_mobile}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Fax`}
                          value={dealerProfile.office_fax ? dealerProfile.office_fax : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            office_fax: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.office_fax ? true : false}
                          errorMessage={profileErrors.office_fax}
                        />
                        <InputCustom
                          label={`Email`}
                          value={dealerProfile.email ? dealerProfile.email : ''}
                          onChange={(e) => setDealerProfile({
                            ...dealerProfile,
                            email: e.target.value
                          })}
                          invalid={profileErrors && profileErrors.email ? true : false}
                          errorMessage={profileErrors.email}
                        />
                      </Col>
                    </Row>
                    <InputCustom
                      label={`Email for Enquiries`}
                      value={dealerProfile.office_email_for_enquiries ? dealerProfile.office_email_for_enquiries : ''}
                      onChange={(e) => setDealerProfile({
                        ...dealerProfile,
                        office_email_for_enquiries: e.target.value
                      })}
                      invalid={profileErrors && profileErrors.office_email_for_enquiries ? true : false}
                      errorMessage={profileErrors.office_email_for_enquiries}
                    />
                    <InputCustom
                      label={`Email for Contract Expiry Notifications`}
                      value={dealerProfile.expire_notification_email ? dealerProfile.expire_notification_email : ''}
                      onChange={(e) => setDealerProfile({
                        ...dealerProfile,
                        expire_notification_email: e.target.value
                      })}
                      invalid={profileErrors && profileErrors.expire_notification_email ? true : false}
                      errorMessage={profileErrors.expire_notification_email}
                    />
                  </CardBody>
                </Card>
                <div className="d-flex flex-row justify-content-center">
                  <Button className="w-25" color="success" onClick={() => updateProfile()}>Save</Button>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h1>Frontend Layout Management</h1>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    About us description
                          </label>
                  <Editor
                    value={dealerLanding.description ? dealerLanding.description : ''}
                    apiKey={constants.TINYMCE_KEY}
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: ['code', 'preview', 'pagebreak', 'image'],
                      toolbar:
                        'undo redo | formatselect | bold italic | \
                                alignleft aligncenter alignright alignjustify | \
                                outdent indent'
                    }}
                    onEditorChange={(content, editor) => setDealerLanding({
                      ...dealerLanding,
                      description: content
                    })}
                  />
                  <div className="invalid-feedback" style={{display: frontendErrors && frontendErrors.description? 'block': 'none'}}>
                    {frontendErrors.description}
                  </div>
                </FormGroup>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    <h2>Testimonials</h2>
                    <span>Please write every testimonial link comma separated</span>
                  </label>
                  <Input
                    id="example-text-input"
                    type="textarea"
                    rows="4"
                    value={dealerLanding.testimonials ? dealerLanding.testimonials : ''}
                    onChange={(e) => setDealerLanding({
                      ...dealerLanding,
                      testimonials: e.target.value
                    })}
                    invalid={frontendErrors && frontendErrors.testimonials? true: false}
                  />
                  <div className="invalid-feedback">
                    {frontendErrors.testimonials}
                  </div>
                </FormGroup>
                <div className="d-flex flex-row justify-content-center">
                  <Button className="w-25" color="success" onClick={() => updateFront()}>Save</Button>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="5">
            <CardHeader>
              <h1 className="title mb-0">Modules</h1>
            </CardHeader>
            <CardBody>
              {modules && 
                Object.keys(modules).map(key => {
                  return (
                    <Row key={key} className="mb-3 border-bottom">
                      <Col md={12}>
                        <Row className="align-items-center">
                          <Col md={12}>
                            <h2 className="mb-4">{`${key.charAt(0).toUpperCase()}${key.slice(1)}`}</h2>
                          </Col>
                        </Row>
                        {modules[key] && Object.keys(modules[key]).length > 0 ?
                          Object.keys(modules[key]).map(key2 => {
                            if (modules[key][key2].type == 'redirect_access') {
                              return (
                                <FormGroup key={key2}>
                                  <label className="form-control-label">{modules[key][key2].label}</label>
                                  {modules[key][key2].value.value === true? 
                                    <Alert color="primary">
                                      <strong>Already have access!</strong>
                                      {modules[key][key2].optional.afterCheck && 
                                        <strong>{modules[key][key2].optional.afterCheck}!</strong>
                                      }
                                    </Alert>:
                                    <div>
                                      <h4 className="text-dark">{modules[key][key2].value.message}</h4>
                                      <Button className="w-50 mt-4" color="success" type="button" onClick={() => actionRequire(modules[key][key2].value.actionRequired)}>Click Here</Button>
                                    </div>
                                  }
                                </FormGroup>
                              )
                            } else {
                              return (
                                <FormGroup key={key2}>
                                  <label className="form-control-label">{modules[key][key2].label}</label>
                                  <Input 
                                    type="text"
                                    placeholder={modules[key][key2].label}
                                    value={modules[key][key2].value? modules[key][key2].value: ''}
                                    onChange={e => setModules({
                                      ...modules,
                                      [key]: {
                                        ...modules[key],
                                        [key2]: {
                                          ...modules[key][key2],
                                          value: e.target.value
                                        }
                                      }
                                    })}
                                    invalid={paymentErrors && paymentErrors[`${key}.${key2}`]? true: false}
                                  />
                                  <div className="invalid-feedback">
                                    {paymentErrors[`${key}.${key2}`]}
                                  </div>
                                </FormGroup>
                              )
                            }
                          }) :
                          <h5 className="mb-1">{`No configuration required!`}</h5>
                        }                        
                      </Col>
                    </Row>
                  )
                })
              }
              {modules && 
                <Button className="w-100 mt-4" color="success" onClick={() => saveConfiguration()}>Save Configuration</Button>
              }
              {!modules && 
                <h3 className="mb-0">{`You have no active module!`}</h3>
              }
            </CardBody>
          </Col>
        </Row>
      </Container>
    </>
  )
}