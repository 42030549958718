import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Button,
} from 'reactstrap';

import {
  deletee,
  hasPermission,
} from 'views/policies/LeadApplicationPolicy';

export default function Applications() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const permissions = useSelector(state => state.getState).permissions

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [dealers, setDealers] = useState('');
  const [items, setItems] = useState('');
  const [applications, setApplications] = useState([]);

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null
  })

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, 1, grs_token)

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (source, pageNumber, token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/applications`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      
      setDealers(data.data.dealers)
      setApplications(data.data.items.data)
      setItems(data.data.items)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const showApplication = (applicationId) => {
    history.push({
      pathname: `/admin/applications/${applicationId}`
    })
  }

  const deleteApplication = (applicationId) => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/applications/${applicationId}`,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        console.log('res :: ', response.data)
        let data = response.data
        setApplications(data.data.items.data)
        setItems(data.data.items)
        notify('success', 'Success', data.message)
        setDeleteModal({
          ...deleteModal,
          show: false
        })
      })
      .catch(error => {
        setDeleteModal({
          ...deleteModal,
          show: false
        })
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const showPDF = (pdfURL) => {
    window.open(`${constants.STORAGE_URL}documents/aml/${pdfURL}`, '_blank')
  }

  return (
    <>
      <CardsHeader name="Lead Applications" parentName="Lead Applications Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title">Applications</h1>
                    <p className="text-sm mb-0">Applications management</p>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  keyField={`id`}
                  searchField={false}
                  items={applications}
                  pagination={items}
                  onChange={(pageNumber) => fetchData(pageNumber, token)}
                  columns={[
                    {
                      dataField: "first_name",
                      text: "First Name",
                      sort: true
                    },
                    {
                      dataField: "last_name",
                      text: "Last Name",
                      sort: true
                    },
                    {
                      dataField: "mobile_number",
                      text: "Phone",
                      sort: true
                    },
                    {
                      dataField: "email",
                      text: "Email",
                      sort: true
                    },
                    {
                      dataField: "created_at",
                      text: "Date",
                      sort: true
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {(hasPermission(
                              permissions,
                              "update-applications"
                            ) ||
                              hasPermission(
                                permissions,
                                "delete-applications"
                              )) && (
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  color=""
                                  role="button"
                                  size="sm"
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  <DropdownItem
                                    onClick={() => showApplication(row.uuid)}
                                  >
                                    Show
                                  </DropdownItem>
                                  {row.pdf && (
                                    <DropdownItem
                                      onClick={() => showPDF(row.pdf)}
                                    >
                                      PDF
                                    </DropdownItem>
                                  )}
                                  {deletee(permissions, row) && (
                                    <DropdownItem
                                      onClick={() =>
                                        setDeleteModal({
                                          ...deleteModal,
                                          show: true,
                                          header: "Delete application",
                                          body: "Are you sure you want to delete this application?",
                                          onClick: () =>
                                            deleteApplication(row.uuid),
                                        })
                                      }
                                    >
                                      Delete
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            )}
                          </>
                        );
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            ...deleteModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {deleteModal.body}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}