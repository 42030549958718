import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import Cookies from "js-cookie";

import * as constants from "constants/index.js";
import CardsHeader from "layouts/Headers/CardsHeader.js";

import InputCustom from "views/pages/components/InputCustom";
import InputSideCustom from "views/pages/components/InputSideCustom";
import InputFile from "views/pages/components/InputFile";
import {
  InputKeySelect,
  InputArraySelect,
  InputKeySideSelect,
  InputArraySideSelect,
} from "views/pages/components/InputSelect";
import InputToggle from "views/pages/components/InputToggle";
import TableCustom from "views/pages/components/TableCustom";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";
import moment from "moment";

export default function VehicleBookingEdit() {
  const { uuid } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState("");
  const [item, setItem] = useState("");
  const [statuses, setStatuses] = useState("");
  const [typeOfRents, setTypeOfRents] = useState();

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get("grs_token");
    setToken(grs_token);

    const source = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const response = await axios({
          method: "GET",
          url: `${constants.API_URL}/customer/daily-deals/${uuid}/view`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        });
        console.log("response :: ", response.data);
        const data = response.data;

        setItem(data.data.item);
        setStatuses(data.data.statuses);
        setTypeOfRents(data.data.typeOfRents);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled :: ", error);
        } else {
          console.error("error :: ", error);
          notify(
            "warning",
            "Warning",
            error.response && error.response.data
              ? error.response.data.message
              : error.response
              ? error.response.statusText
              : "Error Occurred!"
          );
        }
      }
    };

    fetchData();

    return () => {
      source.cancel();
    };
  }, []);

  const goList = () => {
    history.push({
      pathname: `/admin/booking/daily-deals`,
    });
  };

  const print = () => {
    window.print();
  };

  const cancelDeal = async (uuid) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${constants.API_URL}/customer/daily-deals/${uuid}/cancel`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("response :: ", response.data);
      const data = response.data;
      
    } catch (error) {
      console.error("error :: ", error);
      notify(
        "warning",
        "Warning",
        error.response && error.response.data
          ? error.response.data.message
          : error.response
          ? error.response.statusText
          : "Error Occurred!"
      );
    }
  }

  const downloadRentalAgreement = async () => {
    window.open(`${constants.BASE_URL}/admin/download?file=admin/daily-deals-Rental-agreement.pdf`, '_blank')
  }

  const redirectVehicleEdit = (uuid) => {
    history.push({
      pathname: `/admin/daily-rental-vehicle/${uuid}/edit`
    })
  }

  return (
    <>
      <CardsHeader
        name="View reservation"
        parentName="Application Management"
        currentName="List"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">
                      Booking -{" "}
                      <span
                        className={`bg-red`}
                        style={{
                          borderRadius: 4,
                          color: "white",
                          padding: 5,
                        }}
                      >
                        {statuses[item.status]
                          ? statuses[item.status].replace(" status", "")
                          : ""}
                      </span>
                    </h1>
                  </Col>
                  <Col xs={2} className="text-right flex">
                    <Button
                      className="w-100"
                      color="success"
                      type="button"
                      onClick={() => goList()}
                    >
                      Back to list
                    </Button>
                  </Col>
                  <Col xs={2} className="text-right flex">
                    <Button
                      className="w-100"
                      color="primary"
                      type="button"
                      onClick={() => print()}
                    >
                      Print
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Button className="w-50" color="info" onClick={() => downloadRentalAgreement()}>{`Download rental agreement`.toUpperCase()}</Button>
                <h2 className="mb-3 mt-3">{`Personal Details`}</h2>
                <Row>
                  <Col md={3} sm={12}>
                    <b>{`Name: `}</b>
                  </Col>
                  <Col md={9} sm={12}>
                    {`${item && item.first_name ? item.first_name : ""} ${
                      item && item.last_name ? item.last_name : ""
                    }`}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3} sm={12}>
                    <b>{`Email: `}</b>
                  </Col>
                  <Col md={9} sm={12}>
                    {`${item && item.email ? item.email : ""}`}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3} sm={12}>
                    <b>{`Mobile: `}</b>
                  </Col>
                  <Col md={9} sm={12}>
                    {`${item && item.phone_number ? item.phone_number : ""}`}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3} sm={12}>
                    <b>{`DOB: `}</b>
                  </Col>
                  <Col md={9} sm={12}>
                    {`${
                      item && item.date_of_birth
                        ? constants.formatDateShortGB(item.date_of_birth)
                        : ""
                    }`}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3} sm={12}>
                    <b>{`Address: `}</b>
                  </Col>
                  <Col md={9} sm={12}>
                    {`${item && item.address ? item.address : ""}`}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3} sm={12}>
                    <b>{`Suburb: `}</b>
                  </Col>
                  <Col md={9} sm={12}>
                    {`${item && item.suburb ? item.suburb : ""}`}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3} sm={12}>
                    <b>{`State: `}</b>
                  </Col>
                  <Col md={9} sm={12}>
                    {`${item && item.state_name ? item.state_name : ""}`}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3} sm={12}>
                    <b>{`Post Code: `}</b>
                  </Col>
                  <Col md={9} sm={12}>
                    {`${item && item.post_code ? item.post_code : ""}`}
                  </Col>
                </Row>
                <hr />
                <h2 className="mb-3 mt-3">{`Vehicle Details`}</h2>
                <div
                  className="form-group d-flex flex-row"
                  style={{ marginBottom: "0.4rem" }}
                >
                  <div className="flex-grow-1">
                    <b>{`Vehicle`}</b>
                  </div>
                  <div className="col-sm-7" style={{cursor: "pointer"}} onClick={() => redirectVehicleEdit(item?.rentalVehicle?.uuid)}>
                    {`${
                      item &&
                      item.rentalVehicleMake &&
                      item.rentalVehicleMake.name
                        ? item.rentalVehicleMake.name
                        : ""
                    } ${
                      item &&
                      item.rentalVehicleModel &&
                      item.rentalVehicleModel.name
                        ? item.rentalVehicleModel.name
                        : ""
                    }`}
                  </div>
                </div>
                <p>
                  The actual vehicle was automatically assigned in order to keep
                  the reservation by following the first available rule for that
                  interval! Please feel free to revise and change the item!
                </p>
                <hr />
                <h2 className="mb-3 mt-3">{`Booking Details`}</h2>
                <Row>
                  <Col md={3} sm={12}>
                    <b>{`Pickup At: `}</b>
                  </Col>
                  <Col md={9} sm={12}>
                    {`${
                      item && item.pickup_at
                        ? constants.formatDateShortGB(item.pickup_at)
                        : ""
                    }`}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3} sm={12}>
                    <b>{`Return At: `}</b>
                  </Col>
                  <Col md={9} sm={12}>
                    {`${
                      item && item.return_at
                        ? constants.formatDateShortGB(item.return_at)
                        : ""
                    }`}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3} sm={12}>
                    <b>{`Vehicle Daily Price: `}</b>
                  </Col>
                  <Col md={9} sm={12}>
                    {`${
                      item && item.vehicle_daily_price
                        ? `${constants.CURRENCY} ${constants.round2Decimals(
                            item.vehicle_daily_price
                          )}`
                        : ""
                    }`}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3} sm={12}>
                    <b>{`Total Vehicle Price: `}</b>
                  </Col>
                  <Col md={9} sm={12}>
                    {`${
                      item && item.vehicle_total_price
                        ? `${constants.CURRENCY} ${constants.round2Decimals(
                            item.vehicle_total_price
                          )}`
                        : ""
                    }`}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3} sm={12}>
                    <b>{`Extras Price: `}</b>
                  </Col>
                  <Col md={9} sm={12}>
                    {`${
                      item && item.extras_price
                        ? `${constants.CURRENCY} ${constants.round2Decimals(
                            item.extras_price
                          )}`
                        : ""
                    }`}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3} sm={12}>
                    <b>{`Bond: `}</b>
                  </Col>
                  <Col md={9} sm={12}>
                    {`${
                      item && item.warranty_value
                        ? `${constants.CURRENCY} ${constants.round2Decimals(
                            item.warranty_value
                          )}`
                        : ""
                    }`}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3} sm={12}>
                    <b>{`Total Deal Price: `}</b>
                  </Col>
                  <Col md={9} sm={12}>
                    {`${
                      item && item.total_deal_price
                        ? `${constants.CURRENCY} ${constants.round2Decimals(
                            item.total_deal_price
                          )}`
                        : ""
                    }`}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3} sm={12}>
                    <b>{`Insurance: `}</b>
                  </Col>
                  <Col md={9} sm={12}>
                    {`${item && item.has_insurance ? "Yes" : "No"}`}
                  </Col>
                </Row>
                <hr />
                <h2 className="mb-3 mt-3">{`Extras Details`}</h2>
                <TableCustom
                  loading={false}
                  items={item.bookingItems ? item.bookingItems : []}
                  columns={[
                    {
                      dataField: "extra_name",
                      text: "Name",
                      formatter: (rowContent, row) => {
                        return row?.option?.extra?.name
                          ? row?.option?.extra?.name
                          : "";
                      },
                    },
                    {
                      dataField: "qty",
                      text: "QTY",
                      formatter: (rowContent, row) => {
                        return `${row?.quantity ? row?.quantity : ""} / ${
                          row?.option?.type_of_rent && typeOfRents
                            ? typeOfRents[row?.option?.type_of_rent]
                            : ""
                        }`;
                      },
                    },
                    {
                      dataField: "item_price",
                      text: "Price",
                      formatter: (rowContent, row) => {
                        return row?.item_price
                          ? `${constants.CURRENCY} ${row?.item_price}`
                          : "";
                      },
                    },
                    {
                      dataField: "total_price",
                      text: "Total Price",
                      formatter: (rowContent, row) => {
                        return row?.item_price
                          ? `${constants.CURRENCY} ${constants.round2Decimals(
                              row?.total_price
                            )}`
                          : "";
                      },
                    },
                  ]}
                />
                {!item?.bookingItems ||
                  (item.bookingItems.length === 0 && <p>No extras selected</p>)}
                <hr />
                <h2 className="mb-3 mt-3">{`Payment Details`}</h2>
                <Row className="mt-2">
                  <Col md={3} sm={12}>
                    <b>{`User payment selection: `}</b>
                  </Col>
                  <Col md={9} sm={12}>
                    {`${
                      item && item.user_payment_option
                        ? constants.capitalizeFirstLetter(
                            item.user_payment_option
                          )
                        : ""
                    }`}
                  </Col>
                </Row>
                {item?.paid_at && (
                  <div className="alert alert-success">
                    <Row className="mt-2">
                      <Col md={3} sm={12}>
                        <b>{`Paid At: `}</b>
                      </Col>
                      <Col md={9} sm={12}>
                        {`${
                          item && item.paid_at
                            ? constants.formatDateShortGB(item.paid_at)
                            : ""
                        }`}
                      </Col>
                    </Row>
                  </div>
                )}
                {item?.canceled_at && (
                  <div className="alert alert-success">
                    <Row className="mt-2">
                      <Col md={3} sm={12}>
                        <b>{`Paid At: `}</b>
                      </Col>
                      <Col md={9} sm={12}>
                        {`${
                          item && item.canceled_at
                            ? constants.formatDateShortGB(item.canceled_at)
                            : ""
                        }`}
                      </Col>
                    </Row>
                  </div>
                )}
                {item?.payment_reference_id && (
                  <div className="alert alert-success">
                    <Row className="mt-2">
                      <Col md={3} sm={12}>
                        <b>{`Payment info: `}</b>
                      </Col>
                      <Col md={9} sm={12}>
                        {`${
                          item && item.canceled_at && item.uuid
                            ? `Follow up the payment by CRM reference on ezidebit ${item.uuid} or directly on ezidebit by id is #${item.payment_reference_id}`
                            : ""
                        }`}
                      </Col>
                    </Row>
                  </div>
                )}
                {['0', '1'].includes(item?.status) && moment(item.pickup_at).subtract(1, "day") >= moment() && (
                  <div className="row mt-5">
                    <div className="col-2">
                      <Button className="w-50" color="warning" onClick={() => cancelDeal(item.uuid)}>Cancel</Button>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
