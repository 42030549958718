import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import Select2 from "react-select2-wrapper";
import axios from 'axios';
import Cookies from "js-cookie";

import actions from 'actions/index.js';
import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  FormGroup,
  Input,
} from "reactstrap";

export default function Task() {
  const { customerId } = useParams();
  const location = useLocation();
  const parentPath = {
    pathname: '/admin/customer/' + customerId + '/edit',
    state: {
      filter: location.state && location.state.filter? location.state.filter: ''
    }
  };
  const history = useHistory();
  const notificationAlert = useRef(null);
  const dispatch = useDispatch();

  const [token, setToken] = useState('');
  const [tasks, setTasks] = useState([]);
  const [dealerMembers, setDealerMembers] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [loading, setLoading] = useState(true);

  const [addModal, setAddModal] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState('');

  const [task, setTask] = useState('');
  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/customer/${customerId}/tasks`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        setTasks(data.data.tasks)

        let members = data.data.dealerMembers
        members.map(member => {
          member.text = member.name
          delete member.name
        });
        setDealerMembers(members)
        setStatuses(data.data.statuses)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const deleteTask = () => {
    axios({
      method: 'DELETE',
      url: constants.API_URL + '/customer/' + customerId + '/tasks/' + selectedTaskId,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        setConfirmShow(false);
        if (response.status === 200) {
          let data = response.data;
          console.log('deleted data :: ', data);
          setTasks(data.data);
          notify('success', 'Success', response.data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        setConfirmShow(false);
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const editTask = (row) => {
    history.push({
      pathname: '/admin/customer/' + customerId + '/tasks/' + row.uuid + '/edit',
      state: {
        filter: location.state && location.state.filter? location.state.filter: ''
      }
    })
  }

  const addTask = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/customer/' + customerId + '/tasks',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: task,
    })
      .then(response => {
        setAddModal(false);
        setErrors('');
        if (response.status === 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setTasks(data.data);
          dispatch(actions.getNotifications(token));
          notify('success', 'Success', response.data.message);
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error);
        if (error.response && error.response.status === 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
          return;
        }
        setAddModal(false);
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const handleAssigneeChange = (e) => {
    let value = Array.from(e.target.selectedOptions, option => option.value);
    setTask({
      ...task,
      task_assignee: value
    })
  }

  const formatDate = (d) => {
    if (d) {
      let date = new Date(d);
      let option = {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: 'numeric', minute: 'numeric', second: '2-digit',
        hour12: false,
      }
      let formatedDate = new Intl.DateTimeFormat('en-GB', option).format(date);
  
      return formatedDate;
    }
  }

  return (
    <>
      <CardsHeader name="Customers" parentName="Customer Management" currentName="Task List" parentPath={parentPath} />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Tasks</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button className="w-100" color="success" type="button" onClick={() => setAddModal(true)}>Add Task</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  items={tasks}
                  keyField={`title`}
                  searchField={true}
                  columns={[
                    {
                      dataField: "title",
                      text: "Title",
                      sort: true
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      sort: true
                    },
                    {
                      dataField: "created_at",
                      text: "Created at",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return formatDate(row.created_at)
                      }
                    },
                    {
                      dataField: "updated_at",
                      text: "Updated at",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return formatDate(row.updated_at)
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  onClick={e => editTask(row)}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  onClick={e => {
                                    setConfirmShow(true);
                                    setSelectedTaskId(row.uuid);
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={addModal}
          toggle={() => setAddModal(false)}
          size="lg"
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body">
            <form className="new-event--form">
              <FormGroup>
                <label className="form-control-label">Title</label>
                <Input
                  className="form-control-alternative new-event--title"
                  placeholder="Title"
                  type="text"
                  onChange={(e) => setTask({
                    ...task,
                    title: e.target.value,
                  })}
                  invalid={errors && errors.title? true: false}
                />
                <div className="invalid-feedback">
                  {errors.title}
                </div>
              </FormGroup>
              <FormGroup>
                <label className="form-control-label">Description</label>
                <Input
                  className="form-control-alternative edit-event--title"
                  placeholder="Description"
                  type="textarea"
                  rows="8"
                  onChange={(e) => setTask({
                    ...task,
                    comments: e.target.value,
                  })}
                  invalid={errors && errors.comments? true: false}
                />
                <div className="invalid-feedback">
                  {errors.comments}
                </div>
              </FormGroup>
              <Row>
                <Col md={6} sm={12}>
                  <FormGroup>
                    <label className="form-control-label">Due</label>
                    <Input
                      className="form-control-alternative new-event--title"
                      type="date"
                      onChange={(e) => setTask({
                        ...task,
                        due_date: e.target.value,
                      })}
                      invalid={errors && errors.due_date? true: false}
                    />
                    <div className="invalid-feedback">
                      {errors.due_date}
                    </div>
                  </FormGroup>
                </Col>
                <Col md={6} sm={12}>
                  <FormGroup>
                    <label className="form-control-label">Status</label>
                    <Input
                      className="form-control-alternative new-event--title"
                      type="select"
                      onChange={(e) => setTask({
                        ...task,
                        status: e.target.value,
                      })}
                      invalid={errors && errors.status? true: false}
                    >
                      <option>-</option>
                      { Object.keys(statuses).map((key, index) => {
                        return <option value={key} key={key}>{statuses[key]}</option>
                      }) }
                    </Input>
                    <div className="invalid-feedback">
                      {errors.status}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  <FormGroup>
                    <label className="form-control-label">Assign to</label>
                    <Select2
                      className="form-control"
                      multiple
                      data={dealerMembers}
                      value={task? task.task_assignee: undefined}
                      onChange={(e) => handleAssigneeChange(e)}
                    />
                    {errors && errors.task_assignee && <div className="invalid-feedback" style={{display: 'block'}}>
                      {errors.task_assignee}
                    </div>}
                  </FormGroup>
                </Col>
              </Row>
            </form>
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => addTask()}
            >
              Save
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setAddModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={confirmShow}
          toggle={() => setConfirmShow(false)}
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body">
            {`Are you sure you want to delete this task?`}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={() => deleteTask()}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setConfirmShow(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}