import React from 'react';

import {
  FormGroup,
  Input,
  Label,
  Col
} from "reactstrap";

export default function InputSideCustom({
  type = 'text',
  className, 
  label,
  placeholder,
  defaultValue,
  value,
  min,
  max,
  rows,
  onChange,
  disabled,
  readOnly,
  invalid,
  errorMessage
}) {
  return (
    <FormGroup className="row">
      <Label
        className="form-control-label"
        md="5"
      >
        {label}
      </Label>
      <Col md="7">
        <Input
          type={type}
          className={className}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          min={min}
          max={max}
          rows={rows}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
          invalid={invalid}
        />
        <div className="invalid-feedback">
          {errorMessage}
        </div>
      </Col>
    </FormGroup>
  )
}