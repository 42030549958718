import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from "js-cookie";

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import {
  InputKeySelect,
} from 'views/pages/components/InputSelect';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from "reactstrap";

import {
  create,
  update,
  deletee,
} from 'views/policies/CustomerPolicy';

export default function Customer() {
  const history = useHistory();
  const location = useLocation();
  const notificationAlert = useRef(null);

  const permissions = useSelector(state => state.getState).permissions;
  const authUserTeam = useSelector(state => state.getState).authUserTeam;
  
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState('');
  const [dealers, setDealers] = useState('');
  const [filter, setFilter] = useState('');

  const [confirmShow, setConfirmShow] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token');
    setToken(grs_token);

    let savedPageNumber = 1
    let savedFilter     = ''

    if (localStorage.getItem('grms_customer_page_number')) {
      savedPageNumber = localStorage.getItem('grms_customer_page_number')
    }
    if (localStorage.getItem('grms_customer_search_filter')) {
      savedFilter = JSON.parse(localStorage.getItem('grms_customer_search_filter'))
      setFilter(savedFilter)
    }

    const source = axios.CancelToken.source()

    fetchData(savedPageNumber, source, grs_token, savedFilter)

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (pageNumber = 1, source, token, filter) => {
    localStorage.setItem('grms_customer_page_number', pageNumber)
    localStorage.setItem('grms_customer_search_filter', JSON.stringify(filter))

    try {
      const response = await axios({
        method: 'GET',
        url: constants.API_URL + '/customer',
        headers: {
          Authorization: 'Bearer ' + token
        },
        params: {
          ...filter,
          page: pageNumber,
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      setCustomers(data.data.customers.data)
      setItems(data.data.customers)
      if (data.data.dealers) {
        setDealers(data.data.dealers)
      }

      setTimeout(() => {
        setLoading(false)
        setDelay(true)
      }, 500);
    } catch (error) {
      setLoading(false);
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
      }
    }
  }

  const filterCustomer = () => {
    const source = axios.CancelToken.source()
    fetchData(1, source, token, {
      ...filter,
      search: filter.searchValue? filter.searchValue: null
    })
  }

  const resetCustomer = () => {
    setFilter('')
    localStorage.removeItem('grms_customer_page_number')
    localStorage.removeItem('grms_customer_search_filter')

    const source = axios.CancelToken.source()
    fetchData(1, source, token, '')
  }

  const createCustomer = () => {
    history.push('/admin/customer/create');
  }

  const editCustomer = (row) => {
    history.push({
      pathname: '/admin/customer/' + row.id + '/edit',
      state: {
        customer: row,
        filter: filter
      }
    });
  }

  const deleteCustomer = () => {      
    axios({
      method: 'DELETE',
      url: constants.API_URL + '/customer/' + selectedCustomerId,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        setConfirmShow(false);
        if (response.status === 200) {
          let data = response.data;
          console.log('deleted data :: ', data);
          setCustomers(data.data.data);
          notify('success', 'Success', response.data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        setConfirmShow(false);
        notify('warning', 'Warning', error.response.statusText);
      })
  }
  
  return (
    <>
      <CardsHeader name="Customers" parentName="All Customers" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Customers</h1>
                    <p className="text-sm mb-0">You can manage customers.</p>
                  </Col>
                  <Col xs={4} className="text-right">
                    {create(permissions, authUserTeam) &&
                      <Button className="w-100" color="success" type="button" onClick={() => createCustomer()}>Add customer</Button>}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row>
                    {dealers && 
                      <Col md={4}>
                        <InputKeySelect
                          label={`Dealer`}
                          options={dealers}
                          value={filter && filter.dealer_id ? filter.dealer_id : ''}
                          onChange={(e) => setFilter({
                            ...filter,
                            dealer_id: e.target.value,
                          })}
                          defaultOption={true}
                        />
                      </Col>}
                    <Col md={4}>
                      <InputCustom
                        label={`Search`}
                        value={filter && filter.searchValue ? filter.searchValue : ''}
                        onChange={(e) => {
                          setFilter({
                            ...filter,
                            searchValue: e.target.value,
                          })
                        }}
                        onKeyPress={e => {
                          if (e.charCode === 13) {
                            filterCustomer()
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={6} className="mt-2">
                      <Button className="w-100" color="success" type="button" onClick={() => filterCustomer()}>Filter</Button>
                    </Col>
                    <Col md={6} sm={6} className="mt-2">
                      <Button className="w-100" color="warning" type="button" onClick={() => resetCustomer()}>Reset</Button>
                    </Col>
                  </Row>
                </Container>
                <TableCustom 
                  loading={loading}
                  delay={delay}
                  keyField={`full_name`}
                  searchField={true}
                  items={customers}
                  pagination={items}
                  onChange={(pageNumber) => fetchData(pageNumber, axios.CancelToken.source(), token, '')}
                  columns={[
                    {
                      dataField: "full_name",
                      text: "Name",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.first_name? `${row.first_name} ${row.full_name}`: row.full_name
                      }
                    },
                    {
                      dataField: "dealers",
                      text: "Dealer",
                      style: {
                        display: authUserTeam? 'none': 'block'
                      },
                      headerStyle: {
                        display: authUserTeam? 'none': 'block'
                      },
                      sort: true,
                      formatter: (rowContent, row) => {
                        if (row.dealers && row.dealers.length > 0) {
                          return row.dealers.map((dealer, index) => {
                            return index === row.dealers.length - 1? dealer.name: `${dealer.name},`
                          })
                        }
                      }
                    },
                    {
                      dataField: "mobile_number",
                      text: "Phone No",
                      sort: true
                    },
                    {
                      dataField: "email",
                      text: "Email",
                      sort: true
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                {update(permissions, row) && 
                                <DropdownItem
                                  onClick={e => editCustomer(row)}
                                >
                                  Edit
                                </DropdownItem>}
                                {deletee(permissions, row) && 
                                <DropdownItem
                                  onClick={e => {
                                    setConfirmShow(true);
                                    setSelectedCustomerId(row.id);
                                  }}
                                >
                                  Delete
                                </DropdownItem>}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={confirmShow}
          toggle={() => setConfirmShow(false)}
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body">
            {`Are you sure you want to delete this customer?`}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={() => deleteCustomer()}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setConfirmShow(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}