import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap'

export default function Checkout() {
  const { planId } = useParams()
  const notificationAlert = useRef(null)
  const history = useHistory()

  const [token, setToken] = useState('')
  const [plan, setPlan] = useState('')
  const [uniqId, setUniqId] = useState('')

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/tracking/credits/checkout/${planId}`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data

        setPlan(data.data.plan)
        setUniqId(data.data.uniqId)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const goList = () => {
    history.push({
      pathname: `/admin/tracking/index`
    })
  }

  const pay = async () => {
    const base_url = window.location.origin
    try {
      const response = await axios({
        method: "POST",
        url: `${constants.API_URL}/tracking/credits`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          plan_id: planId,
          uniq_id: uniqId,
          return_url: `${base_url}/admin/tracking/credits/confirmation`,
        },
      });
      console.log("response :: ", response.data);
      const data = response.data;

      const paymentUrl = data?.data.paymentUrl;
      if (paymentUrl) {
        document.location.href = paymentUrl;
      }
    } catch (error) {
      console.error("error :: ", error);
      notify(
        "warning",
        "Warning",
        error.response && error.response.data
          ? error.response.data.message
          : error.response
          ? error.response.statusText
          : "Error Occurred!"
      );
    }
  }

  return (
    <>
      <CardsHeader
        name="Leads"
        parentName="Leads Management"
        currentName="Create"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Checkout</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button
                      className="w-100"
                      color="success"
                      type="button"
                      onClick={() => goList()}
                    >
                      Back to list
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <p>
                      <strong>Order Overview !</strong>
                    </p>
                    <hr />
                    <p>Item : {plan?.description}</p>
                    <p>Amount : {constants.round2Decimals(plan?.price)}</p>
                    <hr />
                  </Col>
                  <Col md={12}>
                    <Button className="w-100" onClick={() => pay()}>
                      {`Pay`}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}