import React, { useContext } from 'react';

import { DealContext } from '../context';

import {
  Alert,
} from "reactstrap";

export default function PaymentStatus() {
  const dealContext = useContext(DealContext);
  const {
    canAccessPaymentsDetails,
    isAbleToGetPayments,
  } = dealContext;
  return (
    <>
      {canAccessPaymentsDetails && isAbleToGetPayments &&
        <Alert color="danger">
          Customer has not agreed direct payments flow!
        </Alert>
      }
    </>
  );
}