import React from 'react';

import {
  FormGroup,
  Input,
} from "reactstrap";

export default function InputCustom({ 
  type = 'text',
  className, 
  labelClassName,
  label, 
  placeholder, 
  defaultValue, 
  value, 
  min, 
  max, 
  rows, 
  onChange, 
  disabled, 
  readOnly, 
  invalid, 
  errorMessage,
  onKeyPress,
  checked 
}) {
  return (
    <FormGroup>
      <label className={`form-control-label ${labelClassName}`}>{label}</label>
      <Input
        type={type}
        className={className}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        min={min}
        max={max}
        rows={rows}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
        invalid={invalid}
        onKeyPress={onKeyPress}
        checked={checked}
      />
      <div className="invalid-feedback">
        {errorMessage}
      </div>
    </FormGroup>
  )
}