import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import {
  InputKeySelect,
} from 'views/pages/components/InputSelect'
import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

export default function Daily() {
  const notificationAlert = useRef(null)
  const history = useHistory()

  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [filterOptions, setFilterOptions] = useState("");
  const [items, setItems] = useState([])
  const [dealers, setDealers] = useState([])
  const [role, setRole] = useState('')
  const [vehicleStatuses, setVehicleStatuses] = useState('')
  const [transmissions, setTransmissions] = useState('')
  const [classes, setClasses] = useState('')
  const [types, setTypes] = useState('')

  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState("");

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, 1, grs_token, filterOptions)

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (source, pageNumber = 1, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/inventory/daily-rental-vehicle`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          page: pageNumber,
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data

      setItems(data.data.items)
      setVehicleStatuses(data.data.vehicleStatuses)
      setTransmissions(data.data.transmissions)
      setClasses(data.data.classes)
      setTypes(data.data.types)
      setDealers(data.data.dealers)
      setRole(data.data.role)

      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (error) {
      setLoading(false);
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const filterItems = () => {
    const source = axios.CancelToken.source();
    fetchData(source, 1, token, {
      ...filterOptions,
      search: filterOptions.searchValue,
      dealer_id:
        filterOptions.dealer_id === "-" ? undefined : filterOptions.dealer_id,
    });
  }

  const resetItems = () => {
    const source = axios.CancelToken.source();
    setFilterOptions("");
    fetchData(source, 1, token, "");
  }

  const addVehicle = () => {
    history.push({
      pathname: `/admin/daily-rental-vehicle/create`
    })
  }

  const editVehicle = (row) => {
    history.push({
      pathname: `/admin/daily-rental-vehicle/${row.uuid}/edit`
    })
  }

  return (
    <>
      <CardsHeader
        name="Daily Rental Vehicles Management"
        parentName="Vehicle Management"
        currentName="List"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Vehicles</h1>
                    <p className="text-sm mb-0">
                      Manage all your vehicles here.
                    </p>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button
                      className="w-100"
                      color="success"
                      type="button"
                      onClick={() => addVehicle()}
                    >
                      Add vehicle
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row>
                    {!role.auth_user_team && dealers && (
                      <Col md={4}>
                        <InputKeySelect
                          label={`Dealer`}
                          options={dealers}
                          value={
                            filterOptions && filterOptions.dealer_id
                              ? filterOptions.dealer_id
                              : ""
                          }
                          onChange={(e) =>
                            setFilterOptions({
                              ...filterOptions,
                              dealer_id: e.target.value,
                            })
                          }
                          defaultOption={true}
                        />
                      </Col>
                    )}
                    <Col md={4}>
                      <InputCustom
                        label={`Search`}
                        value={
                          filterOptions && filterOptions.searchValue
                            ? filterOptions.searchValue
                            : ""
                        }
                        onChange={(e) =>
                          setFilterOptions({
                            ...filterOptions,
                            searchValue: e.target.value,
                          })
                        }
                        onKeyPress={(e) => {
                          if (e.charCode === 13) {
                            filterItems();
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <div className="d-flex flex-row justify-content-center">
                    <Button
                      className="w-50"
                      color="success"
                      onClick={() => filterItems()}
                    >
                      Filter
                    </Button>
                    <Button
                      className="w-50"
                      color="danger"
                      onClick={() => resetItems()}
                    >
                      Reset
                    </Button>
                  </div>
                </Container>
                <TableCustom
                  loading={loading}
                  items={items?.data || []}
                  keyField={`id`}
                  searchField={false}
                  pagination={items}
                  onChange={(pageNumber) =>
                    fetchData(axios.CancelToken.source(), pageNumber, token, {
                      ...filterOptions,
                      search: filterOptions.searchValue,
                    })
                  }
                  columns={[
                    {
                      dataField: "title",
                      text: "Vehicle",
                      sort: true,
                    },
                    {
                      dataField: "class",
                      text: "Class",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return classes && row.class ? classes[row.class] : ''
                      }
                    },
                    {
                      dataField: "type",
                      text: "Type",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return types && row.type ? types[row.type] : ''
                      }
                    },
                    {
                      dataField: "no_of_doors",
                      text: "No of Doors",
                      sort: true,
                    },
                    {
                      dataField: "no_of_seats",
                      text: "No of Seats",
                      sort: true,
                    },
                    {
                      dataField: "transmission",
                      text: "Transmission",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return transmissions && row.transmission ? transmissions[row.transmission] : ''
                      }
                    },
                    {
                      dataField: "vehicle_items_count",
                      text: "No of vehicles",
                      sort: true,
                    },
                    {
                      dataField: "daily_price",
                      text: "Daily price",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.daily_price ? constants.round2Decimals(row.daily_price) : ''
                      }
                    },
                    {
                      dataField: "",
                      text: "",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={(e) => editVehicle(row)}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) => {
                                    setSelectedDealId(row.id);
                                    setConfirmModal(true);
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </>
                        );
                      },
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}