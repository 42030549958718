import * as actionTypes from 'reducers/action-types.js';

const login = (email, password) => {
  return {
    type: actionTypes.LOGIN,
    email,
    password
  }
}

const getProfileData = (token) => {
  return {
    type: actionTypes.GET_PROFILE_DATA,
    token
  }
}

const getNotifications = (token) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS,
    token
  }
}

const emptyState = () => {
  return {
    type: actionTypes.EMPTY_STATE
  }
}

export default { 
  login, 
  getProfileData,
  getNotifications,
  emptyState 
};