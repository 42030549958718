import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import Cookies from "js-cookie";

import * as constants from "constants/index.js";
import CardsHeader from "layouts/Headers/CardsHeader.js";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";

export default function Credits() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState("");
  const [plans, setPlans] = useState([]);

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get("grs_token");
    setToken(grs_token);

    const source = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const response = await axios({
          method: "GET",
          url: `${constants.API_URL}/tracking/credits`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        });
        console.log("response :: ", response.data);
        const data = response.data;

        setPlans(data?.data?.plans?.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled :: ", error);
        } else {
          console.error("error :: ", error);
          notify(
            "warning",
            "Warning",
            error.response && error.response.data
              ? error.response.data.message
              : error.response
              ? error.response.statusText
              : "Error Occurred!"
          );
        }
      }
    };

    fetchData();

    return () => {
      source.cancel();
    };
  }, []);

  const goList = () => {
    history.push({
      pathname: `/admin/tracking/index`,
    });
  };

  const show = async (planId) => {
    history.push({
      pathname: `/admin/tracking/credits/checkout/${planId}`
    })
  };

  return (
    <>
      <CardsHeader
        name="Leads"
        parentName="Leads Management"
        currentName="Create"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Load data</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button
                      className="w-100"
                      color="success"
                      type="button"
                      onClick={() => goList()}
                    >
                      Back to list
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  {plans &&
                    plans.length > 0 &&
                    plans.map((plan, index) => {
                      return (
                        <Col
                          md={4}
                          sm={6}
                          xs={12}
                          className="text-center"
                          key={index}
                        >
                          <Card>
                            <CardHeader>
                              <h1 className="mb-0">{plan?.name}</h1>
                            </CardHeader>
                            <CardBody>
                              <p className="card-text mb-4">
                                {plan?.description}
                              </p>
                              <p className="card-text mb-4 font-weight-500">{`${constants.round2Decimals(
                                plan?.price
                              )} ${plan?.currency}`}</p>
                              <Button
                                className="btn-icon-clipboard"
                                onClick={() => show(plan?.id)}
                              >
                                <i className="ni ni-cart"></i>
                                <span>Buy</span>
                              </Button>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
