import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import NotificationAlert from "react-notification-alert"
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import {
  InputKeySelect,
  InputArraySelect,
} from 'views/pages/components/InputSelect'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledAlert,
} from 'reactstrap'

export default function VehicleCreate() {
  const notificationAlert = useRef(null)
  const history = useHistory()
  
  const [token, setToken] = useState('')
  const [vehicle, setVehicle] = useState([])
  const [makes, setMakes] = useState([])
  const [models, setModels] = useState([])
  const [filteredModels, setFilteredModels] = useState([])
  const [vehicleStatuses, setVehicleStatuses] = useState('')

  const [vehicleData, setVehicleData] = useState({
    responseText: ''
  })

  const [errors, setErrors] = useState('')

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/vehicle/create`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        
        setVehicle({
          ...data.data.item,
          status: data.data.item.status ? data.data.item.status : 'available',
          rfl_taxed_date_term: data.data.item.rfl_taxed_date_term ? data.data.item.rfl_taxed_date_term : 12,
          interval: 'day',
          date_of_reg: data.data.item.date_of_reg ? constants.formatDate(data.data.item.date_of_reg) : '',
          delivered_to_us: data.data.item.delivered_to_us ? constants.formatDate(data.data.item.delivered_to_us) : '',
          mot_date: data.data.item.mot_date ? constants.formatDate(data.data.item.mot_date) : '',
          rfl_taxed_date: data.data.item.rfl_taxed_date ? constants.formatDate(data.data.item.rfl_taxed_date) : '',
        })
        setMakes(data.data.makes)
        setModels(data.data.models)
        setFilteredModels(data.data.models)
        setVehicleStatuses(data.data.vehicleStatuses)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const createVehicle = () => {
    let formData = new FormData()
    if (vehicle.images_collection && vehicle.images_collection.length > 0) {
      for (let index = 0; index < vehicle.images_collection.length; index++) {
        const element = vehicle.images_collection[index]
        formData.append(`images_collection[${index}]`, element)
      }
    }
    Object.keys(vehicle).map(key => {
      if (key != 'images_collection') {
        console.log(`form data [${key}]: `, vehicle[key])
        formData.append(key, vehicle[key])
      }
    })
    axios({
      method: 'POST',
      url: `${constants.API_URL}/vehicle`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: formData,
    })
      .then(response => {
        setErrors('')
        console.log('vehicle create response :: ', response.data)
        let data = response.data
        notify('success', 'Success', data.message)
        setTimeout(() => {
          history.push({
            pathname: `/admin/vehicle/${data.data.id}/edit`
          })
        }, 1500)
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors)
          return
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  const getVehicleDataByStockNo = () => {
    if (!vehicle.stock_no) {
      setErrors({
        ...errors,
        stock_no: 'Please fill reg no'
      })
      return
    }

    /**
     * Get vehicle data
     */
    const _vehicle_data_request = axios({
      method: 'GET',
      url: `${constants.UK_VEHICLE_DATA_API_URL}/VehicleData?v=2&api_nullitems=1&auth_apikey=${constants.UK_VEHICLE_DATA_API_KEY}&key_VRM=${vehicle.stock_no}`,
    })

    /**
     * Get vehicle valuation data
     */
    const _valuation_data_request = axios({
      method: 'GET',
      url: `${constants.UK_VEHICLE_DATA_API_URL}/ValuationData?v=2&api_nullitems=1&auth_apikey=${constants.UK_VEHICLE_DATA_API_KEY}&key_VRM=${vehicle.stock_no}`,
    })

    /**
     * Get vehicle valuation data
     */
    const _mot_tax_data_request = axios({
      method: 'GET',
      url: `${constants.UK_VEHICLE_DATA_API_URL}/MotHistoryAndTaxStatusData?v=2&api_nullitems=1&auth_apikey=${constants.UK_VEHICLE_DATA_API_KEY}&key_VRM=${vehicle.stock_no}`,
    })

    axios.all([_vehicle_data_request, _valuation_data_request, _mot_tax_data_request])
      .then(axios.spread((...responses) => {

        const _vehicle_data   = responses[0].data
        const _valuation_data = responses[1].data
        const _mot_tax_data   = responses[2].data

        if (_vehicle_data.Response && _valuation_data.Response && _mot_tax_data.Response) {

          const motTaxData = _mot_tax_data.Response.StatusCode === 'Success' ? _mot_tax_data.Response.DataItems.VehicleStatus : []

          if (_vehicle_data.Response.StatusCode === 'Success' && _valuation_data.Response.StatusCode === 'Success') {
            setErrors({
              ...errors,
              stock_no: ''
            })

            setVehicleData({
              ..._vehicle_data,
              responseText: 'Got successfully!'
            })

            const dataItems     = _vehicle_data.Response.DataItems
            const valuationList = _valuation_data.Response.DataItems
            setVehicle({
              ...vehicle,
              full_vin:              dataItems.VehicleRegistration.Vin ? dataItems.VehicleRegistration.Vin : '',
              engine_number:         dataItems.VehicleRegistration.EngineNumber ? dataItems.VehicleRegistration.EngineNumber : '',
              first_registered:      dataItems.VehicleRegistration.DateFirstRegistered ? dataItems.VehicleRegistration.DateFirstRegistered : '',
              first_registered_uk:   dataItems.VehicleRegistration.DateFirstRegisteredUk ? dataItems.VehicleRegistration.DateFirstRegisteredUk : '',
              year_manufacture:      dataItems.VehicleRegistration.YearOfManufacture ? dataItems.VehicleRegistration.YearOfManufacture : '',
              gearbox_type:          dataItems.VehicleRegistration.Transmission ? dataItems.VehicleRegistration.Transmission : '',
              colour:                dataItems.VehicleRegistration.Colour ? dataItems.VehicleRegistration.Colour : '',
              gears_number:          dataItems.SmmtDetails.NumberOfGears ? dataItems.SmmtDetails.NumberOfGears: 0,
              fuel_type:             dataItems.VehicleRegistration.FuelType,
              engine_capacity:       dataItems.VehicleRegistration.EngineCapacity,
              door_plan:             dataItems.VehicleRegistration.DoorPlanLiteral,
              seating_capacity:      dataItems.VehicleRegistration.SeatingCapacity ? dataItems.VehicleRegistration.SeatingCapacity : 0,
              co2:                   dataItems.VehicleRegistration.Co2Emissions ? dataItems.VehicleRegistration.Co2Emissions : 0,
              kerb_weight:           dataItems.TechnicalDetails.Dimensions.KerbWeight ? dataItems.TechnicalDetails.Dimensions.KerbWeight : 0,
              vin_last_5:            dataItems.VehicleRegistration.VinLast5 ? dataItems.VehicleRegistration.VinLast5 : 0,
              scrapped:              dataItems.VehicleRegistration.Scrapped,
              date_scrapped:         dataItems.VehicleRegistration.DateScrapped,
              exported:              dataItems.VehicleRegistration.Exported,
              date_exported:         dataItems.VehicleRegistration.DateExported,
              imported:              dataItems.VehicleRegistration.Imported,
              imported_non_eu:       dataItems.VehicleRegistration.ImportNonEu,
              euro_status:           dataItems.TechnicalDetails.General.EuroStatus ? dataItems.TechnicalDetails.General.EuroStatus : '',
              driven_axle:           dataItems.TechnicalDetails.General.DrivingAxle ? dataItems.TechnicalDetails.General.DrivingAxle : '',
              type_approval:         dataItems.TechnicalDetails.General.TypeApprovalCategory ? dataItems.TechnicalDetails.General.TypeApprovalCategory : '',
              vehicle_class:         dataItems.VehicleRegistration.VehicleClass,
              gross_weight:          dataItems.TechnicalDetails.Dimensions.GrossVehicleWeight ? dataItems.TechnicalDetails.Dimensions.GrossVehicleWeight : 0,
              length:                dataItems.TechnicalDetails.Dimensions.CarLength ? dataItems.TechnicalDetails.Dimensions.CarLength : 0,
              height:                dataItems.TechnicalDetails.Dimensions.Height ? dataItems.TechnicalDetails.Dimensions.Height : 0,
              width:                 dataItems.TechnicalDetails.Dimensions.Width ? dataItems.TechnicalDetails.Dimensions.Width : 0,
              power_bhp:             dataItems.TechnicalDetails.Performance.Power.Bhp ? dataItems.TechnicalDetails.Performance.Power.Bhp : 0,
              power_kw:              dataItems.TechnicalDetails.Performance.Power.Kw ? dataItems.TechnicalDetails.Performance.Power.Kw : 0,
              power_rpm:             dataItems.TechnicalDetails.Performance.Power.Rpm ? dataItems.TechnicalDetails.Performance.Power.Rpm : 0,
              torque_ftlb:           dataItems.TechnicalDetails.Performance.Torque.FtLb ? dataItems.TechnicalDetails.Performance.Torque.FtLb : 0,
              torque_nm:             dataItems.TechnicalDetails.Performance.Torque.Nm ? dataItems.TechnicalDetails.Performance.Torque.Nm : 0,
              torque_rpm:            dataItems.TechnicalDetails.Performance.Torque.Rpm ? dataItems.TechnicalDetails.Performance.Torque.Rpm : 0,
              max_speed_mph:         dataItems.TechnicalDetails.Performance.MaxSpeed.Mph ? dataItems.TechnicalDetails.Performance.MaxSpeed.Mph : 0,
              max_speed_kph:         dataItems.TechnicalDetails.Performance.MaxSpeed.Kph ? dataItems.TechnicalDetails.Performance.MaxSpeed.Kph : 0,
              cylinders:             dataItems.TechnicalDetails.General.Engine.NumberOfCylinders ? dataItems.TechnicalDetails.General.Engine.NumberOfCylinders : 0,
              valves_per_cyl:        dataItems.TechnicalDetails.General.Engine.ValvesPerCylinder ? dataItems.TechnicalDetails.General.Engine.ValvesPerCylinder : 0,
              stroke:                dataItems.TechnicalDetails.General.Engine.Stroke ? dataItems.TechnicalDetails.General.Engine.Stroke : '',
              bore:                  dataItems.TechnicalDetails.General.Engine.Bore ? dataItems.TechnicalDetails.General.Engine.Bore : '',
              arrangement:           dataItems.TechnicalDetails.General.Engine.CylinderArrangement ? dataItems.TechnicalDetails.General.Engine.CylinderArrangement : 0,
              cam_type:              dataItems.TechnicalDetails.General.Engine.ValveGear ? dataItems.TechnicalDetails.General.Engine.ValveGear : '',
              engine_location:       dataItems.TechnicalDetails.General.Engine.Location ? dataItems.TechnicalDetails.General.Engine.Location : '',
              aspiration:            dataItems.TechnicalDetails.General.Engine.Aspiration ? dataItems.TechnicalDetails.General.Engine.Aspiration : '',
              total_keepers:         dataItems.VehicleHistory.NumberOfPreviousKeepers ? dataItems.VehicleHistory.NumberOfPreviousKeepers : 0,
              colour_changes:        dataItems.VehicleHistory.ColourChangeCount ? dataItems.VehicleHistory.ColourChangeCount : 0,
              v5_certificates:       dataItems.VehicleHistory.V5CCertificateCount ? dataItems.VehicleHistory.V5CCertificateCount : 0,
              dealer_forecourt:      valuationList.ValuationList.DealerForecourt ? valuationList.ValuationList.DealerForecourt : 0,
              part_exchange:         valuationList.ValuationList.PartExchange ? valuationList.ValuationList.PartExchange : 0,
              trade_poor:            valuationList.ValuationList.TradePoor ? valuationList.ValuationList.TradePoor : 0,
              trade_retail:          valuationList.ValuationList.TradeRetail ? valuationList.ValuationList.TradeRetail : 0,
              trade_average:         valuationList.ValuationList.TradeAverage ? valuationList.ValuationList.TradeAverage : 0,
              auction:               valuationList.ValuationList.Auction ? valuationList.ValuationList.Auction : 0,
              valuation_time:        valuationList.ValuationTime ? constants.formatDate(valuationList.ValuationTime) : '',
              mot_date:              motTaxData.NextMotDueDate ? moment(motTaxData.NextMotDueDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
              rfl_taxed_date:        motTaxData.MotVed.VedExpiryDate ? moment(motTaxData.MotVed.VedExpiryDate).format('YYYY-MM-DD') : null,
            })
          } else if (_vehicle_data.Response.StatusCode === 'Success' && _valuation_data.Response.StatusCode !== 'Success') {
            setErrors({
              ...errors,
              stock_no: ''
            })

            setVehicleData({
              ..._vehicle_data,
              responseText: 'Got successfully!'
            })

            const dataItems = _vehicle_data.Response.DataItems
            setVehicle({
              ...vehicle,
              full_vin:              dataItems.VehicleRegistration.Vin ? dataItems.VehicleRegistration.Vin : '',
              engine_number:         dataItems.VehicleRegistration.EngineNumber ? dataItems.VehicleRegistration.EngineNumber : '',
              first_registered:      dataItems.VehicleRegistration.DateFirstRegistered ? dataItems.VehicleRegistration.DateFirstRegistered : '',
              first_registered_uk:   dataItems.VehicleRegistration.DateFirstRegisteredUk ? dataItems.VehicleRegistration.DateFirstRegisteredUk : '',
              year_manufacture:      dataItems.VehicleRegistration.YearOfManufacture ? dataItems.VehicleRegistration.YearOfManufacture : '',
              gearbox_type:          dataItems.VehicleRegistration.Transmission ? dataItems.VehicleRegistration.Transmission : '',
              colour:                dataItems.VehicleRegistration.Colour ? dataItems.VehicleRegistration.Colour : '',
              gears_number:          dataItems.SmmtDetails.NumberOfGears ? dataItems.SmmtDetails.NumberOfGears: 0,
              fuel_type:             dataItems.VehicleRegistration.FuelType,
              engine_capacity:       dataItems.VehicleRegistration.EngineCapacity,
              door_plan:             dataItems.VehicleRegistration.DoorPlanLiteral,
              seating_capacity:      dataItems.VehicleRegistration.SeatingCapacity ? dataItems.VehicleRegistration.SeatingCapacity : 0,
              co2:                   dataItems.VehicleRegistration.Co2Emissions ? dataItems.VehicleRegistration.Co2Emissions : 0,
              kerb_weight:           dataItems.TechnicalDetails.Dimensions.KerbWeight ? dataItems.TechnicalDetails.Dimensions.KerbWeight : 0,
              vin_last_5:            dataItems.VehicleRegistration.VinLast5 ? dataItems.VehicleRegistration.VinLast5 : 0,
              scrapped:              dataItems.VehicleRegistration.Scrapped,
              date_scrapped:         dataItems.VehicleRegistration.DateScrapped,
              exported:              dataItems.VehicleRegistration.Exported,
              date_exported:         dataItems.VehicleRegistration.DateExported,
              imported:              dataItems.VehicleRegistration.Imported,
              imported_non_eu:       dataItems.VehicleRegistration.ImportNonEu,
              euro_status:           dataItems.TechnicalDetails.General.EuroStatus ? dataItems.TechnicalDetails.General.EuroStatus : '',
              driven_axle:           dataItems.TechnicalDetails.General.DrivingAxle ? dataItems.TechnicalDetails.General.DrivingAxle : '',
              type_approval:         dataItems.TechnicalDetails.General.TypeApprovalCategory ? dataItems.TechnicalDetails.General.TypeApprovalCategory : '',
              vehicle_class:         dataItems.VehicleRegistration.VehicleClass,
              gross_weight:          dataItems.TechnicalDetails.Dimensions.GrossVehicleWeight ? dataItems.TechnicalDetails.Dimensions.GrossVehicleWeight : 0,
              length:                dataItems.TechnicalDetails.Dimensions.CarLength ? dataItems.TechnicalDetails.Dimensions.CarLength : 0,
              height:                dataItems.TechnicalDetails.Dimensions.Height ? dataItems.TechnicalDetails.Dimensions.Height : 0,
              width:                 dataItems.TechnicalDetails.Dimensions.Width ? dataItems.TechnicalDetails.Dimensions.Width : 0,
              power_bhp:             dataItems.TechnicalDetails.Performance.Power.Bhp ? dataItems.TechnicalDetails.Performance.Power.Bhp : 0,
              power_kw:              dataItems.TechnicalDetails.Performance.Power.Kw ? dataItems.TechnicalDetails.Performance.Power.Kw : 0,
              power_rpm:             dataItems.TechnicalDetails.Performance.Power.Rpm ? dataItems.TechnicalDetails.Performance.Power.Rpm : 0,
              torque_ftlb:           dataItems.TechnicalDetails.Performance.Torque.FtLb ? dataItems.TechnicalDetails.Performance.Torque.FtLb : 0,
              torque_nm:             dataItems.TechnicalDetails.Performance.Torque.Nm ? dataItems.TechnicalDetails.Performance.Torque.Nm : 0,
              torque_rpm:            dataItems.TechnicalDetails.Performance.Torque.Rpm ? dataItems.TechnicalDetails.Performance.Torque.Rpm : 0,
              max_speed_mph:         dataItems.TechnicalDetails.Performance.MaxSpeed.Mph ? dataItems.TechnicalDetails.Performance.MaxSpeed.Mph : 0,
              max_speed_kph:         dataItems.TechnicalDetails.Performance.MaxSpeed.Kph ? dataItems.TechnicalDetails.Performance.MaxSpeed.Kph : 0,
              cylinders:             dataItems.TechnicalDetails.General.Engine.NumberOfCylinders ? dataItems.TechnicalDetails.General.Engine.NumberOfCylinders : 0,
              valves_per_cyl:        dataItems.TechnicalDetails.General.Engine.ValvesPerCylinder ? dataItems.TechnicalDetails.General.Engine.ValvesPerCylinder : 0,
              stroke:                dataItems.TechnicalDetails.General.Engine.Stroke ? dataItems.TechnicalDetails.General.Engine.Stroke : '',
              bore:                  dataItems.TechnicalDetails.General.Engine.Bore ? dataItems.TechnicalDetails.General.Engine.Bore : '',
              arrangement:           dataItems.TechnicalDetails.General.Engine.CylinderArrangement ? dataItems.TechnicalDetails.General.Engine.CylinderArrangement : 0,
              cam_type:              dataItems.TechnicalDetails.General.Engine.ValveGear ? dataItems.TechnicalDetails.General.Engine.ValveGear : '',
              engine_location:       dataItems.TechnicalDetails.General.Engine.Location ? dataItems.TechnicalDetails.General.Engine.Location : '',
              aspiration:            dataItems.TechnicalDetails.General.Engine.Aspiration ? dataItems.TechnicalDetails.General.Engine.Aspiration : '',
              total_keepers:         dataItems.VehicleHistory.NumberOfPreviousKeepers ? Number(dataItems.VehicleHistory.NumberOfPreviousKeepers) + 1 : 0,
              colour_changes:        dataItems.VehicleHistory.ColourChangeCount ? dataItems.VehicleHistory.ColourChangeCount : 0,
              v5_certificates:       dataItems.VehicleHistory.V5CCertificateCount ? dataItems.VehicleHistory.V5CCertificateCount : 0,
              mot_date:              motTaxData.NextMotDueDate ? moment(motTaxData.NextMotDueDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
              rfl_taxed_date:        motTaxData.MotVed.VedExpiryDate ? moment(motTaxData.MotVed.VedExpiryDate).format('YYYY-MM-DD') : null,
            })
          } else {
            setErrors({
              ...errors,
              stock_no: _vehicle_data.Response.StatusMessage
            })

            setVehicleData({
              ..._vehicle_data,
              responseText: 'There is no result for the current Reg no.'
            })
          }
        }
      }))
      .catch(error => {
        console.error('error :: ', error)
      })
  }

  const keyPressRegNo = (event, regNo) => {
    setVehicle({
      ...vehicle,
      stock_no: regNo
    })
    if (event.key === 'Enter') {
      getVehicleDataByStockNo()
    }
  }

  const selectMake = (makeId) => {
    let _models = []
    if (makeId === '-') {
      setFilteredModels(models)
      return
    }
    if (models.length > 0) {
      _models = models.filter((model) => {
        if (model.vehicle_make && model.vehicle_make.id == makeId) {
          return model
        }
      })

      setFilteredModels(_models)
    }
  }

  return (
    <>
      <CardsHeader name="Vehicles" parentName="Vehicle Management" currentName="Create" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl={6} md={6} sm={12}>
            <Card>
              <CardHeader>
                <h1 className="title mb-0">General vehicle information</h1>
              </CardHeader>			  
              <CardBody>
                <InputKeySelect 
                  label={`Vehicle status`}
                  value={vehicle && vehicle.status? vehicle.status: ''}
                  options={vehicleStatuses}
                  disabled={true}
                />
                <Row>
                  <Col md={6} sm={12}>
                    <InputCustom
                      label={`Reg no`}
                      value={vehicle && vehicle.stock_no ? vehicle.stock_no : ''}
                      onChange={e => setVehicle({
                        ...vehicle,
                        stock_no: e.target.value
                      })}
                      invalid={errors && errors.stock_no ? true : false}
                      errorMessage={errors.stock_no}
                      onKeyPress={e => keyPressRegNo(e, e.target.value)}
                    />
                  </Col>
                  <Col md={6} sm={12} className="pt-4-5">
                    <Button className="w-100" color="success" onClick={() => getVehicleDataByStockNo()}><i className="fa fa-search mr-2" aria-hidden="true"></i> Lookup UK registration</Button>
                  </Col>
                </Row>
                <InputArraySelect
                  label={`Make`}
                  value={vehicle && vehicle.vehicle_make_selector ? vehicle.vehicle_make_selector : ''}
                  options={makes}
                  onChange={e => {
                    setVehicle({
                      ...vehicle,
                      vehicle_make_selector: e.target.value,
                    })
                    selectMake(e.target.value)
                  }}
                  valueKey={`id`}
                  labelKey={`name`}
                  defaultOption={true}
                  invalid={errors && errors.vehicle_make_selector ? true : false}
                  errorMessage={errors.vehicle_make_selector}
                />
                <InputArraySelect
                  label={`Vehicle model`}
                  value={vehicle && vehicle.vehicle_model_id ? vehicle.vehicle_model_id : ''}
                  options={filteredModels}
                  onChange={e => setVehicle({
                    ...vehicle,
                    vehicle_model_id: e.target.value,
                  })}
                  valueKey={`id`}
                  labelKey={`name`}
                  defaultOption={true}
                  invalid={errors && errors.vehicle_model_id ? true : false}
                  errorMessage={errors.vehicle_model_id}
                />
                <InputCustom 
                  label={`Current mileage`}
                  value={vehicle && vehicle.current_mileage? vehicle.current_mileage: ''}
                  onChange={e => setVehicle({
                    ...vehicle,
                    current_mileage: e.target.value,
                  })}
                  invalid={errors && errors.current_mileage? true: false}
                  errorMessage={errors.current_mileage}
                />
                <InputCustom 
                  label={`Derivative`}
                  value={vehicle && vehicle.derivative? vehicle.derivative: ''}
                  onChange={e => setVehicle({
                    ...vehicle,
                    derivative: e.target.value,
                  })}
                  invalid={errors && errors.derivative? true: false}
                  errorMessage={errors.derivative}
                />
                <div className="d-flex flex-row justify-content-center mt-4">
                  <Button className="w-100" color="success" onClick={() => createVehicle()}>Save</Button>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={6} md={6} sm={12}>
            <Card>
              <CardHeader>
                <h1 className="title mb-0">Vehicle information from 3rd service</h1>
              </CardHeader>
              <CardBody>
                {vehicleData && vehicleData.Response && vehicleData.Response.StatusCode === 'Success' ? (
                  <>
                    <textarea cols="30" rows="10" className="vehicle-data-display" readOnly defaultValue={JSON.stringify(vehicleData.Response.DataItems, undefined, 4)}>
                    </textarea>
                  </>
                ) : (
                  <>
                    {vehicleData.responseText &&
                      <UncontrolledAlert color="info" fade={false}>
                        <span className="alert-inner--icon">
                          <i className="ni ni-notification-70" />
                        </span>{" "}
                        <span className="alert-inner--text">
                          <strong>Info!</strong> {vehicleData.responseText}
                        </span>
                      </UncontrolledAlert>
                    }
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}