import React, { useContext } from 'react';

import { DealContext } from '../context';

import InputCustom from 'views/pages/components/InputCustom';
import {
  InputKeySelect,
  InputArraySelect,
} from 'views/pages/components/InputSelect';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
} from "reactstrap";

export default function InfoTab() {
  const dealContext = useContext(DealContext);
  const {
    businessTypes,
    companyTypes,
    dealChecksheet,
    dealStatuses,
    intervals,
    deal, setDeal,
    paymentIntervals,
    company, setCompany,
    deliveredStatuses,
    customer, setCustomer,
    vehicle, setVehicle,
    checkSheetCount,
    dealErrors,
    transitModal, setTransitModal,
    updateDeal,
    defaulter,
    expired
  } = dealContext;
  return (
    <CardBody>
      <Card>
        <CardHeader>
          <h2>Deal Info</h2>
          <Row>
            <Col>
              {deliveredStatuses && deliveredStatuses.length > 0 && deliveredStatuses.includes(deal.deal_status) &&
                <Button className="w-100" color="danger" type="button" onClick={() => setTransitModal({
                  show: true,
                  header: 'Are you sure you want to add customer as defaulter?',
                  body: 'Proceeding with this operation will turn vehicle available!',
                  onClick: () => defaulter()
                })}>Defaulter</Button>}
            </Col>
            <Col>
              {deliveredStatuses && deliveredStatuses[0] && deliveredStatuses[0] === deal.deal_status &&
                <Button className="w-100" color="success" type="button" onClick={() => setTransitModal({
                  show: true,
                  header: 'Are you sure you want to mark as expired?',
                  body: 'Proceeding with this operation will mark deal as expired and vehicle available!',
                  onClick: () => expired()
                })}>Expired</Button>}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={4}>
              <InputKeySelect
                label={`Deal status`}
                value={deal ? deal.deal_status : ''}
                onChange={(e) => setDeal({
                  ...deal,
                  deal_status: e.target.value,
                })}
                options={dealStatuses}
                defaultOption={true}
              />
            </Col>
            <Col md={4}>
              <InputCustom
                type={`date`}
                label={`Deal Date`}
                value={deal && deal.deal_date ? deal.deal_date : ''}
                onChange={(e) => setDeal({
                  ...deal,
                  deal_date: e.target.value
                })}
              />
            </Col>
            <Col md={4}>
              <InputCustom
                type={`date`}
                label={`Delivery date`}
                value={deal && deal.delivery_date ? deal.delivery_date : ''}
                onChange={(e) => setDeal({
                  ...deal,
                  delivery_date: e.target.value
                })}
                invalid={dealErrors && dealErrors.delivery_date ? true : false}
                errorMessage={dealErrors.delivery_date}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <InputCustom
                type={`date`}
                label={`Canceled date`}
                value={deal && deal.canceled_at ? deal.canceled_at : ''}
                onChange={(e) => setDeal({
                  ...deal,
                  canceled_at: e.target.value
                })}
              />
            </Col>
          </Row>
        </CardBody>
        <CardHeader>
          <h2>Customer Info</h2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={4}>
              <InputCustom
                label={`Name`}
                value={customer && customer.full_name ? customer.full_name : ''}
                onChange={(e) => setCustomer({
                  ...customer,
                  full_name: e.target.value,
                })}
              />
            </Col>
            <Col md={4}>
              <InputCustom
                label={`Address`}
                value={customer && customer.address ? customer.address : ''}
                onChange={(e) => setCustomer({
                  ...customer,
                  address: e.target.value,
                })}
              />
            </Col>
            <Col md={4}>
              <InputCustom
                label={`Email`}
                value={customer && customer.email ? customer.email : ''}
                onChange={(e) => setCustomer({
                  ...customer,
                  email: e.target.value,
                })}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <InputCustom
                label={`Mobile Number`}
                value={customer && customer.mobile_number ? customer.mobile_number : ''}
                onChange={(e) => setCustomer({
                  ...customer,
                  mobile_number: e.target.value,
                })}
              />
            </Col>
            <Col md={4}>
              <InputCustom
                label={`Land Line Number`}
                value={customer && customer.land_line_number ? customer.land_line_number : ''}
                onChange={(e) => setCustomer({
                  ...customer,
                  land_line_number: e.target.value,
                })}
              />
            </Col>
            <Col md={4}>
              <InputCustom
                label={`Postal Code`}
                value={customer && customer.postal_code ? customer.postal_code : ''}
                onChange={(e) => setCustomer({
                  ...customer,
                  postal_code: e.target.value,
                })}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <InputCustom
                label={`N.I Number`}
                value={customer && customer.national_insurance_number ? customer.national_insurance_number : ''}
                onChange={(e) => setCustomer({
                  ...customer,
                  national_insurance_number: e.target.value,
                })}
              />
            </Col>
            <Col md={4}>
              <InputCustom
                label={`D/L Number`}
                value={customer && customer.driver_license_number ? customer.driver_license_number : ''}
                onChange={(e) => setCustomer({
                  ...customer,
                  driver_license_number: e.target.value,
                })}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h2 className="text-center">Check Sheet</h2>
        </CardHeader>
        <CardBody>
          <Row className="mt-4">
            {
              Object.keys(dealChecksheet).map(key1 => {
                return (
                  <Col md={12 / checkSheetCount} className={Object.keys(dealChecksheet).pop() != key1 ? "border-right border-dark" : ""} key={key1} >
                    <h2 className="text-center">{key1}</h2>
                    <div className="mt-5">
                      {
                        Object.keys(dealChecksheet[key1]).map(key2 => {
                          return (
                            <Row className="mt-3" key={key2}>
                              <Col md={5}>
                                <label className="form-control-label">{dealChecksheet[key1][key2]}</label>
                              </Col>
                              <Col md={7}>
                                <label className="custom-toggle">
                                  <input
                                    type="checkbox"
                                    checked={deal && deal.checksheet && deal.checksheet[key2] == 1 ? true : false}
                                    onChange={(e) => setDeal({
                                      ...deal,
                                      checksheet: {
                                        ...deal.checksheet,
                                        [key2]: deal.checksheet[key2] && deal.checksheet[key2] == 1 ? 0 : 1
                                      }
                                    })}
                                  />
                                  <span
                                    className="custom-toggle-slider rounded-circle"
                                    data-label-off="No"
                                    data-label-on="Yes"
                                  />
                                </label>
                              </Col>
                            </Row>
                          )
                        })
                      }
                    </div>
                  </Col>
                )
              })
            }
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h2>Vehicle information
                      {deal.vehicleMake && deal.vehicleMake.name ? ` : ${deal.vehicleMake.name}` : ''}
            {deal.vehicleModel && deal.vehicleModel.name ? `, ${deal.vehicleModel.name}` : ''}
          </h2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <InputCustom
                label={`Reg no`}
                value={vehicle && vehicle.stock_no ? vehicle.stock_no : ''}
                onChange={(e) => setVehicle({
                  ...vehicle,
                  stock_no: e.target.value,
                })}
              />
            </Col>
            <Col md={6}>
              <InputCustom
                label={`Colour`}
                value={vehicle && vehicle.colour ? vehicle.colour : ''}
                onChange={(e) => setVehicle({
                  ...vehicle,
                  colour: e.target.value,
                })}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <InputCustom
                label={`Derivative`}
                value={vehicle && vehicle.derivative ? vehicle.derivative : ''}
                onChange={(e) => setVehicle({
                  ...vehicle,
                  derivative: e.target.value,
                })}
              />
            </Col>
            <Col md={6}>
              <InputCustom
                label={`Current mileage`}
                value={deal && deal.deal_mileage ? deal.deal_mileage : ''}
                onChange={(e) => setVehicle({
                  ...deal,
                  deal_mileage: e.target.value,
                })}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <InputCustom
                type={`textarea`}
                label={`Extras`}
                rows="5"
                value={deal && deal.extras ? deal.extras : ''}
                onChange={(e) => setDeal({
                  ...deal,
                  extras: e.target.value,
                })}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h2>Finance info</h2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={3}>
              <InputCustom
                type={`number`}
                label={`Payment frequencies`}
                value={deal && deal.payment_frequency ? deal.payment_frequency : ''}
                onChange={(e) => setDeal({
                  ...deal,
                  payment_frequency: e.target.value,
                })}
              />
            </Col>
            <Col md={3}>
              <InputKeySelect
                label={`Interval`}
                value={deal && deal.payment_frequency_interval ? deal.payment_frequency_interval : ''}
                options={paymentIntervals}
                onChange={(e) => setDeal({
                  ...deal,
                  payment_frequency_interval: e.target.value,
                })}
                defaultOption={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <InputCustom
                label={`Initial payemnt`}
                value={deal && deal.initial_payment ? deal.initial_payment : ''}
                readOnly={true}
                onChange={(e) => setDeal({
                  ...deal,
                  initial_payment: e.target.value,
                })}
              />
            </Col>
            <Col md={3}>
              <InputCustom
                label={`Unit Payment`}
                value={deal && deal.monthly_payment ? deal.monthly_payment : ''}
                readOnly={true}
                onChange={(e) => setDeal({
                  ...deal,
                  monthly_payment: e.target.value,
                })}
              />
            </Col>
            <Col md={3}>
              <InputCustom
                label={`Expected profit`}
                value={deal && deal.expected_profit ? deal.expected_profit : ''}
                readOnly={true}
                onChange={(e) => setDeal({
                  ...deal,
                  expected_profit: e.target.value,
                })}
              />
            </Col>
            <Col md={3}>
              <InputCustom
                label={`End payment`}
                value={deal && deal.end_payment ? deal.end_payment : ''}
                readOnly={true}
                onChange={(e) => setDeal({
                  ...deal,
                  end_payment: e.target.value,
                })}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <InputCustom
                label={`Term`}
                value={deal && deal.term ? deal.term : ''}
                readOnly={true}
                onChange={(e) => setDeal({
                  ...deal,
                  term: e.target.value,
                })}
              />
            </Col>
            <Col md={3}>
              <InputKeySelect
                label={`Interval`}
                value={deal && deal.interval ? deal.interval : ''}
                readOnly={true}
                options={intervals}
                onChange={(e) => setDeal({
                  ...deal,
                  interval: e.target.value,
                })}
                defaultOption={true}
              />
            </Col>
            <Col md={3}>
              <InputCustom
                label={`28 day mileage allowance`}
                value={deal && deal.annual_mileage ? deal.annual_mileage : ''}
                readOnly={true}
                onChange={(e) => setDeal({
                  ...deal,
                  annual_mileage: e.target.value,
                })}
              />
            </Col>
            <Col md={3}>
              <InputCustom
                type={`number`}
                label={`Delivery Fee`}
                value={deal && deal.delivery_fee ? deal.delivery_fee : ''}
                onChange={(e) => setDeal({
                  ...deal,
                  delivery_fee: e.target.value,
                })}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h2>Company information</h2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <InputArraySelect
                label={`Business activity`}
                value={company && company.business_activity_id ? company.business_activity_id : ''}
                options={businessTypes}
                valueKey={`id`}
                labelKey={`name`}
                onChange={(e) => setCompany({
                  ...company,
                  business_activity_id: e.target.value,
                })}
                defaultOption={true}
              />
            </Col>
            <Col md={6}>
              <InputArraySelect
                label={`Company type`}
                value={company && company.company_type_id ? company.company_type_id : ''}
                options={companyTypes}
                valueKey={`id`}
                labelKey={`name`}
                onChange={(e) => setCompany({
                  ...company,
                  company_type_id: e.target.value,
                })}
                defaultOption={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <InputCustom
                label={`Company name`}
                value={company && company.name ? company.name : ''}
                onChange={(e) => setCompany({
                  ...company,
                  name: e.target.value,
                })}
              />
            </Col>
            <Col md={6}>
              <InputCustom
                label={`Business address`}
                value={company && company.address ? company.address : ''}
                onChange={(e) => setCompany({
                  ...company,
                  address: e.target.value,
                })}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <InputCustom
                label={`Business phone number`}
                value={company && company.phone_number ? company.phone_number : ''}
                onChange={(e) => setCompany({
                  ...company,
                  phone_number: e.target.value,
                })}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={12}>
              <Button className="w-100" color="success" onClick={() => updateDeal()}>Update deal</Button>
            </Col>
          </Row>
        </CardBody>
      </Card>      
      <Modal
        isOpen={transitModal.show}
        toggle={() => setTransitModal({
          ...transitModal,
          show: false
        })}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h2 className="modal-title">{transitModal.header}</h2>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setTransitModal({
              ...transitModal,
              show: false
            })}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {transitModal.body}
        </div>
        <div className="modal-footer">
          <Button
            className="new-event--add"
            color="danger"
            type="button"
            onClick={transitModal.onClick}
          >
            {`Proceed`}
          </Button>
          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={() => setTransitModal({
              ...transitModal,
              show: false
            })}
          >
            {`Cancel`}
          </Button>
        </div>
      </Modal>
    </CardBody>
  );
}