import React from 'react';

import {
  FormGroup,
  Input,
  Label,
  Col,
} from "reactstrap";

const InputKeySelect = ({ label, value, onChange, options, disabled, readOnly, defaultOption, invalid, errorMessage }) => {
  return (
    <FormGroup>
      <label className="form-control-label">{label}</label>
      <Input
        type="select"
        value={value}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        invalid={invalid}
      >
        {defaultOption && <option>-</option>}
        { options && 
          Object.keys(options).map(key => {
            return <option value={key} key={key}>{options[key]}</option>
          })
        }
      </Input>
      <div className="invalid-feedback">
        {errorMessage}
      </div>
    </FormGroup>
  )
}

const InputArraySelect = ({ label, value, onChange, options = [], disabled, readOnly, defaultOption, valueKey, labelKey, labelKey2, invalid, errorMessage }) => {
  return (
    <FormGroup>
      <label className="form-control-label">{label}</label>
      <Input
        type="select"
        value={value}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        invalid={invalid}
      >
        {defaultOption && <option>-</option>}
        { options && options.length > 0 &&
          options.map((item, index) => {
            return <option value={item[valueKey]} key={index}>{labelKey2? `${item[labelKey]}, ${item[labelKey2].name}`: item[labelKey]}</option>
          })
        }
      </Input>
      <div className="invalid-feedback">
        {errorMessage}
      </div>
    </FormGroup>
  )
}

const InputKeySideSelect = ({ label, value, onChange, options, disabled, readOnly, defaultOption, invalid, errorMessage }) => {
  return (
    <FormGroup className="row">
      <Label
        className="form-control-label"
        md="5"
      >
        {label}
      </Label>
      <Col md="7">
        <Input
          type="select"
          value={value}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
          invalid={invalid}
        >
          {defaultOption && <option>-</option>}
          { options && 
            Object.keys(options).map(key => {
              return <option value={key} key={key}>{options[key]}</option>
            })
          }
        </Input>
        <div className="invalid-feedback">
          {errorMessage}
        </div>
      </Col>
    </FormGroup>
  )
}

const InputArraySideSelect = ({ label, value, onChange, options = [], disabled, readOnly, defaultOption, valueKey, labelKey, labelKey2, invalid, errorMessage }) => {
  return (
    <FormGroup className="row">
      <Label
        className="form-control-label"
        md="5"
      >
        {label}
      </Label>
      <Col md="7">
        <Input
          type="select"
          value={value}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
          invalid={invalid}
        >
          {defaultOption && <option>-</option>}
          { options && options.length > 0 && 
            options.map((item, index) => {
              return <option value={item[valueKey]} key={index}>{labelKey2 ? `${item[labelKey]}, ${item[labelKey2].name}` : item[labelKey]}</option>
            })
          }
        </Input>
        <div className="invalid-feedback">
          {errorMessage}
        </div>
      </Col>
    </FormGroup>
  )
}

export {
  InputKeySelect,
  InputArraySelect,
  InputKeySideSelect,
  InputArraySideSelect,
}