import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import Cookies from "js-cookie";
import { Editor } from "@tinymce/tinymce-react";

import * as constants from "constants/index.js";
import CardsHeader from "layouts/Headers/CardsHeader.js";

import InputCustom from "views/pages/components/InputCustom";
import InputFile from "views/pages/components/InputFile";
import {
  InputKeySelect,
} from "views/pages/components/InputSelect";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";

export default function Create() {
  const notificationAlert = useRef(null);
  const history = useHistory();
  const { className } = useParams();

  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState("");
  const [model, setModel] = useState("");
  const [models, setModels] = useState([]);
  const [makes, setMakes] = useState([]);
  const [classes, setClasses] = useState("");
  const [doors, setDoors] = useState("");
  const [seats, setSeats] = useState("");
  const [vehicleTransmissions, setVehicleTransmissions] = useState("");
  const [vehicleTypes, setVehicleTypes] = useState("");

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get("grs_token");
    setToken(grs_token);

    const source = axios.CancelToken.source();

    fetchData(source, grs_token);

    return () => {
      source.cancel();
    };
  }, []);

  const fetchData = async (source, token) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${constants.API_URL}/inventory/daily-rental-vehicle/${className}/create`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      });
      console.log("response :: ", response.data);
      const data = response.data;

      let _item = {
        type: 'break',
        transmission: 'manual',
        no_of_doors: 2,
        no_of_seats: 2,
      }
      setItem(_item)

      setModel(data.data.model);
      setModels(data.data.models);
      setMakes(data.data.makes);
      setClasses(data.data.classes);
      setDoors(data.data.doors);
      setSeats(data.data.seats);
      setVehicleTransmissions(data.data.vehicleTransmissions);
      setVehicleTypes(data.data.vehicleTypes);

      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (error) {
      setLoading(false);
      if (axios.isCancel(error)) {
        console.log("Request canceled :: ", error);
      } else {
        console.error("error :: ", error);
        notify(
          "warning",
          "Warning",
          error.response && error.response.data
            ? error.response.data.message
            : error.response
            ? error.response.statusText
            : "Error Occurred!"
        );
      }
    }
  };

  const createGroup = async () => {
    let formData = new FormData();
    formData.append("title", item.title ? item.title : "");
    formData.append("type", item.type);
    formData.append("transmission", item.transmission);
    formData.append("no_of_doors", item.no_of_doors);
    formData.append("no_of_seats", item.no_of_seats);
    formData.append("daily_price", item.daily_price ? item.daily_price : 0);
    formData.append(
      "warranty_value",
      item.warranty_value ? item.warranty_value : 0
    );
    formData.append("description", item.description ? item.description : "");
    formData.append("featured_image", item.featured_image);

    try {
      const response = await axios({
        method: "POST",
        url: `${constants.API_URL}/inventory/daily-rental-vehicle/${className}/store-group`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        "Content-Type": `multipart/form-data`,
        data: formData,
      });
      console.log("response :: ", response.data);
      const data = response.data;
      
      notify("success", "Success", data.message);

      if (data.data.uuid) {
        setTimeout(() => {
          history.push({
            pathname: `/admin/daily-rental-vehicle/${data.data.uuid}/edit`
          })
        }, 500);
      }
    } catch (error) {
      console.error("error :: ", error);
      notify(
        "warning",
        "Warning",
        error.response && error.response.data
          ? error.response.data.message
          : error.response
          ? error.response.statusText
          : "Error Occurred!"
      );
    }
  };

  return (
    <>
      <CardsHeader
        name="Add A Vehicle"
        parentName="Vehicle management"
        currentName="List"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Vehicle general information</h1>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <InputCustom
                      label={`Group title ( will be shown on the website listing )`}
                      value={item && item.title ? item.title : ""}
                      onChange={(e) => {
                        setItem({
                          ...item,
                          title: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <InputKeySelect
                      label={`Type`}
                      options={vehicleTypes}
                      value={item && item.type ? item.type : ""}
                      onChange={(e) => {
                        setItem({
                          ...item,
                          type: e.target.value,
                        });
                      }}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={6}>
                    <InputKeySelect
                      label={`Transmission`}
                      options={vehicleTransmissions}
                      value={item && item.transmission ? item.transmission : ""}
                      onChange={(e) => {
                        setItem({
                          ...item,
                          transmission: e.target.value,
                        });
                      }}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={6}>
                    <InputKeySelect
                      label={`Number of Doors`}
                      options={doors}
                      value={item && item.no_of_doors ? item.no_of_doors : ""}
                      onChange={(e) => {
                        setItem({
                          ...item,
                          no_of_doors: e.target.value,
                        });
                      }}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={6}>
                    <InputKeySelect
                      label={`Number of Seats`}
                      options={seats}
                      value={item && item.no_of_seats ? item.no_of_seats : ""}
                      onChange={(e) => {
                        setItem({
                          ...item,
                          no_of_seats: e.target.value,
                        });
                      }}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={6}>
                    <InputCustom
                      label={`Daily price`}
                      value={item && item.daily_price ? item.daily_price : ""}
                      onChange={(e) => {
                        setItem({
                          ...item,
                          daily_price: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <InputCustom
                      type={`number`}
                      label={`Bond`}
                      value={
                        item && item.warranty_value ? item.warranty_value : ""
                      }
                      onChange={(e) => {
                        setItem({
                          ...item,
                          warranty_value: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={12}>
                    <Editor
                      value={item && item.description ? item.description : ""}
                      apiKey={constants.TINYMCE_KEY}
                      init={{
                        height: 300,
                        menubar: true,
                        plugins: ["code", "preview", "pagebreak", "image"],
                        toolbar:
                          "undo redo | formatselect | bold italic | \
                                  alignleft aligncenter alignright alignjustify | \
                                  outdent indent",
                      }}
                      onEditorChange={(content, editor) =>
                        setItem({
                          ...item,
                          description: content,
                        })
                      }
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h1 className="mb-0">{`Photos`}</h1>
              </CardHeader>
              <CardBody>
                <InputFile
                  label={`Featured image`}
                  placeholder={`Featured image`}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      featured_image: e.target.files[0],
                    })
                  }
                  // invalid={errors && errors.featured_image? true: false}
                  // errorMessage={errors.featured_image}
                />
                <Button
                  className="w-100"
                  color="success"
                  onClick={() => createGroup()}
                >
                  Save Group
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
