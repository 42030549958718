export const BASE_URL    = process.env.REACT_APP_BASE_URL;
export const SITE_CODE   = process.env.REACT_APP_SITE_CODE;
export const CURRENCY    = process.env.REACT_APP_CURRENCY;
export const TINYMCE_KEY = process.env.REACT_APP_TINYMCE_KEY;

export const API_URL     = `${BASE_URL}/api/v1`;
export const STORAGE_URL = `${BASE_URL}/storage/`;

export const TRACKING_CLIENT_ID     = process.env.REACT_APP_TRACKING_CLIENT_ID;
export const TRACKING_CLIENT_SECRET = process.env.REACT_APP_TRACKING_CLIENT_SECRET;
export const TRACKING_URL           = process.env.REACT_APP_TRACKING_URL;

export const FLESPI_API_HOST  = process.env.REACT_APP_FLESPI_API_HOST;
export const FLESPI_API_TOKEN = process.env.REACT_APP_FLESPI_API_TOKEN;

export const PUSHER_APP_ID      = process.env.REACT_APP_PUSHER_APP_ID;
export const PUSHER_APP_KEY     = process.env.REACT_APP_PUSHER_APP_KEY;
export const PUSHER_APP_SECRET  = process.env.REACT_APP_PUSHER_APP_SECRET;
export const PUSHER_APP_CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER;

export const UK_VEHICLE_DATA_BASE_URL = process.env.REACT_APP_UK_VEHICLE_DATA_API_HOST;
export const UK_VEHICLE_DATA_API_URL  = `${UK_VEHICLE_DATA_BASE_URL}/api/datapackage`;
export const UK_VEHICLE_DATA_API_KEY  = process.env.REACT_APP_UK_VEHICLE_DATA_API_KEY;

export const AU_VEHICLE_DATA_BASE_URL = process.env.REACT_APP_AU_SANDBOX_API_HOST;
export const AU_VEHICLE_DATA_API_URL  = `${AU_VEHICLE_DATA_BASE_URL}/nevdis`;
export const AU_VEHICLE_DATA_API_KEY  = process.env.REACT_APP_AU_SANDBOX_API_KEY;

export const formatDate = (d) => {
  if (d) {
    let date = new Date(d);
    let ye = Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    let mo = Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    let da = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

    return `${ye}-${mo}-${da}`
  }
}

export const formatDateShortGB = (d) => {
  if (d) {
    let date = new Date(d);
    let ye = Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    let mo = Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    let da = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

    return `${da}/${mo}/${ye}`
  }
}

export const formatDateFullGB = (d) => {
  if (d) {
    let date = new Date(d);

    let options = {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', second: '2-digit',
      hour12: false
    }

    let formatted = Intl.DateTimeFormat('en-BG', options).format(date);

    return formatted
  }
}

export const formatDateHoursSeconds = (d) => {
  if (d) {
    let date = new Date(d);
    let ye = Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    let mo = Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    let da = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    let ho = Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false }).format(date);
    let mi = Intl.DateTimeFormat('en', { minute: '2-digit' }).format(date);

    return `${ye}-${mo}-${da}T${ho}:${mi}`
  }
}

export const getRandomColor = () => {
  let letters = '0123456789ABCDEF'.split('');
  let color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const getColorsForSources = (labels) => {
  let colors = localStorage.getItem('sourcesColors') !== null ? JSON.parse(localStorage.getItem('sourcesColors')) : {};

  let bgColors = [];
  let shouldUpdate = false;

  if (Array.isArray(labels)) {
    labels.forEach(element => {
      if (colors[element]) {
        bgColors.push(colors[element]);
      } else {
        let tempColor = getRandomColor();
        bgColors.push(tempColor);
        colors[element] = tempColor;
        shouldUpdate = true;
      }
    });
  } else {
    if (colors[labels]) {
      return colors[labels]
    } else {
      let tempColor = getRandomColor();
      colors[labels] = tempColor;
      window.localStorage.setItem('sourcesColors', JSON.stringify(colors));

      return tempColor;
    }
  }

  if (shouldUpdate) {
    window.localStorage.setItem('sourcesColors', JSON.stringify(colors))
  }

  return bgColors;
}

export const formatString = (str, num) => {
  return str.slice(0, num) + '..';
}

export const convertKmToMile = (val) => {
  return Math.round(val * 0.621371);
}

export const strPad = (input, padLength, padString, padType) => {
  //  discuss at: https://locutus.io/php/str_pad/
  // original by: Kevin van Zonneveld (https://kvz.io)
  // improved by: Michael White (https://getsprink.com)
  //    input by: Marco van Oort
  // bugfixed by: Brett Zamir (https://brett-zamir.me)
  //   example 1: str_pad('Kevin van Zonneveld', 30, '-=', 'STR_PAD_LEFT')
  //   returns 1: '-=-=-=-=-=-Kevin van Zonneveld'
  //   example 2: str_pad('Kevin van Zonneveld', 30, '-', 'STR_PAD_BOTH')
  //   returns 2: '------Kevin van Zonneveld-----'
  let half = ''
  let padToGo
  const _strPadRepeater = function (s, len) {
    let collect = ''
    while (collect.length < len) {
      collect += s
    }
    collect = collect.substr(0, len)
    return collect
  }
  input += ''
  padString = padString !== undefined ? padString : ' '
  if (padType !== 'STR_PAD_LEFT' && padType !== 'STR_PAD_RIGHT' && padType !== 'STR_PAD_BOTH') {
    padType = 'STR_PAD_RIGHT'
  }
  if ((padToGo = padLength - input.length) > 0) {
    if (padType === 'STR_PAD_LEFT') {
      input = _strPadRepeater(padString, padToGo) + input
    } else if (padType === 'STR_PAD_RIGHT') {
      input = input + _strPadRepeater(padString, padToGo)
    } else if (padType === 'STR_PAD_BOTH') {
      half = _strPadRepeater(padString, Math.ceil(padToGo / 2))
      input = half + input + half
      input = input.substr(0, padLength)
    }
  }
  return input
}

export const round2Decimals = (num) => {
  return Math.round(num * 100) / 100
}

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}