import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from "react-redux";
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import TableCustom from 'views/pages/components/TableCustom'
import {
  InputKeySelect,
  InputArraySelect,
} from 'views/pages/components/InputSelect'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Button
} from 'reactstrap'

import { create, update, deletee } from "views/policies/DealPolicy";

export default function VehicleBooking() {
  const notificationAlert = useRef(null)
  const history = useHistory()
  const profilePermissions = useSelector((state) => state.getState).permissions;

  const [role, setRole] = useState("");

  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState('')
  const [isDealer, setIsDealer] = useState(false)
  const [statuses, setStatuses] = useState('')
  
  const [item, setItem] = useState('')
  const [itemModal, setItemModal] = useState({
    show: false,
    header: '',
    division: ''
  })
  const [errors, setErrors] = useState('')

  const [filter, setFilter] = useState('')

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(1, source, grs_token, {
      ...filter,
      search: filter && filter.searchValue ? filter.searchValue : '',
    })

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (pageNumber, source, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/customer/daily-deals`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          search: filter && filter.searchValue ? filter.searchValue : '',
          page: pageNumber,
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      setItems(data.data.items.data)
      setPagination(data.data.items)
      setRole(data.data.role);
      setIsDealer(data.data.isDealer)
      setStatuses(data.data.statuses)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const filterDeals = () => {
    const source = axios.CancelToken.source();
    fetchData(source, 1, token, filter);
  }

  const resetDeals = () => {
    const source = axios.CancelToken.source();
    setFilter("");
    fetchData(source, 1, token, "");
  }

  const updateDeal = () => {

  }

  const dealView = async (uuid) => {
    history.push({
      pathname: `/admin/booking/daily-deals/${uuid}/edit`
    })
  }

  return (
    <>
      <CardsHeader name="Leads" parentName="Leads Management" currentName="Create" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Daily Deals</h1>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className={`mb-5`}>
                  <Col md={6}>
                    <InputCustom 
                      type={`date`}
                      label={`Pickup At`}
                      value={filter && filter.pickup_at ? filter.pickup_at : ''}
                      onChange={e => {
                        setFilter({
                          ...filter,
                          pickup_at: e.target.value
                        })
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <InputCustom 
                      type={`date`}
                      label={`Return At`}
                      value={filter && filter.return_at ? filter.return_at : ''}
                      onChange={e => {
                        setFilter({
                          ...filter,
                          return_at: e.target.value
                        })
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <InputCustom 
                      type={`date`}
                      label={`Created From`}
                      value={filter && filter.created_from ? filter.created_from : ''}
                      onChange={e => {
                        setFilter({
                          ...filter,
                          created_from: e.target.value
                        })
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <InputCustom 
                      type={`date`}
                      label={`Created To`}
                      value={filter && filter.created_to ? filter.created_to : ''}
                      onChange={e => {
                        setFilter({
                          ...filter,
                          created_to: e.target.value
                        })
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <InputKeySelect
                      label={`Vehicle Status`}
                      options={statuses}
                      value={filter && filter.vehicle_status ? filter.vehicle_status : ''}
                      onChange={e => {
                        setFilter({
                          ...filter,
                          vehicle_status: e.target.value
                        })
                      }}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={6}>
                    <InputCustom
                      label={`Search`}
                      value={filter && filter.searchValue ? filter.searchValue : ''}
                      onChange={e => {
                        setFilter({
                          ...filter,
                          searchValue: e.target.value
                        })
                      }}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={6}>
                    <Button className="w-100" color="success" onClick={() => filterDeals()}>{`Filter`}</Button>
                  </Col>
                  <Col md={6}>
                    <Button className="w-100" color="warning" onClick={() => resetDeals()}>{`Reset`}</Button>
                  </Col>
                </Row>
                <TableCustom 
                  loading={loading}
                  items={items}
                  pagination={pagination}
                  onChange={pageNumber => fetchData(pageNumber, axios.CancelToken.source(), token)}
                  columns={[
                    {
                      dataField: "name",
                      text: "Name",
                      formatter: (rowContent, row) => {
                        return row.first_name || row.last_name ? `${row.first_name} ${row.last_name}` : ''
                      }
                    },
                    {
                      dataField: "phone_number",
                      text: "Phone",
                    },
                    {
                      dataField: "email",
                      text: "Email",
                    },
                    {
                      dataField: "pickup_at",
                      text: "From",
                      formatter: (rowContent, row) => {
                        return row.pickup_at? constants.formatDateShortGB(row.pickup_at): ''
                      }
                    },
                    {
                      dataField: "return_at",
                      text: "To",
                      formatter: (rowContent, row) => {
                        return row.return_at? constants.formatDateShortGB(row.return_at): ''
                      }
                    },
                    {
                      dataField: "created_at",
                      text: "Created At",
                      formatter: (rowContent, row) => {
                        return row.created_at? constants.formatDateFullGB(row.created_at): ''
                      }
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      formatter: (rowContent, row) => {
                        let color = '';
                        switch (row.status) {
                          case '0':
                            color = 'red'
                            break;
                        
                          case '2':
                            color = 'green'
                            break;
                          
                          case '10':
                            color = 'cyan'
                            break;
                          
                          default:
                            break;
                        }
                        return (
                          <span style={{backgroundColor: color, color: 'white', padding: 3, borderRadius: 5}}>
                            {row && row.status ? statuses[row.status] : ''}
                          </span>
                        )
                      }
                    },
                    {
                      dataField: "total_deal_price",
                      text: "Total",
                      formatter: (rowContent, row) => {
                        return row.total_deal_price? `${constants.CURRENCY} ${constants.round2Decimals(row.total_deal_price)}`: ''
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                {/* {
                                  update(
                                    profilePermissions,
                                    role.auth_user_team,
                                    row
                                  ) && 
                                  <DropdownItem
                                    onClick={e => {
                                      setItem(row)
                                      setItemModal({
                                        ...itemModal,
                                        show: true,
                                        header: 'Update daily deal',
                                        division: 'update'
                                      })
                                    }}
                                  >
                                    Edit
                                  </DropdownItem>
                                } */}
                                <DropdownItem
                                  onClick={() => {
                                    dealView(row.uuid)
                                  }}
                                >
                                  View
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={itemModal.show}
          toggle={() => setItemModal({
            ...itemModal,
            show: false,
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{itemModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setItemModal({
                ...itemModal,
                show: false,
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <InputCustom 
              label={`Name`}
              value={item && (item.first_name || item.last_name) ? `${item.first_name} ${item.last_name}`: ''}
              onChange={e => setItem({
                ...item,
                name: e.target.value
              })}
              invalid={errors && errors.name? true: false}
              errorMessage={errors.name}
            />
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => updateDeal()}
            >
              {`Save`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setItemModal({
                ...itemModal,
                show: false,
              })}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}