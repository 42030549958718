import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'actions/index.js';
import Cookies from "js-cookie";
import axios from 'axios';
import * as constants from 'constants/index.js';
import NotificationAlert from "react-notification-alert";

import InputCustom from 'views/pages/components/InputCustom';
import InputFile from 'views/pages/components/InputFile';


import {
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
} from "reactstrap";

import CardsHeader from 'layouts/Headers/CardsHeader.js';

export default function Profile() {
  const notificationAlert = useRef(null);
  const profileData = useSelector((state) => state.getState).profileData;
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [imageFile, setImageFile] = useState('');
  const [token, setToken] = useState('');

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [detailErrors, setDetailErrors] = useState('');
  const [passwordErrors, setPasswordErrors] = useState('');

  const data = profileData? profileData: { data: {name: '', email: ''} };

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  const pullAsyncData = () => {
    if (Cookies.get('grs_token')) {
      let token = Cookies.get('grs_token');
      setToken(token);
      dispatch(actions.getProfileData(token));
    }
  }

  useEffect(() => {
    pullAsyncData()
  }, [])

  useEffect(() => {
    setName(data.name);
    setEmail(data.email);
  }, [data])

  const saveUserInformation = () => {
    let formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('photo', imageFile);

    axios({
      method: 'POST',
      url: constants.API_URL + '/profile',
      data: formData,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        if (response.status == 200) {
          setDetailErrors('');
          notify('success', 'Success', response.data.message);
          dispatch(actions.getProfileData(token));
        }
      })
      .catch(error => {
        if (error.response.status = 422) {
          let errors = error.response.data.errors;
          setDetailErrors(errors);
          return
        }
        notify('warning', 'Error', error.response.statusText);
      })
  }

  const changePassword = () => {
    let formData = new FormData();
    formData.append('old_password', currentPassword);
    formData.append('password', newPassword);
    formData.append('password_confirmation', confirmPassword);

    axios({
      method: 'POST',
      url: constants.API_URL + '/password',
      data: formData,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        if (response.status == 200) {
          setPasswordErrors('');
          notify('success', 'Success', response.data.message);
          setCurrentPassword('');
          setNewPassword('');
          setConfirmPassword('');
        }
      })
      .catch(error => {
        console.log('error :: ', error.response);
        if (error.response.status == 422) {
          let errors = error.response.data.errors;
          setPasswordErrors(errors);
          return
        }
        notify('warning', 'Error', error.response.statusText);
      })
  }

  const handleChange = (files) => {
    setImageFile(files[0]);
  }

  return (
    <>
      <CardsHeader name="Admin" parentName="Profile" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title mb-0">Edit Profile</h1>
              </CardHeader>
              <CardBody>
                <Form>
                  <h2 className="surtitle mb-4">User Information</h2>
                  <Row>
                    <Col md="4">
                      <InputCustom 
                        label={`Name`}
                        placeholder={`Name`}
                        value={name? name: ''}
                        onChange={(e) => setName(e.target.value)}
                        invalid={detailErrors && detailErrors.name? true: false}
                        errorMessage={detailErrors.name}
                      />
                    </Col>
                    <Col md="4">
                      <InputCustom 
                        type={`email`}
                        label={`Email`}
                        placeholder={`Email`}
                        value={email? email: ''}
                        onChange={(e) => setEmail(e.target.value)}
                        invalid={detailErrors && detailErrors.email? true: false}
                        errorMessage={detailErrors.email}
                      />
                    </Col>
                    <Col md="4">
                      <InputFile 
                        label={`Profile photo`}
                        placeholder={`Select file`}
                        onChange={(e) => handleChange(e.target.files)}
                        invalid={detailErrors && detailErrors.photo? true: false}
                        errorMessage={detailErrors.photo}
                      />
                    </Col>
                  </Row>
                  <Button block color="success" size="lg" type="button" onClick={() => saveUserInformation()}>
                    Save
                  </Button>
                </Form>
                <hr />
                <Form>
                  <h2 className="surtitle mb-4">Password</h2>
                  <Row>
                    <Col md="4">
                      <InputCustom 
                        type={`password`}
                        label={`Current password`}
                        placeholder={`Current password`}
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        invalid={passwordErrors && passwordErrors.old_password? true: false}
                        errorMessage={passwordErrors.old_password}
                      />
                    </Col>
                    <Col md="4">
                      <InputCustom 
                        type={`password`}
                        label={`New password`}
                        placeholder={`New password`}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        invalid={passwordErrors && passwordErrors.password? true: false}
                        errorMessage={passwordErrors.password}
                      />
                    </Col>
                    <Col md="4">
                      <InputCustom 
                        type={`password`}
                        label={` Confirm new password`}
                        placeholder={` Confirm new password`}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        invalid={passwordErrors && passwordErrors.password_confirmation? true: false}
                        errorMessage={passwordErrors.password_confirmation}
                      />
                    </Col>
                  </Row>
                  <Button block color="success" size="lg" type="button" onClick={() => changePassword()}>
                    Change password
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}