import React, { useContext } from 'react';

import { DealContext } from '../context';

import {
  Row,
  Col,
  Button,
} from "reactstrap";

export default function Tabs() {
  const dealContext = useContext(DealContext);
  const {
    tab, setTab,
    goPayment
  } = dealContext;
  return (
    <>
      <Row>
        <Col md={12} sm={12}>
          <Button
            className={`col-md-2 col-sm-12 deal-tab ${
              tab == "info" ? "selectedButton" : "releasedSelectedButton"
            }`}
            style={{
              margin: 5,
            }}
            onClick={() => setTab("info")}
          >
            Deal info
          </Button>
          <Button
            className={`col-md-2 col-sm-12 deal-tab ${
              tab == "documents" ? "selectedButton" : "releasedSelectedButton"
            }`}
            style={{
              margin: 5,
            }}
            onClick={() => setTab("documents")}
          >
            Documents
          </Button>
          <Button
            className={`col-md-2 col-sm-12 deal-tab ${
              tab == "uploads" ? "selectedButton" : "releasedSelectedButton"
            }`}
            style={{
              margin: 5,
            }}
            onClick={() => setTab("uploads")}
          >
            Uploads
          </Button>
          <Button
            className={`col-md-2 col-sm-12 deal-tab ${
              tab == "notes" ? "selectedButton" : "releasedSelectedButton"
            }`}
            style={{
              margin: 5,
            }}
            onClick={() => setTab("notes")}
          >
            Conditions
          </Button>
          <Button
            className={`col-md-2 col-sm-12 deal-tab ${
              tab == "tasks" ? "selectedButton" : "releasedSelectedButton"
            }`}
            style={{
              margin: 5,
            }}
            onClick={() => setTab("tasks")}
          >
            Tasks
          </Button>
          <Button
            className={`col-md-2 col-sm-12 deal-tab ${
              tab == "payments" ? "selectedButton" : "releasedSelectedButton"
            }`}
            style={{
              margin: 5,
            }}
            onClick={() => goPayment()}
          >
            Payments
          </Button>
        </Col>
      </Row>
    </>
  );
}