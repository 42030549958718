import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import actions from 'actions/index';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';
import InputCustom from 'views/pages/components/InputCustom';
import {
  InputKeySelect,
} from 'views/pages/components/InputSelect';

import {
  create,
  update,
  deletee,
  hasPermission,
} from 'views/policies/VehiclePolicy.js';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from 'reactstrap';

export default function Vehicle() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.getState).permissions;
  const authUserTeam = useSelector((state) => state.getState).authUserTeam;

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [vehicleStatuses, setVehicleStatuses] = useState('');
  const [filter, setFilter] = useState('');
  const [siteCode, setSiteCode] = useState('');
  const [activeStatuses, setActiveStatuses] = useState('');
  const [items, setItems] = useState('');

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    onClick: null
  })

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)
    dispatch(actions.getProfileData(grs_token));

    const source = axios.CancelToken.source()

    let savedPageNumber = 1
    let savedFilter     = ''

    if (localStorage.getItem('grms_vehicle_page_number')) {
      savedPageNumber = localStorage.getItem('grms_vehicle_page_number')
    }
    if (localStorage.getItem('grms_vehicle_search_filter')) {
      savedFilter = JSON.parse(localStorage.getItem('grms_vehicle_search_filter'))
      setFilter(savedFilter)
    }

    fetchData(source, savedPageNumber, grs_token, savedFilter)
  }, [])

  const fetchData = async (source, pageNumber = 1, token, filter) => {
    localStorage.setItem('grms_vehicle_page_number', pageNumber)
    localStorage.setItem('grms_vehicle_search_filter', JSON.stringify(filter))

    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/vehicle`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          page: pageNumber,
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      
      setVehicles(data.data.items.data)
      setVehicleStatuses(data.data.vehicleStatuses)
      setFilter(data.data.filter)
      setActiveStatuses(data.data.activeStatuses)
      setSiteCode(data.data.siteCode)
      setItems(data.data.items)

      setTimeout(() => {
        setDelay(true)
        setLoading(false)
      }, 500);
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const addVehicle = () => {
    history.push({
      pathname: `/admin/vehicle/create`
    })
  }

  const filterVehicle = () => {
    const source = axios.CancelToken.source()
    fetchData(source, 1, token, {
      ...filter,
      search: filter && filter.searchValue? filter.searchValue: ''
    })
  }

  const resetVehicle = () => {
    setFilter('')
    const source = axios.CancelToken.source()
    localStorage.removeItem('grms_vehicle_page_number')
    localStorage.removeItem('grms_vehicle_search_filter')
    fetchData(source, 1, token, '')
  }

  const editVehicle = (row) => {
    history.push({
      pathname: `/admin/vehicle/${row.id}/edit`
    })
  }

  const deleteVehicle = (vehicleId) => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/vehicle/${vehicleId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        setLoading(true)
        setDeleteModal({
          ...deleteModal,
          show: false
        })
        console.log('res :: ', response.data)
        let data = response.data
        setVehicles(data.data.data)

        setTimeout(() => {
          setLoading(false)
        }, 1000)
      })
      .catch(error => {
        setDeleteModal({
          ...deleteModal,
          show: false
        })
        console.error('error :: ', error)
        if (error.response && error.response.data && error.response.data.message) {
          notify('warning', 'Warning', error.response.data.message)
          return
        }
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  return (
    <>
      <CardsHeader name="Vehicle Management" parentName="Vehicle Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Vehicles</h1>
                    <p className="text-sm mb-0">Manage all your vehicles here.</p>
                  </Col>
                  <Col xs={4} className="text-right">
                    {create(permissions, authUserTeam) && 
                    <Button className="w-100" color="success" type="button" onClick={() => addVehicle()}>Add vehicle</Button>}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={3} sm={6}>
                    <InputKeySelect 
                      label={`Vehicle Status`}
                      value={filter && filter.vehicle_status? filter.vehicle_status: ''}
                      options={vehicleStatuses}
                      onChange={(e) => setFilter({
                        ...filter,
                        vehicle_status: e.target.value,
                      })}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={3} sm={6}>
                    <InputCustom 
                      label={`Search`}
                      value={filter && filter.searchValue? filter.searchValue: ''}
                      onChange={(e) => setFilter({
                        ...filter,
                        searchValue: e.target.value
                      })}
                      onKeyPress={e => {
                        if (e.charCode === 13) {
                          filterVehicle()
                        }
                      }}
                    />
                  </Col>
                  <Col md={3} sm={6} className="pt-4-5">
                    <Button className="w-100" color="success" type="button" onClick={() => filterVehicle()}>Filter</Button>
                  </Col>
                  <Col md={3} sm={6} className="pt-4-5">
                    <Button className="w-100" color="danger" type="button" onClick={() => resetVehicle()}>Reset</Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  delay={delay}
                  items={vehicles}
                  keyField={`id`}
                  searchField={false}
                  pagination={items}
                  onChange={(pageNumber) => fetchData(axios.CancelToken.source(), pageNumber, token, filter)}
                  columns={siteCode === 'Uk'?[
                    {
                      dataField: "vehicle",
                      text: "Vehicle",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return `${row.vehicle_make.name} ${row.vehicle_model.name}`
                      }
                    },
                    {
                      dataField: "stock_no",
                      text: "Stock No",
                      sort: true
                    },
                    {
                      dataField: "active",
                      text: "Active",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return activeStatuses[row.enabled]
                      }
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return vehicleStatuses[row.status]
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {(hasPermission(permissions, 'update-vehicles') 
                            || hasPermission(permissions, 'delete-vehicles'))
                            && <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                {update(permissions, authUserTeam, row) 
                                && <DropdownItem
                                  onClick={e => editVehicle(row)}
                                >
                                  Edit
                                </DropdownItem>}
                                {deletee(permissions, authUserTeam, row) && 
                                <DropdownItem
                                  onClick={e => setDeleteModal({
                                    ...deleteModal,
                                    show: true,
                                    header: 'Delete vehicle',
                                    onClick: () => deleteVehicle(row.id)
                                  })}
                                >
                                  Delete
                                </DropdownItem>}
                              </DropdownMenu>
                            </UncontrolledDropdown>}
                          </>
                        )
                      }
                    }
                  ]: [
                    {
                      dataField: "vehicle",
                      text: "Vehicle",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return `${row.vehicle_make.name} ${row.vehicle_model.name}`
                      }
                    },
                    {
                      dataField: "stock_no",
                      text: "Stock No",
                      sort: true
                    },
                    {
                      dataField: "active",
                      text: "Active",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return activeStatuses[row.enabled]
                      }
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return vehicleStatuses[row.status]
                      }
                    },
                    {
                      dataField: "interval_payment",
                      text: "Rental",
                      sort: true
                    },
                    {
                      dataField: "rental_term",
                      text: "Term",
                      sort: true
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {hasPermission(permissions, 'update-vehicles') 
                            && hasPermission(permissions, 'delete-vehicles') 
                            && <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                {update(permissions, authUserTeam, row) 
                                && <DropdownItem
                                  onClick={e => editVehicle(row)}
                                >
                                  Edit
                                </DropdownItem>}
                                {deletee(permissions, authUserTeam, row) && 
                                <DropdownItem
                                  onClick={e => setDeleteModal({
                                    ...deleteModal,
                                    show: true,
                                    header: 'Delete vehicle',
                                    onClick: () => deleteVehicle(row.id)
                                  })}
                                >
                                  Delete
                                </DropdownItem>}
                              </DropdownMenu>
                            </UncontrolledDropdown>}
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            ...deleteModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {`Are you sure you want to delete this vehicle?`}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
			        {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}