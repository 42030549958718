import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Cookies from "js-cookie";

import AdminLayout from "layouts/Admin.js";
import IndexView from "views/Index.js";

export default function App() {
  return (
    <Switch>
      <ProtectedRoute path="/admin" component={AdminLayout} />
      <ProtectedLogin path="/" component={IndexView} />
      <Redirect from="*" to="/" />
    </Switch>
  )
}

const ProtectedRoute = ({ component: Component, ...rest }) => {
  let auth = false;
  if (Cookies.get('loggedin')) {
    auth = true;
  }
  return (
    <Route
      {...rest}
      render={(props) => auth ? (
        <Component {...props} />
      ) : (
          <Redirect to="/" />
        )
      }
    />
  )
}

const ProtectedLogin = ({ component: Component, ...rest }) => {
  let auth = false;
  if (Cookies.get('loggedin')) {
    auth = true;
  }
  return (
    <Route
      {...rest}
      render={(props) => !auth ? (
        <Component {...props} />
      ) : (
          <Redirect to="/admin/dashboard" />
        )
      }
    />
  )
}