import * as constants from 'constants/index.js';
import Dashboard from 'views/pages/Dashboard.js';
import PaulAuDashboard from 'views/paulAu/Dashboard.js';

/**
 * Admin
 */
import Profile from 'views/pages/admin/Profile.js';
import RentalCalculator from 'views/pages/admin/RentalCalculator.js';
import RuleOf78Calculator from 'views/pages/admin/RuleOf78Calculator.js';
import Role from 'views/pages/admin/role/Index.js';
import RoleEdit from 'views/pages/admin/role/Edit.js';
import RoleShow from 'views/pages/admin/role/Show.js';
import Dealer from 'views/pages/admin/dealer/Index.js';
import DealerEdit from 'views/pages/admin/dealer/Edit.js';
import Supplier from 'views/pages/admin/supplier/Index';
import Permission from 'views/pages/admin/permission/Index';
import PermissionEdit from 'views/pages/admin/permission/Edit';
import User from 'views/pages/admin/user/Index';
import UserEdit from 'views/pages/admin/user/Edit';
import Message from 'views/pages/admin/message/Index';
import MessageEdit from 'views/pages/admin/message/Edit';
import DealerProfile from 'views/pages/admin/dealerProfile/Index';
import Integration from 'views/pages/admin/3rdPartyIntegration/Index';
import Holiday from 'views/pages/admin/holiday/Index';
import UserHoliday from 'views/pages/admin/holiday/IndexUser';

/**
 * Customer
 */
import Customer from 'views/pages/customers/customer/Index';
import CustomerCreate from 'views/pages/customers/customer/Create';
import CustomerEdit from 'views/pages/customers/customer/Edit';
import CustomerTask from 'views/pages/customers/customer/task/Index';
import CustomerTaskEdit from 'views/pages/customers/customer/task/Edit';
import CustomerNote from 'views/pages/customers/customer/note/Index';
import CustomerNoteEdit from 'views/pages/customers/customer/note/Edit';
import Defaulters from 'views/pages/customers/defaulters/Index';
import Payments from 'views/pages/customers/payments/Index';
import Notifications from 'views/pages/customers/notifications/Index';

/**
 * Deal
 */
import Deal from 'views/pages/deals/Index';
import DealCreate from 'views/pages/deals/Create';
import DealEdit from 'views/pages/deals/Edit';
import DealTaskEdit from 'views/pages/deals/task/Edit';
import DealPayment from 'views/pages/deals/payment/Index';

/**
 * Fleet
 */
import Fleet from 'views/pages/fleet/Dashboard';
import VehicleInStock from 'views/pages/fleet/VehicleInStock';
import JobCreate from 'views/pages/fleet/JobCreate';
import VehicleInspect from 'views/pages/fleet/VehicleInspect';
import CategoryDashBoard from 'views/pages/fleet/CategoryDashBoard';
import TypeInspect from 'views/pages/fleet/TypeInspect';
import BookInPage from 'views/pages/fleet/BookInPage';
import JobsByDate from 'views/pages/fleet/JobsByDate';
import PartsByDate from 'views/pages/fleet/PartsByDate';
import VehicleReady from 'views/pages/fleet/VehicleReady';
import JobReport from 'views/pages/fleet/JobReport';
import Scheduler from 'views/pages/fleet/scheduler/Index';

/**
 * Lead
 */
import Lead from 'views/pages/lead/Index';
import LeadEdit from 'views/pages/lead/Edit';
import LeadCreate from 'views/pages/lead/Create';
import Statistics from 'views/pages/lead/Statistics';
import UsersStatistics from 'views/pages/lead/UsersStatistics';

/**
 * Tracking
 */
import AccountIntegration from 'views/pages/tracking/AccountIntegration';
import AccountIntegrationCallBack from 'views/pages/tracking/AccountIntegrationCallBack';
import Tracking from 'views/pages/tracking/Index';
import TrackingEdit from 'views/pages/tracking/Edit';
import TrackingDashboard from 'views/pages/tracking/Dashboard';
import AlertsAndNotification from 'views/pages/tracking/AlertsAndNotification';
import OrderTrackers from 'views/pages/tracking/OrderTrackers';
import ActivateTracker from 'views/pages/tracking/ActivateTracker';
import GeneralOverview from 'views/pages/tracking/GeneralOverview';
import AssigningFunder from 'views/pages/tracking/AssigningFunder';

/**
 * Vehicle
 */
import Vehicle from 'views/pages/vehicles/Index';
import VehicleEdit from 'views/pages/vehicles/Edit';
import VehicleCreate from 'views/pages/vehicles/Create';
import VehicleDuplicate from 'views/pages/vehicles/Duplicate';
import VehicleCost from 'views/pages/vehicles/VehicleCost';
import VehicleFinancialDetails from 'views/pages/vehicles/FinancialDetails';
import VehicleFinancialHistory from 'views/pages/vehicles/FinancialHistory';
import VehicleMake from 'views/pages/vehicles/make/Index';
import VehicleModel from 'views/pages/vehicles/model/Index';

/**
 * Reports
 */
import BusinessActivity from 'views/pages/reports/BusinessActivity';
import VehiclesCounty from 'views/pages/reports/VehiclesCounty';
import BusinessTypeCounty from 'views/pages/reports/BusinessTypeCounty';

/**
 * Settings
 */
import SettingsDocuments from 'views/pages/settings/documents/Index';
import SettingsDocumentsForm from 'views/pages/settings/documents/Form';
import SettingsBusinessActivities from 'views/pages/settings/businessActivities/Index';
import SettingsCompanyTypes from 'views/pages/settings/companyTypes/Index';
import SettingsCounties from 'views/pages/settings/counties/Index';
import SettingsAutomationTasks from 'views/pages/settings/automationTasks/Index';
import SettingsAutomationTasksForm from 'views/pages/settings/automationTasks/Form';
import SettingsReminders from 'views/pages/settings/reminders/Index';
import SettingsReminderEdit from 'views/pages/settings/reminders/Edit';
import SettingsPersonalisation from 'views/pages/settings/personalisation/Index';
import SettingsAutomatedReminders from 'views/pages/settings/personalisation/AutomatedReminders';
import SettingsResourceTasks from 'views/pages/settings/tasks/Index';

/**
 * Website
 */
import Applications from 'views/pages/website/applications/Index';
import ApplicationShow from 'views/pages/website/applications/Show';
import EmailTemplates from 'views/pages/website/emailTemplates/Index';

/**
 * Types
 */
import LeadSources from 'views/pages/types/sources/Index';
import Countries from 'views/pages/types/countries/Index';
import Regions from 'views/pages/types/regions/Index';

const DashboardComponents = {
  Dashboard,
  PaulAuDashboard
};

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    miniName: "D",
    icon: "fas fa-home text-primary",
    component: constants.SITE_CODE != 'Uk' ?
      DashboardComponents[constants.SITE_CODE + 'Dashboard'] :
      DashboardComponents['Dashboard'],
    layout: "/admin"
  },
  {
    collapse: true,
    name: "Admin",
    icon: "fas fa-users-cog text-info",
    state: "adminCollapse",
    views: [
      {
        path: "/profile",
        name: "Profile",
        miniName: "P",
        component: Profile,
        layout: "/admin"
      },
      {
        path: "/rental-calculator",
        name: "Rental Calculator",
        miniName: "R",
        component: RentalCalculator,
        layout: "/admin"
      },
      {
        path: "/rule-78-calculator",
        name: "Rule of 78 calculator",
        miniName: "R78",
        component: RuleOf78Calculator,
        layout: "/admin"
      },
      {
        path: "/dealer/profile",
        name: "Dealer profile",
        miniName: "DP",
        component: DealerProfile,
        layout: "/admin",
        authUserTeam: true,
        permission: [
          'read-dealer-profile',
          'update-dealer-profile',
          'create-dealer-profile'
        ]
      },
      {
        path: "/dealer/integration",
        name: "3rd Party Integration",
        miniName: "3PI",
        component: Integration,
        layout: "/admin",
        authUserTeam: true,
        permission: [
          'read-dealer-profile',
          'update-dealer-profile',
          'create-dealer-profile'
        ]
      },
      {
        path: "/role",
        name: "Role Management",
        miniName: "RM",
        component: Role,
        layout: "/admin",
        permission: [
          'read-acl'
        ]
      },
      {
        path: "/role/:id/edit",
        name: "NavHidden",
        miniName: "RE",
        component: RoleEdit,
        layout: "/admin",
      },
      {
        path: "/role/:id/show",
        name: "NavHidden",
        miniName: "RS",
        component: RoleShow,
        layout: "/admin",
      },
      {
        path: "/dealer",
        name: "Dealers Management",
        miniName: "D",
        component: Dealer,
        layout: "/admin",
        permission: [
          'read-dealer-dealers'
        ]
      },
      {
        path: "/dealer/:id/edit",
        name: "NavHidden",
        miniName: "DE",
        component: DealerEdit,
        layout: "/admin",
      },
      {
        path: "/supplier",
        name: "Suppliers Management",
        miniName: "S",
        component: Supplier,
        layout: "/admin",
        permission: [
          'read-supplier-dealers'
        ]
      },
      {
        path: "/permission",
        name: "Permission Management",
        miniName: "PL",
        component: Permission,
        layout: "/admin",
        permission: [
          'read-acl'
        ],
        role: [
          'superadministrator'
        ]
      },
      {
        path: "/permission/:id/edit",
        name: "NavHidden",
        miniName: "PE",
        component: PermissionEdit,
        layout: "/admin",
      },
      {
        path: "/user",
        name: "User Management",
        miniName: "UL",
        component: User,
        layout: "/admin",
        permission: [
          'read-users'
        ],
      },
      {
        path: "/user/:id/edit",
        name: "NavHidden",
        miniName: "UE",
        component: UserEdit,
        layout: "/admin",
      },
      {
        path: "/message",
        name: "Messages",
        miniName: "M",
        component: Message,
        layout: "/admin",
        permission: [
          'create-messages'
        ],
      },
      {
        path: "/message/:id/edit",
        name: "NavHidden",
        miniName: "ME",
        component: MessageEdit,
        layout: "/admin",
      },
      {
        path: "/all-notifications",
        name: "NavHidden",
        miniName: "AN",
        component: Notifications,
        layout: "/admin",
      },
      {
        path: "/holiday",
        name: "Holidays",
        miniName: "H",
        component: Holiday,
        layout: "/admin",
      },
      {
        path: "/holiday/:id/user",
        name: "NavHidden",
        miniName: "UH",
        component: UserHoliday,
        layout: "/admin",
      }
    ]
  },
  {
    collapse: true,
    name: "Customers",
    icon: "fas fa-users text-red",
    state: "customersCollapse",
    permission: [
      'read-customers'
    ],
    views: [
      {
        path: "/customer",
        name: "Customer",
        miniName: "C",
        component: Customer,
        layout: "/admin",
        permission: [
          'read-customers'
        ],
      },
      {
        path: "/customer/create",
        name: "NavHidden",
        miniName: "CC",
        component: CustomerCreate,
        layout: "/admin"
      },
      {
        path: "/customer/:id/edit",
        name: "NavHidden",
        miniName: "CE",
        component: CustomerEdit,
        layout: "/admin"
      },
      {
        path: "/customer/:customerId/tasks",
        name: "NavHidden",
        miniName: "CT",
        component: CustomerTask,
        layout: "/admin"
      },
      {
        path: "/customer/:customerId/tasks/:taskId/edit",
        name: "NavHidden",
        miniName: "CTE",
        component: CustomerTaskEdit,
        layout: "/admin"
      },
      {
        path: "/customer/:customerId/notes",
        name: "NavHidden",
        miniName: "CN",
        component: CustomerNote,
        layout: "/admin"
      },
      {
        path: "/customer/:customerId/notes/:noteId/edit",
        name: "NavHidden",
        miniName: "CNE",
        component: CustomerNoteEdit,
        layout: "/admin"
      },
      {
        path: "/defaulters",
        name: "Defaulters",
        miniName: "D",
        component: Defaulters,
        layout: "/admin",
        permission: [
          'read-customers'
        ],
      },
      {
        path: "/payments",
        name: "Payments",
        miniName: "P",
        component: Payments,
        layout: "/admin",
        can: 'canAccessPaymentsDetails'
      }
    ]
  },
  {
    collapse: true,
    name: "Deals",
    icon: "fas fa-shopping-cart text-green",
    state: "dealsCollapse",
    permission: [
      'read-deals',
      'create-award-deal',
      'read-unawarded-deals'
    ],
    views: [
      {
        path: "/deals",
        name: "All Deals",
        miniName: "AD",
        component: Deal,
        layout: "/admin",
        permission: [
          'read-deals'
        ],
      },
      {
        path: "/deals/create",
        name: "New Deal",
        miniName: "ND",
        component: DealCreate,
        layout: "/admin",
        can: 'canDealCreate'
      },
      {
        path: "/deals/:id/edit",
        name: "NavHidden",
        miniName: "DE",
        component: DealEdit,
        layout: "/admin"
      },
      {
        path: "/deals/:dealId/task/:taskId",
        name: "NavHidden",
        miniName: "DTE",
        component: DealTaskEdit,
        layout: "/admin"
      },
      {
        path: "/deals/:id/payment",
        name: "NavHidden",
        miniName: "DP",
        component: DealPayment,
        layout: "/admin"
      },
    ]
  },
  {
    collapse: true,
    name: "Fleet Management",
    icon: "fas fa-truck text-warning",
    state: "fleetCollapse",
    permission: [
      'read-fleet-management',
      'read-body-shop',,
      'read-valeting',,
      'read-mechanical',
    ],
    views: [
      {
        path: "/fleet-management",
        name: "Fleet Dashboard",
        miniName: "FD",
        component: Fleet,
        layout: "/admin",
        permission: [
          'read-fleet-management'
        ]
      },
      {
        path: "/vehicle-in-stock",
        name: "Vehicle in Stock",
        miniName: "FS",
        component: VehicleInStock,
        layout: "/admin",
        permission: [
          'read-fleet-management'
        ]
      },
      // {
      //   path: "/book-in-page",
      //   name: "Book In Page",
      //   miniName: "BIP",
      //   component: BookInPage,
      //   layout: "/admin",
      //   permission: [
      //     'read-fleet-management'
      //   ]
      // },
      {
        path: "/fleet-job-report",
        name: "Report",
        miniName: "ABD",
        component: JobReport,
        layout: "/admin",
        permission: [
          'read-fleet-management'
        ]
      },
      {
        path: "/fleet-vehicle-ready",
        name: "Vehicle Ready",
        miniName: "VR",
        component: VehicleReady,
        layout: "/admin",
        permission: [
          'read-fleet-management'
        ]
      },
      // {
      //   path: "/jobs-by-date",
      //   name: "Jobs By Date",
      //   miniName: "JBD",
      //   component: JobsByDate,
      //   layout: "/admin",
      //   // permission: [
      //   //   'read-fleet-management'
      //   // ]
      // },
      {
        path: "/scheduler",
        name: "Scheduler",
        miniName: "S",
        component: Scheduler,
        layout: "/admin",
      },
      {
        path: "/parts-by-date",
        name: "Parts List",
        miniName: "PBD",
        component: PartsByDate,
        layout: "/admin",
        permission: [
          'read-fleet-management'
        ]
      },
      {
        path: "/fleet-job/create",
        name: "NavHidden",
        miniName: "FJC",
        component: JobCreate,
        layout: "/admin"
      },
      {
        path: "/fleet-job/:id/inspect",
        name: "NavHidden",
        miniName: "FJI",
        component: VehicleInspect,
        layout: "/admin"
      },
      // {
      //   path: "/fleet-job/dashboard/body_shop",
      //   name: "Bodyshop Dashboard",
      //   miniName: "FBSD",
      //   component: CategoryDashBoard,
      //   layout: "/admin",
      //   permission: [
      //     'read-body-shop'
      //   ],
      // },
      // {
      //   path: "/fleet-job/inspect/body_shop",
      //   name: "Bodyshop Vehicles",
      //   miniName: "FBSI",
      //   component: VehicleInStock,
      //   layout: "/admin",
      //   permission: [
      //     'read-body-shop'
      //   ],
      // },
      // {
      //   path: "/fleet-job/dashboard/valeting",
      //   name: "Valeting Dashboard",
      //   miniName: "FVD",
      //   component: CategoryDashBoard,
      //   layout: "/admin",
      //   permission: [
      //     'read-valeting'
      //   ],
      // },
      // {
      //   path: "/fleet-job/inspect/valeting",
      //   name: "Valeting Vehicles",
      //   miniName: "FBSI",
      //   component: VehicleInStock,
      //   layout: "/admin",
      //   permission: [
      //     'read-valeting'
      //   ],
      // },
      // {
      //   path: "/fleet-job/dashboard/mechanical",
      //   name: "Mechanical Dashboard",
      //   miniName: "FMD",
      //   component: CategoryDashBoard,
      //   layout: "/admin",
      //   permission: [
      //     'read-mechanical'
      //   ],
      // },
      // {
      //   path: "/fleet-job/inspect/mechanical",
      //   name: "Mechanical Vehicles",
      //   miniName: "MV",
      //   component: VehicleInStock,
      //   layout: "/admin",
      //   permission: [
      //     'read-mechanical'
      //   ],
      // },
      {
        path: "/fleet-job/type-inspect/:type",
        name: "NavHidden",
        miniName: "FMD",
        component: TypeInspect,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Leads CRM",
    icon: "fas fa-cube text-default",
    state: "leadCollapse",
    permission: [
      'read-leads'
    ],
    views: [
      {
        path: "/leads",
        name: "View leads",
        miniName: "VL",
        component: Lead,
        layout: "/admin",
        permission: [
          'read-leads'
        ],
      },
      {
        path: "/leads/:id/edit",
        name: "NavHidden",
        miniName: "LE",
        component: LeadEdit,
        layout: "/admin"
      },
      {
        path: "/leads/create",
        name: "Create Lead",
        miniName: "CL",
        component: LeadCreate,
        layout: "/admin",
        permission: [
          'create-leads'
        ]
      },
      {
        path: "/leads/statistics",
        name: "Statistics",
        miniName: "S",
        component: Statistics,
        layout: "/admin",
        permission: [
          'create-leads'
        ],
      },
      {
        path: "/leads/statistics/users",
        name: "Users Stats",
        miniName: "US",
        component: UsersStatistics,
        layout: "/admin",
        permission: [
          'create-leads'
        ],
      },
    ]
  },
  {
    collapse: true,
    name: "Tracking",
    icon: "fas fa-map-marker-alt text-yellow",
    state: "trackingCollapse",
    views: [
      {
        path: "/tracking/account-integration",
        name: "Integration",
        miniName: "I",
        component: AccountIntegration,
        layout: "/admin",
        can: 'cannotAccessToTracking',
      },
      {
        path: "/tracking/account-integration-callback",
        name: "NavHidden",
        miniName: "IC",
        component: AccountIntegrationCallBack,
        layout: "/admin",
      },
      {
        path: "/tracking/index",
        name: "Tracking",
        miniName: "T",
        component: Tracking,
        layout: "/admin",
        can: 'canAccessToTracking',
      },
      // {
      //   path: "/tracking/:id/edit",
      //   name: "NavHidden",
      //   miniName: "TE",
      //   component: TrackingEdit,
      //   layout: "/admin"
      // },
      {
        path: "/tracking/general",
        name: "General Overview",
        miniName: "TD",
        component: GeneralOverview,
        layout: "/admin",
        can: 'canAccessToTracking',
        permission: [
          'create-tracking-device'
        ],
      },
      // {
      //   path: "/tracking/dashboard",
      //   name: "Dashboard",
      //   miniName: "TD",
      //   component: TrackingDashboard,
      //   layout: "/admin",
      //   can: 'canAccessToTracking',
      // },
      {
        path: "/tracking/alerts-and-notification",
        name: "Alert & Notifications",
        miniName: "TA",
        component: AlertsAndNotification,
        layout: "/admin",
        can: 'canAccessToTracking',
        permission: [
          'create-tracking-device'
        ],
      },
      // {
      //   path: "/tracking/order-trackers",
      //   name: "Order Trackers",
      //   miniName: "TA",
      //   component: OrderTrackers,
      //   layout: "/admin"
      // },
      {
        path: "/tracking/activate-tracker",
        name: "Activate Tracker",
        miniName: "TAT",
        component: ActivateTracker,
        layout: "/admin",
        can: 'canAccessToTracking',
        permission: [
          'create-tracking-device'
        ],
      },
      {
        path: "/tracking/device/:uuid",
        name: "NavHidden",
        miniName: "DS",
        component: TrackingEdit,
        layout: "/admin",
        can: 'canAccessToTracking',
      },
      {
        path: "/tracking/assigning-funder",
        name: "Assigning Funder",
        miniName: "AF",
        component: AssigningFunder,
        layout: "/admin",
        can: 'canAccessToTracking',
        permission: [
          'create-tracking-device'
        ],
      },
    ]
  },
  {
    collapse: true,
    name: "Vehicles",
    icon: "fas fa-car text-purple",
    state: "vehicleCollapse",
    permission: [
      'read-vehicles',
      'read-vehicle-make',
      'read-vehicle-model'
    ],
    views: [
      {
        path: "/vehicle",
        name: "Vehicles",
        miniName: "V",
        component: Vehicle,
        layout: "/admin",
        permission: [
          'read-vehicles'
        ],
      },
      {
        path: "/vehicle/:id/edit",
        name: "NavHidden",
        miniName: "VE",
        component: VehicleEdit,
        layout: "/admin"
      },
      {
        path: "/vehicle/create",
        name: "NavHidden",
        miniName: "VC",
        component: VehicleCreate,
        layout: "/admin"
      },
      {
        path: "/vehicle/:id/duplicate",
        name: "NavHidden",
        miniName: "VD",
        component: VehicleDuplicate,
        layout: "/admin"
      },
      {
        path: "/vehicle/:id/cost",
        name: "NavHidden",
        miniName: "VC",
        component: VehicleCost,
        layout: "/admin"
      },
      {
        path: "/vehicle/:id/financial",
        name: "NavHidden",
        miniName: "VFD",
        component: VehicleFinancialDetails,
        layout: "/admin"
      },
      {
        path: "/vehicle/:id/financial-history",
        name: "NavHidden",
        miniName: "VFH",
        component: VehicleFinancialHistory,
        layout: "/admin"
      },
      {
        path: "/vehicle-make",
        name: "Vehicle Makes",
        miniName: "VMA",
        component: VehicleMake,
        layout: "/admin",
        permission: [
          'read-vehicle-make'
        ],
      },
      {
        path: "/vehicle-model",
        name: "Vehicle Models",
        miniName: "VMO",
        component: VehicleModel,
        layout: "/admin",
        permission: [
          'read-vehicle-model'
        ],
      },
    ]
  },
  {
    collapse: true,
    name: "Reports",
    icon: "fas fa-industry text-info",
    state: "reportsCollapse",
    permission: [
      'read-admin-reports'
    ],
    views: [
      {
        path: "/report/deals/business-activity",
        name: "Business Activities Report",
        miniName: "BAR",
        component: BusinessActivity,
        layout: "/admin"
      },
      {
        path: "/report/vhicles/county",
        name: "Vehicles by County",
        miniName: "VBC",
        component: VehiclesCounty,
        layout: "/admin",
        authUserTeam: true
      },
      {
        path: "/report/business-type/county",
        name: "Business types by County",
        miniName: "BTC",
        component: BusinessTypeCounty,
        layout: "/admin",
        authUserTeam: true
      }
    ]
  },
  {
    collapse: true,
    name: "Settings",
    icon: "fas fa-cogs text-warning",
    state: "settingsCollapse",
    permission: [
      'read-business-activities',
      'read-company-types',
      'read-counties',
      'read-automation-task',
      'read-automated-reminders'
    ],
    views: [
      {
        path: "/settings/documents",
        name: "Documents",
        miniName: "SD",
        component: SettingsDocuments,
        layout: "/admin",
        permission: [
          'read-dealer-documents'
        ]
      },
      {
        path: "/settings/documents/create",
        name: "NavHidden",
        miniName: "SD",
        component: SettingsDocumentsForm,
        layout: "/admin"
      },
      {
        path: "/settings/documents/:id/edit",
        name: "NavHidden",
        miniName: "SD",
        component: SettingsDocumentsForm,
        layout: "/admin"
      },
      {
        path: "/settings/business-activity",
        name: "Business Activities",
        miniName: "BA",
        component: SettingsBusinessActivities,
        layout: "/admin",
        permission: [
          'read-business-activities'
        ]
      },
      {
        path: "/settings/company-type",
        name: "Company Types",
        miniName: "CT",
        component: SettingsCompanyTypes,
        layout: "/admin",
        permission: [
          'read-company-types'
        ]
      },
      {
        path: "/settings/county",
        name: "Counties",
        miniName: "C",
        component: SettingsCounties,
        layout: "/admin",
        permission: [
          'read-counties'
        ]
      },
      {
        path: "/settings/automation-task",
        name: "Automation Tasks",
        miniName: "AT",
        component: SettingsAutomationTasks,
        layout: "/admin",
        role: [
          'superadministrator'
        ],
        permission: [
          'read-automation-task'
        ]
      },
      {
        path: "/settings/automation-task/create",
        name: "NavHidden",
        miniName: "ATC",
        component: SettingsAutomationTasksForm,
        layout: "/admin"
      },
      {
        path: "/settings/automation-task/:id/edit",
        name: "NavHidden",
        miniName: "ATE",
        component: SettingsAutomationTasksForm,
        layout: "/admin"
      },
      {
        path: "/settings/automated-reminders",
        name: "Reminders",
        miniName: "AR",
        component: SettingsReminders,
        layout: "/admin",
        permission: [
          'read-automated-reminders'
        ]
      },
      {
        path: "/settings/automated-reminders/:id/edit",
        name: "NavHidden",
        miniName: "RE",
        component: SettingsReminderEdit,
        layout: "/admin"
      },
      {
        path: "/settings/personalisation",
        name: "Personalisation",
        miniName: "AR",
        component: SettingsPersonalisation,
        layout: "/admin",
        permission: [
          'read-notifications'
        ],
        authUserTeam: true
      },
      {
        path: "/settings/personalisation/automated-reminders",
        name: "NavHidden",
        miniName: "PAR",
        component: SettingsAutomatedReminders,
        layout: "/admin"
      },
      {
        path: "/resources/tasks",
        name: "NavHidden",
        miniName: "SRT",
        component: SettingsResourceTasks,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    name: "Website",
    icon: "fas fa-sitemap text-success",
    state: "websiteCollapse",
    permission: [
      'read-applications',
      'read-email-templates',
    ],
    views: [
      {
        path: "/applications",
        name: "Applications",
        miniName: "A",
        component: Applications,
        layout: "/admin",
        permission: [
          'read-applications'
        ],
      },
      {
        path: "/applications/:id",
        name: "NavHidden",
        miniName: "AS",
        component: ApplicationShow,
        layout: "/admin"
      },
      {
        path: "/settings/emails",
        name: "Email Templates",
        miniName: "ET",
        component: EmailTemplates,
        layout: "/admin",
        permission: [
          'read-email-templates'
        ],
      }
    ]
  },
  {
    collapse: true,
    name: "Types",
    icon: "fas fa-sitemap text-default",
    state: "typesCollapse",
    permission: [
      'read-lead-sources',
      'read-countries',
      'read-regions'
    ],
    views: [
      {
        path: "/lead-sources",
        name: "Lead Sources",
        miniName: "LS",
        component: LeadSources,
        layout: "/admin",
        permission: [
          'read-lead-sources'
        ],
      },
      {
        path: "/settings/countries",
        name: "Countries",
        miniName: "C",
        component: Countries,
        layout: "/admin",
        permission: [
          'read-countries'
        ],
      },
      {
        path: "/types/regions",
        name: "Regions",
        miniName: "R",
        component: Regions,
        layout: "/admin",
        permission: [
          'read-regions'
        ],
      }
    ]
  }
];

export default routes;
