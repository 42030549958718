import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import Cookies from "js-cookie";

import * as constants from "constants/index.js";
import CardsHeader from "layouts/Headers/CardsHeader.js";

import InputCustom from "views/pages/components/InputCustom";
import { InputKeySelect } from "views/pages/components/InputSelect";
import TableCustom from "views/pages/components/TableCustom";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from "reactstrap";

import { create, update, deletee } from "views/policies/DealPolicy";

export default function Deal() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const profilePermissions = useSelector((state) => state.getState).permissions;

  const [token, setToken] = useState("");
  const [deals, setDeals] = useState([]);
  const [dealStatus, setDealStatus] = useState("");
  const [dealStatusFiltering, setDealStatusFiltering] = useState("");
  const [dealerUsers, setDealerUsers] = useState("");
  const [dealersList, setDealersList] = useState("");
  const [isDealer, setIsDealer] = useState(false);
  const [siteCode, setSiteCode] = useState("");
  const [items, setItems] = useState("");

  const [role, setRole] = useState("");

  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState(false);

  const [filterOptions, setFilterOptions] = useState("");

  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState("");

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    let grs_token = Cookies.get("grs_token");
    setToken(grs_token);

    let savedPageNumber = 1;
    let savedFilter = "";

    if (localStorage.getItem("grms_deal_page_number")) {
      savedPageNumber = localStorage.getItem("grms_deal_page_number");
    }
    if (localStorage.getItem("grms_deal_search_filter")) {
      savedFilter = JSON.parse(localStorage.getItem("grms_deal_search_filter"));
      setFilterOptions(savedFilter);
    }

    const source = axios.CancelToken.source();

    fetchData(source, savedPageNumber, grs_token, savedFilter);

    return () => {
      source.cancel();
    };
  }, []);

  const fetchData = async (source, pageNumber = 1, token, filter) => {
    localStorage.setItem("grms_deal_page_number", pageNumber);
    localStorage.setItem("grms_deal_search_filter", JSON.stringify(filter));

    try {
      const response = await axios({
        method: "GET",
        url: `${constants.API_URL}/deal`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          page: pageNumber,
        },
        cancelToken: source.token,
      });
      console.log("response :: ", response.data);
      const data = response.data;

      setDeals(data.data.items.data);
      setRole(data.data.role);
      setDealStatus(data.data.dealStatus);
      setDealStatusFiltering(data.data.dealStatusFiltering);
      setDealerUsers(data.data.dealerUsers ? data.data.dealerUsers : "");
      setDealersList(data.data.dealersList ? data.data.dealersList : "");
      setIsDealer(data.data.isDealer);
      setSiteCode(data.data.siteCode);
      setItems(data.data.items);

      setTimeout(() => {
        setLoading(false);
        setDelay(true);
      }, 500);
    } catch (error) {
      setLoading(false);
      if (axios.isCancel(error)) {
        console.log("Request canceled :: ", error);
      } else {
        console.error("error :: ", error);
        notify(
          "warning",
          "Warning",
          error.response && error.response.data
            ? error.response.data.message
            : error.response
            ? error.response.statusText
            : "Error Occurred!"
        );
      }
    }
  };

  const editDeal = (row) => {
    history.push({
      pathname: "/admin/deals/" + row.id + "/edit",
    });
  };

  const deleteDeal = () => {
    axios({
      method: "DELETE",
      url: constants.API_URL + "/deal/" + selectedDealId,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        setConfirmModal(false);
        if (response.status === 200) {
          let data = response.data;
          console.log("response data :: ", data);
          setDeals(data.data.data);
          notify("success", "Success", data.message);

          setTimeout(() => {
            setDelay(true);
          }, 1000);
        }
      })
      .catch((error) => {
        setConfirmModal(false);
        console.error("error :: ", error);
        notify("warning", "Warning", error.response.statusText);
      });
  };

  const filterPayments = () => {
    const source = axios.CancelToken.source();
    fetchData(source, 1, token, {
      ...filterOptions,
      search: filterOptions.searchValue,
      dealer_id:
        filterOptions.dealer_id === "-" ? undefined : filterOptions.dealer_id,
      deal_status:
        filterOptions.deal_status === "-"
          ? undefined
          : filterOptions.deal_status,
      created_by:
        filterOptions.created_by === "-" ? undefined : filterOptions.created_by,
    });
  };

  const resetPayments = () => {
    const source = axios.CancelToken.source();
    setFilterOptions("");
    localStorage.removeItem("grms_deal_page_number");
    localStorage.removeItem("grms_deal_search_filter");
    fetchData(source, 1, token, "");
  };

  const goAdd = () => {
    history.push({
      pathname: "/admin/deals/create",
    });
  };

  return (
    <>
      <CardsHeader
        name="Deal Management"
        parentName="Deals Management"
        currentName="List"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Deals</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    {create(profilePermissions, role.auth_user_team) && (
                      <Button
                        className="w-100"
                        color="success"
                        type="button"
                        onClick={() => goAdd()}
                      >
                        Add deal
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row>
                    <Col md={12}>
                      <Row>
                        {!role.auth_user_team && dealersList && (
                          <Col md={4}>
                            <InputKeySelect
                              label={`Dealer`}
                              options={dealersList}
                              value={
                                filterOptions && filterOptions.dealer_id
                                  ? filterOptions.dealer_id
                                  : ""
                              }
                              onChange={(e) =>
                                setFilterOptions({
                                  ...filterOptions,
                                  dealer_id: e.target.value,
                                })
                              }
                              defaultOption={true}
                            />
                          </Col>
                        )}
                        <Col md={4}>
                          <InputKeySelect
                            label={`Deal status`}
                            options={dealStatusFiltering}
                            value={
                              filterOptions && filterOptions.deal_status
                                ? filterOptions.deal_status
                                : ""
                            }
                            onChange={(e) =>
                              setFilterOptions({
                                ...filterOptions,
                                deal_status: e.target.value,
                              })
                            }
                            defaultOption={true}
                          />
                        </Col>
                        {isDealer && (
                          <Col md={4}>
                            <InputKeySelect
                              label={`Created By`}
                              options={dealerUsers}
                              value={
                                filterOptions && filterOptions.created_by
                                  ? filterOptions.created_by
                                  : ""
                              }
                              onChange={(e) =>
                                setFilterOptions({
                                  ...filterOptions,
                                  created_by: e.target.value,
                                })
                              }
                              defaultOption={true}
                            />
                          </Col>
                        )}
                        <Col md={4}>
                          <InputCustom
                            label={`Search`}
                            value={
                              filterOptions && filterOptions.searchValue
                                ? filterOptions.searchValue
                                : ""
                            }
                            onChange={(e) =>
                              setFilterOptions({
                                ...filterOptions,
                                searchValue: e.target.value,
                              })
                            }
                            onKeyPress={(e) => {
                              if (e.charCode === 13) {
                                filterPayments();
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={6} sm={12}>
                          <Button
                            className="w-100"
                            color="success"
                            type="button"
                            onClick={() => filterPayments()}
                            style={{ margin: 5 }}
                          >
                            Filter
                          </Button>
                        </Col>
                        <Col md={6} sm={12}>
                          <Button
                            className="w-100"
                            color="danger"
                            type="button"
                            onClick={() => resetPayments()}
                            style={{ margin: 5 }}
                          >
                            Reset
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
                <TableCustom
                  loading={loading}
                  delay={delay}
                  items={deals}
                  keyField={`id`}
                  searchField={false}
                  pagination={items}
                  onChange={(pageNumber) =>
                    fetchData(axios.CancelToken.source(), pageNumber, token, {
                      ...filterOptions,
                      search: filterOptions.searchValue,
                      dealer_id:
                        filterOptions.dealer_id === "-"
                          ? undefined
                          : filterOptions.dealer_id,
                      deal_status:
                        filterOptions.deal_status === "-"
                          ? undefined
                          : filterOptions.deal_status,
                      created_by:
                        filterOptions.created_by === "-"
                          ? undefined
                          : filterOptions.created_by,
                    })
                  }
                  columns={[
                    {
                      dataField: "full_name",
                      text: "Name",
                      sort: true,
                    },
                    {
                      dataField: "mobile_number",
                      text: "Phone",
                      sort: true,
                    },
                    {
                      dataField: "created_by",
                      text: "Created By",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {!role.auth_user_team &&
                              (row.dealer.name ? row.dealer.name : "-")}
                            {!role.superadministrator &&
                              (row.owner.name ? row.owner.name : "-")}
                          </>
                        );
                      },
                    },
                    {
                      dataField: "deal_status",
                      text: "Deal Status",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.last_action
                          ? dealStatus[row.last_action]
                          : dealStatus[row.deal_status];
                      },
                    },
                    {
                      dataField: "stock_no",
                      text: "Stock No",
                      sort: true,
                    },
                    {
                      dataField: "",
                      text: "",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {(update(
                              profilePermissions,
                              role.auth_user_team,
                              row
                            ) ||
                              deletee(
                                profilePermissions,
                                role.auth_user_team,
                                row,
                                siteCode
                              )) && (
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  color=""
                                  role="button"
                                  size="sm"
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-arrow"
                                  right
                                >
                                  {update(
                                    profilePermissions,
                                    role.auth_user_team,
                                    row
                                  ) && (
                                    <DropdownItem
                                      onClick={(e) => editDeal(row)}
                                    >
                                      Edit
                                    </DropdownItem>
                                  )}
                                  {deletee(
                                    profilePermissions,
                                    role.auth_user_team,
                                    row,
                                    siteCode
                                  ) && (
                                    <DropdownItem
                                      onClick={(e) => {
                                        setSelectedDealId(row.id);
                                        setConfirmModal(true);
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            )}
                          </>
                        );
                      },
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* Delete Modal */}
        <Modal
          isOpen={confirmModal}
          toggle={() => setConfirmModal(false)}
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body">
            Are you sure you want to delete this deal?
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={() => deleteDeal()}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setConfirmModal(false)}
            >
              Close
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  );
}
