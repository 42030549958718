import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useDropzone } from "react-dropzone"
import NotificationAlert from "react-notification-alert"
import Select2 from "react-select2-wrapper";
import CreatableSelect from "react-select/creatable";
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment'

import actions from 'actions'
import * as constants from 'constants/index.js'

import CardsHeader from 'layouts/Headers/CardsHeader.js'
import InputCustom from 'views/pages/components/InputCustom'
import {
  InputKeySelect,
  InputArraySelect,
} from 'views/pages/components/InputSelect'
import TableCustom from 'views/pages/components/TableCustom'
import VerticalLabel from 'views/pages/components/VerticalLabel'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,  
  Carousel,
  CarouselItem,
  CarouselControl,
  Table,
  Input,
  Modal,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
} from 'reactstrap'

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out"
}

const activeStyle = {
  borderColor: "#2196f3"
}

const acceptStyle = {
  borderColor: "#00e676"
}

const rejectStyle = {
  borderColor: "#ff1744"
}

export default function VehicleEdit() {
  const { id } = useParams()
  const notificationAlert = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()

  const [tab, setTab] = useState('details')

  const [token, setToken] = useState('')
  const [delay, setDelay] = useState(false)
  const [contract, setContract] = useState('')
  const [intervals, setIntervals] = useState('')
  const [vehicle, setVehicle] = useState('')
  const [makes, setMakes] = useState([])
  const [models, setModels] = useState([])
  const [filteredModels, setFilteredModels] = useState([])
  const [vehicleImages, setVehicleImages] = useState('')
  const [vehicleStatuses, setVehicleStatuses] = useState('')
  const [vehicleTransmissions, setVehicleTransmissions] = useState('')
  const [vehicleTypes, setVehicleTypes] = useState('')
  const [vehicleRepository, setVehicleRepository] = useState('')
  // const [settlementCost, setSettlementCost] = useState('')
  const [dealerMembers, setDealerMembers] = useState([])
  const [vehicleTaskStatuses, setVehicleTaskStatuses] = useState('')
  const [gears, setGears] = useState('')
  const [fuels, setFuels] = useState('')

  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const [slides, setSlides] = useState('')

  const [errors, setErrors] = useState('')
  const [soldErrors, setSoldErrors] = useState('')
  const [documentErrors, setDocumentErrors] = useState('')

  const [selectedImage, setSelectedImage] = useState({
    id: '',
    index: ''
  })
  const [confirmModal, setConfirmModal] = useState(false)

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null,
  })

  const [fileLists, setFileLists] = useState([])
  
  const [updateVehicleEnable, setUpdateVehicleEnable] = useState(false)
  const [updateValuationEnable, setUpdateValuationEnable] = useState(false)

  const [addTaskModal, setAddTaskModal] = useState({
    show: false,
    header: '',
    division: '',
  })
  const [task, setTask] = useState('')
  const [taskErrors, setTaskErrors] = useState('')

  const [updateVehicleModal, setUpdateVehicleModal] = useState({
    show: false,
    header: '',
    key: '',
    type: 'text'
  })

  const [updateMakeModelModal, setUpdateMakeModelModal] = useState({
    show: false,
  })

  const [updateVehicleSelectModal, setUpdateVehicleSelectModal] = useState({
    show: false,
    header: '',
    key: '',
    name: '',
    options: []
  })

  const onDrop = useCallback(acceptedFiles => {
    setFileLists(prev => [...prev, ...acceptedFiles])
  })
  
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({onDrop})

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/vehicle/${id}/edit`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        
        setContract(data.data.contract)
        setIntervals(data.data.intervals)
        setVehicle({
          ...data.data.item,
          date_of_reg: data.data.item.date_of_reg ? constants.formatDate(data.data.item.date_of_reg) : '',
          mot_date: data.data.item.mot_date ? moment(data.data.item.mot_date).format("YYYY-MM-DD") : '',
          delivered_to_us: data.data.item.delivered_to_us ? constants.formatDate(data.data.item.delivered_to_us) : '',
          rfl_taxed_date: data.data.item.rfl_taxed_date ? moment(data.data.item.rfl_taxed_date).format("YYYY-MM-DD") : '',
          sold_at: data.data.item.sold_at ? constants.formatDate(data.data.item.sold_at) : '',
          valuation_time: data.data.item.valuation_time ? moment(data.data.item.valuation_time).format("YYYY-MM-DD") : '',
          gearbox_type_name: data.data.item.vehicleGear && data.data.item.vehicleGear.name ? data.data.item.vehicleGear.name : null,
          fuel_type_name: data.data.item.vehicleFuel && data.data.item.vehicleFuel.name ? data.data.item.vehicleFuel.name : null,
        })
        setMakes(data.data.makes)
        setModels(data.data.models)
        setFilteredModels(data.data.models)
        setVehicleImages(data.data.vehicleImages)
        setVehicleStatuses(data.data.vehicleStatuses)
        setVehicleTransmissions(data.data.vehicleTransmissions)
        setVehicleTypes(data.data.vehicleTypes)
        setVehicleRepository(data.data.vehicleRepository)
        // setSettlementCost(data.data.settlementCost)

        const _gears = data.data.gears
        let _gears_ = []
        _gears.map(gear => {
          _gears_.push({
            value: gear.id,
            label: gear.name
          })
        })
        setGears(_gears_)
        
        const _fuels = data.data.fuels
        let _fuels_ = []
        _fuels.map(gear => {
          _fuels_.push({
            value: gear.id,
            label: gear.name
          })
        })
        setFuels(_fuels_)

        let dealer_members = data.data.dealerMembers
        dealer_members.map(dealer => {
          dealer.text = dealer.name
        })
        setDealerMembers(dealer_members)
        setVehicleTaskStatuses(data.data.vehicleTaskStatuses)

        let _images = data.data.vehicleImages.images_collection
        const slides = _images.map((item) => {
          return (
            <CarouselItem
              onExiting={() => setAnimating(true)}
              onExited={() => setAnimating(false)}
              key={item.link}
            >
              <img src={`${constants.BASE_URL}${item.link}`} width={900} height={405} />
            </CarouselItem>
          )
        })
        setSlides(slides)

        setTimeout(() => {
          setDelay(true)
        }, 500)
        dispatch(actions.getNotifications(grs_token))
      } catch (error) {
        setDelay(true)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const updateVehicle = () => {
    delete vehicle['vehicleGear']
    delete vehicle['vehicle_gear']
    delete vehicle['gearbox_type_name']
    delete vehicle['vehicleFuel']
    delete vehicle['vehicle_fuel']
    delete vehicle['fuel_type_name']
    delete vehicle['vehicleMake']
    delete vehicle['vehicle_make']
    delete vehicle['vehicleModel']
    delete vehicle['vehicle_model']
    delete vehicle['document_files']
    delete vehicle['tasks']

    let formData = new FormData()

    if (vehicle.images_collection && vehicle.images_collection.length > 0) {
      for (let index = 0; index < vehicle.images_collection.length; index++) {
        const element = vehicle.images_collection[index]
        if (typeof element == 'object' && element.type) {
          formData.append(`images_collection[${index}]`, element)
        }
      }
    }

    Object.keys(vehicle).map(key => {
      if (key != 'images_collection' && key != 'ownerable' && ![null, undefined].includes(vehicle[key])) {
        if (key == 'featured_image') {
          if (typeof vehicle[key] == 'object') {
            formData.append(key, vehicle[key])
          }
        // } else if (key == 'mot_date' || key == 'rfl_taxed_date' || key == 'valuation_time') {
        //   formData.append(key, vehicle[key] ? moment(vehicle[key], "DD/MM/YYYY").format('YYYY-MM-DD') : '')
        } else {
          if (key == 'tracker' || key == 'enabled') {
            if (vehicle[key] != 0 && vehicle[key] != false) {
              formData.append(key, vehicle[key])
            }
          } else if (key == 'maintenance_included') {
            formData.append(key, (vehicle[key] === true || vehicle[key] === 1 ) ? 1: 0)
          } else {
            formData.append(key, vehicle[key])
          }
        }
      }
    })
    formData.append('vehicle_make_selector', vehicle && vehicle.vehicle_make_selector ? vehicle.vehicle_make_selector : vehicle.redundant_vehicle_make_id)
    formData.append('_method', 'PUT')

    axios({
      method: 'POST',
      url: `${constants.API_URL}/vehicle/${id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: formData,
    })
      .then(response => {
        setErrors('')
        setDelay(false)
        console.log('response :: ', response.data)
        const data = response.data
        notify('success', 'Success', data.message)
        
        setVehicle({
          ...data.data.item,
          date_of_reg: data.data.item.date_of_reg ? constants.formatDate(data.data.item.date_of_reg) : '',
          mot_date: data.data.item.mot_date ? moment(data.data.item.mot_date).format("YYYY-MM-DD") : '',
          delivered_to_us: data.data.item.delivered_to_us ? constants.formatDate(data.data.item.delivered_to_us) : '',
          rfl_taxed_date: data.data.item.rfl_taxed_date ? moment(data.data.item.rfl_taxed_date).format("YYYY-MM-DD") : '',
          sold_at: data.data.item.sold_at ? constants.formatDate(data.data.item.sold_at) : '',
          valuation_time: data.data.item.valuation_time ? moment(data.data.item.valuation_time).format("YYYY-MM-DD") : '',
          gearbox_type_name: data.data.item.vehicleGear && data.data.item.vehicleGear.name ? data.data.item.vehicleGear.name : null,
          fuel_type_name: data.data.item.vehicleFuel && data.data.item.vehicleFuel.name ? data.data.item.vehicleFuel.name : null,
        })
        setVehicleImages(data.data.vehicleImages)

        let _images = data.data.vehicleImages.images_collection;
        const slides = _images.map((item) => {
          return (
            <CarouselItem
              onExiting={() => setAnimating(true)}
              onExited={() => setAnimating(false)}
              key={item.link}
            >
              <img src={`${constants.BASE_URL}${item.link}`} width={900} height={405} />
            </CarouselItem>
          );
        });
        setSlides(slides)

        setTimeout(() => {
          setDelay(true)
        }, 500)

        setUpdateVehicleModal({
          ...updateVehicleModal,
          show: false
        })

        setUpdateMakeModelModal({
          ...updateMakeModelModal,
          show: false
        })

        setUpdateVehicleSelectModal({
          ...updateVehicleSelectModal,
          show: false
        })
      })
      .catch(error => {
        setErrors('')
        setDelay(true)
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors)
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  const next = (items) => {
    if (animating) return
    if (items.length > 0) {
      const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
      setActiveIndex(nextIndex)
    }
  }

  const previous = (items) => {
    if (animating) return
    if (items.length > 0) {
      const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
      setActiveIndex(nextIndex)
    }
  }

  const goToIndex = (newIndex) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  // const duplicate = () => {
  //   history.push({
  //     pathname: `/admin/vehicle/${id}/duplicate`,
  //   })
  // }

  const additionalCosts = () => {
    history.push({
      pathname: `/admin/vehicle/${id}/cost`,
    })
  }

  const financialDetails = () => {
    history.push({
      pathname: `/admin/vehicle/${id}/financial`,
    })
  }

  const financialHistory = () => {
    history.push({
      pathname: `/admin/vehicle/${id}/financial-history`,
    })
  }
  
  const sold = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/vehicle/${id}/action/sold`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        // settlement_cost: settlementCost.settlementCost,
        sold_amount: vehicle.sold_amount,
        sold_at: vehicle.sold_at,
      },
    })
      .then(response => {
        setSoldErrors('')
        console.log('response :: ', response.data)
        const data = response.data
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        setSoldErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          setSoldErrors(error.response.data.errors)
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  const deleteImage = () => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/vehicle/${id}/remove/${selectedImage.id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        setConfirmModal(false)
        console.log('response :: ', response.data)
        const data = response.data
        if (vehicleImages.images_collection) {

          const collection_images = vehicleImages.images_collection
          let _new_1 = []
          let _new_2 = []
          let new_collection_images = []

          _new_1 = collection_images.slice(0, selectedImage.index)
          _new_2 = collection_images.slice(selectedImage.index + 1, collection_images.length)
          new_collection_images = _new_1.concat(_new_2)
          
          const slides = new_collection_images.map((item) => {
            return (
              <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.link}
              >
                <img src={`${constants.BASE_URL}${item.link}`} width={900} height={405} />
              </CarouselItem>
            )
          })
          setSlides(slides)
          setVehicleImages({
            ...vehicleImages,
            images_collection: new_collection_images,
          })
        }
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        setConfirmModal(false)
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  const returnedToFunder = () => {
    axios({
      method: 'GET',
      url: `${constants.API_URL}/vehicle/${id}/action/returned`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        console.log('response :: ', response.data)
        const data = response.data
        notify('success', 'Success', data.message)
      })
      .catch(error => {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  const uploadDocument = () => {
    let formData = new FormData()
    for (let index = 0; index < fileLists.length; index++) {
      const element = fileLists[index]
      formData.append(`file[${index}]`, element)
    }

    axios({
      method: 'POST',
      url: `${constants.API_URL}/vehicle/${id}/general-documents`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: formData
    })
      .then(response => {
        if (response.status == 200) {
          const data = response.data
          console.log('response data :: ', data)

          setVehicle({
            ...vehicle,
            document_files: data.data,
          })
          setFileLists([])
          notify('success', 'Success', data.message)
        }
      })
      .catch(error => {
        setDocumentErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          const errors = error.response.data.errors
          setDocumentErrors(errors)
        }
        notify('warning', 'Warning', error.response && error.response.statusText? error.response.statusText: 'Error occur.')
      })
  }

  const viewDocument = (row) => {
    axios({
      method: 'GET',
      url: `${constants.API_URL}/vehicle/${id}/document/${row.id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob'
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data
          console.log('response data :: ', data)
          const disposition = response.request.getResponseHeader('Content-Disposition')
          let fileName = ""
          const filenameRegex = /filename[^=\n]*=((['"]).*?\2|[^\n]*)/
          const matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '')
          }
          const blob = new Blob([data])
          const downloadUrl = URL.createObjectURL(blob)
          let a = window.document.createElement("a")
          a.href = downloadUrl
          a.download = fileName
          window.document.body.appendChild(a)
          a.click()
        }
      })
      .catch(error => {
        console.error('error :: ', error.response)
        notify('warning', 'Warning', error.response && error.response.data.message? error.response.data.message: error.response.statusText)
      })
  }

  const deleteDocument = (documentId) => {
    axios({
      method: 'GET',
      url: `${constants.API_URL}/vehicle/${id}/remove/${documentId}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        })
        if (response.status == 200) {
          let data = response.data
          console.log('response data :: ', data)
          setVehicle({
            ...vehicle,
            document_files: data.data,
          })
          notify('success', 'Success', data.message)
        }
      })
      .catch(error => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        })
        console.error('error :: ', error.response)
        notify('warning', 'Warning', error.response && error.response.data.message? error.response.data.message: error.response.statusText)
      })
  }

  /**
   * New
   */
  // const getValuation = () => {
  //   axios({
  //     method: 'GET',
  //     url: `${constants.UK_VEHICLE_DATA_API_URL}/ValuationData?v=2&api_nullitems=1&auth_apikey=${constants.UK_VEHICLE_DATA_API_KEY}&key_VRM=${vehicle.stock_no}`,
  //   })
  //     .then(response => {
  //       const _valuation_data = response.data
  //       console.log('_valuation_data :: ', _valuation_data)

  //       if (_valuation_data.Response) {

  //         if (_valuation_data.Response.StatusCode === 'Success') {
  //           const valuationList = _valuation_data.Response.DataItems

  //           setVehicle({
  //             ...vehicle,    
  //             dealer_forecourt: valuationList.ValuationList.DealerForecourt ? valuationList.ValuationList.DealerForecourt : 0,
  //             part_exchange:    valuationList.ValuationList.PartExchange ? valuationList.ValuationList.PartExchange : 0,
  //             trade_poor:       valuationList.ValuationList.TradePoor ? valuationList.ValuationList.TradePoor : 0,
  //             trade_retail:     valuationList.ValuationList.TradeRetail ? valuationList.ValuationList.TradeRetail : 0,
  //             trade_average:    valuationList.ValuationList.TradeAverage ? valuationList.ValuationList.TradeAverage : 0,
  //             auction:          valuationList.ValuationList.Auction ? valuationList.ValuationList.Auction : 0,
  //             valuation_time:   valuationList.ValuationTime ? constants.formatDateShortGB(valuationList.ValuationTime) : ''
  //           })

  //           notify('success', 'Success', 'Got valuation data successfully, please click Save button if you need to save them')
  //           setUpdateValuationEnable(true)
  //         } else {
  //           notify('warning', 'Getting valuation warning', _valuation_data.Response.StatusMessage)
  //         }
  //       }
  //     })
  //     .catch(error => {
  //       console.error(error)
  //     })
  // }

  // const getVehicleData = () => {
  //   /**
  //    * Get vehicle data
  //    */
  //   const _vehicle_data_request = axios({
  //     method: 'GET',
  //     url: `${constants.UK_VEHICLE_DATA_API_URL}/VehicleData?v=2&api_nullitems=1&auth_apikey=${constants.UK_VEHICLE_DATA_API_KEY}&key_VRM=${vehicle.stock_no}`,
  //   })
  
  //   /**
  //    * Get vehicle valuation data
  //    */
  //   const _mot_tax_data_request = axios({
  //     method: 'GET',
  //     url: `${constants.UK_VEHICLE_DATA_API_URL}/MotHistoryAndTaxStatusData?v=2&api_nullitems=1&auth_apikey=${constants.UK_VEHICLE_DATA_API_KEY}&key_VRM=${vehicle.stock_no}`,
  //   })

  //   axios.all([_vehicle_data_request, _mot_tax_data_request])
  //     .then(axios.spread((...responses) => {

  //       const _vehicle_data = responses[0].data
  //       const _mot_tax_data = responses[1].data

  //       if (_vehicle_data.Response && _mot_tax_data.Response) {

  //         if (_vehicle_data.Response.StatusCode === 'Success') {

  //           const dataItems  = _vehicle_data.Response.DataItems
  //           const motTaxData = _mot_tax_data.Response.StatusCode === 'Success' ? _mot_tax_data.Response.DataItems.VehicleStatus : []

  //           setVehicle({
  //             ...vehicle,
  //             full_vin:              dataItems.VehicleRegistration.Vin ? dataItems.VehicleRegistration.Vin : '',
  //             engine_number:         dataItems.VehicleRegistration.EngineNumber ? dataItems.VehicleRegistration.EngineNumber : '',
  //             first_registered:      dataItems.VehicleRegistration.DateFirstRegistered ? dataItems.VehicleRegistration.DateFirstRegistered : '',
  //             first_registered_uk:   dataItems.VehicleRegistration.DateFirstRegisteredUk ? dataItems.VehicleRegistration.DateFirstRegisteredUk : '',
  //             year_manufacture:      dataItems.VehicleRegistration.YearOfManufacture ? dataItems.VehicleRegistration.YearOfManufacture : '',
  //             gearbox_type:          dataItems.VehicleRegistration.Transmission ? dataItems.VehicleRegistration.Transmission : '',
  //             colour:                dataItems.VehicleRegistration.Colour ? dataItems.VehicleRegistration.Colour : '',
  //             gears_number:          dataItems.SmmtDetails.NumberOfGears ? dataItems.SmmtDetails.NumberOfGears: 0,
  //             fuel_type:             dataItems.VehicleRegistration.FuelType,
  //             engine_capacity:       dataItems.VehicleRegistration.EngineCapacity,
  //             door_plan:             dataItems.VehicleRegistration.DoorPlanLiteral,
  //             seating_capacity:      dataItems.VehicleRegistration.SeatingCapacity ? dataItems.VehicleRegistration.SeatingCapacity : 0,
  //             co2:                   dataItems.VehicleRegistration.Co2Emissions ? dataItems.VehicleRegistration.Co2Emissions : 0,
  //             kerb_weight:           dataItems.TechnicalDetails.Dimensions.KerbWeight ? dataItems.TechnicalDetails.Dimensions.KerbWeight : 0,
  //             vin_last_5:            dataItems.VehicleRegistration.VinLast5 ? dataItems.VehicleRegistration.VinLast5 : 0,
  //             scrapped:              dataItems.VehicleRegistration.Scrapped,
  //             date_scrapped:         dataItems.VehicleRegistration.DateScrapped,
  //             exported:              dataItems.VehicleRegistration.Exported,
  //             date_exported:         dataItems.VehicleRegistration.DateExported,
  //             imported:              dataItems.VehicleRegistration.Imported,
  //             imported_non_eu:       dataItems.VehicleRegistration.ImportNonEu,
  //             euro_status:           dataItems.TechnicalDetails.General.EuroStatus ? dataItems.TechnicalDetails.General.EuroStatus : '',
  //             driven_axle:           dataItems.TechnicalDetails.General.DrivingAxle ? dataItems.TechnicalDetails.General.DrivingAxle : '',
  //             type_approval:         dataItems.TechnicalDetails.General.TypeApprovalCategory ? dataItems.TechnicalDetails.General.TypeApprovalCategory : '',
  //             vehicle_class:         dataItems.VehicleRegistration.VehicleClass,
  //             gross_weight:          dataItems.TechnicalDetails.Dimensions.GrossVehicleWeight ? dataItems.TechnicalDetails.Dimensions.GrossVehicleWeight : 0,
  //             length:                dataItems.TechnicalDetails.Dimensions.CarLength ? dataItems.TechnicalDetails.Dimensions.CarLength : 0,
  //             height:                dataItems.TechnicalDetails.Dimensions.Height ? dataItems.TechnicalDetails.Dimensions.Height : 0,
  //             width:                 dataItems.TechnicalDetails.Dimensions.Width ? dataItems.TechnicalDetails.Dimensions.Width : 0,
  //             power_bhp:             dataItems.TechnicalDetails.Performance.Power.Bhp ? dataItems.TechnicalDetails.Performance.Power.Bhp : 0,
  //             power_kw:              dataItems.TechnicalDetails.Performance.Power.Kw ? dataItems.TechnicalDetails.Performance.Power.Kw : 0,
  //             power_rpm:             dataItems.TechnicalDetails.Performance.Power.Rpm ? dataItems.TechnicalDetails.Performance.Power.Rpm : 0,
  //             torque_ftlb:           dataItems.TechnicalDetails.Performance.Torque.FtLb ? dataItems.TechnicalDetails.Performance.Torque.FtLb : 0,
  //             torque_nm:             dataItems.TechnicalDetails.Performance.Torque.Nm ? dataItems.TechnicalDetails.Performance.Torque.Nm : 0,
  //             torque_rpm:            dataItems.TechnicalDetails.Performance.Torque.Rpm ? dataItems.TechnicalDetails.Performance.Torque.Rpm : 0,
  //             max_speed_mph:         dataItems.TechnicalDetails.Performance.MaxSpeed.Mph ? dataItems.TechnicalDetails.Performance.MaxSpeed.Mph : 0,
  //             max_speed_kph:         dataItems.TechnicalDetails.Performance.MaxSpeed.Kph ? dataItems.TechnicalDetails.Performance.MaxSpeed.Kph : 0,
  //             cylinders:             dataItems.TechnicalDetails.General.Engine.NumberOfCylinders ? dataItems.TechnicalDetails.General.Engine.NumberOfCylinders : 0,
  //             valves_per_cyl:        dataItems.TechnicalDetails.General.Engine.ValvesPerCylinder ? dataItems.TechnicalDetails.General.Engine.ValvesPerCylinder : 0,
  //             stroke:                dataItems.TechnicalDetails.General.Engine.Stroke ? dataItems.TechnicalDetails.General.Engine.Stroke : '',
  //             bore:                  dataItems.TechnicalDetails.General.Engine.Bore ? dataItems.TechnicalDetails.General.Engine.Bore : '',
  //             arrangement:           dataItems.TechnicalDetails.General.Engine.CylinderArrangement ? dataItems.TechnicalDetails.General.Engine.CylinderArrangement : 0,
  //             cam_type:              dataItems.TechnicalDetails.General.Engine.ValveGear ? dataItems.TechnicalDetails.General.Engine.ValveGear : '',
  //             engine_location:       dataItems.TechnicalDetails.General.Engine.Location ? dataItems.TechnicalDetails.General.Engine.Location : '',
  //             aspiration:            dataItems.TechnicalDetails.General.Engine.Aspiration ? dataItems.TechnicalDetails.General.Engine.Aspiration : '',
  //             total_keepers:         dataItems.VehicleHistory.NumberOfPreviousKeepers ? Number(dataItems.VehicleHistory.NumberOfPreviousKeepers) + 1 : 0,
  //             colour_changes:        dataItems.VehicleHistory.ColourChangeCount ? dataItems.VehicleHistory.ColourChangeCount : 0,
  //             v5_certificates:       dataItems.VehicleHistory.V5CCertificateCount ? dataItems.VehicleHistory.V5CCertificateCount : 0,
  //             mot_date:              motTaxData.NextMotDueDate ? motTaxData.NextMotDueDate : null,
  //             rfl_taxed_date:        motTaxData.MotVed.VedExpiryDate ? moment(motTaxData.MotVed.VedExpiryDate).format('DD/MM/YYYY') : null,
  //             vehicleGear: {
  //               name: dataItems.VehicleRegistration.Transmission
  //             },
  //             vehicleFuel: {
  //               name: dataItems.VehicleRegistration.FuelType
  //             }
  //           })
  //           notify('success', 'Success', 'Got vehicle data successfully, please click below Save button if you need to save them')
  //           setUpdateVehicleEnable(true)
  //         } else {
  //           notify('warning', 'Getting vehicle data warning', _vehicle_data.Response.StatusMessage)
  //         }
  //       }
  //     }))
  //     .catch(error => {
  //       console.error('error :: ', error)
  //     })
  // }

  const addTask = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/vehicle/${id}/task`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: task,
    })
      .then(response => {
        setAddTaskModal({
          ...addTaskModal,
          show: false
        })
        if (response.status == 200) {
          const data = response.data;
          console.log('response data :: ', data);
          setVehicle({
            ...vehicle,
            tasks: data.data.tasks,
          })
          setTask('');
          dispatch(actions.getNotifications(token));
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setTaskErrors('');
        console.error('error :: ', error.response);
        if (error.response && error.response.status == 422) {
          setTaskErrors(error.response.data.errors)
          return
        }
        setAddTaskModal({
          ...addTaskModal,
          show: false
        });
        notify('warning', 'Warning', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const updateTask = () => {
    axios({
      method: 'PUT',
      url: `${constants.API_URL}/vehicle/${id}/task/${task.uuid}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...task,
        task_assignee: task.taskAssignee,
      },
    })
      .then(response => {
        setAddTaskModal({
          ...addTaskModal,
          show: false
        })
        if (response.status == 200) {
          const data = response.data;
          console.log('response data :: ', data);
          setVehicle({
            ...vehicle,
            tasks: data.data.tasks,
          })
          setTask('');
          dispatch(actions.getNotifications(token));
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setTaskErrors('');
        console.error('error :: ', error.response);
        if (error.response && error.response.status == 422) {
          setTaskErrors(error.response.data.errors)
          return
        }
        setAddTaskModal({
          ...addTaskModal,
          show: false
        });
        notify('warning', 'Warning', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const deleteTask = (taskId) => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/vehicle/${id}/task/${taskId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        if (response.status == 200) {
          const data = response.data;
          console.log('response data :: ', data);
          setVehicle({
            ...vehicle,
            tasks: data.data.tasks,
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const selectMake = (makeId) => {
    let _models = []
    if (makeId === '-') {
      setFilteredModels(models)
      return
    }
    if (models.length > 0) {
      _models = models.filter((model) => {
        if (model.vehicle_make && model.vehicle_make.id == makeId) {
          return model
        }
      })

      setFilteredModels(_models)
    }
  }

  return (
    <>
      <CardsHeader
        name="Vehicle"
        parentName="Vehicle Management"
        currentName="List"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Card>
          <CardHeader>
            <Row>
              <Col md={12} sm={12}>
                <Button
                  className={`col-md-2 col-sm-12 deal-tab ${
                    tab == "details"
                      ? "selectedButton"
                      : "releasedSelectedButton"
                  }`}
                  style={{
                    margin: 5,
                  }}
                  onClick={() => setTab("details")}
                >
                  Vehicle Details
                </Button>
                <Button
                  className={`col-md-2 col-sm-12 deal-tab ${
                    tab == "financial"
                      ? "selectedButton"
                      : "releasedSelectedButton"
                  }`}
                  style={{
                    margin: 5,
                  }}
                  onClick={() => setTab("financial")}
                >
                  Financial
                </Button>
                <Button
                  className={`col-md-2 col-sm-12 deal-tab ${
                    tab == "frontend"
                      ? "selectedButton"
                      : "releasedSelectedButton"
                  }`}
                  style={{
                    margin: 5,
                  }}
                  onClick={() => setTab("frontend")}
                >
                  Front End
                </Button>
                <Button
                  className={`col-md-2 col-sm-12 deal-tab ${
                    tab == "documents"
                      ? "selectedButton"
                      : "releasedSelectedButton"
                  }`}
                  style={{
                    margin: 5,
                  }}
                  onClick={() => setTab("documents")}
                >
                  Documents
                </Button>
                <Button
                  className={`col-md-2 col-sm-12 deal-tab ${
                    tab == "tasks" ? "selectedButton" : "releasedSelectedButton"
                  }`}
                  style={{
                    margin: 5,
                  }}
                  onClick={() => setTab("tasks")}
                >
                  Tasks
                </Button>
              </Col>
            </Row>
          </CardHeader>
          {tab === "details" && (
            <CardBody>
              {/* <Row>
                <Col md={9} sm={12}></Col>
                <Col md={3} sm={12}>
                  <Button
                    className="w-100"
                    color="success"
                    onClick={() => getValuation()}
                  >
                    Get Valuation
                  </Button>
                </Col>
              </Row> */}
              <Row>
                <Col md={12}>
                  <h1>{`Valuations ${
                    vehicle && vehicle.valuation_time
                      ? `(${vehicle.valuation_time})`
                      : ""
                  }`}</h1>
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Dealer Forecourt`}
                    value={
                      vehicle && vehicle.dealer_forecourt
                        ? `${constants.CURRENCY} ${vehicle.dealer_forecourt}`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Dealer Forecourt",
                        key: "dealer_forecourt",
                        type: "number",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Part Exchange`}
                    value={
                      vehicle && vehicle.part_exchange
                        ? `${constants.CURRENCY} ${vehicle.part_exchange}`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Part Exchange",
                        key: "part_exchange",
                        type: "number",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Trade Poor`}
                    value={
                      vehicle && vehicle.trade_poor
                        ? `${constants.CURRENCY} ${vehicle.trade_poor}`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Trade Poor",
                        key: "trade_poor",
                        type: "number",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Trade Retail`}
                    value={
                      vehicle && vehicle.trade_retail
                        ? `${constants.CURRENCY} ${vehicle.trade_retail}`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Trade Retail",
                        key: "trade_retail",
                        type: "number",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Trade Average`}
                    value={
                      vehicle && vehicle.trade_average
                        ? `${constants.CURRENCY} ${vehicle.trade_average}`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Trade Average",
                        key: "trade_average",
                        type: "number",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Auction`}
                    value={
                      vehicle && vehicle.auction
                        ? `${constants.CURRENCY} ${vehicle.auction}`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Auction",
                        key: "auction",
                        type: "number",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                {/* <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Settlement figure`}
                    value={
                      settlementCost && settlementCost.settlementCost
                        ? `${constants.CURRENCY} ${settlementCost.settlementCost}`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Settlement figure",
                        key: "settlementCost",
                        type: "number",
                      });
                    }}
                    editable={true}
                  />
                </Col> */}
              </Row>
              {/* <Row>
                <Col md={12}>
                  <Button
                    className="w-100"
                    color="success"
                    onClick={() => updateVehicle()}
                    disabled={!updateValuationEnable}
                  >
                    Save
                  </Button>
                </Col>
              </Row> */}
              {/* <Row className="mt-4">
                <Col md={9} sm={12}></Col>
                <Col md={3} sm={12}>
                  <Button
                    className="w-100"
                    color="success"
                    onClick={() => getVehicleData()}
                  >
                    Get Vehicle Data
                  </Button>
                </Col>
              </Row> */}
              <Row className="mt-4">
                <Col md={12}>
                  <h1>{`Vehicle registration`}</h1>
                </Col>
                <Col md={3} sm={12}>
                  <FormGroup>
                    <label
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        borderBottom: "1px solid #c0c0c0",
                        color: "#2d2d2d",
                        lineHeight: "16px",
                      }}
                    >
                      {`Make`}
                    </label>
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#414141",
                        cursor: "pointer",
                        textDecorationLine: "underline",
                        textDecorationStyle: "dotted",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          vehicle &&
                          vehicle.vehicleMake &&
                          vehicle.vehicleMake.name
                            ? `${vehicle.vehicleMake.name.toUpperCase()}`
                            : ` `,
                      }}
                      onClick={() => {
                        setUpdateMakeModelModal({
                          ...updateMakeModelModal,
                          show: true,
                        });
                        selectMake(
                          vehicle.redundant_vehicle_make_id
                            ? vehicle.redundant_vehicle_make_id
                            : "-"
                        );
                      }}
                    ></div>
                  </FormGroup>
                </Col>
                <Col md={3} sm={12}>
                  <FormGroup>
                    <label
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        borderBottom: "1px solid #c0c0c0",
                        color: "#2d2d2d",
                        lineHeight: "16px",
                      }}
                    >
                      {`Model`}
                    </label>
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#414141",
                        cursor: "pointer",
                        textDecorationLine: "underline",
                        textDecorationStyle: "dotted",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          vehicle &&
                          vehicle.vehicleModel &&
                          vehicle.vehicleModel.name
                            ? `${vehicle.vehicleModel.name.toUpperCase()}`
                            : ` `,
                      }}
                      onClick={() => {
                        setUpdateMakeModelModal({
                          ...updateMakeModelModal,
                          show: true,
                        });
                        selectMake(
                          vehicle.redundant_vehicle_make_id
                            ? vehicle.redundant_vehicle_make_id
                            : "-"
                        );
                      }}
                    ></div>
                  </FormGroup>
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Status`}
                    value={
                      vehicle && vehicle.status
                        ? `${vehicle.status.toUpperCase()}`
                        : ` `
                    }
                  />
                </Col>
                <Col md={3} sm={12}>
                  <FormGroup>
                    <label
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        borderBottom: "1px solid #c0c0c0",
                        color: "#2d2d2d",
                        lineHeight: "16px",
                      }}
                    >
                      {`Derivative`}
                    </label>
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        color:
                          vehicle && vehicle.derivative ? "#414141" : "#B5B5B5",
                        cursor: "pointer",
                        textDecorationLine: "underline",
                        textDecorationStyle: "dotted",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          vehicle && vehicle.derivative
                            ? `${vehicle.derivative.toUpperCase()}`
                            : `[ Editable ]`,
                      }}
                      onClick={() => {
                        setUpdateVehicleModal({
                          ...updateVehicleModal,
                          show: true,
                          header: "Derivative",
                          key: "derivative",
                          type: "text",
                        });
                      }}
                    ></div>
                  </FormGroup>
                </Col>
                <Col md={3} sm={12}>
                  <FormGroup>
                    <label
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        borderBottom: "1px solid #c0c0c0",
                        color: "#2d2d2d",
                        lineHeight: "16px",
                      }}
                    >
                      {`Mileage`}
                    </label>
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#414141",
                        cursor: "pointer",
                        textDecorationLine: "underline",
                        textDecorationStyle: "dotted",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          vehicle && vehicle.current_mileage
                            ? `${vehicle.current_mileage}`
                            : ` `,
                      }}
                      onClick={() => {
                        setUpdateVehicleModal({
                          ...updateVehicleModal,
                          show: true,
                          header: "Mileage",
                          key: "current_mileage",
                          type: "number",
                        });
                      }}
                    ></div>
                  </FormGroup>
                </Col>
                <Col md={3} sm={12}>
                  <FormGroup>
                    <label
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        borderBottom: "1px solid #c0c0c0",
                        color: "#2d2d2d",
                        lineHeight: "16px",
                      }}
                    >
                      {`VRM`}
                    </label>
                    <div
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#414141",
                        cursor: "pointer",
                        textDecorationLine: "underline",
                        textDecorationStyle: "dotted",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          vehicle && vehicle.stock_no
                            ? `${vehicle.stock_no.toUpperCase()}`
                            : ` `,
                      }}
                      onClick={() => {
                        setUpdateVehicleModal({
                          ...updateVehicleModal,
                          show: true,
                          header: "VRM",
                          key: "stock_no",
                          type: "text",
                        });
                      }}
                    ></div>
                  </FormGroup>
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Full VIN`}
                    value={
                      vehicle && vehicle.full_vin
                        ? `${vehicle.full_vin.toUpperCase()}`
                        : ` `
                    }
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Engin number`}
                    value={
                      vehicle && vehicle.engine_number
                        ? `${vehicle.engine_number.toUpperCase()}`
                        : ` `
                    }
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`First registered`}
                    value={
                      vehicle && vehicle.first_registered
                        ? constants.formatDateShortGB(vehicle.first_registered)
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "First registered",
                        key: "first_registered",
                        type: "date",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`First registered UK`}
                    value={
                      vehicle && vehicle.first_registered_uk
                        ? constants.formatDateShortGB(
                            vehicle.first_registered_uk
                          )
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "First registered UK",
                        key: "first_registered_uk",
                        type: "date",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Year manufacture`}
                    value={
                      vehicle && vehicle.year_manufacture
                        ? vehicle.year_manufacture
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Year manufacture",
                        key: "year_manufacture",
                        type: "number",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Colour`}
                    value={vehicle && vehicle.colour ? vehicle.colour : null}
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Colour",
                        key: "colour",
                        type: "text",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Gearbox`}
                    value={
                      vehicle && vehicle.gearbox_type_name
                        ? vehicle.gearbox_type_name.toUpperCase()
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleSelectModal({
                        ...updateVehicleSelectModal,
                        show: true,
                        header: "Gearbox",
                        key: "gearbox_type",
                        name: "gearbox_type_name",
                        options: gears,
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Forward gears`}
                    value={
                      vehicle && vehicle.gears_number
                        ? `${vehicle.gears_number} gears`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Forward gears",
                        key: "gears_number",
                        type: "number",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Fuel`}
                    value={
                      vehicle && vehicle.fuel_type_name
                        ? vehicle.fuel_type_name.toUpperCase()
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleSelectModal({
                        ...updateVehicleSelectModal,
                        show: true,
                        header: "Fuel",
                        key: "fuel_type",
                        name: "fuel_type_name",
                        options: fuels,
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Engine capacity`}
                    value={
                      vehicle && vehicle.engine_capacity
                        ? `${vehicle.engine_capacity}cc`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Engine capacity",
                        key: "engine_capacity",
                        type: "number",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Door plan`}
                    value={
                      vehicle && vehicle.door_plan
                        ? `${vehicle.door_plan.toUpperCase()}`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Door plan",
                        key: "door_plan",
                        type: "text",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Seating capacity`}
                    value={
                      vehicle && vehicle.seating_capacity
                        ? `${vehicle.seating_capacity} seats`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Seating capacity",
                        key: "seating_capacity",
                        type: "number",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`CO2`}
                    value={vehicle && vehicle.co2 ? `${vehicle.co2}g/km` : null}
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "CO2",
                        key: "co2",
                        type: "number",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Kerb weight`}
                    value={
                      vehicle && vehicle.kerb_weight
                        ? `${vehicle.kerb_weight}kg`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Kerb weight",
                        key: "kerb_weight",
                        type: "number",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`VIN last 5`}
                    value={
                      vehicle && vehicle.vin_last_5
                        ? `${vehicle.vin_last_5}`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "VIN last 5",
                        key: "vin_last_5",
                        type: "text",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                {/* <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Scrapped`}
                    value={
                      vehicle && vehicle.scrapped ? `${vehicle.scrapped}` : `NO`
                    }
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Date scrapped`}
                    value={
                      vehicle && vehicle.date_scrapped
                        ? `${vehicle.date_scrapped}`
                        : `Not scrapped`
                    }
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Exported`}
                    value={
                      vehicle && vehicle.exported ? `${vehicle.exported}` : `NO`
                    }
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Date exported`}
                    value={
                      vehicle && vehicle.date_exported
                        ? `${vehicle.date_exported}`
                        : `Not exported`
                    }
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Imported`}
                    value={
                      vehicle && vehicle.imported ? `${vehicle.imported}` : `NO`
                    }
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Imported Non EU`}
                    value={
                      vehicle && vehicle.imported_non_eu
                        ? `${vehicle.imported_non_eu}`
                        : `NO`
                    }
                  />
                </Col> */}
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Euro status`}
                    value={
                      vehicle && vehicle.euro_status
                        ? `${vehicle.euro_status}`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Euro status",
                        key: "euro_status",
                        type: "number",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Driven Axle`}
                    value={
                      vehicle && vehicle.driven_axle
                        ? `${vehicle.driven_axle}`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Driven Axle",
                        key: "driven_axle",
                        type: "text",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Type approval`}
                    value={
                      vehicle && vehicle.type_approval
                        ? `${vehicle.type_approval}`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Type approval",
                        key: "type_approval",
                        type: "text",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Vehicle Class`}
                    value={
                      vehicle && vehicle.vehicle_class
                        ? `${vehicle.vehicle_class}`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Vehicle Class",
                        key: "vehicle_class",
                        type: "text",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Total keepers`}
                    value={
                      vehicle && vehicle.total_keepers
                        ? `${vehicle.total_keepers}`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Total keepers",
                        key: "total_keepers",
                        type: "number",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`Colour changes`}
                    value={
                      vehicle && vehicle.colour_changes
                        ? `${vehicle.colour_changes}`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "Colour changes",
                        key: "colour_changes",
                        type: "number",
                      });
                    }}
                    editable={true}
                  />
                </Col>
                <Col md={3} sm={12}>
                  <VerticalLabel
                    label={`V5 certificates`}
                    value={
                      vehicle && vehicle.v5_certificates
                        ? `${vehicle.v5_certificates}`
                        : null
                    }
                    onClick={() => {
                      setUpdateVehicleModal({
                        ...updateVehicleModal,
                        show: true,
                        header: "V5 certificates",
                        key: "v5_certificates",
                        type: "number",
                      });
                    }}
                    editable={true}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={12}>
                  <h1>{`Technical Data`}</h1>
                </Col>
                <Col md={6} sm={12}>
                  <Card>
                    <CardHeader>
                      <h2 className="mb-0">{`Weights & Dimensions`}</h2>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Kerb weight`}
                            value={
                              vehicle && vehicle.kerb_weight
                                ? `${vehicle.kerb_weight}<sup>kg</sup>`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Kerb weight",
                                key: "kerb_weight",
                                type: "number",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Gross weight`}
                            value={
                              vehicle && vehicle.gross_weight
                                ? `${vehicle.gross_weight}<sup>kg</sup>`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Gross weight",
                                key: "gross_weight",
                                type: "number",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Length`}
                            value={
                              vehicle && vehicle.length
                                ? `${vehicle.length}<sup>mm</sup>`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Length",
                                key: "length",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Height`}
                            value={
                              vehicle && vehicle.height
                                ? `${vehicle.height}<sup>mm</sup>`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Height",
                                key: "height",
                                type: "number",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Width`}
                            value={
                              vehicle && vehicle.width
                                ? `${vehicle.width}<sup>mm</sup>`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Width",
                                key: "width",
                                type: "number",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} sm={12}>
                  <Card>
                    <CardHeader>
                      <h2 className="mb-0">{`Performance`}</h2>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Power Bhp`}
                            value={
                              vehicle && vehicle.power_bhp
                                ? `${vehicle.power_bhp}`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Power Bhp",
                                key: "power_bhp",
                                type: "number",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Power Kw`}
                            value={
                              vehicle && vehicle.power_kw
                                ? `${vehicle.power_kw}`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Power Kw",
                                key: "power_kw",
                                type: "number",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Peak power`}
                            value={
                              vehicle && vehicle.power_rpm
                                ? `${vehicle.power_rpm}rpm`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Peak power",
                                key: "power_rpm",
                                type: "number",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Torque FtLb`}
                            value={
                              vehicle && vehicle.torque_ftlb
                                ? `${vehicle.torque_ftlb}`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Torque FtLb",
                                key: "torque_ftlb",
                                type: "number",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Torque Nm`}
                            value={
                              vehicle && vehicle.torque_nm
                                ? `${vehicle.torque_nm}`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Torque Nm",
                                key: "torque_nm",
                                type: "number",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Peak Torque`}
                            value={
                              vehicle && vehicle.torque_rpm
                                ? `${vehicle.torque_rpm}rpm`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Peak Torque",
                                key: "torque_rpm",
                                type: "number",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Max speed(mph)`}
                            value={
                              vehicle && vehicle.max_speed_mph
                                ? `${vehicle.max_speed_mph}mph`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Max speed(mph)",
                                key: "max_speed_mph",
                                type: "number",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Max speed(kph)`}
                            value={
                              vehicle && vehicle.max_speed_kph
                                ? `${vehicle.max_speed_kph}kph`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Max speed(kph)",
                                key: "max_speed_kph",
                                type: "number",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} sm={12}>
                  <Card>
                    <CardHeader>
                      <h2 className="mb-0">{`Engine technical`}</h2>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Cylinders`}
                            value={
                              vehicle && vehicle.cylinders
                                ? `${vehicle.cylinders}`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Cylinders",
                                key: "cylinders",
                                type: "number",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Valves/cyl`}
                            value={
                              vehicle && vehicle.valves_per_cyl
                                ? `${vehicle.valves_per_cyl}`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Valves/cyl",
                                key: "valves_per_cyl",
                                type: "number",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Stroke`}
                            value={
                              vehicle && vehicle.stroke
                                ? `${vehicle.stroke}mm`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Stroke",
                                key: "stroke",
                                type: "number",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Bore`}
                            value={
                              vehicle && vehicle.bore
                                ? `${vehicle.bore}mm`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Bore",
                                key: "bore",
                                type: "number",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Arrangement`}
                            value={
                              vehicle && vehicle.arrangement
                                ? `${vehicle.arrangement}`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Arrangement",
                                key: "arrangement",
                                type: "text",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Cam type`}
                            value={
                              vehicle && vehicle.cam_type
                                ? `${vehicle.cam_type}`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Cam type",
                                key: "cam_type",
                                type: "text",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Engine location`}
                            value={
                              vehicle && vehicle.engine_location
                                ? `${vehicle.engine_location}`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Engine location",
                                key: "engine_location",
                                type: "text",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={3} sm={12}>
                          <VerticalLabel
                            label={`Aspiration`}
                            value={
                              vehicle && vehicle.aspiration
                                ? `${vehicle.aspiration}`
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Aspiration",
                                key: "aspiration",
                                type: "text",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={6} sm={12}>
                  <Card>
                    <CardHeader>
                      <h2 className="mb-0">{`Mot & Tax data`}</h2>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={6} sm={12}>
                          <VerticalLabel
                            label={`Next mot due date`}
                            value={
                              vehicle && vehicle.mot_date
                                ? moment(vehicle.mot_date, "YYYY-MM-DD").format(
                                    "DD/MM/YYYY"
                                  )
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Next mot due date",
                                key: "mot_date",
                                type: "date",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={6} sm={12}>
                          <VerticalLabel
                            label={`Days until next mot is due`}
                            value={
                              vehicle && vehicle.mot_date
                                ? moment(vehicle.mot_date, "YYYY-MM-DD").diff(
                                    moment(),
                                    "days"
                                  )
                                : ` `
                            }
                          />
                        </Col>
                        <Col md={6} sm={12}>
                          <VerticalLabel
                            label={`Ved expiry date`}
                            value={
                              vehicle && vehicle.rfl_taxed_date
                                ? moment(
                                    vehicle.rfl_taxed_date,
                                    "YYYY-MM-DD"
                                  ).format("DD/MM/YYYY")
                                : null
                            }
                            onClick={() => {
                              setUpdateVehicleModal({
                                ...updateVehicleModal,
                                show: true,
                                header: "Ved expiry date",
                                key: "rfl_taxed_date",
                                type: "date",
                              });
                            }}
                            editable={true}
                          />
                        </Col>
                        <Col md={6} sm={12}>
                          <VerticalLabel
                            label={`Ved days remaining`}
                            value={
                              vehicle && vehicle.rfl_taxed_date
                                ? moment(
                                    vehicle.rfl_taxed_date,
                                    "YYYY-MM-DD"
                                  ).diff(moment(), "days")
                                : ` `
                            }
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {/* <Row>
                <Col md={12}>
                  <Button
                    className="w-100"
                    color="success"
                    onClick={() => updateVehicle()}
                    disabled={!updateVehicleEnable}
                  >
                    Save
                  </Button>
                </Col>
              </Row> */}
            </CardBody>
          )}
          {tab === "financial" && (
            <CardBody>
              <Card>
                <CardHeader>
                  <h1 className="title mb-0">Financial information</h1>
                  <Row className="mt-3">
                    <Col md={4} sm={12}>
                      <Button
                        className="w-100"
                        color="success"
                        type="button"
                        onClick={() => financialDetails()}
                      >
                        Financial details
                      </Button>
                    </Col>
                    <Col md={4} sm={12}>
                      <Button
                        className="w-100"
                        color="success"
                        type="button"
                        onClick={() => financialHistory()}
                      >
                        Financial history
                      </Button>
                    </Col>
                    <Col md={4} sm={12}>
                      <Button
                        className="w-100"
                        color="success"
                        type="button"
                        onClick={() => additionalCosts()}
                      >
                        Additional Costs
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={6} sm={12}>
                      <InputCustom
                        type={`date`}
                        label={`Delivered to us`}
                        value={
                          vehicle && vehicle.delivered_to_us
                            ? vehicle.delivered_to_us
                            : ""
                        }
                        onChange={(e) =>
                          setVehicle({
                            ...vehicle,
                            delivered_to_us: e.target.value,
                          })
                        }
                        invalid={
                          soldErrors && soldErrors.delivered_to_us
                            ? true
                            : false
                        }
                        errorMessage={soldErrors.delivered_to_us}
                      />
                      <InputCustom
                        type={`number`}
                        label={`Monthly Cost`}
                        value={
                          vehicle && vehicle.monthly_cost
                            ? vehicle.monthly_cost
                            : ""
                        }
                        readOnly={true}
                      />
                      <InputCustom
                        type={`number`}
                        label={`OTR`}
                        value={vehicle && vehicle.otr ? vehicle.otr : ""}
                        readOnly={true}
                      />
                      <InputCustom
                        type={`number`}
                        label={`VAT deposit`}
                        value={
                          vehicle && vehicle.total_deposit
                            ? vehicle.total_deposit
                            : ""
                        }
                        readOnly={true}
                      />
                      <InputCustom
                        type={`number`}
                        label={`End payment`}
                        value={
                          vehicle && vehicle.end_payment
                            ? vehicle.end_payment
                            : ""
                        }
                        readOnly={true}
                      />
                      <InputKeySelect
                        label={`Interval`}
                        value={
                          vehicle && vehicle.interval ? vehicle.interval : ""
                        }
                        options={intervals}
                        readOnly={true}
                      />
                    </Col>
                    <Col md={6} sm={12}>
                      <InputCustom
                        label={`Supplier details`}
                        value={
                          vehicle && vehicle.supplying_dealer
                            ? vehicle.supplying_dealer
                            : ""
                        }
                        onChange={(e) =>
                          setVehicle({
                            ...vehicle,
                            supplying_dealer: e.target.value,
                          })
                        }
                      />
                      <InputCustom
                        type={`number`}
                        label={`Finance rate`}
                        value={
                          vehicle && vehicle.finance_rate
                            ? vehicle.finance_rate
                            : ""
                        }
                        readOnly={true}
                      />
                      <InputCustom
                        type={`number`}
                        label={`Total interest`}
                        value={
                          vehicle && vehicle.total_interest
                            ? vehicle.total_interest
                            : ""
                        }
                        readOnly={true}
                      />
                      <InputCustom
                        type={`number`}
                        label={`Non Refundable deposit`}
                        value={
                          vehicle && vehicle.non_refundable_deposit
                            ? vehicle.non_refundable_deposit
                            : ""
                        }
                        readOnly={true}
                      />
                      <InputCustom
                        type={`number`}
                        label={`Term`}
                        value={vehicle && vehicle.term ? vehicle.term : ""}
                        readOnly={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Button
                        className="w-100"
                        color="success"
                        onClick={() => updateVehicle()}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h1>Sold process</h1>
                  <Row>
                    <Col md={6} sm={12}>
                      <InputCustom
                        type={`number`}
                        label={`Sold amount`}
                        value={
                          vehicle && vehicle.sold_amount
                            ? vehicle.sold_amount
                            : ""
                        }
                        onChange={(e) =>
                          setVehicle({
                            ...vehicle,
                            sold_amount: e.target.value,
                          })
                        }
                        invalid={
                          soldErrors && soldErrors.sold_amount ? true : false
                        }
                        errorMessage={soldErrors.sold_amount}
                      />
                    </Col>
                    <Col md={6} sm={12}>
                      <InputCustom
                        type={`date`}
                        label={`Sold at`}
                        value={
                          vehicle && vehicle.sold_at ? vehicle.sold_at : ""
                        }
                        onChange={(e) =>
                          setVehicle({
                            ...vehicle,
                            sold_at: e.target.value,
                          })
                        }
                        invalid={
                          soldErrors && soldErrors.sold_at ? true : false
                        }
                        errorMessage={soldErrors.sold_at}
                      />
                    </Col>
                  </Row>
                  {vehicle.status != vehicleRepository.sold && (
                    <Row>
                      <Col md={6} sm={12}>
                        <Button
                          className="w-100"
                          color="success"
                          type="button"
                          onClick={() => sold()}
                        >
                          Sold
                        </Button>
                      </Col>
                      <Col md={6} sm={12}>
                        <Button
                          className="w-100"
                          color="danger"
                          type="button"
                          onClick={() => returnedToFunder()}
                        >
                          Returned to Funder
                        </Button>
                      </Col>
                    </Row>
                  )}
                </CardHeader>
              </Card>
              {/* <Card>
                <CardHeader>
                  <h1>Settlement cost</h1>
                  {vehicle.status == vehicleRepository.sold &&
                    vehicle.delivered_to_us &&
                    vehicle.sold_at && (
                      <h3 className="mt-3 text-muted">{`From ${constants.formatDate(
                        vehicle.delivered_to_us
                      )} To ${constants.formatDate(vehicle.sold_at)}`}</h3>
                    )}
                </CardHeader>
                <CardBody>
                  {delay && (
                    <Table className="align-items-center" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Amount Paid</th>
                          <th scope="col">Paid / Remained months</th>
                          <th scope="col">Monthly cost</th>
                          <th scope="col">Settlement cost</th>
                          <th scope="col">Total cost</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{settlementCost.totalPaid}</td>
                          <td>
                            {`${settlementCost.paidMonths} / ${settlementCost.remainedMonths}`}
                          </td>
                          <td>{settlementCost.monthlyCost}</td>
                          <td>
                            <Input
                              type={`number`}
                              value={
                                settlementCost && settlementCost.settlementCost
                                  ? settlementCost.settlementCost
                                  : ""
                              }
                              onChange={(e) =>
                                setSettlementCost({
                                  ...settlementCost,
                                  settlementCost: e.target.value,
                                })
                              }
                              readOnly={
                                vehicleRepository.sold == vehicle.status
                                  ? true
                                  : false
                              }
                            />
                          </td>
                          <td>{settlementCost.totalCost}</td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                </CardBody>
              </Card> */}
            </CardBody>
          )}
          {tab === "frontend" && (
            <CardBody>
              <Card>
                <CardHeader>
                  <h1>
                    Financial details available for front end ( customer section
                    )
                  </h1>
                </CardHeader>
                <CardHeader className="d-flex justify-content-around">
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      className="custom-control-input"
                      id="customRadioInline1"
                      name="customRadioInline1"
                      type="radio"
                      checked={
                        vehicle && vehicle.vehicle_division === 0 ? true : false
                      }
                      onChange={() =>
                        setVehicle({
                          ...vehicle,
                          vehicle_division: 0,
                        })
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline1"
                      onClick={() =>
                        setVehicle({
                          ...vehicle,
                          vehicle_division: 0,
                        })
                      }
                    >
                      Rental Deal
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      className="custom-control-input"
                      id="customRadioInline2"
                      name="customRadioInline1"
                      type="radio"
                      checked={
                        vehicle && vehicle.vehicle_division === 1 ? true : false
                      }
                      onChange={() =>
                        setVehicle({
                          ...vehicle,
                          vehicle_division: 1,
                        })
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline2"
                      onClick={() =>
                        setVehicle({
                          ...vehicle,
                          vehicle_division: 1,
                        })
                      }
                    >
                      Purchase Deal
                    </label>
                  </div>
                </CardHeader>
                <CardBody>
                  {vehicle && vehicle.vehicle_division === 0 && (
                    <Row>
                      <Col md={4} sm={12}>
                        <InputCustom
                          type={`number`}
                          label={`Estimated Price`}
                          value={
                            vehicle && vehicle.estimated_term_price
                              ? vehicle.estimated_term_price
                              : ""
                          }
                          onChange={(e) =>
                            setVehicle({
                              ...vehicle,
                              estimated_term_price: e.target.value,
                            })
                          }
                          invalid={
                            errors && errors.estimated_term_price ? true : false
                          }
                          errorMessage={errors.estimated_term_price}
                        />
                      </Col>
                      <Col md={4} sm={12}>
                        <InputCustom
                          type={`number`}
                          label={`Estimated Term`}
                          value={
                            vehicle && vehicle.estimated_term
                              ? vehicle.estimated_term
                              : ""
                          }
                          onChange={(e) =>
                            setVehicle({
                              ...vehicle,
                              estimated_term: e.target.value,
                            })
                          }
                          invalid={
                            errors && errors.estimated_term ? true : false
                          }
                          errorMessage={errors.estimated_term}
                        />
                      </Col>
                      <Col md={4} sm={12}>
                        <InputCustom
                          type={`number`}
                          label={`Initial Payment`}
                          value={
                            vehicle && vehicle.estimated_initial_payment
                              ? vehicle.estimated_initial_payment
                              : ""
                          }
                          onChange={(e) =>
                            setVehicle({
                              ...vehicle,
                              estimated_initial_payment: e.target.value,
                            })
                          }
                          invalid={
                            errors && errors.estimated_initial_payment
                              ? true
                              : false
                          }
                          errorMessage={errors.estimated_initial_payment}
                        />
                      </Col>
                      <Col md={4} sm={12}>
                        <InputKeySelect
                          label={`Interval`}
                          value={
                            vehicle && vehicle.estimated_term_interval
                              ? vehicle.estimated_term_interval
                              : ""
                          }
                          options={intervals}
                          onChange={(e) =>
                            setVehicle({
                              ...vehicle,
                              estimated_term_interval: e.target.value,
                            })
                          }
                          defaultOption={true}
                          invalid={
                            errors && errors.estimated_term_interval
                              ? true
                              : false
                          }
                          errorMessage={errors.estimated_term_interval}
                        />
                      </Col>
                      <Col md={4} sm={12}>
                        <InputCustom
                          type={`number`}
                          label={`28 days mileage`}
                          value={
                            vehicle && vehicle.annual_mileage
                              ? vehicle.annual_mileage
                              : ""
                          }
                          onChange={(e) =>
                            setVehicle({
                              ...vehicle,
                              annual_mileage: e.target.value,
                            })
                          }
                          defaultOption={true}
                          invalid={
                            errors && errors.annual_mileage ? true : false
                          }
                          errorMessage={errors.annual_mileage}
                        />
                      </Col>
                    </Row>
                  )}
                  {vehicle && vehicle.vehicle_division === 1 && (
                    <Row>
                      <Col md={3} sm={12}>
                        <InputCustom
                          type={`number`}
                          label={`Purchase Price`}
                          value={
                            vehicle && vehicle.purchase_price
                              ? vehicle.purchase_price
                              : ""
                          }
                          onChange={(e) =>
                            setVehicle({
                              ...vehicle,
                              purchase_price: e.target.value,
                            })
                          }
                          invalid={
                            errors && errors.purchase_price ? true : false
                          }
                          errorMessage={errors.purchase_price}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col md={12}>
                      <Button
                        className="w-100"
                        color="success"
                        onClick={() => updateVehicle()}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h1>Image Upload</h1>
                </CardHeader>
                <CardBody>
                  <div className="custom-file">
                    <input
                      className="custom-file-input profile-file-input"
                      id="featured_image"
                      lang="en"
                      type="file"
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          featured_image: e.target.files[0],
                        })
                      }
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileLang"
                    >
                      Featured image
                    </label>
                  </div>
                  <div className="custom-file mt-3">
                    <input
                      className="custom-file-input profile-file-input"
                      id="images_collection"
                      lang="en"
                      type="file"
                      multiple
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          images_collection: e.target.files,
                        })
                      }
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileLang"
                    >
                      Images collection
                    </label>
                  </div>
                  <Row className="mt-3">
                    <Col md={12}>
                      <Button
                        className="w-100"
                        color="success"
                        onClick={() => updateVehicle()}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={12}>
                      <h1>Vehicle uploaded photos</h1>
                    </Col>
                  </Row>
                  <CardBody>
                    {vehicleImages.featured_image && (
                      <Row>
                        <Col md={12}>
                          <h2 className="text-center mb-3">Featured image</h2>
                          <div className="col-lg-8 offset-lg-2">
                            <img
                              src={`${constants.BASE_URL}${vehicleImages.featured_image}`}
                              className="img-fluid"
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                    {delay &&
                      vehicleImages.images_collection &&
                      vehicleImages.images_collection.length > 0 && (
                        <Row className="justify-content-md-center">
                          <Col md={7}>
                            <h2 className="text-center my-4">
                              Images collection
                            </h2>
                            <Carousel
                              activeIndex={activeIndex}
                              next={() => next(vehicleImages.images_collection)}
                              previous={() =>
                                previous(vehicleImages.images_collection)
                              }
                            >
                              {slides}
                              <CarouselControl
                                direction="prev"
                                directionText="Previous"
                                onClickHandler={() =>
                                  previous(vehicleImages.images_collection)
                                }
                              />
                              <CarouselControl
                                direction="next"
                                directionText="Next"
                                onClickHandler={() =>
                                  next(vehicleImages.images_collection)
                                }
                              />
                            </Carousel>
                            <ol
                              className="carousel-indicators"
                              style={{
                                position: "relative",
                                width: "100%",
                                margin: 0,
                                overflowX: "scroll",
                                display: "-webkit-box",
                              }}
                            >
                              {vehicleImages.images_collection.map(
                                (item, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className={`${
                                        activeIndex === index ? "active" : ""
                                      }`}
                                      style={{
                                        position: "relative",
                                        width: 220,
                                        height: 130,
                                        textIndent: 0,
                                      }}
                                    >
                                      <img
                                        src={`${constants.BASE_URL}${item.link}`}
                                        width={220}
                                        height={100}
                                        onClick={() => goToIndex(index)}
                                      />
                                      <span className="text-center carousel-trash">
                                        <i
                                          className="fas fa-trash text-danger"
                                          onClick={() => {
                                            setSelectedImage({
                                              ...selectedImage,
                                              id: item._id,
                                              index: index,
                                            });
                                            setConfirmModal(true);
                                          }}
                                        ></i>
                                      </span>
                                    </li>
                                  );
                                }
                              )}
                            </ol>
                          </Col>
                        </Row>
                      )}
                  </CardBody>
                </CardBody>
              </Card>
            </CardBody>
          )}
          {tab === "documents" && (
            <CardBody>
              <Card>
                <CardHeader>
                  <h1>Documents</h1>
                </CardHeader>
                <CardBody>
                  <div className="container">
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>
                        Select or drop the documents, then match each of them to
                        the right type
                      </p>
                    </div>
                    <aside>
                      {fileLists.map((item, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              borderBottom: "1px solid #CCC",
                              alignItems: "center",
                              padding: 20,
                            }}
                            key={index}
                          >
                            <Col md={3} sm={6}>
                              <span>{item.name}</span>
                            </Col>
                            <Col md={3} sm={6}>
                              <span>{`${item.size} bytes`}</span>
                            </Col>
                          </div>
                        );
                      })}
                    </aside>
                  </div>
                  <div className="d-flex flex-row justify-content-center mt-3">
                    <Button
                      className="w-25"
                      color="success"
                      onClick={() => uploadDocument()}
                    >
                      Save
                    </Button>
                  </div>
                  <h2 className="text-center mt-5">Uploaded documents</h2>
                  {vehicle.document_files && (
                    <TableCustom
                      loading={false}
                      items={vehicle.document_files}
                      keyField={`name`}
                      searchField={true}
                      columns={[
                        {
                          dataField: "name",
                          text: "Name",
                          sort: true,
                          formatter: (rowContent, row) => {
                            return `Document - ${row.name}`;
                          },
                        },
                        {
                          dataField: "",
                          text: "Action",
                          formatter: (rowContent, row) => {
                            return (
                              <>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    color=""
                                    role="button"
                                    size="sm"
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      onClick={() => viewDocument(row)}
                                    >
                                      {`View`}
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        setDeleteModal({
                                          ...deleteModal,
                                          show: true,
                                          header: "Delete document",
                                          body: "Are you sure to delete this document?",
                                          onClick: () => deleteDocument(row.id),
                                        });
                                      }}
                                    >
                                      {`Delete`}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </>
                            );
                          },
                        },
                      ]}
                    />
                  )}
                </CardBody>
              </Card>
            </CardBody>
          )}
          {tab === "tasks" && (
            <CardBody>
              <Row>
                <Col md={12}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h2 className="title mb-0">Tasks</h2>
                    </div>
                    {/* {hasPermission(profilePermissions, 'create-deals-tasks') && allowEdit && */}
                    <Button
                      className="h-50"
                      color="success"
                      type="button"
                      onClick={() => {
                        setTask("");
                        setAddTaskModal({
                          ...addTaskModal,
                          show: true,
                          header: "Add Task",
                          division: "create",
                        });
                      }}
                    >
                      Add task
                    </Button>
                    {/* } */}
                  </div>
                </Col>
              </Row>
              <TableCustom
                loading={false}
                delay={true}
                items={vehicle.tasks}
                keyField={`title`}
                searchField={true}
                columns={[
                  {
                    dataField: "title",
                    text: "Task",
                    sort: true,
                  },
                  {
                    dataField: "due_date",
                    text: "Due at",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return row.due_date
                        ? constants.formatDateShortGB(row.due_date)
                        : "";
                    },
                  },
                  {
                    dataField: "created_by",
                    text: "Created by",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return row.owner.name;
                    },
                  },
                  {
                    dataField: "status",
                    text: "Status",
                    sort: true,
                    formatter: (rowContent, row) => {
                      return vehicleTaskStatuses[row.status];
                    },
                  },
                  {
                    dataField: "",
                    text: "Action",
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          {/* {hasPermission(profilePermissions, 'update-deals-tasks') && hasPermission(profilePermissions, 'delete-deals-tasks') && */}
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              color=""
                              role="button"
                              size="sm"
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {/* {update(profilePermissions, authUserTeam, deal) && */}
                              <DropdownItem
                                onClick={(e) => {
                                  setTask({
                                    ...row,
                                    due_date: constants.formatDate(
                                      row.due_date
                                    ),
                                  });
                                  setTaskErrors("");
                                  setAddTaskModal({
                                    ...addTaskModal,
                                    show: true,
                                    header: "Edit task",
                                    division: "update",
                                  });
                                }}
                              >
                                Edit
                              </DropdownItem>
                              {/* } */}
                              <DropdownItem
                                onClick={(e) => {
                                  setDeleteModal({
                                    ...deleteModal,
                                    show: true,
                                    header: "Delete task",
                                    body: "Are you sure to delete this task?",
                                    onClick: () => deleteTask(row.uuid),
                                  });
                                }}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                          {/* } */}
                        </>
                      );
                    },
                  },
                ]}
              />
            </CardBody>
          )}
        </Card>
        <Modal
          isOpen={confirmModal}
          toggle={() => setConfirmModal(false)}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{`Delete Image`}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setConfirmModal(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {`Are you sure you want to delete this image?`}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={() => deleteImage()}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setConfirmModal(false)}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={deleteModal.show}
          toggle={() =>
            setDeleteModal({
              ...deleteModal,
              show: false,
            })
          }
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() =>
                setDeleteModal({
                  ...deleteModal,
                  show: false,
                })
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <span>{deleteModal.body}</span>
          </div>
          <div className="modal-footer">
            <Button
              className="w-100"
              color="danger"
              type="button"
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={addTaskModal.show}
          toggle={() =>
            setAddTaskModal({
              ...addTaskModal,
              show: false,
            })
          }
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{addTaskModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() =>
                setAddTaskModal({
                  ...addTaskModal,
                  show: false,
                })
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <InputCustom
              label={`Task title`}
              value={task && task.title ? task.title : ""}
              onChange={(e) =>
                setTask({
                  ...task,
                  title: e.target.value,
                })
              }
              invalid={taskErrors && taskErrors.title ? true : false}
              errorMessage={
                taskErrors && taskErrors.title ? taskErrors.title : ""
              }
            />
            <InputCustom
              type={`textarea`}
              label={`Description`}
              rows={5}
              value={task && task.comments ? task.comments : ""}
              onChange={(e) =>
                setTask({
                  ...task,
                  comments: e.target.value,
                })
              }
            />
            <FormGroup>
              <label className="form-control-label">Assign to</label>
              <Select2
                className="form-control"
                multiple
                value={task && task.taskAssignee ? task.taskAssignee : ""}
                data={dealerMembers}
                onChange={(e) =>
                  setTask({
                    ...task,
                    taskAssignee: Array.from(
                      e.target.selectedOptions,
                      (option) => option.value
                    ),
                  })
                }
              />
              {taskErrors && taskErrors.task_assignee && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {taskErrors.task_assignee}
                </div>
              )}
            </FormGroup>
            <InputKeySelect
              label={`Task status`}
              value={task && task.status ? task.status : ""}
              options={vehicleTaskStatuses}
              onChange={(e) =>
                setTask({
                  ...task,
                  status: e.target.value,
                })
              }
              defaultOption={true}
              invalid={taskErrors && taskErrors.status ? true : false}
              errorMessage={
                taskErrors && taskErrors.status ? taskErrors.status : ""
              }
            />
            <InputCustom
              type={`date`}
              label={`Due date`}
              value={task && task.due_date ? task.due_date : ""}
              onChange={(e) =>
                setTask({
                  ...task,
                  due_date: e.target.value,
                })
              }
              invalid={taskErrors && taskErrors.due_date ? true : false}
              errorMessage={
                taskErrors && taskErrors.due_date ? taskErrors.due_date : ""
              }
            />
          </div>
          <div className="modal-footer">
            <Button
              className="w-100"
              color="success"
              type="button"
              onClick={() =>
                addTaskModal.division === "create" ? addTask() : updateTask()
              }
            >
              {`Save`}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={deleteModal.show}
          toggle={() =>
            setDeleteModal({
              ...deleteModal,
              show: false,
            })
          }
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() =>
                setDeleteModal({
                  ...deleteModal,
                  show: false,
                })
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <span>{deleteModal.body}</span>
          </div>
          <div className="modal-footer">
            <Button
              className="w-100"
              color="danger"
              type="button"
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={updateVehicleModal.show}
          toggle={() =>
            setUpdateVehicleModal({
              ...updateVehicleModal,
              show: false,
            })
          }
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{`${updateVehicleModal.header} update`}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() =>
                setUpdateVehicleModal({
                  ...updateVehicleModal,
                  show: false,
                })
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{ paddingBottom: 0 }}>
            <InputCustom
              type={updateVehicleModal.type}
              label={updateVehicleModal.header}
              value={
                vehicle && vehicle[updateVehicleModal.key]
                  ? vehicle[updateVehicleModal.key]
                  : ""
              }
              onChange={(e) => {
                setVehicle({
                  ...vehicle,
                  [updateVehicleModal.key]: e.target.value,
                });
              }}
            />
          </div>
          <div className="modal-footer" style={{ paddingTop: 0 }}>
            <Button
              className="w-100"
              color="success"
              type="button"
              onClick={() => updateVehicle()}
            >
              {`Save`}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={updateMakeModelModal.show}
          toggle={() =>
            setUpdateMakeModelModal({
              ...updateMakeModelModal,
              show: false,
            })
          }
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{`Make/Model update`}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() =>
                setUpdateMakeModelModal({
                  ...updateMakeModelModal,
                  show: false,
                })
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{ paddingBottom: 0 }}>
            <InputArraySelect
              label={`Make`}
              value={
                vehicle && vehicle.redundant_vehicle_make_id
                  ? vehicle.redundant_vehicle_make_id
                  : ""
              }
              options={makes}
              onChange={(e) => {
                setVehicle({
                  ...vehicle,
                  redundant_vehicle_make_id: e.target.value,
                });
                selectMake(e.target.value);
              }}
              valueKey={`id`}
              labelKey={`name`}
              defaultOption={true}
            />
            <InputArraySelect
              label={`Vehicle model`}
              value={
                vehicle && vehicle.vehicle_model_id
                  ? vehicle.vehicle_model_id
                  : ""
              }
              options={filteredModels}
              onChange={(e) =>
                setVehicle({
                  ...vehicle,
                  vehicle_model_id: e.target.value,
                })
              }
              valueKey={`id`}
              labelKey={`name`}
              defaultOption={true}
              invalid={errors && errors.vehicle_model_id ? true : false}
              errorMessage={errors.vehicle_model_id}
            />
          </div>
          <div className="modal-footer" style={{ paddingTop: 0 }}>
            <Button
              className="w-100"
              color="success"
              type="button"
              onClick={() => updateVehicle()}
            >
              {`Save`}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={updateVehicleSelectModal.show}
          toggle={() =>
            setUpdateVehicleSelectModal({
              ...updateVehicleSelectModal,
              show: false,
            })
          }
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{updateVehicleSelectModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() =>
                setUpdateVehicleSelectModal({
                  ...updateVehicleSelectModal,
                  show: false,
                })
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <CreatableSelect
              isClearable
              value={
                vehicle && vehicle[updateVehicleSelectModal.key]
                  ? updateVehicleSelectModal.options.find(
                      (element) =>
                        element.value == vehicle[updateVehicleSelectModal.key]
                    ) || ""
                  : ""
              }
              onChange={(item) => {
                setVehicle({
                  ...vehicle,
                  [updateVehicleSelectModal.key]: item ? item.value : null,
                  [updateVehicleSelectModal.name]: item ? item.label : null,
                });
              }}
              options={updateVehicleSelectModal.options}
            />
          </div>
          <div className="modal-footer" style={{ paddingTop: 0 }}>
            <Button
              className="w-100"
              color="success"
              type="button"
              onClick={() => updateVehicle()}
            >
              {`Save`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  );
}