import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import Cookies from "js-cookie";

import * as constants from "constants/index.js";
import CardsHeader from "layouts/Headers/CardsHeader.js";

import InputSideCustom from "views/pages/components/InputSideCustom";
import {
  InputArraySideSelect,
} from "views/pages/components/InputSelect";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";

export default function DailyDealsFee() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState("");
  const [filter, setFilter] = useState("");
  const [items, setItems] = useState([]);
  const [dealers, setDealers] = useState([]);

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get("grs_token");
    setToken(grs_token);

    const source = axios.CancelToken.source();

    fetchData(source, grs_token, "");

    return () => {
      source.cancel();
    };
  }, []);

  const fetchData = async (source, token, filter) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${constants.API_URL}/report/daily-deals/fees`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filter,
        cancelToken: source.token,
      });
      console.log("response :: ", response.data);
      const data = response.data;

      setDealers(data.data.dealers);
      setFilter({
        ...data.data.filter,
        from: data.data.filter.from
          ? constants.formatDate(data.data.filter.from)
          : "",
        to: data.data.filter.to
          ? constants.formatDate(data.data.filter.to)
          : "",
      });
      setItems(data.data.items);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled :: ", error);
      } else {
        console.error("error :: ", error);
        notify(
          "warning",
          "Warning",
          error.response && error.response.data
            ? error.response.data.message
            : error.response
            ? error.response.statusText
            : "Error Occurred!"
        );
      }
    }
  };

  const filterItems = () => {
    const source = axios.CancelToken.source();
    fetchData(source, token, {
      ...filter,
      from: constants.formatDate(filter.from),
      to: constants.formatDate(filter.to),
    });
  };

  const resetItems = () => {
    setFilter("");
    const source = axios.CancelToken.source();
    fetchData(source, token, "");
  };

  const download = async (dealerId) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/fees/daily-deals/${dealerId}/download`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: filter
      })
      console.log('response :: ', response.data)
    } catch (error) {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
    }
  };

  return (
    <>
      <CardsHeader name="Sales" parentName="Home" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Sales</h1>
                    <p className="text-sm mb-0">Sales overview</p>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row>
                    <Col md={6} sm={12}>
                      <InputSideCustom
                        type={`date`}
                        label={`From`}
                        value={filter && filter.from ? filter.from : ""}
                        onChange={(e) =>
                          setFilter({
                            ...filter,
                            from: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col md={6} sm={12}>
                      <InputSideCustom
                        type={`date`}
                        label={`To`}
                        value={filter && filter.to ? filter.to : ""}
                        onChange={(e) =>
                          setFilter({
                            ...filter,
                            to: e.target.value,
                          })
                        }
                      />
                    </Col>
                    {filter.isAllowedToFilterByDealer && (
                      <Col md={6} sm={12}>
                        <InputArraySideSelect
                          label={`Offices`}
                          options={dealers}
                          value={filter && filter.office ? filter.office : ""}
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              office: e.target.value,
                            })
                          }
                          valueKey={`id`}
                          labelKey={`name`}
                          defaultOption={true}
                        />
                      </Col>
                    )}
                  </Row>
                  <div className="d-flex flex-row justify-content-center">
                    <Button
                      className="w-50"
                      color="success"
                      onClick={() => filterItems()}
                    >
                      Filter
                    </Button>
                    <Button
                      className="w-50"
                      color="danger"
                      onClick={() => resetItems()}
                    >
                      Reset
                    </Button>
                  </div>
                </Container>
                <Row className="mt-4">
                  <Col xl={12} className="px-5">
                    <div className="d-flex flex-row justify-content-between flex-wrap">
                      <div className="p-2">
                        <h2 className="mb-0">{`TOTAL`}</h2>
                        <h2 className="mb-0">
                          {constants.CURRENCY} {items?.all_subtotal}
                        </h2>
                      </div>
                      <div className="p-2">
                        <h2 className="mb-0">{`Total number of deals`}</h2>
                        <h2 className="mb-0">{items?.total_no_of_deals}</h2>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={12}>
                    <table class="table align-items-center table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th scope="col">{`Id`}</th>
                          <th scope="col">{`Number`}</th>
                          <th scope="col">{`Office`}</th>
                          <th scope="col">{`From`}</th>
                          <th scope="col">{`To`}</th>
                          <th scope="col">{`Vehicle`}</th>
                          <th scope="col">{`Customer`}</th>
                          <th scope="col">{`Total Vehicle Price`}</th>
                          <th scope="col">{`Extras Price`}</th>
                          <th scope="col">{`Bond`}</th>
                          <th scope="col">{`Total`}</th>
                          <th scope="col">{`Fee`}</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      {items?.dealer &&
                        items?.dealer.length > 0 &&
                        items?.dealer.map((dealer, index) => {
                          if (dealer.deals) {
                            return (
                              <tbody key={index}>
                                {dealer.deals.length > 0 &&
                                  dealer.deals.map((deal, _index) => {
                                    return (
                                      <tr key={_index}>
                                        <td scope="col">{deal.id}</td>
                                        <td scope="col">{deal.number}</td>
                                        <td scope="col">
                                          {dealer.business_name}
                                        </td>
                                        <td scope="col">
                                          {constants.formatDateShortGB(
                                            deal.pickup_at
                                          )}
                                        </td>
                                        <td scope="col">
                                          {constants.formatDateShortGB(
                                            deal.return_at
                                          )}
                                        </td>
                                        <td scope="col">{deal.vehicle}</td>
                                        <td scope="col">{deal.full_name}</td>
                                        <td scope="col">{`${constants.CURRENCY} ${deal.vehicle_total_price}`}</td>
                                        <td scope="col">{`${constants.CURRENCY} ${deal.extras_price}`}</td>
                                        <td scope="col">{`${constants.CURRENCY} ${deal.warranty_value}`}</td>
                                        <td scope="col">{`${constants.CURRENCY} ${deal.total_deal_price}`}</td>
                                        <td scope="col">{`${constants.CURRENCY} ${deal.fee}`}</td>
                                        <td scope="col">
                                          {_index === 0 && (
                                            <Button
                                              className="w-100"
                                              color="success"
                                              onClick={() => download(dealer.dealer_id)}
                                            >
                                              Download
                                            </Button>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                <tr class="bold-row">
                                  <td scope="col">{`Total`}</td>
                                  <td scope="col">
                                    {dealer.total_number_of_deals}
                                  </td>
                                  <td scope="col"></td>
                                  <td scope="col"></td>
                                  <td scope="col"></td>
                                  <td scope="col"></td>
                                  <td scope="col"></td>
                                  <td scope="col">{`${constants.CURRENCY} ${dealer.total_vehicle_price}`}</td>
                                  <td scope="col">{`${constants.CURRENCY} ${dealer.total_extras}`}</td>
                                  <td scope="col"></td>
                                  <td scope="col">{`${constants.CURRENCY} ${dealer.total}`}</td>
                                  <td scope="col">{`${constants.CURRENCY} ${dealer.total_to_be_paid}`}</td>
                                </tr>
                              </tbody>
                            );
                          }
                        })}
                    </table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
