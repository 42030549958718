import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import NotificationAlert from "react-notification-alert"
import axios from 'axios'
import Cookies from "js-cookie"

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import FleetCalendar from 'views/pages/components/FleetCalendar'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
} from "reactstrap"

export default function Fleet() {
  const notificationAlert = useRef(null)
  const history = useHistory()

  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [jobs, setJobs] = useState([])
  const [bodyShopJobs, setBodyShopJobs] = useState([])
  const [valetingJobs,setValetingJobs] = useState([])
  const [mechanicalJobs, setMechanicalJobs] = useState([])
  const [statusTypes, setStatusTypes] = useState([])
  const [jobTypes, setJobTypes] = useState([])

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    let grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    fetchData(grs_token, '')
  }, [])

  const fetchData = (token, filter) => {
    axios({
      method: 'GET',
      url: `${constants.API_URL}/fleet-management/dashboard`,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data
          console.log('response data :: ', data)
          setJobs(data.data.jobs)
          setBodyShopJobs(data.data.bodyShopJobs)
          setValetingJobs(data.data.valetingJobs)
          setMechanicalJobs(data.data.mechanicalJobs)
          setStatusTypes(data.data.statusTypes)
          setJobTypes(data.data.jobTypes)

          setLoading(false)
        }
      })
      .catch(error => {
        setLoading(false)
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response.statusText)
      })
  }

  const goCategory = (category) => {
    history.push({
      pathname: `/admin/fleet-job/dashboard/${category}`
    })
  }

  return (
    <>
      <CardsHeader name="Fleet Management" parentName="Fleet Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">Fleet Management</h1>
                    <p className="text-sm mb-0">Manage all your vehicle here.</p>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Row className="m-2">
                  <Col md={4} className="p-2">
                    <Card className="card-stats bg-gradient-yellow">
                      <CardBody>
                        <Row>
                          <div className="col my-3">
                            <CardTitle
                              tag="h2"
                              className="mb-0 text-black text-center"
                            >
                              Awaiting Body Shop
                            </CardTitle>
                            <h2 className="font-weight-bold mb-0 text-black text-center">
                              {bodyShopJobs && bodyShopJobs.length}
                            </h2>
                          </div>
                        </Row>
                      </CardBody>
                      <CardBody className="bg-green">
                        <p className="my-0 text-sm text-white text-center cursor-pointer" onClick={() => goCategory('body_shop')}>
                          <span className="text-nowrap mr-2">View all Awaiting Body Shop</span>{" "}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4} className="p-2">
                    <Card className="card-stats bg-gradient-purple">
                      <CardBody>
                        <Row>
                          <div className="col my-3">
                            <CardTitle
                              tag="h2"
                              className="mb-0 text-white text-center"
                            >
                              Awaiting Valeting
                            </CardTitle>
                            <h2 className="font-weight-bold mb-0 text-white text-center">
                              {valetingJobs && valetingJobs.length}
                            </h2>
                          </div>
                        </Row>
                      </CardBody>
                      <CardBody className="bg-green">
                        <p className="my-0 text-sm text-white text-center cursor-pointer" onClick={() => goCategory('valeting')}>
                          <span className="text-nowrap mr-2">View all Awaiting Valeting</span>{" "}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4} className="p-2">
                    <Card className="card-stats bg-gradient-primary">
                      <CardBody>
                        <Row>
                          <div className="col my-3">
                            <CardTitle
                              tag="h2"
                              className="mb-0 text-white text-center"
                            >
                              Awaiting Mechanical
                            </CardTitle>
                            <h2 className="font-weight-bold mb-0 text-white text-center">
                              {mechanicalJobs && mechanicalJobs.length}
                            </h2>
                          </div>
                        </Row>
                      </CardBody>
                      <CardBody className="bg-green">
                        <p className="my-0 text-sm text-white text-center cursor-pointer" onClick={() => goCategory('mechanical')}>
                          <span className="text-nowrap mr-2">View all Awaiting Mechanical</span>{" "}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                {!loading &&
                  <FleetCalendar jobs={jobs} statusTypes={statusTypes} jobTypes={jobTypes} />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}