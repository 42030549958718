import React, { useContext } from 'react';
import { LeadsContext } from '../context';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Button,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Row,
  Col,
} from "reactstrap";

export default function DetailsTab() {
  const leadsContext = useContext(LeadsContext);
  const {
    noteModal, setNoteModal,
    lead,
    constants,
    deletee, hasPermission,
    profilePermissions,
    authUserTeam,
    deleteModal, setDeleteModal,
    deleteNote,
    loading,
    InputCustom,
    selectedNote, setSelectedNote,
    noteErrors,
    addNote,
  } = leadsContext;
  return (
    <Card>
      <CardBody>
        <Row>
          <Col xs={8}>
            <h1 className="mb-0">Add Notes</h1>
          </Col>
          <Col xs={4} className="text-right">
            <Button className="w-100" color="success" type="button" onClick={() => setNoteModal({
              ...noteModal,
              show: true,
              header: 'Add a new note',
              division: 'create',
            })}>Add note</Button>
          </Col>
        </Row>
        <TableCustom 
          loading={loading}
          items={lead.notes}
          keyField={`id`}
          searchField={false}
          columns={[
            {
              dataField: "note",
              text: "Note",
              sort: true
            },
            {
              dataField: "model",
              text: "Model",
              sort: true,
              formatter: (rowContent, row) => {
                return constants.formatDateShortGB(row.created_at)
              }
            },
            {
              dataField: "",
              text: "Action",
              formatter: (rowContent, row) => {
                return (
                  <>
                    {hasPermission(profilePermissions, 'delete-leads') && <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn-icon-only text-light"
                        color=""
                        role="button"
                        size="sm"
                      >
                        <i className="fas fa-ellipsis-v" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        {deletee(profilePermissions, authUserTeam, lead) && <DropdownItem
                          onClick={() => {
                            setDeleteModal({
                              ...deleteModal,
                              show: true,
                              header: 'Delete lead note.',
                              body: 'Are you sure to delete this note?',
                              onClick: () => deleteNote(row.id)
                            })
                          }}
                        >
                          Delete
                        </DropdownItem>}
                      </DropdownMenu>
                    </UncontrolledDropdown>}
                  </>
                )
              }
            }
          ]}
        />
      </CardBody>
      {/* Note Add Modal */}
      <Modal
        isOpen={noteModal.show}
        toggle={() => setNoteModal({
          ...noteModal,
          show: false,
        })}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h6 className="modal-title">{noteModal.header}</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setNoteModal({
              ...noteModal,
              show: false,
            })}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <InputCustom 
            type={`textarea`}
            label={`Note`}
            rows={5}
            value={selectedNote && selectedNote.note? selectedNote.note: ''}
            onChange={(e) => setSelectedNote({
              ...selectedNote,
              note: e.target.value,
            })}
            invalid={noteErrors && noteErrors.note? true: false}
            errorMessage={noteErrors.note}
          />
        </div>
        <div className="modal-footer">
          <Button
            className="new-event--add"
            color="primary"
            type="button"
            onClick={() => addNote()}
          >
            {`Save note`}
          </Button>
          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={() => setNoteModal({
              ...noteModal,
              show: false,
            })}
          >
            Close
          </Button>
        </div>
      </Modal>
      {/* Delete Modal */}
      <Modal
        isOpen={deleteModal.show}
        toggle={() => setDeleteModal({
          ...deleteModal,
          show: false,
        })}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h6 className="modal-title">{deleteModal.header}</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setDeleteModal({
              ...deleteModal,
              show: false,
            })}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {deleteModal.body}
        </div>
        <div className="modal-footer">
          <Button
            className="new-event--add"
            color="warning"
            type="button"
            onClick={deleteModal.onClick}
          >
            {`Delete`}
          </Button>
          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={() => setDeleteModal({
              ...deleteModal,
              show: false,
            })}
          >
            Close
          </Button>
        </div>
      </Modal>
    </Card>
  );
}