import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import Select from 'react-select'
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'

import Content from './Content.js';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input,
} from 'reactstrap'

export default function LeadCreate() {
  const notificationAlert = useRef(null)
  const autoReschedule = useRef(false)
  const history = useHistory()

  const [token, setToken] = useState('')
  const [jobs, setJobs] = useState('')
  const [regNos, setRegNos] = useState([])
  const [regNo, setRegNo] = useState('')
  const [filter, setFilter] = useState({
    date: moment().format('YYYY-MM-DD')
  })

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, grs_token, filter)

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (source, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/fleet-management/jobs-by-date`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filter,
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data

      let _regNos = data.data.regNos;
      let _regNos_array = [];
      _regNos.map(_regNo => {
        return _regNos_array.push({
          ..._regNo,
          value: _regNo.id,
          label: _regNo.stock_no,
        })
      })
      setRegNos(_regNos_array)
      setJobs(data.data.jobs)
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data && error.response.data.message ? error.response.data.message : error.response ? error.response.statusText : 'Error occurred')
      }
    }
  }

  const filterJobs = (date) => {
    const source = axios.CancelToken.source()
    fetchData(source, token, {
      ...filter,
      date: moment(date).format('YYYY-MM-DD'),
      vehicle_id: regNo ? regNo : '',
    })
  }

  const getUnassignedJobs = () => {
    if (regNo) {
      const source = axios.CancelToken.source()
      fetchData(source, token, {
        ...filter,
        vehicle_id: regNo
      })
    } else {
      notify('info', 'Information', 'Please select a reg no.')
    }
  }

  return (
    <>
      <CardsHeader name="Fleets" parentName="Jobs management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Jobs By Date</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={4} sm={12} xs={12}>
                          <FormGroup>
                            <label className="form-control-label">{`Select Date`}</label>
                            <Input
                              type={`date`}
                              label={`Select Date`}
                              value={filter && filter.date ? filter.date : ''}
                              className={`event-filter-date`}
                              onChange={e => {
                                setFilter({
                                  ...filter,
                                  date: e.target.value
                                })
                                filterJobs(e.target.value)
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4} sm={12} xs={12}>
                          <FormGroup>
                            <label className="form-control-label">Reg No</label>
                            <Select
                              options={regNos}
                              value={regNo ? (regNos.find((element) => element.id == regNo) || '') : ''}
                              onChange={value => setRegNo(value.id)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4} sm={12} xs={12} className="pt-3-4">
                          <Button className="w-100" color="success" style={{ margin: 5, padding: '0.425rem 1.25rem', }} type="button" onClick={() => getUnassignedJobs()}>Pull Unassigned Tasks</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                {jobs &&
                  <Content 
                    autoReschedule={autoReschedule} 
                    jobs={jobs}
                    date={filter.date}
                  />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}