import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap';

import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

export default function FinancialDetails() {
  const { id } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState(false);
  const [intervals, setIntervals] = useState('');
  const [vehicle, setVehicle] = useState('');
  const [models, setModels] = useState([]);
  // const [vehicleCostUs, setVehicleCostUs] = useState('');
  const [vehicleProfit, setVehicleProfit] = useState('');
  const [vehicleStatuses, setVehicleStatuses] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
      const grs_token = Cookies.get('grs_token')
      setToken(grs_token)

      const source = axios.CancelToken.source()
  
      const fetchData = async () => {
        try {
          const response = await axios({
            method: 'GET',
            url: `${constants.API_URL}/vehicle/${id}/financial`,
            headers: {
              Authorization: `Bearer ${grs_token}`,
            },
            cancelToken: source.token,
          })
          console.log('response :: ', response.data)
          const data = response.data
          
          setIntervals(data.data.intervals)
          setVehicle(data.data.item)
          setModels(data.data.models)
          // setVehicleCostUs(data.data.vehicleCostUs)
          setVehicleProfit(data.data.vehicleProfit)
          setVehicleStatuses(data.data.vehicleStatuses)

          setTimeout(() => {
            setLoading(false)
            setDelay(true)
          }, 500);
        } catch (error) {
          setLoading(false)
          if (axios.isCancel(error)) {
            console.log('Request canceled :: ', error)
          } else {
            console.error('error :: ', error)
            notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
          }
        }
      }
  
      fetchData()
  
      return () => {
        source.cancel()
      }
  }, [])

  const goList = () => {
    history.push({
      pathname: `/admin/vehicle/${id}/edit`
    })
  }

  const financialHistory = () => {
    history.push({
      pathname: `/admin/vehicle/${id}/financial-history`
    })
  }

  const renderProfitTableData = () => {
    return vehicleProfit.deals.map(deal => {
      if (deal.groupedCosts && deal.groupedCosts.length > 0) {
        return deal.groupedCosts.map((cost, index) => {
          return (
            <tr key={index}>
              <th scope="row">{index == 0 ? deal.customer_name : ''}</th>
              <td>{index == 0 ? deal.monthly_settled : ''}</td>
              <td>{index == 0 ? deal.interval : ''}</td>
              <td>{index == 0 ? deal.expected_profit : ''}</td>
              <td>{index == 0 ? deal.initial_payment : ''}</td>
              <td>{index == 0 ? deal.end_payment : ''}</td>
              <td className="border-left">{cost.from}</td>
              <td>{cost.to}</td>
              <td>{deal.monthly_payment}</td>
              <td>{cost.real_profit}</td>
              <td>{cost.monthly_cost}</td>
              <td>{cost.total_cost}</td>
              <td>{cost.additionalCostOnUs}</td>
              <td>{cost.additionalCostCustomer}</td>
            </tr>
          )
        })
      }
    })
  }

  const renderDetailedLossTableData = () => {
    return vehicleProfit.intervalsInStock.map((lossIntervals, index) => {
      return (
        <tr key={index}>
          <td>{constants.formatDate(lossIntervals.from)}</td>
          <td>{constants.formatDate(lossIntervals.to)}</td>
          <td>{lossIntervals.daysInStock}</td>
          <td>{lossIntervals.intervalLoss}</td>
        </tr>
      )
    })
  }

  return (
    <>
      <CardsHeader name="Vehicle" parentName="Vehicle Management" currentName="Financial" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Vehicle profit</h1>
                  </Col>
                  <Col xs={2}>
                    <Button className="w-100" color="success" style={{margin: 5}} onClick={() => financialHistory()}>Financial history</Button>
                  </Col>
                  <Col xs={2}>
                    <Button className="w-100" color="success" style={{margin: 5}} onClick={() => goList()}>Back to vehicle</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="text-center">
                  <Col md={3} sm={6}>
                    <h2 className="mb-0">{`Real Profit`}</h2>
                    <h3 className="mb-0">{`£ ${vehicleProfit.realProfit}`}</h3>
                  </Col>
                  <Col md={3} sm={6}>
                    <h2 className="mb-0">{`Extra cost us`}</h2>
                    <h3 className="mb-0">{`£ ${vehicleProfit.additionalCostUs}`}</h3>
                  </Col>
                  <Col md={3} sm={6}>
                    <h2 className="mb-0">{`Extra cost customer`}</h2>
                    <h3 className="mb-0">{`£ ${vehicleProfit.additionalCostCustomer}`}</h3>
                  </Col>
                  {vehicle.sold_amount && <Col md={3} sm={6}>
                    <h2 className="mb-0">{`Sold Amount`}</h2>
                    <h3 className="mb-0">{`£ ${vehicle.sold_amount}`}</h3>
                  </Col>}
                </Row>
                <div className="table-responsive mt-4">
                  {!loading 
                  && delay 
                  && vehicleProfit 
                  && vehicleProfit.deals 
                  && vehicleProfit.deals.length > 0 ? 
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">{`Name`}</th>
                        <th scope="col">{`Settled For`}</th>
                        <th scope="col">{`Interval`}</th>
                        <th scope="col">{`Expected Profit`}</th>
                        <th scope="col">{`Initial Payment`}</th>
                        <th scope="col">{`End Payment`}</th>
                        <th scope="col" className="border-left">{`From`}</th>
                        <th scope="col">{`To`}</th>
                        <th scope="col">{`Payment`}</th>
                        <th scope="col">{`Real Profit`}</th>
                        <th scope="col">{`Unit Cost`}</th>
                        <th scope="col">{`Total Cost`}</th>
                        <th scope="col">{`Extra Cost us`}</th>
                        <th scope="col">{`Extra Cost customer`}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderProfitTableData()}
                    </tbody>
                  </table> :
                    <ClipLoader
                      css={override}
                      size={40}
                      color={`#7B61E4`}
                      loading={loading}
                    />}
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h1>Vehicle detailed loss</h1>
              </CardHeader>
              <CardBody>
                <Row className="text-center">
                  <Col md={3} sm={6}>
                    <h2 className="mb-0">Days in stock</h2>
                    <h3 className="mb-0">{vehicleProfit.daysInStock}</h3>
                  </Col>
                  <Col md={3} sm={6}>
                    <h2 className="mb-0">Total Loss</h2>
                    <h3 className="mb-0">{vehicleProfit.notRentedLoss}</h3>
                  </Col>
                </Row>
                <div className="table-responsive mt-4">
                  {!loading 
                  && delay 
                  && vehicleProfit 
                  && vehicleProfit.intervalsInStock 
                  && vehicleProfit.intervalsInStock.length > 0 ? 
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">{`From`}</th>
                        <th scope="col">{`To`}</th>
                        <th scope="col">{`Days in stock`}</th>
                        <th scope="col">{`Loss`}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderDetailedLossTableData()}
                    </tbody>
                  </table> :
                    <ClipLoader
                      css={override}
                      size={40}
                      color={`#7B61E4`}
                      loading={loading}
                    />}
                </div>
              </CardBody>
            </Card>
            {/* <Card>
              <CardHeader>
                <h1>Vehicle Cost</h1>
              </CardHeader>
              <CardBody>
                <Row className="text-center">
                  <Col md={3} sm={6}>
                    <h2 className="mb-0">Total Payments</h2>
                    <h3 className="mb-0">{`$ ${vehicleCostUs.totalPayment}`}</h3>
                  </Col>
                  <Col md={2} sm={6}>
                    <h2 className="mb-0">Total Interest</h2>
                    <h3 className="mb-0">{`$ ${vehicleCostUs.totalInterest}`}</h3>
                  </Col>
                  <Col md={3} sm={6}>
                    <h2 className="mb-0">Total Monthly Payments</h2>
                    <h3 className="mb-0">{`$ ${vehicleCostUs.numberOfPayments}`}</h3>
                  </Col>
                  <Col md={2} sm={6}>
                    <h2 className="mb-0">Monthly Payment</h2>
                    <h3 className="mb-0">{`$ ${vehicleCostUs.monthlyPayment}`}</h3>
                  </Col>
                  <Col md={2} sm={6}>
                    <h2 className="mb-0">End Payment</h2>
                    <h3 className="mb-0">{`$ ${vehicleCostUs.endPayment}`}</h3>
                  </Col>
                </Row>
                <TableCustom 
                  loading={loading}
                  delay={delay}
                  items={vehicleCostUs && vehicleCostUs.detailedTable? vehicleCostUs.detailedTable: []}
                  keyField={`month`}
                  searchField={false}
                  columns={[
                    {
                      dataField: "month",
                      text: "Payment Month",
                      sort: true
                    },
                    {
                      dataField: "beginning_balance",
                      text: "Beginning Balance",
                      sort: true
                    },
                    {
                      dataField: "interest_payment",
                      text: "Interest Payment",
                      sort: true
                    },
                    {
                      dataField: "paid_off_vehicle",
                      text: "Paid off Vehicle",
                      sort: true
                    },
                    {
                      dataField: "current_balance",
                      text: "Current Balance",
                      sort: true
                    },
                    {
                      dataField: "interest_paid_to_date",
                      text: "Interest Paid Todate",
                      sort: true
                    },
                    {
                      dataField: "total_amount_paid",
                      text: "Total Amount Paid",
                      sort: true
                    }
                  ]}
                />
              </CardBody>
            </Card> */}
          </Col>
        </Row>
      </Container>
    </>
  )
}