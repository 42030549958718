import React, { useContext } from 'react';
import { LeadsContext } from '../context';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Button,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  FormGroup,
  Row,
  Col,
} from "reactstrap";

export default function DetailsTab() {
  const leadsContext = useContext(LeadsContext);
  const {
    taskModal, setTaskModal,
    lead,
    constants,
    deletee, hasPermission,
    profilePermissions,
    authUserTeam,
    selectedTask, setSelectedTask,
    deleteModal, setDeleteModal,
    loading,
    InputCustom,
    taskErrors,
    deleteTask,
    InputKeySelect,
    statuses,
    Select2,
    customDealerMembers,
    addTask,
    updateTask,
  } = leadsContext;
  return (
    <Card>
      <CardBody>
        <Row>
          <Col xs={8}>
            <h1 className="mb-0">Add Tasks</h1>
          </Col>
          <Col xs={4} className="text-right">
            <Button className="w-100" color="success" type="button" onClick={() => setTaskModal({
              ...taskModal,
              show: true,
              header: 'Add a new task',
              division: 'create',
            })}>Add task</Button>
          </Col>
        </Row>
        <TableCustom 
          loading={loading}
          items={lead.tasks}
          keyField={`id`}
          searchField={false}
          columns={[
            {
              dataField: "title",
              text: "Title",
              sort: true
            },
            {
              dataField: "comments",
              text: "Description",
              sort: true
            },
            {
              dataField: "created_at",
              text: "Created",
              sort: true,
              formatter: (rowContent, row) => {
                return constants.formatDateShortGB(row.created_at)
              }
            },
            {
              dataField: "owner",
              text: "Created By",
              sort: true,
              formatter: (rowContent, row) => {
                return row.owner.name
              }
            },
            {
              dataField: "updated_at",
              text: "Updated At",
              sort: true,
              formatter: (rowContent, row) => {
                return constants.formatDateShortGB(row.updated_at)
              }
            },
            {
              dataField: "",
              text: "Action",
              formatter: (rowContent, row) => {
                return (
                  <>
                    {hasPermission(profilePermissions, 'read-leads') && <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn-icon-only text-light"
                        color=""
                        role="button"
                        size="sm"
                      >
                        <i className="fas fa-ellipsis-v" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          onClick={() => {
                            setSelectedTask({
                              ...row,
                              due_date: row.due_date? constants.formatDate(row.due_date): '',
                            })
                            setTaskModal({
                              ...taskModal,
                              show: true,
                              header: 'Edit task.',
                              division: 'update',
                            })
                          }}
                        >
                          Edit
                        </DropdownItem>
                        {deletee(profilePermissions, authUserTeam, lead) && <DropdownItem
                          onClick={() => {
                            setDeleteModal({
                              ...deleteModal,
                              show: true,
                              header: 'Delete lead task.',
                              body: 'Are you sure to delete this task?',
                              onClick: () => deleteTask(row.uuid)
                            })
                          }}
                        >
                          Delete
                        </DropdownItem>}
                      </DropdownMenu>
                    </UncontrolledDropdown>}
                  </>
                )
              }
            }
          ]}
        />
      </CardBody>
      <Modal
        isOpen={taskModal.show}
        toggle={() => setTaskModal({
          ...taskModal,
          show: false,
        })}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h6 className="modal-title">{taskModal.header}</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setTaskModal({
              ...taskModal,
              show: false,
            })}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <InputCustom
            label={`Title`}
            value={selectedTask && selectedTask.title ? selectedTask.title : ''}
            onChange={(e) => setSelectedTask({
              ...selectedTask,
              title: e.target.value,
            })}
            invalid={taskErrors && taskErrors.title ? true : false}
            errorMessage={taskErrors.title}
          />
          <InputCustom
            type={`textarea`}
            label={`Description`}
            rows={5}
            value={selectedTask && selectedTask.comments ? selectedTask.comments : ''}
            onChange={(e) => setSelectedTask({
              ...selectedTask,
              comments: e.target.value,
            })}
            invalid={taskErrors && taskErrors.comments ? true : false}
            errorMessage={taskErrors.comments}
          />
          <InputCustom
            type={`date`}
            label={`Due`}
            value={selectedTask && selectedTask.due_date ? selectedTask.due_date : ''}
            onChange={(e) => setSelectedTask({
              ...selectedTask,
              due_date: e.target.value,
            })}
            invalid={taskErrors && taskErrors.due_date ? true : false}
            errorMessage={taskErrors.due_date}
          />
          <InputKeySelect
            label={`Status`}
            value={selectedTask && selectedTask.status ? selectedTask.status : ''}
            options={statuses}
            onChange={(e) => setSelectedTask({
              ...selectedTask,
              status: e.target.value,
            })}
            defaultOption={true}
            invalid={taskErrors && taskErrors.status ? true : false}
            errorMessage={taskErrors.status}
          />
          <FormGroup>
            <label className="form-control-label">Assign to</label>
            <Select2
              className="form-control"
              multiple
              value={selectedTask && selectedTask.taskAssignee ? selectedTask.taskAssignee : ''}
              data={customDealerMembers}
              onChange={(e) => setSelectedTask({
                ...selectedTask,
                taskAssignee: Array.from(e.target.selectedOptions, option => option.value),
              })}
            />
            {taskErrors && taskErrors.task_assignee && <div className="invalid-feedback" style={{ display: 'block' }}>
              {taskErrors.task_assignee}
            </div>}
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button
            className="new-event--add"
            color="success"
            type="button"
            onClick={() => taskModal.division == 'create' ? addTask() : updateTask()}
          >
            {`Save task`}
          </Button>
          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={() => setTaskModal({
              ...taskModal,
              show: false,
            })}
          >
            {`Close`}
          </Button>
        </div>
      </Modal>
      {/* Delete Modal */}
      <Modal
        isOpen={deleteModal.show}
        toggle={() => setDeleteModal({
          ...deleteModal,
          show: false,
        })}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h6 className="modal-title">{deleteModal.header}</h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setDeleteModal({
              ...deleteModal,
              show: false,
            })}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {deleteModal.body}
        </div>
        <div className="modal-footer">
          <Button
            className="new-event--add"
            color="warning"
            type="button"
            onClick={deleteModal.onClick}
          >
            {`Delete`}
          </Button>
          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={() => setDeleteModal({
              ...deleteModal,
              show: false,
            })}
          >
            Close
            </Button>
        </div>
      </Modal>
    </Card>
  );
}