import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
} from 'reactstrap'

import {
  hasPermission
} from 'views/policies/FleetPolicy'

export default function PartsByDate() {
  const notificationAlert = useRef(null)
  const history = useHistory()

  const permissions = useSelector(state => state.getState).permissions

  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [parts, setParts] = useState([])
  const [pagination, setPagination] = useState('')
  const [partStatuses, setPartStatuses] = useState([])

  const [filter, setFilter] = useState({
    from: moment().clone().startOf('month').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  })

  const [confirmModal, setConfirmModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null
  })

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(1, source, grs_token, {
      ...filter,
      from: moment().clone().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
      to: moment().format('YYYY-MM-DD HH:mm:ss'),
    })

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (pageNumber, source, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/fleet-management/parts-by-date`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          page: pageNumber,
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      setParts(data.data.parts.data)
      setPagination(data.data.parts)
      setPartStatuses(data.data.partStatuses)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
      }
    }
  }

  const filterParts = () => {
    const source = axios.CancelToken.source()
    fetchData(1, source, token, {
      ...filter,
      from: filter && filter.from? moment(filter.from).format('YYYY-MM-DD HH:mm:ss'): moment().clone().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
      to: filter && filter.to? moment(filter.to).add(1, 'day').format('YYYY-MM-DD HH:mm:ss'): moment().format('YYYY-MM-DD HH:mm:ss'),
    })
  }

  const resetParts = () => {
    const source = axios.CancelToken.source()
    setFilter({
      from: moment().clone().startOf('month').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    })

    fetchData(1, source, token, {
      ...filter,
      from: moment().clone().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
      to: moment().format('YYYY-MM-DD HH:mm:ss'),
    })
  }

  const updatePart = async (part) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${constants.API_URL}/fleet-management/${part.vehicle_id}/parts/${part.uuid}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          ...part,
          origin: 'partsByDate',
          from: filter && filter.from? moment(filter.from).format('YYYY-MM-DD HH:mm:ss'): moment().clone().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
          to: filter && filter.to? moment(filter.to).add(1, 'day').format('YYYY-MM-DD HH:mm:ss'): moment().format('YYYY-MM-DD HH:mm:ss'),
        },
      })
      console.log('response :: ', response.data)
      const data = response.data
      setParts(data.data.parts.data)
      setPagination(data.data.parts)

      setConfirmModal({
        ...confirmModal,
        show: false
      })
      notify('success', 'Success', data.message)
    } catch (error) { 
      console.error('error :: ', error)
      setConfirmModal({
        ...confirmModal,
        show: false
      })
      notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
    }
  }

  return (
    <>
      <CardsHeader name="Fleets" parentName="Fleets Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Fleet Parts</h1>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row>
                    <Col md={6} sm={12}>
                      <InputCustom
                        type={`date`}
                        label={`From`}
                        value={filter && filter.from ? filter.from : ''}
                        onChange={e => setFilter({
                          ...filter,
                          from: e.target.value,
                        })}
                      />
                    </Col>
                    <Col md={6} sm={12}>
                      <InputCustom
                        type={`date`}
                        label={`To`}
                        value={filter && filter.to ? filter.to : ''}
                        onChange={e => setFilter({
                          ...filter,
                          to: e.target.value,
                        })}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={12}>
                      <Button className="w-100" color="success" style={{margin: 5}} type="button" onClick={() => filterParts()}>Filter</Button>
                    </Col>
                    <Col md={6} sm={12}>
                      <Button className="w-100" color="danger" style={{margin: 5}} type="button" onClick={() => resetParts()}>Reset</Button>
                    </Col>
                  </Row>
                </Container>
                <TableCustom
                  loading={loading}
                  items={parts}
                  pagination={pagination}
                  onChange={pageNumber => fetchData(pageNumber, axios.CancelToken.source(), token, filter)}
                  columns={[
                    {
                      dataField: "part_type_id",
                      text: "Parts",
                      formatter: (rowContent, row) => {
                        return row.part_type ? row.part_type.part_type : ''
                      }
                    },
                    {
                      dataField: "stock_no",
                      text: "Reg No",
                      formatter: (rowContent, row) => {
                        return row.vehicle? row.vehicle.stock_no: ''
                      }
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      formatter: (rowContent, row) => {
                        return partStatuses[row.status]
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {hasPermission(permissions, 'update-fleet-management') &&
                              row.status == 0 &&
                              <Button className="fleet-table-button" color="success" type="button" onClick={() => {
                                setConfirmModal({
                                  ...confirmModal,
                                  show: true,
                                  header: 'Update part status',
                                  body: 'Are you sure you ordered this part?',
                                  onClick: () => updatePart({
                                    ...row,
                                    status: 1
                                  })
                                })
                              }}>
                                {`Ordered`}
                              </Button>
                            }
                            {hasPermission(permissions, 'update-fleet-management') &&
                              row.status <= 1 &&
                              <Button className="fleet-table-button" color="success" type="button" onClick={() => {
                                setConfirmModal({
                                  ...confirmModal,
                                  show: true,
                                  header: 'Update part status',
                                  body: 'Are you sure you have this part here?',
                                  onClick: () => updatePart({
                                    ...row,
                                    status: 2
                                  })
                                })
                              }}>
                                {`Here`}
                              </Button>
                            }
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={confirmModal.show}
          toggle={() => setConfirmModal({
            ...confirmModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{confirmModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setConfirmModal({
                ...confirmModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {confirmModal.body}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={confirmModal.onClick}
            >
              {`Confirm`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setConfirmModal({
                ...confirmModal,
                show: false
              })}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}