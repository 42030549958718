import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input
} from 'reactstrap';

export default function BusinessTypeCounty() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('');
  const [activityTypes, setActivityTypes] = useState([]);
  const [items, setItems] = useState([]);

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, grs_token, '')

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (source, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/report/business-type/county`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filter,
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      
      setFilter({
        ...data.data.filter,
        from: data.data.filter.from ? constants.formatDate(data.data.filter.from) : '',
        to: data.data.filter.to ? constants.formatDate(data.data.filter.to) : '',
      })
      setItems(data.data.items)
      setActivityTypes(data.data.activityTypes)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const filterStatistics = () => {
    const source = axios.CancelToken.source()
    fetchData(source, token, {
      ...filter,
      from: constants.formatDate(filter.from),
      to: constants.formatDate(filter.to),
    })
  }

  const resetStatistics = () => {
    setFilter('')
    const source = axios.CancelToken.source()
    fetchData(source, token, '')
  }

  return (
    <>
      <CardsHeader name="Reports" parentName="Business Activity By County Report" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title">Sales</h1>
                <p className="text-sm mb-0">Sales overview</p>
              </CardHeader>
              <CardBody>
                <Row className="m-2 justify-content-md-center">
                  <Col md={4} sm={12}>
                    <FormGroup>
                      <Row className="d-flex align-items-center justify-content-md-center">
                        <Col md={4}>
                          <label className="form-control-label">From</label>
                        </Col>
                        <Col md={6}>
                          <Input
                            type="date"
                            value={filter ? filter.from : ''}
                            onChange={(e) => setFilter({
                              ...filter,
                              from: e.target.value,
                            })}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col md={4} sm={12}>
                    <FormGroup>
                      <Row className="d-flex align-items-center justify-content-md-center">
                        <Col md={4}>
                          <label className="form-control-label">To</label>
                        </Col>
                        <Col md={6}>
                          <Input
                            type="date"
                            value={filter ? filter.to : ''}
                            onChange={(e) => setFilter({
                              ...filter,
                              to: e.target.value,
                            })}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="m-2 justify-content-md-center">
                  <Col md={4} sm={6}>
                    <Button className="w-100" color="success" style={{margin: 5}} type="button" onClick={() => filterStatistics()}>Filter</Button>
                  </Col>
                  <Col md={4} sm={6}>
                    <Button className="w-100" color="warning" style={{margin: 5}} type="button" onClick={() => resetStatistics()}>Reset</Button>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <div className="table-responsive">
                    <table className="table align-items-center table-flush" id="datatable-basic">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">{`County`}</th>
                          {activityTypes && activityTypes.length > 0 &&
                            activityTypes.map((activityType, index) => {
                              return <th scope="col" key={index}>{activityType.name}</th>
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {items && items.length > 0 &&
                          items.map((item, index) => {
                            return (
                              <tr key={index}>
                                { item &&
                                  Object.keys(item).map(key => {
                                    return <td key={key}>{item[key]}</td>
                                  })
                                }
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}