import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import Cookies from "js-cookie";
import { Editor } from "@tinymce/tinymce-react";

import * as constants from "constants/index.js";
import CardsHeader from "layouts/Headers/CardsHeader.js";

import InputCustom from "views/pages/components/InputCustom";
import InputFile from "views/pages/components/InputFile";
import {
  InputKeySelect,
} from "views/pages/components/InputSelect";
import TableCustom from "views/pages/components/TableCustom";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from "reactstrap";

export default function Edit() {
  const notificationAlert = useRef(null);
  const history = useHistory();
  const { id } = useParams();

  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState("");
  const [rules, setRules] = useState([]);
  const [model, setModel] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [models, setModels] = useState([]);
  const [makes, setMakes] = useState([]);
  const [classes, setClasses] = useState("");
  const [doors, setDoors] = useState("");
  const [seats, setSeats] = useState("");
  const [vehicleTransmissions, setVehicleTransmissions] = useState("");
  const [vehicleTypes, setVehicleTypes] = useState("");
  const [featuredImage, setFeaturedImage] = useState("");

  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [selectedVehicleId, setSelectedVehicleId] = useState("");
  const [vehicleEditModal, setVehicleEditModal] = useState({
    show: false,
    header: "",
    division: "",
  });
  const [confirmModal, setConfirmModal] = useState(false);

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get("grs_token");
    setToken(grs_token);

    const source = axios.CancelToken.source();

    fetchData(source, grs_token);

    return () => {
      source.cancel();
    };
  }, []);

  const fetchData = async (source, token) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${constants.API_URL}/inventory/daily-rental-vehicle/${id}/edit`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      });
      console.log("response :: ", response.data);
      const data = response.data;

      setItem(data.data.item);
      setRules(data.data.rules);
      setModel(data.data.model);
      setVehicles(data.data.vehicles);
      setModels(data.data.models);
      setMakes(data.data.makes);
      setClasses(data.data.classes);
      setDoors(data.data.doors);
      setSeats(data.data.seats);
      setVehicleTransmissions(data.data.vehicleTransmissions);
      setVehicleTypes(data.data.vehicleTypes);
      setFeaturedImage(data.data.featuredImage);

      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (error) {
      setLoading(false);
      if (axios.isCancel(error)) {
        console.log("Request canceled :: ", error);
      } else {
        console.error("error :: ", error);
        notify(
          "warning",
          "Warning",
          error.response && error.response.data
            ? error.response.data.message
            : error.response
            ? error.response.statusText
            : "Error Occurred!"
        );
      }
    }
  };

  const addVeicleItem = () => {
    setVehicleEditModal({
      ...vehicleEditModal,
      show: true,
      header: "Create vehicle",
      division: "create",
    });
  };

  const createVehicleItem = async () => {
    try {
      const response = await axios({
        method: "POST",
        url: `${constants.API_URL}/inventory/daily-rental-vehicle/${id}/save-vehicle`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: selectedVehicle,
      });
      console.log("response :: ", response.data);
      const data = response.data;

      setVehicles(data.data.vehicles);
      notify("success", "Success", data.message);

      setVehicleEditModal({
        show: false,
      });
    } catch (error) {
      console.error("error :: ", error);
      notify(
        "warning",
        "Warning",
        error.response && error.response.data
          ? error.response.data.message
          : error.response
          ? error.response.statusText
          : "Error Occurred!"
      );
    }
  };

  const updateVehicleItem = async () => {
    try {
      const response = await axios({
        method: "POST",
        url: `${constants.API_URL}/inventory/daily-rental-vehicle/update-vehicle`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          ...selectedVehicle,
          vehicleId: selectedVehicle.uuid,
          dailyRentalVehicleId: id,
        },
      });
      console.log("response :: ", response.data);
      const data = response.data;

      setVehicles(data.data.vehicles);
      notify("success", "Success", data.message);

      setVehicleEditModal({
        show: false,
      });
    } catch (error) {
      console.error("error :: ", error);
      notify(
        "warning",
        "Warning",
        error.response && error.response.data
          ? error.response.data.message
          : error.response
          ? error.response.statusText
          : "Error Occurred!"
      );
    }
  };

  const deleteVehicle = async () => {
    try {
      const response = await axios({
        method: "DELETE",
        url: `${constants.API_URL}/inventory/daily-rental-vehicle/${selectedVehicleId}/delete-vehicle`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          dailyRentalVehicleId: id,
        },
      });
      console.log("response :: ", response.data);
      const data = response.data;

      setVehicles(data.data.vehicles);
      notify("success", "Success", data.message);

      setConfirmModal(false);
    } catch (error) {
      console.error("error :: ", error);
      notify(
        "warning",
        "Warning",
        error.response && error.response.data
          ? error.response.data.message
          : error.response
          ? error.response.statusText
          : "Error Occurred!"
      );
    }
  };

  const updateGroup = async () => {
    let formData = new FormData();
    formData.append("title", item.title ? item.title : "");
    formData.append("type", item.type);
    formData.append("transmission", item.transmission);
    formData.append("no_of_doors", item.no_of_doors);
    formData.append("no_of_seats", item.no_of_seats);
    formData.append("daily_price", item.daily_price ? item.daily_price : 0);
    formData.append(
      "warranty_value",
      item.warranty_value ? item.warranty_value : 0
    );
    formData.append("description", item.description ? item.description : "");
    formData.append("featured_image", item.featured_image);
    formData.append("_method", "PUT");

    try {
      const response = await axios({
        method: "POST",
        url: `${constants.API_URL}/inventory/daily-rental-vehicle/${id}/update-group`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        "Content-Type": `multipart/form-data`,
        data: formData,
      });
      console.log("response :: ", response.data);
      const data = response.data;

      setVehicles(data.data.vehicles);
      notify("success", "Success", data.message);
    } catch (error) {
      console.error("error :: ", error);
      notify(
        "warning",
        "Warning",
        error.response && error.response.data
          ? error.response.data.message
          : error.response
          ? error.response.statusText
          : "Error Occurred!"
      );
    }
  };

  const handleChange = (e, index, name) => {
    const _rules = [...rules];
    _rules[index] = {
      ..._rules[index],
      [name]: e.target.value
    };
    setRules(_rules)
  };

  const saveRules = async () => {
    let formData = new FormData();
    
    rules.map((rule, index) => {
      formData.append(`rules[${index}][from]`, rule.from)
      formData.append(`rules[${index}][to]`, rule.to)
      formData.append(`rules[${index}][price]`, rule.price)
    })

    try {
      const response = await axios({
        method: "POST",
        url: `${constants.API_URL}/inventory/daily-rental-vehicle/save-rules/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      });
      console.log("response :: ", response.data);
      const data = response.data;
      
      notify("success", "Success", data.message);
    } catch (error) {
      console.error("error :: ", error);
      notify(
        "warning",
        "Warning",
        error.response && error.response.data
          ? error.response.data.message
          : error.response
          ? error.response.statusText
          : "Error Occurred!"
      );
    }
  }

  const addRule = () => {
    if (
      rules.length > 0 &&
      (!rules[rules.length - 1].from ||
        !rules[rules.length - 1].to ||
        !rules[rules.length - 1].price)
    ) {
      notify(
        "info",
        "Information",
        "You need to fill all values of the previous rule in order to create a new set"
      );
      return;
    }
    const item = {
      from: "",
      to: "",
      price: "",
    }
    const _rules = [...rules]
    _rules.push(item)

    setRules(_rules)
  }

  return (
    <>
      <CardsHeader
        name="Add A Vehicle"
        parentName="Vehicle management"
        currentName="List"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Vehicles</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button
                      className="w-100"
                      color="success"
                      type="button"
                      onClick={() => addVeicleItem()}
                    >
                      Add vehicle item
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <p className="text-info font-weight-500">
                      {`Manage vehicle items under ${
                        item.class ? classes[item.class] : classes[model]
                      } group`}
                    </p>
                  </Col>
                </Row>
                <TableCustom
                  loading={loading}
                  items={vehicles || []}
                  keyField={`id`}
                  searchField={false}
                  columns={[
                    {
                      dataField: "stock_no",
                      text: "Rego",
                      sort: true,
                    },
                    {
                      dataField: "colour",
                      text: "Colour",
                      sort: true,
                    },
                    {
                      dataField: "",
                      text: "",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  onClick={(e) => {
                                    setSelectedVehicle(row);
                                    setVehicleEditModal({
                                      show: true,
                                      header: "Vehicle update",
                                      division: "update",
                                    });
                                  }}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  onClick={(e) => {
                                    setSelectedVehicleId(row.uuid);
                                    setConfirmModal(true);
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </>
                        );
                      },
                    },
                  ]}
                />
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Vehicle general information</h1>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <InputCustom
                      label={`Group title ( will be shown on the website listing )`}
                      value={item && item.title ? item.title : ""}
                      onChange={(e) => {
                        setItem({
                          ...item,
                          title: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <InputKeySelect
                      label={`Type`}
                      options={vehicleTypes}
                      value={item && item.type ? item.type : ""}
                      onChange={(e) => {
                        setItem({
                          ...item,
                          type: e.target.value,
                        });
                      }}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={6}>
                    <InputKeySelect
                      label={`Transmission`}
                      options={vehicleTransmissions}
                      value={item && item.transmission ? item.transmission : ""}
                      onChange={(e) => {
                        setItem({
                          ...item,
                          transmission: e.target.value,
                        });
                      }}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={6}>
                    <InputKeySelect
                      label={`Number of Doors`}
                      options={doors}
                      value={item && item.no_of_doors ? item.no_of_doors : ""}
                      onChange={(e) => {
                        setItem({
                          ...item,
                          no_of_doors: e.target.value,
                        });
                      }}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={6}>
                    <InputKeySelect
                      label={`Number of Seats`}
                      options={seats}
                      value={item && item.no_of_seats ? item.no_of_seats : ""}
                      onChange={(e) => {
                        setItem({
                          ...item,
                          no_of_seats: e.target.value,
                        });
                      }}
                      defaultOption={true}
                    />
                  </Col>
                  <Col md={6}>
                    <InputCustom
                      label={`Daily price`}
                      value={item && item.daily_price ? item.daily_price : ""}
                      onChange={(e) => {
                        setItem({
                          ...item,
                          daily_price: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <InputCustom
                      type={`number`}
                      label={`Bond`}
                      value={
                        item && item.warranty_value ? item.warranty_value : ""
                      }
                      onChange={(e) => {
                        setItem({
                          ...item,
                          warranty_value: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col md={12}>
                    <Editor
                      value={item && item.description ? item.description : ""}
                      apiKey={constants.TINYMCE_KEY}
                      init={{
                        height: 300,
                        menubar: true,
                        plugins: ["code", "preview", "pagebreak", "image"],
                        toolbar:
                          "undo redo | formatselect | bold italic | \
                                  alignleft aligncenter alignright alignjustify | \
                                  outdent indent",
                      }}
                      onEditorChange={(content, editor) =>
                        setItem({
                          ...item,
                          description: content,
                        })
                      }
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h1 className="mb-0">{`Photos`}</h1>
              </CardHeader>
              <CardBody>
                <InputFile
                  label={`Featured image`}
                  placeholder={`Featured image`}
                  onChange={(e) =>
                    setItem({
                      ...item,
                      featured_image: e.target.files[0],
                    })
                  }
                  // invalid={errors && errors.featured_image? true: false}
                  // errorMessage={errors.featured_image}
                />
                <Button
                  className="w-100"
                  color="success"
                  onClick={() => updateGroup()}
                >
                  Save Group
                </Button>
                {featuredImage && (
                  <div
                    className="col-12 mt-2 d-flex justify-content-center spatie-image-display"
                    style={{ maxWidth: 600 }}
                  >
                    <img
                      src={`${constants.BASE_URL}/storage/media/${featuredImage?.id}/${featuredImage?.file_name}`}
                      className="img-fluid"
                    />
                  </div>
                )}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h1 className="mb-0">{`Price Rules per day according to intervals`}</h1>
              </CardHeader>
              <CardBody>
                <table className="table align-items-center table-stripped price-rules">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">{`From`}</th>
                      <th scope="col">{`To`}</th>
                      <th scope="col">{`Daily price`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rules.length === 0 ? (
                      <tr>
                        <td>
                          <input
                            className="form-control form-control-alternative rule_from"
                            type="number"
                            placeholder="From"
                            onChange={(e) => {
                              let _rules = [];
                              let _rule = {
                                from: e.target.value,
                              };
                              _rules.push(_rule);
                              setRules(_rules);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            className="form-control form-control-alternative rule_to"
                            type="number"
                            placeholder="To"
                            onChange={(e) => {
                              let _rules = [];
                              let _rule = {
                                to: e.target.value,
                              };
                              _rules.push(_rule);
                              setRules(_rules);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            className="form-control form-control-alternative daily_price"
                            type="number"
                            placeholder="Daily price"
                            onChange={(e) => {
                              let _rules = [];
                              let _rule = {
                                price: e.target.value,
                              };
                              _rules.push(_rule);
                              setRules(_rules);
                            }}
                          />
                        </td>
                      </tr>
                    ) : (
                      rules.map((rule, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <input
                                className="form-control form-control-alternative rule_from"
                                type="number"
                                placeholder="From"
                                value={rule && rule.from ? rule.from : ''}
                                onChange={e => handleChange(e, index, 'from')}
                              />
                            </td>
                            <td>
                              <input
                                className="form-control form-control-alternative rule_to"
                                type="number"
                                placeholder="To"
                                value={rule && rule.to ? rule.to : ''}
                                onChange={e => handleChange(e, index, 'to')}
                              />
                            </td>
                            <td>
                              <input
                                className="form-control form-control-alternative daily_price"
                                type="number"
                                placeholder="Daily price"
                                value={rule && rule.price ? rule.price : ''}
                                onChange={e => handleChange(e, index, 'price')}
                              />
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                <Row>
                  <Col md={12}>
                    <Button className="w-100" color="secondary" onClick={() => addRule()}>Add new rule</Button>
                  </Col>
                </Row>
                <Row className={`mt-2`}>
                  <Col md={12}>
                    <Button className="w-100" color="success" onClick={() => saveRules()}>Save</Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={vehicleEditModal.show}
          toggle={() =>
            setVehicleEditModal({
              ...vehicleEditModal,
              show: false,
            })
          }
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{vehicleEditModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() =>
                setVehicleEditModal({
                  ...vehicleEditModal,
                  show: false,
                })
              }
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <InputCustom
              label={`Rego`}
              value={
                selectedVehicle && selectedVehicle.stock_no
                  ? selectedVehicle.stock_no
                  : ""
              }
              onChange={(e) => {
                setSelectedVehicle({
                  ...selectedVehicle,
                  stock_no: e.target.value,
                });
              }}
            />
            <InputCustom
              label={`Colour`}
              value={
                selectedVehicle && selectedVehicle.colour
                  ? selectedVehicle.colour
                  : ""
              }
              onChange={(e) => {
                setSelectedVehicle({
                  ...selectedVehicle,
                  colour: e.target.value,
                });
              }}
            />
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() =>
                vehicleEditModal.division === "create"
                  ? createVehicleItem()
                  : updateVehicleItem()
              }
            >
              {`Save`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() =>
                setVehicleEditModal({
                  ...vehicleEditModal,
                  show: false,
                })
              }
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={confirmModal}
          toggle={() => setConfirmModal(false)}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{`Delete this vehicle`}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setConfirmModal(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {`Are you sure you want to delete this item`}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={() => deleteVehicle()}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setConfirmModal(false)}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  );
}
