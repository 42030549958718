import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import Cookies from "js-cookie";

import * as constants from "constants/index.js";
import CardsHeader from "layouts/Headers/CardsHeader.js";

import Pagination from "react-js-pagination";

import { css } from "@emotion/core";
import { ClipLoader } from "react-spinners";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from "reactstrap";

import { deletee, hasPermission } from "views/policies/LeadPolicy";

import classnames from "classnames";
import moment from "moment";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

export default function Lead() {
  const notificationAlert = useRef(null);
  const history = useHistory();
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);

  const profilePermissions = useSelector((state) => state.getState).permissions;

  const [filter, setFilter] = useState("");
  const [leads, setLeads] = useState([]);
  const [leadsStatuses, setLeadsStatuses] = useState("");
  const [leadsTypes, setLeadsTypes] = useState("");
  const [dealers, setDealers] = useState([]);
  const [authUserTeam, setAuthUserTeam] = useState("");
  const [items, setItems] = useState("");

  const [selectedLead, setSelectedLead] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    let grs_token = Cookies.get("grs_token");
    setToken(grs_token);

    let savedPageNumber = 1;
    let savedFilter = "";

    if (localStorage.getItem("grms_lead_page_number")) {
      savedPageNumber = localStorage.getItem("grms_lead_page_number");
    }
    if (localStorage.getItem("grms_lead_search_filter")) {
      savedFilter = JSON.parse(localStorage.getItem("grms_lead_search_filter"));
      setFilter(savedFilter);
    }

    const source = axios.CancelToken.source();

    fetchData(source, savedPageNumber, grs_token, savedFilter);

    return () => {
      source.cancel();
    };
  }, []);

  const fetchData = async (source, pageNumber = 1, token, filter) => {
    localStorage.setItem("grms_lead_page_number", pageNumber);
    localStorage.setItem("grms_lead_search_filter", JSON.stringify(filter));

    try {
      const response = await axios({
        method: "GET",
        url: `${constants.API_URL}/leads`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          from: constants.formatDate(filter.from),
          to: constants.formatDate(filter.to),
          page: pageNumber,
        },
        cancelToken: source.token,
      });
      console.log("response :: ", response.data);
      const data = response.data;

      setFilter({
        ...data.data.filter,
        from: data.data.filter.from
          ? constants.formatDate(data.data.filter.from)
          : "",
        to: data.data.filter.to
          ? constants.formatDate(data.data.filter.to)
          : "",
      });
      setLeads(data.data.leads.data);
      setLeadsStatuses(data.data.leadsStatuses);
      setLeadsTypes(data.data.leadsTypes);
      setAuthUserTeam(data.data.authUserTeam);
      setItems(data.data.leads);

      if (!data.data.authUserTeam) {
        setDealers(data.data.dealers);
      }

      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (error) {
      setLoading(false);
      if (axios.isCancel(error)) {
        console.log("Request canceled :: ", error);
      } else {
        console.error("error :: ", error);
        notify(
          "warning",
          "Warning",
          error.response && error.response.data
            ? error.response.data.message
            : error.response
            ? error.response.statusText
            : "Error Occurred!"
        );
      }
    }
  };

  const filterLeads = () => {
    const source = axios.CancelToken.source();
    fetchData(source, 1, token, {
      ...filter,
      from: constants.formatDate(filter.from),
      to: constants.formatDate(filter.to),
      dealer_id:
        !authUserTeam && filter.dealerId && filter.dealerId !== "All"
          ? filter.dealerId
          : undefined,
      status_filter:
        filter.statusFilter && filter.statusFilter !== "All"
          ? filter.statusFilter
          : undefined,
      type_filter:
        filter.typeFilter && filter.typeFilter !== "All"
          ? filter.typeFilter
          : undefined,
      search: filter.searchValue || '',
    });
  };

  const resetLeads = () => {
    setFilter("");
    localStorage.removeItem("grms_lead_page_number");
    localStorage.removeItem("grms_lead_search_filter");

    const source = axios.CancelToken.source();
    fetchData(source, 1, token, "");
  };

  const deleteLead = () => {
    axios({
      method: "DELETE",
      url: constants.API_URL + "/leads/" + selectedLead.uuid,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        setConfirmModal(false);
        if (response.status === 200) {
          let data = response.data;
          console.log("response data :: ", data);
          setLeads(data.data.data);
          notify("success", "success", data.message);

          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("error :: ", error);
        notify("warning", "Warning", error.response.statusText);
      });
  };

  const editLead = (leadId) => {
    history.push({
      pathname: "/admin/leads/" + leadId + "/edit",
    });
  };

  const markWonLead = (leadId) => {
    axios({
      method: "GET",
      url: constants.API_URL + "/leads/" + leadId + "/process",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          console.log("response data :: ", data);
          setLeads(data.data.leads.data);
          notify("success", "Success", data.message);

          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("error :: ", error);
        notify("warning", "Warning", error.response.statusText);
      });
  };

  const markLostLead = (leadId) => {
    axios({
      method: "GET",
      url: constants.API_URL + "/leads/" + leadId + "/lost",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          console.log("response data :: ", data);
          setLeads(data.data.leads.data);
          notify("success", "Success", data.message);

          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("error :: ", error);
        notify("warning", "Warning", error.response.statusText);
      });
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Date",
      sort: true,
      formatter: (rowContent, row) => {
        return constants.formatDateFullGB(row.created_at);
      },
    },
    {
      dataField: "assignedTo",
      text: "Assigned to",
      sort: true,
      formatter: (rowContent, row) => {
        return row.assigned_to && row.assigned_to.name
          ? row.assigned_to.name
          : "-";
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (rowContent, row) => {
        return leadsStatuses[row.status];
      },
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      formatter: (rowContent, row) => {
        return leadsTypes[row.lead_type];
      },
    },
    {
      dataField: "dealer",
      text: !authUserTeam ? "Dealer" : "",
      sort: true,
      formatter: (rowContent, row) => {
        if (!authUserTeam) {
          return row.dealer_id ? dealers[row.dealer_id] : "-";
        }
      },
    },
    {
      dataField: "",
      text: hasPermission(profilePermissions, "read-leads") ? "Action" : "",
      formatter: (rowContent, row) => {
        if (hasPermission(profilePermissions, "read-leads")) {
          return (
            <>
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  color=""
                  role="button"
                  size="sm"
                >
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem onClick={(e) => editLead(row.uuid)}>
                    View
                  </DropdownItem>
                  <DropdownItem onClick={(e) => markWonLead(row.uuid)}>
                    Mark Won
                  </DropdownItem>
                  <DropdownItem onClick={(e) => markLostLead(row.uuid)}>
                    Mark Lost
                  </DropdownItem>
                  {deletee(profilePermissions, authUserTeam, row) && (
                    <DropdownItem
                      onClick={(e) => {
                        setSelectedLead(row);
                        setConfirmModal(true);
                      }}
                    >
                      Delete
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          );
        }
      },
    },
  ];

  return (
    <>
      <CardsHeader
        name="Lead Applications"
        parentName="Leads Management"
        currentName="List"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">Leads</h1>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="container-fluid">
                  <Row className="m-2 justify-content-md-center">
                    <Col md={5} sm={12}>
                      <FormGroup>
                        <Row className="d-flex align-items-center justify-content-md-center">
                          <Col md={3}>
                            <label className="form-control-label">From</label>
                          </Col>
                          <Col md={5}>
                            <Input
                              type="date"
                              value={filter ? filter.from : ""}
                              onChange={(e) =>
                                setFilter({
                                  ...filter,
                                  from: e.target.value,
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col md={5} sm={12}>
                      <FormGroup>
                        <Row className="d-flex align-items-center justify-content-md-center">
                          <Col md={3}>
                            <label className="form-control-label">To</label>
                          </Col>
                          <Col md={5}>
                            <Input
                              type="date"
                              value={filter ? filter.to : ""}
                              onChange={(e) =>
                                setFilter({
                                  ...filter,
                                  to: e.target.value,
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="m-2 justify-content-md-center">
                    {!authUserTeam && (
                      <Col md={4} sm={12}>
                        <FormGroup>
                          <Row className="d-flex align-items-center justify-content-md-center">
                            <Col md={3}>
                              <label className="form-control-label">
                                Dealer
                              </label>
                            </Col>
                            <Col md={5}>
                              <Input
                                type="select"
                                value={
                                  filter && filter.dealerId
                                    ? filter.dealerId
                                    : ""
                                }
                                onChange={(e) =>
                                  setFilter({
                                    ...filter,
                                    dealerId: e.target.value,
                                  })
                                }
                              >
                                <option>All</option>
                                {Object.keys(dealers).map((key) => {
                                  return (
                                    <option value={key} key={key}>
                                      {dealers[key]}
                                    </option>
                                  );
                                })}
                              </Input>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    )}
                    <Col md={!authUserTeam ? 4 : 5} sm={12}>
                      <FormGroup>
                        <Row className="d-flex align-items-center justify-content-md-center">
                          <Col md={3}>
                            <label className="form-control-label">Status</label>
                          </Col>
                          <Col md={5}>
                            <Input
                              type="select"
                              value={
                                filter && filter.statusFilter
                                  ? filter.statusFilter
                                  : ""
                              }
                              onChange={(e) =>
                                setFilter({
                                  ...filter,
                                  statusFilter: e.target.value,
                                })
                              }
                            >
                              <option>All</option>
                              {Object.keys(leadsStatuses).map((key) => {
                                return (
                                  <option value={key} key={key}>
                                    {leadsStatuses[key]}
                                  </option>
                                );
                              })}
                            </Input>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col md={!authUserTeam ? 4 : 5} sm={12}>
                      <FormGroup>
                        <Row className="d-flex align-items-center justify-content-md-center">
                          <Col md={3}>
                            <label className="form-control-label">Type</label>
                          </Col>
                          <Col md={5}>
                            <Input
                              type="select"
                              value={
                                filter && filter.typeFilter
                                  ? filter.typeFilter
                                  : ""
                              }
                              onChange={(e) =>
                                setFilter({
                                  ...filter,
                                  typeFilter: e.target.value,
                                })
                              }
                            >
                              <option>All</option>
                              {Object.keys(leadsTypes).map((key) => {
                                return (
                                  <option value={key} key={key}>
                                    {leadsTypes[key]}
                                  </option>
                                );
                              })}
                            </Input>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="m-2 justify-content-md-center">
                    <Col md={5} sm={12}>
                      <FormGroup>
                        <Row className="d-flex align-items-center justify-content-md-center">
                          <Col md={3}>
                            <label className="form-control-label">Search</label>
                          </Col>
                          <Col md={5}>
                            <Input
                              type="text"
                              value={filter ? filter.searchValue : ""}
                              onChange={(e) =>
                                setFilter({
                                  ...filter,
                                  searchValue: e.target.value,
                                })
                              }
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    <Col md={5} sm={12}>
                    </Col>
                  </Row>
                  <Row className="m-2 justify-content-md-center">
                    <Col md={4} sm={6} style={{ margin: 5 }}>
                      <Button
                        className="w-100"
                        color="success"
                        type="button"
                        onClick={() => filterLeads()}
                      >
                        Filter
                      </Button>
                    </Col>
                    <Col md={4} sm={6} style={{ margin: 5 }}>
                      <Button
                        className="w-100"
                        color="warning"
                        type="button"
                        onClick={() => resetLeads()}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </div>
                <React.Fragment>
                  <div className="py-4 table-responsive">
                    {!loading && leads.length > 0 ? (
                      <div className="react-bootstrap-table">
                        <table className="table">
                          <thead>
                            <tr>
                              {columns &&
                                columns.length > 0 &&
                                columns.map((column, index) => {
                                  return (
                                    <th
                                      tabIndex="0"
                                      key={index}
                                      style={
                                        column.headerStyle
                                          ? column.headerStyle
                                          : {}
                                      }
                                    >
                                      {column.text}
                                    </th>
                                  );
                                })}
                            </tr>
                          </thead>
                          <tbody>
                            {leads &&
                              leads.length > 0 &&
                              leads.map((item, index) => {
                                return (
                                  <tr
                                    key={index}
                                    className={classnames({
                                      "alert-no-contact": item.status === "new",
                                      "no-contact alert-danger":
                                        item.status === "new" &&
                                        moment().diff(item.created_at, "days") >
                                          2,
                                    })}
                                  >
                                    {columns &&
                                      columns.length > 0 &&
                                      columns.map((column, index2) => {
                                        return (
                                          <td
                                            key={index2}
                                            style={
                                              column.style ? column.style : {}
                                            }
                                          >
                                            {column.formatter
                                              ? column.formatter("", item)
                                              : item[column.dataField]}
                                          </td>
                                        );
                                      })}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <ClipLoader
                        css={override}
                        size={40}
                        color={`#7B61E4`}
                        loading={loading}
                      />
                    )}
                  </div>
                  {items && leads.length > 0 && (
                    <Row className="react-bootstrap-table-pagination">
                      <Col lg={6}>
                        <p className="text-sm text-black">
                          {`Showing rows ${
                            (items.current_page - 1) * items.per_page + 1
                          } to ${
                            items.per_page * items.current_page > items.total
                              ? items.total
                              : items.per_page * items.current_page
                          } of ${items.total}`}
                        </p>
                      </Col>
                      <Col
                        lg={6}
                        className="react-bootstrap-table-pagination-list"
                      >
                        <Pagination
                          activePage={items.current_page}
                          totalItemsCount={items.total}
                          itemsCountPerPage={items.per_page}
                          onChange={(pageNumber) =>
                            fetchData(
                              axios.CancelToken.source(),
                              pageNumber,
                              token,
                              filter
                            )
                          }
                          itemClass="page-item"
                          linkClass="page-link"
                          innerClass="pagination react-bootstrap-table-page-btns-ul"
                        />
                      </Col>
                    </Row>
                  )}
                </React.Fragment>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={confirmModal}
          toggle={() => setConfirmModal(false)}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{`Delete lead`}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setConfirmModal(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {`Are you sure to delete this lead?`}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={() => deleteLead()}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setConfirmModal(false)}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  );
}
