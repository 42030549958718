import * as actionTypes from 'reducers/action-types.js';
import { combineReducers } from 'redux';

const initialState = {
  loginData: {},
  profileData: {},
  roles: [],
  permissions: [],
  authUserTeam: {},
  routeCan: {},
  actionNotifications: [],
  generalNotifications: {},
  csrfToken: '',
}

const getState = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PROFILE_DATA:
      return {...state, profileData: action.user};

    case actionTypes.SET_ROLES_DATA:
      return {...state, roles: action.roles};
      
    case actionTypes.SET_PERMISSIONS_DATA:
      return {...state, permissions: action.permissions};
      
    case actionTypes.SET_AUTH_USER_TEAM:
      return {...state, authUserTeam: action.authUserTeam};
      
    case actionTypes.SET_ROUTE_CAN:
      return {...state, routeCan: action.routeCan};
      
    case actionTypes.SET_CSRF_TOKEN:
      return { ...state, csrfToken: action.csrfToken };
      
    case actionTypes.SET_ACTION_NOTIFICATIONS:
      return {...state, actionNotifications: action.actionNotifications};
      
    case actionTypes.SET_GENERAL_NOTIFICATIONS:
      return {...state, generalNotifications: action.generalNotifications};

    case actionTypes.SET_LOGIN_DATA:
      return {...state, loginData: action.payload};

    case actionTypes.SET_EMPTY_STATE:
      return {...state, ...initialState};

    default:
      return state;
  }
}

const rootReducer = combineReducers({getState});

export default rootReducer;