import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import Cookies from "js-cookie";

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import InputSideCustom from 'views/pages/components/InputSideCustom';
import {
  InputKeySelect,
  InputKeySideSelect
} from 'views/pages/components/InputSelect';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
} from "reactstrap";

const t = new Date();
const year = Intl.DateTimeFormat('en-GB', {year: 'numeric'}).format(t);
const month = Intl.DateTimeFormat('en-GB', {month: '2-digit'}).format(t);
const day = Intl.DateTimeFormat('en-GB', {day: '2-digit'}).format(t);
const today = `${year}-${month}-${day}`;

export default function DealCreate() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');

  const [businessTypes, setBusinessTypes] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [customer, setCustomer] = useState('');
  const [customers, setCustomers] = useState([]);
  const [dealStatuses, setDealStatuses] = useState('');
  const [intervals, setIntervals] = useState('');
  const [paymentIntervals, setPaymentIntervals] = useState('');
  const [regeneratedFrom, setRegeneratedFrom] = useState(false);
  const [vehicle, setVehicle] = useState('');
  const [vehicles, setVehicles] = useState('');

  const [filterOptions, setFilterOptions] = useState({
    customerId: '',
    vehicleId: '',
  });

  const [division, setDivision] = useState(true);

  const [monthly, setMonthly] = useState('');
  const [purchase, setPurchase] = useState('');

  const [annualMileage, setAnnualMileage] = useState('');
  const [dealStatus, setDealStatus] = useState('delivered');
  const [dealDate, setDealDate] = useState(today);

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
      const grs_token = Cookies.get('grs_token')
      setToken(grs_token)

      const source = axios.CancelToken.source()

      fetchData(source, grs_token, '')

      return () => {
        source.cancel()
      }
  }, [])

  const fetchData = async (source, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/deal/create`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filter,
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      
      setBusinessTypes(data.data.businessTypes);
      setCompanyTypes(data.data.companyTypes);
      setCustomer(data.data.customer);

      let _customers = data.data.customers || [];
      if (_customers.length > 0) {
        _customers.map((item, index) => {
          item['value'] = item.id;
          item['label'] = item.text;
        })
      }
      setCustomers(_customers);

      setDealStatuses({delivered: "Delivered"});
      setIntervals(data.data.intervals);
      setPaymentIntervals(data.data.paymentIntervals);
      setRegeneratedFrom(data.data.regeneratedFrom);
      setVehicle(data.data.vehicle);

      let _vehicles = data.data.vehicles;
      let _vehicles_array = [];
      Object.keys(_vehicles).map(key => {
        return _vehicles_array.push({
          ..._vehicles[key],
          value: _vehicles[key].id,
          label: _vehicles[key].stock_no,
        })
      })
      setVehicles(_vehicles_array);

      setMonthly({
        ...monthly,
        vehicle_monthly_cost:
          data.data.vehicle && data.data.vehicle.monthly_cost
            ? data.data.vehicle.monthly_cost
            : 0,
        vehicle_monthly_settled_interval: "week",
        payment_frequency: 1,
        payment_frequency_interval: "weeks",
        monthly_payment:
          data.data.vehicle && data.data.vehicle.estimated_term_price
            ? data.data.vehicle.estimated_term_price
            : 0,
        interval: "month",
        initial_payment:
          data.data.vehicle && data.data.vehicle.estimated_initial_payment
            ? data.data.vehicle.estimated_initial_payment
            : 0,
        total_vehicle_cost:
          data.data.vehicle && data.data.vehicle.total_cost
            ? data.data.vehicle.total_cost -
              data.data.vehicle.end_of_term_profit
            : data.data.vehicle && data.data.vehicle.otr
            ? data.data.vehicle.otr +
              data.data.vehicle.repair_cost +
              data.data.vehicle.other_cost
            : 0,
        vehicle_total_estimated_cost:
          data.data.vehicle && data.data.vehicle.total_cost
            ? data.data.vehicle.total_cost -
              data.data.vehicle.end_of_term_profit
            : data.data.vehicle && data.data.vehicle.otr
            ? data.data.vehicle.otr +
              data.data.vehicle.repair_cost +
              data.data.vehicle.other_cost
            : 0,
      });

      setPurchase({
        ...purchase,
        vehicle_monthly_cost:
          data.data.vehicle && data.data.vehicle.total_cost
            ? data.data.vehicle.total_cost -
              data.data.vehicle.end_of_term_profit
            : data.data.vehicle && data.data.vehicle.otr
            ? data.data.vehicle.otr +
              data.data.vehicle.end_of_term_profit +
              data.data.vehicle.other_cost
            : 0,
        vehicle_monthly_settled_interval: "week",
        monthly_payment:
          data.data.vehicle && data.data.vehicle.estimated_term_price
            ? data.data.vehicle.estimated_term_price
            : 0,
        interval: "day",
        initial_payment:
          data.data.vehicle && data.data.vehicle.estimated_initial_payment
            ? data.data.vehicle.estimated_initial_payment
            : 0,
        total_vehicle_cost:
          data.data.vehicle && data.data.vehicle.total_cost
            ? data.data.vehicle.total_cost -
              data.data.vehicle.end_of_term_profit
            : data.data.vehicle && data.data.vehicle.otr
            ? data.data.vehicle.otr +
              data.data.vehicle.repair_cost +
              data.data.vehicle.other_cost
            : 0,
        payment_frequency: 1,
        payment_frequency_interval: "weeks",
        term: 1,
        interval: "week",
        initial_payment: 0,
        vehicle_total_estimated_cost:
          data.data.vehicle && data.data.vehicle.total_cost
            ? data.data.vehicle.total_cost -
              data.data.vehicle.end_of_term_profit
            : data.data.vehicle && data.data.vehicle.otr
            ? data.data.vehicle.otr +
              data.data.vehicle.repair_cost +
              data.data.vehicle.other_cost
            : 0,
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const goList = () => {
    history.push({
      pathname: '/admin/deals',
    })
  }

  const load = () => {
    if (filterOptions.customerId == '') {
      notify('info', 'Information', 'Please select a customer');
      return
    }
    if (filterOptions.vehicleId == '') {
      notify('info', 'Information', 'Please select a vehicle');
      return
    }
    
    const source = axios.CancelToken.source()
    fetchData(source, token, filterOptions)
  }

  const reset = () => {
    setFilterOptions({
      customerId: '',
      vehicleId: '',
    })
    
    const source = axios.CancelToken.source()
    fetchData(source, token, '')
  }

  useEffect(() => {    
    let totalVehicleCost = monthly && monthly.total_vehicle_cost? monthly.total_vehicle_cost: 0;
    let YearSettled = monthly.term? monthly.term: 0;
    let RentalCost = Math.round((Number(totalVehicleCost) / Number(YearSettled)) * 100) / 100;
    let RentalSoldAt = monthly.monthly_payment? monthly.monthly_payment: 0;
    let InitalPayment = monthly.initial_payment? monthly.initial_payment: 0;
    let TotalRentalReceived = Number(RentalSoldAt) * Number(YearSettled) + Number(InitalPayment);
    let RentalProfit = Number(TotalRentalReceived) - Number(totalVehicleCost);

    setMonthly({
      ...monthly,
      vehicle_monthly_cost: RentalCost,
      weekly_payment: Number(RentalSoldAt) / Number(4),
      rental_received: Math.round(TotalRentalReceived * 100) / 100,
      expected_profit: Math.round(RentalProfit * 100) / 100,
    })
  }, [
    monthly.total_vehicle_cost,
    monthly.vehicle_monthly_cost,
    monthly.monthly_payment,
    monthly.term,
    monthly.initial_payment,
  ])

  useEffect(() => {
    let totalVehicleCost = purchase && purchase.total_vehicle_cost ? purchase.total_vehicle_cost : 0;
    let soldAt = purchase.monthly_payment? purchase.monthly_payment: 0;
    let RentalProfit = Number(soldAt) - Number(totalVehicleCost);

    setPurchase({
      ...purchase,
      rental_received: Math.round(soldAt * 100) / 100,
      expected_profit: Math.round(RentalProfit * 100) / 100,
    })
  }, [
    purchase.total_vehicle_cost,
    purchase.monthly_payment,
  ])

  const createDeal = () => {
    if (!customer || !vehicle) {
      notify('info', 'Information', 'Please load customer and vehicle.');
      return
    }
    if (constants.SITE_CODE === 'PaulAu') {
      delete vehicle['derivative']
      delete vehicle['colour']
    }
    axios({
      method: 'POST',
      url: constants.API_URL + '/deal',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        regeneratedFrom: regeneratedFrom,
        rental_calculator: division? 'weekly_rental_calculator': 'purchase_calculator',
        deal_date: dealDate,
        deal_status: dealStatus? dealStatus: dealStatuses[0],
        annual_mileage: annualMileage? annualMileage: undefined,
        customer: {
          ...customer,
          company: {
            ...customer.company,
            business_address: customer.company && customer.company.address ? customer.company.address : '',
            business_phone_number: customer.company && customer.company.phone_number ? customer.company.phone_number : '',
            company_name: customer.company && customer.company.name ? customer.company.name: '',
          }
        },
        vehicle: vehicle,
        monthly: {
          ...monthly,
          vehicle_monthly_settled_term: monthly.term,
        },
        purchase: {
          ...purchase,
          vehicle_monthly_settled_term: 1,
        },
        vehicle_id: vehicle? vehicle.id: undefined,
        customer_id: vehicle? customer.id: undefined,
        end_payment: 0,
      }
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('filtered response data :: ', data);
          notify('success', 'Success', data.message);
          setTimeout(() => {
            goList()
          }, 1500);
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error);
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors);
          return
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const filterCustomers = inputValue => {
    return customers.filter(i =>
      i.text.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = inputValue => {
    if (inputValue.length < 2) {
      return
    } else {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(filterCustomers(inputValue));
        }, 1000);
      });
    }
  }

  return (
    <>
      <CardsHeader
        name="Deal Management"
        parentName="Deals Management"
        currentName="Create"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Load data</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button
                      className="w-100"
                      color="success"
                      type="button"
                      onClick={() => goList()}
                    >
                      Back to list
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <label className="form-control-label">Customer</label>
                          <AsyncSelect
                            loadOptions={promiseOptions}
                            value={
                              filterOptions && filterOptions.customerId
                                ? customers.find(
                                    (element) =>
                                      element.value == filterOptions.customerId
                                  ) || ""
                                : ""
                            }
                            onChange={(value) => {
                              setFilterOptions({
                                ...filterOptions,
                                customerId: value.id,
                              });
                            }}
                            placeholder="Search..."
                            noOptionsMessage={(value) => {
                              if (value.inputValue.length > 1) {
                                return "No options";
                              }
                              return `Please enter ${
                                2 - value.inputValue.length
                              } or more characters`;
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <label className="form-control-label">Vehicle</label>
                          <Select
                            options={vehicles}
                            value={
                              filterOptions && filterOptions.vehicleId
                                ? vehicles.find(
                                    (element) =>
                                      element.value == filterOptions.vehicleId
                                  ) || ""
                                : ""
                            }
                            onChange={(value) =>
                              setFilterOptions({
                                ...filterOptions,
                                vehicleId: value.id,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12}>
                    <div className="d-flex flex-row justify-content-center">
                      <Button
                        className="w-50 mr-3"
                        color="success"
                        type="button"
                        onClick={() => load()}
                      >
                        Load
                      </Button>
                      <Button
                        className="w-50 ml-3"
                        color="danger"
                        type="button"
                        onClick={() => reset()}
                      >
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card>
          <CardHeader>
            <h2>Customer Info</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={4}>
                <InputCustom
                  label={`First Name`}
                  value={
                    customer && customer.first_name ? customer.first_name : ""
                  }
                  onChange={(e) =>
                    setCustomer({
                      ...customer,
                      first_name: e.target.value,
                    })
                  }
                  invalid={
                    errors && errors["customer.first_name"] ? true : false
                  }
                  errorMessage={errors["customer.first_name"]}
                />
              </Col>
              <Col md={4}>
                <InputCustom
                  label={`Last Name`}
                  value={
                    customer && customer.full_name ? customer.full_name : ""
                  }
                  onChange={(e) =>
                    setCustomer({
                      ...customer,
                      full_name: e.target.value,
                    })
                  }
                  invalid={
                    errors && errors["customer.full_name"] ? true : false
                  }
                  errorMessage={errors["customer.full_name"]}
                />
              </Col>
              <Col md={4}>
                <InputCustom
                  label={`Mobile Number`}
                  value={
                    customer && customer.mobile_number
                      ? customer.mobile_number
                      : ""
                  }
                  onChange={(e) =>
                    setCustomer({
                      ...customer,
                      mobile_number: e.target.value,
                    })
                  }
                  invalid={
                    errors && errors["customer.mobile_number"] ? true : false
                  }
                  errorMessage={errors["customer.mobile_number"]}
                />
              </Col>
              <Col md={8}>
                <InputCustom
                  label={`Address`}
                  value={customer && customer.address ? customer.address : ""}
                  onChange={(e) =>
                    setCustomer({
                      ...customer,
                      address: e.target.value,
                    })
                  }
                  invalid={errors && errors["customer.address"] ? true : false}
                  errorMessage={errors["customer.address"]}
                />
              </Col>
              <Col md={4}>
                <InputCustom
                  label={`Postal Code`}
                  value={
                    customer && customer.postal_code ? customer.postal_code : ""
                  }
                  onChange={(e) =>
                    setCustomer({
                      ...customer,
                      postal_code: e.target.value,
                    })
                  }
                  invalid={
                    errors && errors["customer.postal_code"] ? true : false
                  }
                  errorMessage={errors["customer.postal_code"]}
                />
              </Col>
              <Col md={6}>
                <InputCustom
                  type={`email`}
                  label={`Email`}
                  value={customer && customer.email ? customer.email : ""}
                  onChange={(e) =>
                    setCustomer({
                      ...customer,
                      email: e.target.value,
                    })
                  }
                  invalid={errors && errors["customer.email"] ? true : false}
                  errorMessage={errors["customer.email"]}
                />
              </Col>
              <Col md={6}>
                <InputCustom
                  label={`D/L Number`}
                  value={
                    customer && customer.driver_license_number
                      ? customer.driver_license_number
                      : ""
                  }
                  onChange={(e) =>
                    setCustomer({
                      ...customer,
                      driver_license_number: e.target.value,
                    })
                  }
                  invalid={
                    errors && errors["customer.driver_license_number"]
                      ? true
                      : false
                  }
                  errorMessage={errors["customer.driver_license_number"]}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h2>Company information</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6}>
                <InputCustom
                  label={`Company name`}
                  value={
                    customer && customer.company ? customer.company.name : ""
                  }
                  onChange={(e) =>
                    setCustomer({
                      ...customer,
                      company: {
                        ...customer.company,
                        name: e.target.value,
                      },
                    })
                  }
                  invalid={
                    errors && errors["customer.company.company_name"]
                      ? true
                      : false
                  }
                  errorMessage={errors["customer.company.company_name"]}
                />
              </Col>
              <Col md={6}>
                <InputCustom
                  label={`Business phone number`}
                  value={
                    customer && customer.company
                      ? customer.company.phone_number
                      : ""
                  }
                  onChange={(e) =>
                    setCustomer({
                      ...customer,
                      company: {
                        ...customer.company,
                        phone_number: e.target.value,
                      },
                    })
                  }
                  invalid={
                    errors && errors["customer.company.business_phone_number"]
                      ? true
                      : false
                  }
                  errorMessage={
                    errors["customer.company.business_phone_number"]
                  }
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h2>
              Vehicle information{" "}
              {vehicle && vehicle.vehicleMake && vehicle.vehicleModel
                ? ` : ${vehicle.vehicleMake.name}, ${vehicle.vehicleModel.name}`
                : ""}
            </h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={4}>
                <InputCustom
                  label={`Stock Number`}
                  value={vehicle && vehicle.stock_no ? vehicle.stock_no : ""}
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      stock_no: e.target.value,
                    })
                  }
                  invalid={errors && errors["vehicle.stock_no"] ? true : false}
                  errorMessage={errors["vehicle.stock_no"]}
                />
              </Col>
              <Col md={4}>
                <InputCustom
                  label={`Rego`}
                  value={vehicle && vehicle.vehicle_internal_id ? vehicle.vehicle_internal_id : ""}
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      vehicle_internal_id: e.target.value,
                    })
                  }
                  invalid={errors && errors["vehicle.vehicle_internal_id"] ? true : false}
                  errorMessage={errors["vehicle.vehicle_internal_id"]}
                />
              </Col>
              <Col md={4}>
                <InputCustom
                  label={`Current mileage`}
                  value={
                    vehicle && vehicle.current_mileage
                      ? vehicle.current_mileage
                      : ""
                  }
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      current_mileage: e.target.value,
                    })
                  }
                  invalid={
                    errors && errors["vehicle.current_mileage"] ? true : false
                  }
                  errorMessage={errors["vehicle.current_mileage"]}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h2 className="text-center">Finance info</h2>
            <div className="d-flex justify-content-around mt-5">
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  className="custom-control-input"
                  id="customRadioInline1"
                  name="customRadioInline1"
                  type="radio"
                  checked={division}
                  onChange={() => setDivision(true)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInline1"
                  onClick={() => setDivision(true)}
                >
                  Weekly Rental Calculator
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  className="custom-control-input"
                  id="customRadioInline2"
                  name="customRadioInline1"
                  type="radio"
                  checked={!division}
                  onChange={() => setDivision(false)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customRadioInline2"
                  onClick={() => setDivision(false)}
                >
                  Purchase Deal
                </label>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            {division && (
              <Row>
                <Col md={6} className="border-right border-dark">
                  <h2 className="text-center">Vehicle Cost</h2>
                  <div className="mt-5">
                    <InputSideCustom
                      type={`number`}
                      label={`Vehicle Weekly Cost`}
                      disabled={true}
                      value={
                        monthly && monthly.vehicle_monthly_cost
                          ? monthly.vehicle_monthly_cost
                          : ""
                      }
                      invalid={
                        errors && errors["monthly.vehicle_monthly_cost"]
                          ? true
                          : false
                      }
                      errorMessage={errors["monthly.vehicle_monthly_cost"]}
                    />
                    <InputSideCustom
                      type={`number`}
                      label={`Rental Cost`}
                      disabled={true}
                      value={
                        monthly && monthly.vehicle_total_estimated_cost
                          ? monthly.vehicle_total_estimated_cost
                          : ""
                      }
                      invalid={
                        errors && errors["monthly.vehicle_total_estimated_cost"]
                          ? true
                          : false
                      }
                      errorMessage={
                        errors["monthly.vehicle_total_estimated_cost"]
                      }
                    />
                    <InputSideCustom
                      type={`number`}
                      label={`Payment frequencies`}
                      value={
                        monthly && monthly.payment_frequency
                          ? monthly.payment_frequency
                          : ""
                      }
                      onChange={(e) =>
                        setMonthly({
                          ...monthly,
                          payment_frequency: e.target.value,
                        })
                      }
                      invalid={
                        errors && errors["monthly.payment_frequency"]
                          ? true
                          : false
                      }
                      errorMessage={errors["monthly.payment_frequency"]}
                    />
                    <InputKeySideSelect
                      label={`Interval`}
                      options={paymentIntervals}
                      value={
                        monthly && monthly.payment_frequency_interval
                          ? monthly.payment_frequency_interval
                          : ""
                      }
                      onChange={(e) =>
                        setMonthly({
                          ...monthly,
                          payment_frequency_interval: e.target.value,
                        })
                      }
                      invalid={
                        errors && errors["monthly.payment_frequency_interval"]
                          ? true
                          : false
                      }
                      errorMessage={
                        errors["monthly.payment_frequency_interval"]
                      }
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <h2 className="text-center">Rental Quote</h2>
                  <div className="mt-5">
                    <InputSideCustom
                      type={`number`}
                      label={`Weekly Rental`}
                      value={
                        monthly && monthly.monthly_payment
                          ? monthly.monthly_payment
                          : ""
                      }
                      onChange={(e) =>
                        setMonthly({
                          ...monthly,
                          monthly_payment: e.target.value,
                        })
                      }
                      invalid={
                        errors && errors["monthly.monthly_payment"]
                          ? true
                          : false
                      }
                      errorMessage={errors["monthly.monthly_payment"]}
                    />
                    <InputSideCustom
                      type={`number`}
                      label={`Rental term`}
                      value={monthly && monthly.term ? monthly.term : ""}
                      onChange={(e) =>
                        setMonthly({
                          ...monthly,
                          term: e.target.value,
                        })
                      }
                      invalid={errors && errors["monthly.term"] ? true : false}
                      errorMessage={errors["monthly.term"]}
                    />
                    <InputKeySideSelect
                      label={`Interval`}
                      options={{
                        week: "Week(s)",
                      }}
                      value={
                        monthly && monthly.interval ? monthly.interval : ""
                      }
                      onChange={(e) =>
                        setMonthly({
                          ...monthly,
                          interval: e.target.value,
                        })
                      }
                      invalid={
                        errors && errors["monthly.interval"] ? true : false
                      }
                      errorMessage={errors["monthly.interval"]}
                    />
                    <InputSideCustom
                      type={`number`}
                      label={`Setup Fee`}
                      value={
                        monthly && monthly.initial_payment
                          ? monthly.initial_payment
                          : ""
                      }
                      onChange={(e) =>
                        setMonthly({
                          ...monthly,
                          initial_payment: e.target.value,
                        })
                      }
                      invalid={
                        errors && errors["monthly.initial_payment"]
                          ? true
                          : false
                      }
                      errorMessage={errors["monthly.initial_payment"]}
                    />
                    <InputSideCustom
                      type={`number`}
                      label={`Estimated Rental received`}
                      disabled={true}
                      value={
                        monthly && monthly.rental_received
                          ? monthly.rental_received
                          : ""
                      }
                      invalid={
                        errors && errors["monthly.rental_received"]
                          ? true
                          : false
                      }
                      errorMessage={errors["monthly.rental_received"]}
                    />
                    <InputSideCustom
                      type={`number`}
                      label={`Estimated Rental profit`}
                      disabled={true}
                      value={
                        monthly && monthly.expected_profit
                          ? monthly.expected_profit
                          : ""
                      }
                      invalid={
                        errors && errors["monthly.expected_profit"]
                          ? true
                          : false
                      }
                      errorMessage={errors["monthly.expected_profit"]}
                    />
                  </div>
                </Col>
              </Row>
            )}
            {!division && (
              <Row>
                <Col md={12} className="border-right border-dark">
                  <h2 className="text-center">Quote</h2>
                  <div className="mt-5">
                    <InputSideCustom
                      type={`number`}
                      label={`Vehicle Cost`}
                      disabled={true}
                      value={
                        purchase && purchase.vehicle_total_estimated_cost
                          ? purchase.vehicle_total_estimated_cost
                          : ""
                      }
                      invalid={
                        errors && errors["purchase.vehicle_total_estimated_cost"]
                          ? true
                          : false
                      }
                      errorMessage={
                        errors["purchase.vehicle_total_estimated_cost"]
                      }
                    />
                    <InputSideCustom
                      type={`number`}
                      label={`Price`}
                      value={
                        purchase && purchase.monthly_payment
                          ? purchase.monthly_payment
                          : ""
                      }
                      onChange={(e) =>
                        setPurchase({
                          ...purchase,
                          monthly_payment: e.target.value,
                        })
                      }
                      invalid={
                        errors && errors["purchase.monthly_payment"] ? true : false
                      }
                      errorMessage={errors["purchase.monthly_payment"]}
                    />
                    <InputSideCustom
                      type={`number`}
                      label={`Total Received`}
                      disabled={true}
                      value={
                        purchase && purchase.rental_received
                          ? purchase.rental_received
                          : ""
                      }
                      invalid={
                        errors && errors["purchase.rental_received"] ? true : false
                      }
                      errorMessage={errors["purchase.rental_received"]}
                    />
                    <InputSideCustom
                      type={`number`}
                      label={`Estimated Profit`}
                      disabled={true}
                      value={
                        purchase && purchase.expected_profit
                          ? purchase.expected_profit
                          : ""
                      }
                      invalid={
                        errors && errors["purchase.expected_profit"] ? true : false
                      }
                      errorMessage={errors["purchase.expected_profit"]}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h2>Deal info</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={6}>
                <InputKeySelect
                  label={`Deal status`}
                  options={dealStatuses}
                  value={dealStatus}
                  onChange={(e) => setDealStatus(e.target.value)}
                  readOnly={true}
                  invalid={errors && errors.deal_status ? true : false}
                  errorMessage={errors.deal_status}
                />
              </Col>
              <Col md={6}>
                <InputCustom
                  type={`date`}
                  label={`Deal date`}
                  value={dealDate}
                  onChange={(e) => setDealDate(e.target.value)}
                  invalid={errors && errors.deal_date ? true : false}
                  errorMessage={errors.deal_date}
                />
              </Col>
            </Row>
            <div className="d-flex flex-row justify-content-center mt-5">
              <Button
                className="w-25"
                color="success"
                onClick={() => createDeal()}
              >
                Save
              </Button>
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}