import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';
import { Editor } from '@tinymce/tinymce-react';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import {
  InputKeySelect,
  InputArraySelect,
} from 'views/pages/components/InputSelect';
import InputToggle from 'views/pages/components/InputToggle';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
} from 'reactstrap';

export default function VehicleCreate() {
  const notificationAlert = useRef(null);
  const history = useHistory();
  
  const [token, setToken] = useState('');
  const [contract, setContract] = useState('');
  const [intervals, setIntervals] = useState('');
  const [vehicle, setVehicle] = useState([]);
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [vehicleStatuses, setVehicleStatuses] = useState('');
  const [vehicleTransmissions, setVehicleTransmissions] = useState('');
  const [vehicleTypes, setVehicleTypes] = useState('');
  const [states, setStates] = useState('');

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/vehicle/create`,
          headers: {
            Authorization: `Bearer ${grs_token}`
          },
        })
        console.log('response :: ', response.data)
        const data = response.data

        setContract(data.data.contract)
        setIntervals(data.data.intervals)
        setVehicle({
          ...data.data.item,
          status: data.data.item.status? data.data.item.status: 'available',
          rfl_taxed_date_term: data.data.item.rfl_taxed_date_term? data.data.item.rfl_taxed_date_term: 12,
          interval: 'day',
          vehicle_deal_type: 'rental_deal',
        })
        setMakes(data.data.makes)
        setModels(data.data.models)
        setVehicleStatuses(data.data.vehicleStatuses)
        setVehicleTransmissions(data.data.vehicleTransmissions)
        setVehicleTypes(data.data.vehicleTypes)
        setStates(data.data.states)
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const createVehicle = () => {
    let formData = new FormData()
    if (vehicle.images_collection && vehicle.images_collection.length > 0) {
      for (let index = 0; index < vehicle.images_collection.length; index++) {
        const element = vehicle.images_collection[index]
        formData.append(`images_collection[${index}]`, element)
      }
    }
    Object.keys(vehicle).map(key => {
      if (key != 'images_collection' && key != 'state') {
        console.log(`form data [${key}]: `, vehicle[key])
        formData.append(key, vehicle[key])
      }
    })
    formData.append("rego_expiry", constants.formatDate(vehicle.rego_expiry))
    formData.append("delivered_to_us", constants.formatDate(vehicle.delivered_to_us))
    formData.append("date_of_reg", constants.formatDate(vehicle.date_of_reg))

    axios({
      method: "POST",
      url: constants.API_URL + "/vehicle",
      headers: {
        Authorization: "Bearer " + token,
      },
      data: formData,
    })
      .then((response) => {
        setErrors("");
        console.log("vehicle create response :: ", response.data);
        let data = response.data;
        notify("success", "Success", data.message);
        setTimeout(() => {
          history.push({
            pathname: "/admin/vehicle/" + data.data.id + "/edit",
          });
        }, 1500);
      })
      .catch((error) => {
        setErrors("");
        console.error("error :: ", error);
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors);
        }
        notify(
          "warning",
          "Warning",
          error.response ? error.response.statusText : "Error occurred!"
        );
      });
  }

  const getVehicleDataByStockNo = () => {
    if (!vehicle.stock_no) {
      setErrors({
        ...errors,
        stock_no: 'Please fill reg no'
      })
      return
    }

    if (!vehicle.state) {
      setErrors({
        ...errors,
        state: 'Please select a state'
      })
      return
    }

    /**
     * Get vehicle data
     */
    axios({
      method: 'GET',
      url: `${constants.AU_VEHICLE_DATA_API_URL}/vehicle_details_registration_status_build_and_compliance?plate=${vehicle.stock_no}&state=${vehicle.state}`,
      headers: {
        Authorization: `${constants.AU_VEHICLE_DATA_API_KEY}`
      },
    })
      .then(response => {
        setErrors({
          ...errors,
          stock_no: '',
          state: ''
        })

        console.log('vehicle response from blueflag :: ', response.data.result)
        const data = response.data.result
        if (data[0]) {
          setVehicle({
            ...vehicle,
            chassis_number: data[0].chassis,
            full_vin: data[0].vin,
            colour: data[0].colour,
            engine_number: data[0].engine_number,
            date_of_reg: data[0].year_of_manufacture
          })
        }
      })
      .catch(error => {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  return (
    <>
      <CardsHeader
        name="Vehicles"
        parentName="Vehicle Management"
        currentName="Create"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl={6} md={6} sm={12}>
            <Card>
              <CardHeader>
                <h1 className="title mb-0">General vehicle information</h1>
              </CardHeader>
              <CardBody>
                <InputKeySelect
                  label={`Vehicle status`}
                  value={vehicle && vehicle.status ? vehicle.status : ""}
                  options={vehicleStatuses}
                  disabled={true}
                />
                <Card>
                  <CardHeader>
                    <h3 className="title mb-0">Vehicle Lookup</h3>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6} sm={12}>
                        <InputCustom
                          label={`Rego`}
                          value={
                            vehicle && vehicle.stock_no ? vehicle.stock_no : ""
                          }
                          onChange={(e) =>
                            setVehicle({
                              ...vehicle,
                              stock_no: e.target.value,
                            })
                          }
                          invalid={errors && errors.stock_no ? true : false}
                          errorMessage={errors.stock_no}
                        />
                      </Col>
                      <Col md={6} sm={12}>
                        <InputKeySelect
                          label={`State`}
                          value={vehicle && vehicle.state ? vehicle.state : ""}
                          options={states}
                          defaultOption={true}
                          onChange={(e) =>
                            setVehicle({
                              ...vehicle,
                              state: e.target.value,
                            })
                          }
                          invalid={errors && errors.state ? true : false}
                          errorMessage={errors.state}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} sm={12}>
                        <Button
                          className="w-100"
                          color="success"
                          onClick={() => getVehicleDataByStockNo()}
                        >
                          <i
                            className="fa fa-search mr-2"
                            aria-hidden="true"
                          ></i>{" "}
                          Lookup Australia registration
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <InputArraySelect
                  label={`Make`}
                  value={
                    vehicle && vehicle.vehicle_make_selector
                      ? vehicle.vehicle_make_selector
                      : ""
                  }
                  options={makes}
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      vehicle_make_selector: e.target.value,
                    })
                  }
                  valueKey={`id`}
                  labelKey={`name`}
                  defaultOption={true}
                />
                <InputArraySelect
                  label={`Vehicle model`}
                  value={
                    vehicle && vehicle.vehicle_model_id
                      ? vehicle.vehicle_model_id
                      : ""
                  }
                  options={models}
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      vehicle_model_id: e.target.value,
                    })
                  }
                  valueKey={`id`}
                  labelKey={`name`}
                  defaultOption={true}
                  invalid={errors && errors.vehicle_model_id ? true : false}
                  errorMessage={errors.vehicle_model_id}
                />
                <InputCustom
                  label={`Derivative`}
                  value={
                    vehicle && vehicle.derivative ? vehicle.derivative : ""
                  }
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      derivative: e.target.value,
                    })
                  }
                  invalid={errors && errors.derivative ? true : false}
                  errorMessage={errors.derivative}
                />
                <Row>
                  <Col md={6} sm={12}>
                    <InputKeySelect
                      label={`Type`}
                      value={vehicle && vehicle.type ? vehicle.type : ""}
                      options={vehicleTypes}
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          type: e.target.value,
                        })
                      }
                      defaultOption={true}
                      invalid={errors && errors.type ? true : false}
                      errorMessage={errors.type}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <InputKeySelect
                      label={`Transmission`}
                      value={
                        vehicle && vehicle.transmission
                          ? vehicle.transmission
                          : ""
                      }
                      options={vehicleTransmissions}
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          transmission: e.target.value,
                        })
                      }
                      defaultOption={true}
                      invalid={errors && errors.transmission ? true : false}
                      errorMessage={errors.transmission}
                    />
                  </Col>
                </Row>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="example3cols2Input"
                  >
                    Description
                  </label>
                  <Editor
                    value={
                      vehicle && vehicle.description ? vehicle.description : ""
                    }
                    apiKey={constants.TINYMCE_KEY}
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: ["code", "preview", "pagebreak", "image"],
                      toolbar:
                        "undo redo | formatselect | bold italic | \
                        alignleft aligncenter alignright alignjustify | \
                        outdent indent",
                    }}
                    onEditorChange={(content, editor) =>
                      setVehicle({
                        ...vehicle,
                        description: content,
                      })
                    }
                  />
                </FormGroup>
                <Row>
                  <Col md={6} sm={12}>
                    <InputCustom
                      label={`Current mileage`}
                      value={
                        vehicle && vehicle.current_mileage
                          ? vehicle.current_mileage
                          : ""
                      }
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          current_mileage: e.target.value,
                        })
                      }
                      invalid={errors && errors.current_mileage ? true : false}
                      errorMessage={errors.current_mileage}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <InputCustom
                      label={`Engine number`}
                      value={
                        vehicle && vehicle.engine_number
                          ? vehicle.engine_number
                          : ""
                      }
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          engine_number: e.target.value,
                        })
                      }
                      invalid={errors && errors.engine_number ? true : false}
                      errorMessage={errors.engine_number}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <InputCustom
                      label={`Chassis Number`}
                      value={
                        vehicle && vehicle.chassis_number
                          ? vehicle.chassis_number
                          : ""
                      }
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          chassis_number: e.target.value,
                        })
                      }
                      invalid={errors && errors.chassis_number ? true : false}
                      errorMessage={errors.chassis_number}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <InputCustom
                      type={`date`}
                      label={`Rego Expiry`}
                      value={
                        vehicle && vehicle.rego_expiry
                          ? vehicle.rego_expiry
                          : ""
                      }
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          rego_expiry: e.target.value,
                        })
                      }
                      invalid={errors && errors.rego_expiry ? true : false}
                      errorMessage={errors.rego_expiry}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <InputCustom
                      label={`Colour`}
                      value={vehicle && vehicle.colour ? vehicle.colour : ""}
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          colour: e.target.value,
                        })
                      }
                      invalid={errors && errors.colour ? true : false}
                      errorMessage={errors.colour}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <InputCustom
                      type={`date`}
                      label={`Year of manufacture`}
                      value={
                        vehicle && vehicle.date_of_reg
                          ? vehicle.date_of_reg
                          : ""
                      }
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          date_of_reg: e.target.value,
                        })
                      }
                      invalid={errors && errors.date_of_reg ? true : false}
                      errorMessage={errors.date_of_reg}
                    />
                  </Col>
                </Row>
                <InputCustom
                  type={`date`}
                  label={`Purchase Date`}
                  value={
                    vehicle && vehicle.delivered_to_us
                      ? vehicle.delivered_to_us
                      : ""
                  }
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      delivered_to_us: e.target.value,
                    })
                  }
                  invalid={errors && errors.delivered_to_us ? true : false}
                  errorMessage={errors.delivered_to_us}
                />
                {/* <InputCustom
                  label={`Reg no`}
                  value={vehicle && vehicle.stock_no ? vehicle.stock_no : ""}
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      stock_no: e.target.value,
                    })
                  }
                  invalid={errors && errors.stock_no ? true : false}
                  errorMessage={errors.stock_no}
                /> */}
                {/* <InputCustom
                  type={`date`}
                  label={`Date of reg`}
                  value={
                    vehicle && vehicle.date_of_reg
                      ? constants.formatDate(vehicle.date_of_reg)
                      : ""
                  }
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      date_of_reg: constants.formatDate(e.target.value),
                    })
                  }
                  invalid={errors && errors.date_of_reg ? true : false}
                  errorMessage={errors.date_of_reg}
                /> */}
                {/* <InputCustom
                  type={`date`}
                  label={`MOT`}
                  value={
                    vehicle && vehicle.mot_date
                      ? constants.formatDate(vehicle.mot_date)
                      : ""
                  }
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      mot_date: constants.formatDate(e.target.value),
                    })
                  }
                /> */}
                {/* <InputCustom
                  type={`date`}
                  label={`RFL Taxed Date`}
                  value={
                    vehicle && vehicle.rfl_taxed_date
                      ? constants.formatDate(vehicle.rfl_taxed_date)
                      : ""
                  }
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      rfl_taxed_date: constants.formatDate(e.target.value),
                    })
                  }
                /> */}
                {/* <InputCustom
                  type={`number`}
                  label={`RFL Taxed Term`}
                  value={
                    vehicle && vehicle.rfl_taxed_date_term
                      ? vehicle.rfl_taxed_date_term
                      : ""
                  }
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      rfl_taxed_date_term: e.target.value,
                    })
                  }
                /> */}
                {/* <InputKeySelect
                  label={`RFL Taxed Interval`}
                  value={
                    vehicle && vehicle.rfl_taxed_date_interval
                      ? vehicle.rfl_taxed_date_interval
                      : ""
                  }
                  options={{ month: "Month(s)" }}
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      rfl_taxed_date_interval: e.target.value,
                    })
                  }
                /> */}
                <Row>
                  <Col md={6} sm={12}>
                    <InputToggle
                      label={`Vehicle Tracker`}
                      checked={vehicle && vehicle.tracker == 1 ? true : false}
                      onChange={() =>
                        setVehicle({
                          ...vehicle,
                          tracker: vehicle.tracker == 1 ? 0 : 1,
                        })
                      }
                      side={false}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <InputToggle
                      label={`Is active`}
                      checked={vehicle && vehicle.enabled == 1 ? true : false}
                      onChange={() =>
                        setVehicle({
                          ...vehicle,
                          enabled: vehicle.enabled == 1 ? 0 : 1,
                        })
                      }
                      side={false}
                    />
                  </Col>
                </Row>
                <div className="d-flex flex-row justify-content-center mt-4">
                  <Button
                    className="w-100"
                    color="success"
                    onClick={() => createVehicle()}
                  >
                    Save
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={6} md={6} sm={12}>
            <Card>
              <CardHeader>
                <h1 className="title mb-0">Vehicle Costs</h1>
              </CardHeader>
              <CardBody>
                {/* <InputCustom
                  type={`number`}
                  label={`Monthly Cost`}
                  value={
                    vehicle && vehicle.monthly_cost ? vehicle.monthly_cost : ""
                  }
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      monthly_cost: e.target.value,
                    })
                  }
                /> */}
                {/* <InputCustom
                  type={`number`}
                  label={`Finance rate`}
                  value={
                    vehicle && vehicle.finance_rate ? vehicle.finance_rate : ""
                  }
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      finance_rate: e.target.value,
                    })
                  }
                  invalid={errors && errors.finance_rate ? true : false}
                  errorMessage={errors.finance_rate}
                /> */}
                <Row>
                  <Col md={6} sm={12}>
                    <InputCustom
                      type={`number`}
                      label={`Purchase Price`}
                      value={vehicle && vehicle.otr ? vehicle.otr : ""}
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          otr: e.target.value,
                        })
                      }
                      invalid={errors && errors.otr ? true : false}
                      errorMessage={errors.otr}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <InputCustom
                      type={`number`}
                      label={`Repair Cost`}
                      value={
                        vehicle && vehicle.repair_cost
                          ? vehicle.repair_cost
                          : ""
                      }
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          repair_cost: e.target.value,
                        })
                      }
                      invalid={errors && errors.repair_cost ? true : false}
                      errorMessage={errors.repair_cost}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <InputCustom
                      type={`number`}
                      label={`Other Cost`}
                      value={
                        vehicle && vehicle.other_cost ? vehicle.other_cost : ""
                      }
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          other_cost: e.target.value,
                        })
                      }
                      invalid={errors && errors.other_cost ? true : false}
                      errorMessage={errors.other_cost}
                    />
                  </Col>
                </Row>
                {/* <InputCustom
                  type={`number`}
                  label={`Total interest`}
                  value={
                    vehicle && vehicle.total_interest
                      ? vehicle.total_interest
                      : ""
                  }
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      total_interest: e.target.value,
                    })
                  }
                  invalid={errors && errors.total_interest ? true : false}
                  errorMessage={errors.total_interest}
                /> */}
                {/* <InputCustom
                  type={`number`}
                  label={`VAT deposit`}
                  value={
                    vehicle && vehicle.total_deposit
                      ? vehicle.total_deposit
                      : ""
                  }
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      total_deposit: e.target.value,
                    })
                  }
                  invalid={errors && errors.total_deposit ? true : false}
                  errorMessage={errors.total_deposit}
                /> */}
                {/* <InputCustom
                  type={`number`}
                  label={`Non Refundable deposit`}
                  value={
                    vehicle && vehicle.non_refundable_deposit
                      ? vehicle.non_refundable_deposit
                      : ""
                  }
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      non_refundable_deposit: e.target.value,
                    })
                  }
                /> */}
                {/* <InputCustom
                  type={`number`}
                  label={`End payment`}
                  value={
                    vehicle && vehicle.end_payment ? vehicle.end_payment : ""
                  }
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      end_payment: e.target.value,
                    })
                  }
                  invalid={errors && errors.end_payment ? true : false}
                  errorMessage={errors.end_payment}
                /> */}
                {/* <InputCustom
                  type={`number`}
                  label={`Term`}
                  value={vehicle && vehicle.term ? vehicle.term : ""}
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      term: e.target.value,
                    })
                  }
                /> */}
                {/* <InputKeySelect
                  label={`Interval`}
                  value={vehicle && vehicle.interval ? vehicle.interval : ""}
                  options={intervals}
                  onChange={(e) =>
                    setVehicle({
                      ...vehicle,
                      interval: e.target.value,
                    })
                  }
                /> */}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h1>Selling Figures</h1>
              </CardHeader>
              <CardHeader className="d-flex justify-content-around">
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    className="custom-control-input"
                    id="customRadioInline1"
                    name="customRadioInline1"
                    type="radio"
                    checked={
                      vehicle && vehicle.vehicle_deal_type === 'rental_deal' ? true : false
                    }
                    onChange={() =>
                      setVehicle({
                        ...vehicle,
                        vehicle_deal_type: 'rental_deal',
                      })
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadioInline1"
                    onClick={() =>
                      setVehicle({
                        ...vehicle,
                        vehicle_deal_type: 'rental_deal',
                      })
                    }
                  >
                    Rental Deal
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    className="custom-control-input"
                    id="customRadioInline2"
                    name="customRadioInline1"
                    type="radio"
                    checked={
                      vehicle && vehicle.vehicle_deal_type === 'purchase_deal' ? true : false
                    }
                    onChange={() =>
                      setVehicle({
                        ...vehicle,
                        vehicle_deal_type: 'purchase_deal',
                      })
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadioInline2"
                    onClick={() =>
                      setVehicle({
                        ...vehicle,
                        vehicle_deal_type: 'purchase_deal',
                      })
                    }
                  >
                    Purchase Deal
                  </label>
                </div>
              </CardHeader>
              <CardBody>
                {vehicle && vehicle.vehicle_deal_type === 'rental_deal' && (
                  <Row>
                    <Col md={6} sm={12}>
                      <InputCustom
                        type={`number`}
                        label={`Set Up Fee`}
                        value={vehicle && vehicle.bond ? vehicle.bond : ""}
                        onChange={(e) =>
                          setVehicle({
                            ...vehicle,
                            bond: e.target.value,
                          })
                        }
                        invalid={errors && errors.bond ? true : false}
                        errorMessage={errors.bond}
                      />
                    </Col>
                    <Col md={6} sm={12}>
                      <InputCustom
                        type={`number`}
                        label={`Rental`}
                        value={
                          vehicle && vehicle.interval_payment
                            ? vehicle.interval_payment
                            : ""
                        }
                        onChange={(e) =>
                          setVehicle({
                            ...vehicle,
                            interval_payment: e.target.value,
                          })
                        }
                        invalid={
                          errors && errors.interval_payment ? true : false
                        }
                        errorMessage={errors.interval_payment}
                      />
                    </Col>
                    <Col md={6} sm={12}>
                      <InputCustom
                        type={`number`}
                        label={`Profit Required`}
                        value={
                          vehicle && vehicle.profit_required
                            ? vehicle.profit_required
                            : ""
                        }
                        onChange={(e) =>
                          setVehicle({
                            ...vehicle,
                            profit_required: e.target.value,
                          })
                        }
                        invalid={
                          errors && errors.profit_required ? true : false
                        }
                        errorMessage={errors.profit_required}
                      />
                    </Col>
                    <Col md={6} sm={12}>
                      <InputCustom
                        type={`number`}
                        label={`Term ( Weeks )`}
                        value={
                          vehicle && vehicle.rental_term
                            ? vehicle.rental_term
                            : ""
                        }
                        onChange={(e) =>
                          setVehicle({
                            ...vehicle,
                            rental_term: e.target.value,
                          })
                        }
                        defaultOption={true}
                        invalid={errors && errors.rental_term ? true : false}
                        errorMessage={errors.rental_term}
                      />
                    </Col>
                    <Col md={6} sm={12}>
                      <InputCustom
                        type={`number`}
                        label={`Franchise Fee`}
                        value={
                          vehicle && vehicle.admin_fee ? vehicle.admin_fee : ""
                        }
                        onChange={(e) =>
                          setVehicle({
                            ...vehicle,
                            admin_fee: e.target.value,
                          })
                        }
                        defaultOption={true}
                        invalid={errors && errors.admin_fee ? true : false}
                        errorMessage={errors.admin_fee}
                      />
                    </Col>
                  </Row>
                )}
                {vehicle && vehicle.vehicle_deal_type === 'purchase_deal' && (
                  <Row>
                    <Col md={6} sm={12}>
                      <InputCustom
                        type={`number`}
                        label={`Purchase deal price`}
                        value={
                          vehicle && vehicle.purchase_price
                            ? vehicle.purchase_price
                            : ""
                        }
                        onChange={(e) =>
                          setVehicle({
                            ...vehicle,
                            purchase_price: e.target.value,
                          })
                        }
                        invalid={errors && errors.purchase_price ? true : false}
                        errorMessage={errors.purchase_price}
                      />
                    </Col>
                  </Row>
                )}
                {/* <Row>
                  <Col md={6} sm={12}>
                    <InputCustom
                      type={`number`}
                      label={`Estimated Price`}
                      value={
                        vehicle && vehicle.estimated_term_price
                          ? vehicle.estimated_term_price
                          : ""
                      }
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          estimated_term_price: e.target.value,
                        })
                      }
                    />
                    <InputCustom
                      type={`number`}
                      label={`Estimated Term`}
                      value={
                        vehicle && vehicle.estimated_term
                          ? vehicle.estimated_term
                          : ""
                      }
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          estimated_term: e.target.value,
                        })
                      }
                    />
                    <InputToggle
                      label={`Maintenance Included`}
                      checked={
                        vehicle && vehicle.maintenance_included == 1
                          ? true
                          : false
                      }
                      onChange={() =>
                        setVehicle({
                          ...vehicle,
                          maintenance_included:
                            vehicle.maintenance_included == 1 ? 0 : 1,
                        })
                      }
                      side={false}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <InputCustom
                      type={`number`}
                      label={`Initial Payment`}
                      value={
                        vehicle && vehicle.estimated_initial_payment
                          ? vehicle.estimated_initial_payment
                          : ""
                      }
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          estimated_initial_payment: e.target.value,
                        })
                      }
                    />
                    <InputKeySelect
                      label={`Interval`}
                      value={
                        vehicle && vehicle.estimated_term_interval
                          ? vehicle.estimated_term_interval
                          : ""
                      }
                      options={intervals}
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          estimated_term_interval: e.target.value,
                        })
                      }
                      defaultOption={true}
                    />
                  </Col>
                </Row> */}
              </CardBody>
            </Card>
            {/* <Card>
              <CardHeader>
                <h1>Supplier details</h1>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6} sm={12}>
                    <InputCustom
                      label={`Supplier Name`}
                      value={
                        vehicle && vehicle.supplying_dealer
                          ? vehicle.supplying_dealer
                          : ""
                      }
                      onChange={(e) =>
                        setVehicle({
                          ...vehicle,
                          supplying_dealer: e.target.value,
                        })
                      }
                      invalid={errors && errors.supplying_dealer ? true : false}
                      errorMessage={errors.supplying_dealer}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card> */}
            <Card>
              <CardHeader>
                <h1>Vehicle photos</h1>
              </CardHeader>
              <CardBody>
                <div className="custom-file">
                  <input
                    className="custom-file-input profile-file-input"
                    type="file"
                    onChange={(e) =>
                      setVehicle({
                        ...vehicle,
                        featured_image: e.target.files[0],
                      })
                    }
                  />
                  <label className="custom-file-label" htmlFor="customFileLang">
                    Featured image
                  </label>
                </div>
                <div className="custom-file mt-3">
                  <input
                    className="custom-file-input profile-file-input"
                    type="file"
                    multiple
                    onChange={(e) =>
                      setVehicle({
                        ...vehicle,
                        images_collection: e.target.files,
                      })
                    }
                  />
                  <label className="custom-file-label" htmlFor="customFileLang">
                    Images collection
                  </label>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}