import React, { useContext } from 'react';

import { DealContext } from '../context';

import InputCustom from 'views/pages/components/InputCustom';
import {
  InputKeySelect,
} from 'views/pages/components/InputSelect';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
} from "reactstrap";

export default function InfoTab() {
  const dealContext = useContext(DealContext);
  const {
    dealChecksheet,
    dealStatuses,
    intervals,
    deal, setDeal,
    paymentIntervals,
    company, setCompany,
    customer, setCustomer,
    vehicle, setVehicle,
    checkSheetCount,
    dealErrors,
    transitModal, setTransitModal,
    updateDeal,
    defaulter,
    isEditable,
    dealTracking,
    dealStatusesLabels,
    repossessed,
    returned,
    paidOut,
    deliveredStatus,
    earlyTerminatedStatus
  } = dealContext;
  return (
    <CardBody>
      <Card>
        <CardHeader>
          <h2>Deal Info</h2>
          {deal.deal_status == deliveredStatus &&
            deal.deal_status != earlyTerminatedStatus && (
              <Row>
                <Col>
                  <Button
                    className="w-100"
                    color="success"
                    type="button"
                    onClick={() =>
                      setTransitModal({
                        show: true,
                        header: "Are you sure you want to repossess?",
                        body: "Proceeding with this operation will turn vehicle available!",
                        onClick: () => repossessed(),
                      })
                    }
                  >
                    {`Repossessed`}
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="w-100"
                    color="success"
                    type="button"
                    onClick={() =>
                      setTransitModal({
                        show: true,
                        header: "Are you sure you want to mark as return?",
                        body: "Proceeding with this operation will turn vehicle available!",
                        onClick: () => returned(),
                      })
                    }
                  >
                    {`Returned`}
                  </Button>
                </Col>
                <Col>
                  {deal?.defaults?.length === 0 && (
                    <Button
                      className="w-100"
                      color="danger"
                      type="button"
                      onClick={() =>
                        setTransitModal({
                          show: true,
                          header:
                            "Are you sure you want to add customer as defaulter and terminate the deal?",
                          body: "Proceeding with this operation will turn vehicle available!",
                          onClick: () => defaulter(),
                        })
                      }
                    >
                      {`Defaulter`}
                    </Button>
                  )}
                </Col>
                <Col>
                  <Button
                    className="w-100"
                    color="success"
                    type="button"
                    onClick={() =>
                      setTransitModal({
                        show: true,
                        header: "Are you sure you want to mark as expired?",
                        body: "Proceeding with this operation will mark deal as expired and vehicle available!",
                        onClick: () => paidOut(),
                      })
                    }
                  >
                    {`Paid Out`}
                  </Button>
                </Col>
              </Row>
            )}
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={4}>
              {isEditable ? (
                <InputKeySelect
                  label={`Deal status`}
                  value={deal ? deal.deal_status : ""}
                  onChange={(e) =>
                    setDeal({
                      ...deal,
                      deal_status: e.target.value,
                    })
                  }
                  options={dealStatuses}
                  defaultOption={true}
                />
              ) : (
                <>
                  <div className="col-12">
                    <label className="form-control-label">{`Deal status`}</label>
                  </div>
                  <div className="alert alert-primary" role="alert">
                    {`Current status is: ${
                      dealTracking
                        ? dealStatusesLabels[dealTracking.action]
                        : dealStatusesLabels[deal.deal_status]
                    }`}
                  </div>
                </>
              )}
            </Col>
            <Col md={4}>
              <InputCustom
                type={`date`}
                label={`Deal Date`}
                value={deal && deal.deal_date ? deal.deal_date : ""}
                onChange={(e) =>
                  setDeal({
                    ...deal,
                    deal_date: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={4}>
              <InputCustom
                type={`date`}
                label={
                  deal && deal.deal_type === "rental_deal"
                    ? `Payment Start Date`
                    : `Sold At`
                }
                value={deal && deal.delivery_date ? deal.delivery_date : ""}
                onChange={(e) =>
                  setDeal({
                    ...deal,
                    delivery_date: e.target.value,
                  })
                }
                invalid={dealErrors && dealErrors.delivery_date ? true : false}
                errorMessage={dealErrors.delivery_date}
              />
            </Col>
          </Row>
        </CardBody>
        <CardHeader>
          <h2>Customer Info</h2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={4}>
              <InputCustom
                label={`First Name`}
                value={
                  customer && customer.first_name ? customer.first_name : ""
                }
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    first_name: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={4}>
              <InputCustom
                label={`Last Name`}
                value={customer && customer.full_name ? customer.full_name : ""}
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    full_name: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={4}>
              <InputCustom
                label={`Mobile Number`}
                value={
                  customer && customer.mobile_number
                    ? customer.mobile_number
                    : ""
                }
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    mobile_number: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={8}>
              <InputCustom
                label={`Address`}
                value={customer && customer.address ? customer.address : ""}
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    address: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={4}>
              <InputCustom
                label={`Postal Code`}
                value={
                  customer && customer.postal_code ? customer.postal_code : ""
                }
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    postal_code: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={6}>
              <InputCustom
                label={`Email`}
                value={customer && customer.email ? customer.email : ""}
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    email: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={6}>
              <InputCustom
                label={`Driving License Number`}
                value={
                  customer && customer.driver_license_number
                    ? customer.driver_license_number
                    : ""
                }
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    driver_license_number: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h2 className="text-center">Check Sheet</h2>
        </CardHeader>
        <CardBody>
          <Row className="mt-4">
            {Object.keys(dealChecksheet).map((key1) => {
              return (
                <Col
                  md={12 / checkSheetCount}
                  className={
                    Object.keys(dealChecksheet).pop() != key1
                      ? "border-right border-dark"
                      : ""
                  }
                  key={key1}
                >
                  <h2 className="text-center">{key1}</h2>
                  <div className="mt-5">
                    {Object.keys(dealChecksheet[key1]).map((key2) => {
                      return (
                        <Row className="mt-3" key={key2}>
                          <Col md={5}>
                            <label className="form-control-label">
                              {dealChecksheet[key1][key2]}
                            </label>
                          </Col>
                          <Col md={7}>
                            <label className="custom-toggle">
                              <input
                                type="checkbox"
                                checked={
                                  deal &&
                                  deal.checksheet &&
                                  deal.checksheet[key2] == 1
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  setDeal({
                                    ...deal,
                                    checksheet: {
                                      ...deal.checksheet,
                                      [key2]:
                                        deal.checksheet[key2] &&
                                        deal.checksheet[key2] == 1
                                          ? 0
                                          : 1,
                                    },
                                  })
                                }
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="No"
                                data-label-on="Yes"
                              />
                            </label>
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </Col>
              );
            })}
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h2>
            Vehicle information
            {deal.vehicleMake && deal.vehicleMake.name
              ? ` : ${deal.vehicleMake.name}`
              : ""}
            {deal.vehicleModel && deal.vehicleModel.name
              ? `, ${deal.vehicleModel.name}`
              : ""}
          </h2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={4}>
              <InputCustom
                label={`Stock Number`}
                value={vehicle && vehicle.stock_no ? vehicle.stock_no : ""}
                onChange={(e) =>
                  setVehicle({
                    ...vehicle,
                    stock_no: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={4}>
              <InputCustom
                label={`Rego`}
                value={
                  vehicle && vehicle.vehicle_internal_id
                    ? vehicle.vehicle_internal_id
                    : ""
                }
                onChange={(e) =>
                  setVehicle({
                    ...vehicle,
                    vehicle_internal_id: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={4}>
              <InputCustom
                label={`Current mileage`}
                value={
                  vehicle && vehicle.current_mileage
                    ? vehicle.current_mileage
                    : ""
                }
                onChange={(e) =>
                  setVehicle({
                    ...deal,
                    current_mileage: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h2>Finance info</h2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={3}>
              <InputCustom
                type={`number`}
                label={`Payment frequencies`}
                value={
                  deal && deal.payment_frequency ? deal.payment_frequency : ""
                }
                onChange={(e) =>
                  setDeal({
                    ...deal,
                    payment_frequency: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={3}>
              <InputKeySelect
                label={`Interval`}
                value={
                  deal && deal.payment_frequency_interval
                    ? deal.payment_frequency_interval
                    : ""
                }
                options={paymentIntervals}
                onChange={(e) =>
                  setDeal({
                    ...deal,
                    payment_frequency_interval: e.target.value,
                  })
                }
                defaultOption={true}
              />
            </Col>
            <Col md={3}>
              <InputCustom
                label={`Term`}
                value={deal && deal.term ? deal.term : ""}
                readOnly={true}
                onChange={(e) =>
                  setDeal({
                    ...deal,
                    term: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={3}>
              <InputKeySelect
                label={`Interval`}
                value={deal && deal.interval ? deal.interval : ""}
                readOnly={true}
                options={intervals}
                onChange={(e) =>
                  setDeal({
                    ...deal,
                    interval: e.target.value,
                  })
                }
                defaultOption={true}
              />
            </Col>
            <Col md={3}>
              <InputCustom
                label={`Set Up Fee`}
                value={deal && deal.initial_payment ? deal.initial_payment : ""}
                readOnly={true}
                onChange={(e) =>
                  setDeal({
                    ...deal,
                    initial_payment: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={3}>
              <InputCustom
                label={`Rental Payment`}
                value={deal && deal.monthly_payment ? deal.monthly_payment : ""}
                onChange={(e) =>
                  setDeal({
                    ...deal,
                    monthly_payment: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={3}>
              <InputCustom
                label={`Expected profit`}
                value={deal && deal.expected_profit ? deal.expected_profit : ""}
                readOnly={true}
                onChange={(e) =>
                  setDeal({
                    ...deal,
                    expected_profit: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={3}>
              <InputCustom
                label={`End payment`}
                value={deal && deal.end_payment ? deal.end_payment : ""}
                readOnly={true}
                onChange={(e) =>
                  setDeal({
                    ...deal,
                    end_payment: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h2>Company information</h2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={6}>
              <InputCustom
                label={`Company name`}
                value={company && company.name ? company.name : ""}
                onChange={(e) =>
                  setCompany({
                    ...company,
                    name: e.target.value,
                  })
                }
              />
            </Col>
            <Col md={6}>
              <InputCustom
                label={`Business phone number`}
                value={
                  company && company.phone_number ? company.phone_number : ""
                }
                onChange={(e) =>
                  setCompany({
                    ...company,
                    phone_number: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={12}>
              <Button
                className="w-100"
                color="success"
                onClick={() => updateDeal()}
              >
                Update deal
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Modal
        isOpen={transitModal.show}
        toggle={() =>
          setTransitModal({
            ...transitModal,
            show: false,
          })
        }
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h2 className="modal-title">{transitModal.header}</h2>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() =>
              setTransitModal({
                ...transitModal,
                show: false,
              })
            }
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">{transitModal.body}</div>
        <div className="modal-footer">
          <Button
            className="new-event--add"
            color="danger"
            type="button"
            onClick={transitModal.onClick}
          >
            {`Proceed`}
          </Button>
          <Button
            className="ml-auto"
            color="link"
            type="button"
            onClick={() =>
              setTransitModal({
                ...transitModal,
                show: false,
              })
            }
          >
            {`Cancel`}
          </Button>
        </div>
      </Modal>
    </CardBody>
  );
}