import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap'

export default function JobReport() {
  const notificationAlert = useRef(null)
  const history = useHistory()

  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [report, setReport] = useState([])

  const [filter, setFilter] = useState({
    from: moment().clone().startOf('month').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  })

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, grs_token, filter)

    return () => {
      source.cancel()
    }
  }, [])

  const fetchData = async (source, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/fleet-management/job-report`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filter,
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data

      setReport(data.data.report)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
      }
    }
  }

  const filterReport = () => {
    const source = axios.CancelToken.source()
    fetchData(source, token, {
      ...filter,
      from: filter.from? constants.formatDate(filter.from): '',
      to: filter.to? constants.formatDate(filter.to): ''
    })
  }

  const resetReport = () => {
    const source = axios.CancelToken.source()
    setFilter({
      ...filter,
      from: moment().clone().startOf('month').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    })
    fetchData(source, token, {
      ...filter,
      from: moment().clone().startOf('month').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    })
  }

  return (
    <>
      <CardsHeader name="Fleets" parentName="Fleets Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
				        <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Reports</h1>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={6} sm={12}>
                          <InputCustom 
                            type={`date`}
                            label={`From`}
                            value={filter && filter.from? filter.from: ''}
                            onChange={e => setFilter({
                              ...filter,
                              from: e.target.value
                            })}
                          />
                        </Col>
                        <Col md={6} sm={12}>
                          <InputCustom 
                            type={`date`}
                            label={`To`}
                            value={filter && filter.to? filter.to: ''}
                            onChange={e => setFilter({
                              ...filter,
                              to: e.target.value
                            })}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={6} sm={12}>
                          <Button className="w-100" color="success" type="button" onClick={() => filterReport()} style={{ margin: 5 }}>Filter</Button>
                        </Col>
                        <Col md={6} sm={12}>
                          <Button className="w-100" color="danger" type="button" onClick={() => resetReport()} style={{ margin: 5 }}>Reset</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
                <TableCustom 
                  loading={loading}
                  items={report}
                  columns={[
                    {
                      dataField: "name",
                      text: "Name",
                      formatter: (rowContent, row) => {
                        return row.booker? row.booker.name: ''
                      }
                    },
                    {
                      dataField: "worked_hours",
                      text: "Worked hours"
                    },
                    {
                      dataField: "worked_percent",
                      text: "Percentage"
                    },
                    {
                      dataField: "week_worked_hours",
                      text: "Week worked hours"
                    },
                    {
                      dataField: "week_worked_percent",
                      text: "Week percentage"
                    },
                    {
                      dataField: "month_worked_hours",
                      text: "Month worked hours"
                    },
                    {
                      dataField: "month_worked_percent",
                      text: "Month percentage"
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}