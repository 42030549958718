import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import Cookies from "js-cookie";
import Select2 from "react-select2-wrapper";

import actions from "actions";
import * as constants from "constants/index.js";
import CardsHeader from "layouts/Headers/CardsHeader.js";

import InputCustom from "views/pages/components/InputCustom";
import { InputKeySelect } from "views/pages/components/InputSelect";
import TableCustom from "views/pages/components/TableCustom";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
} from "reactstrap";

export default function TaskEdit() {
  const { dealId, taskId } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const [token, setToken] = useState("");

  const [deal, setDeal] = useState("");
  const [dealTaskStatuses, setDealTaskStatuses] = useState("");
  const [dealerMembers, setDealerMembers] = useState([]);
  const [task, setTask] = useState({
    logs: [],
  });
  const [taskErrors, setTaskErrors] = useState("");

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get("grs_token");
    setToken(grs_token);

    const source = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const response = await axios({
          method: "GET",
          url: `${constants.API_URL}/deal/${dealId}/task/${taskId}`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        });
        console.log("response :: ", response.data);
        const data = response.data;

        setDeal(data.data.deal);
        setDealTaskStatuses(data.data.dealTaskStatuses);

        let dealer_members = data.data.dealerMembers;
        dealer_members.map((dealer) => {
          dealer.text = dealer.name;
        });
        setDealerMembers(dealer_members);

        let item = data.data.item;
        let assignee = [];
        if (item.task_assignee && item.task_assignee.length > 0) {
          item.task_assignee.map((val, index) => {
            assignee.push(val.id);
          });
        }
        setTask({
          ...item,
          task_assignee: assignee,
          due_date: item.due_date ? formatDate(item.due_date) : "",
        });
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled :: ", error);
        } else {
          console.error("error :: ", error);
          notify(
            "warning",
            "Warning",
            error.response && error.response.data
              ? error.response.data.message
              : error.response
              ? error.response.statusText
              : "Error Occurred!"
          );
        }
      }
    };

    fetchData();

    return () => {
      source.cancel();
    };
  }, []);

  const goDeal = () => {
    history.push({
      pathname: "/admin/deals/" + dealId + "/edit",
    });
  };

  const goCustomer = () => {
    history.push({
      pathname: "/admin/customer/" + deal.customer_id + "/edit",
    });
  };

  const formatDate = (d) => {
    if (d) {
      let date = new Date(d);
      let ye = Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
      let mo = Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
      let da = Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);

      return `${ye}-${mo}-${da}`;
    }
  };

  const formatDateFull = (d) => {
    if (d) {
      let date = new Date(d);

      let options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "2-digit",
        hour12: false,
      };

      let da = Intl.DateTimeFormat("en-BG", options).format(date);

      return da;
    }
  };

  const updateTask = () => {
    axios({
      method: "PUT",
      url: constants.API_URL + "/deal/" + dealId + "/task/" + taskId,
      headers: {
        Authorization: "Bearer " + token,
      },
      data: {
        ...task,
        task_title: task.title,
        task_comments: task.comments,
        task_status: task.status,
        task_due_date: formatDate(task.due_date),
      },
    })
      .then((response) => {
        if (response.status == 200) {
          let data = response.data;
          console.log("response data :: ", data);

          let item = data.data;
          let assignee = [];
          if (item.task_assignee && item.task_assignee.length > 0) {
            item.task_assignee.map((val, index) => {
              assignee.push(val.id);
            });
          }
          setTask({
            ...item,
            task_assignee: assignee,
            due_date: item.due_date ? formatDate(item.due_date) : "",
          });
          dispatch(actions.getNotifications(token));
          notify("success", "Success", data.message);
        }
      })
      .catch((error) => {
        setTaskErrors("");
        console.error("error :: ", error.response);
        if (error.response && error.response.status == 422) {
          setTaskErrors(error.response.data.errors);
          return;
        }
        notify(
          "error",
          "Error",
          error.response && error.response.statusText
            ? error.response.statusText
            : "Error occur."
        );
      });
  };

  return (
    <>
      <CardsHeader
        name="Deal Management"
        parentName="Deals Management"
        currentName="List"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col md={4} sm={12}>
            <Card>
              <CardHeader>
                <h1 className="title mb-0">Customer details</h1>
              </CardHeader>
              <CardBody>
                <Row className="py-3 align-items-center">
                  <Col md={4} sm={5}>
                    <small className="text-uppercase font-weight-bold">{`Name`}</small>
                  </Col>
                  <Col md={8} sm={7}>
                    <h3 className="heading mb-0">{deal.full_name}</h3>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col md={4} sm={5}>
                    <small className="text-uppercase font-weight-bold">{`Email`}</small>
                  </Col>
                  <Col md={8} sm={7}>
                    <h3 className="heading mb-0">{deal.email}</h3>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col md={4} sm={5}>
                    <small className="text-uppercase font-weight-bold">{`Phone number`}</small>
                  </Col>
                  <Col md={8} sm={7}>
                    <h3 className="heading mb-0">{deal.mobile_number}</h3>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col md={4} sm={5}>
                    <small className="text-uppercase font-weight-bold">{`More details`}</small>
                  </Col>
                  <Col md={8} sm={7}>
                    <Button
                      className="h-50"
                      color="white"
                      type="button"
                      onClick={() => goCustomer()}
                    >
                      Customer
                    </Button>
                  </Col>
                </Row>
              </CardBody>
              <CardHeader>Vehicle details</CardHeader>
              <CardBody>
                <Row className="py-3 align-items-center">
                  <Col md={4} sm={5}>
                    <small className="text-uppercase font-weight-bold">{`Reg no`}</small>
                  </Col>
                  <Col md={8} sm={7}>
                    <h3 className="heading mb-0">{deal.stock_no}</h3>
                  </Col>
                </Row>
                <Row className="py-3 align-items-center">
                  <Col md={4} sm={5}>
                    <small className="text-uppercase font-weight-bold">{`Deal details`}</small>
                  </Col>
                  <Col md={8} sm={7}>
                    <Button
                      className="h-50"
                      color="white"
                      type="button"
                      onClick={() => goDeal()}
                    >
                      Deal
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={8} sm={12}>
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">Task Management</h1>
                  </div>
                  <Button
                    className="h-50"
                    color="success"
                    type="button"
                    onClick={() => goDeal()}
                  >
                    Back to deal
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <h2 className="heading-small mb-4">{`Vehicle make information`}</h2>
                    <InputCustom
                      label={`Task title`}
                      value={task && task.title ? task.title : ""}
                      onChange={(e) =>
                        setTask({
                          ...task,
                          title: e.target.value,
                        })
                      }
                      invalid={
                        taskErrors && taskErrors.task_title ? true : false
                      }
                      errorMessage={
                        taskErrors && taskErrors.task_title
                          ? taskErrors.task_title
                          : ""
                      }
                    />
                    <InputCustom
                      type={`textarea`}
                      label={`Description`}
                      rows={5}
                      value={task && task.comments ? task.comments : ""}
                      onChange={(e) =>
                        setTask({
                          ...task,
                          comments: e.target.value,
                        })
                      }
                    />
                    <InputKeySelect
                      label={`Task deal status`}
                      value={task && task.status ? task.status : ""}
                      options={dealTaskStatuses}
                      onChange={(e) =>
                        setTask({
                          ...task,
                          status: e.target.value,
                        })
                      }
                      defaultOption={true}
                      invalid={
                        taskErrors && taskErrors.task_status ? true : false
                      }
                      errorMessage={
                        taskErrors && taskErrors.task_status
                          ? taskErrors.task_status
                          : ""
                      }
                    />
                    <FormGroup>
                      <label className="form-control-label">Assign to</label>
                      <Select2
                        className="form-control"
                        multiple
                        value={
                          task && task.task_assignee ? task.task_assignee : ""
                        }
                        data={dealerMembers}
                        onChange={(e) =>
                          setTask({
                            ...task,
                            task_assignee: Array.from(
                              e.target.selectedOptions,
                              (option) => option.value
                            ),
                          })
                        }
                      />
                      {taskErrors && taskErrors.task_assignee && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {taskErrors.task_assignee}
                        </div>
                      )}
                    </FormGroup>
                    <InputCustom
                      type={`date`}
                      label={`Due date`}
                      value={task && task.due_date ? task.due_date : ""}
                      onChange={(e) =>
                        setTask({
                          ...task,
                          due_date: e.target.value,
                        })
                      }
                      invalid={
                        taskErrors && taskErrors.task_due_date ? true : false
                      }
                      errorMessage={
                        taskErrors && taskErrors.task_due_date
                          ? taskErrors.task_due_date
                          : ""
                      }
                    />
                    <div className="d-flex flex-row justify-content-center mb-4">
                      <Button
                        className="w-100"
                        color="success"
                        onClick={() => updateTask()}
                      >
                        Save
                      </Button>
                    </div>
                    <TableCustom
                      loading={false}
                      items={task.logs}
                      keyField={`id`}
                      searchField={false}
                      columns={[
                        {
                          dataField: "owner",
                          text: "Author",
                          sort: true,
                          formatter: (rowContent, row) => {
                            return row.owner.name;
                          },
                        },
                        {
                          dataField: "notification",
                          text: "Notification",
                          sort: true,
                        },
                        {
                          dataField: "created_at",
                          text: "CreatedAt",
                          formatter: (rowContent, row) => {
                            return formatDateFull(row.created_at);
                          },
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
