import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import TableCustom from 'views/pages/components/TableCustom';
import { InputArraySelect } from 'views/pages/components/InputSelect';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from 'reactstrap';

import {
  deletee,
  hasPermission
} from 'views/policies/RegionPolicy';

export default function Regions() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const permissions = useSelector(state => state.getState).permissions;

  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);

  const [region, setRegion] = useState('');
  const [regionModal, setRegionModal] = useState({
    show: false,
    header: '',
    division: ''
  })

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    onClick: null
  })

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/types/regions`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        
        setCountries(data.data.countries)
        setRegions(data.data.items)

        setTimeout(() => {
          setLoading(false)
        }, 500)
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const addRegion = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/types/regions`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: region
    })
      .then(response => {
        console.log('res :: ', response.data)
        let data = response.data
        setRegions(data.data.items)
        notify('success', 'Success', data.message)
        setErrors('')
        setRegionModal({
          ...regionModal,
          show: false
        })
      })
      .catch(error => {
        console.error('error :: ', error)
        if (error.response && error.response.data.errors) {
          let errors = error.response.data.errors
          setErrors(errors)
          return
        }
        setRegionModal({
          ...regionModal,
          show: false
        })
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const updateRegion = () => {
    axios({
      method: 'PUT',
      url: `${constants.API_URL}/types/regions/${region.id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: region
    })
      .then(response => {
        console.log('res :: ', response.data)
        let data = response.data
        setRegions(data.data.items)
        notify('success', 'Success', data.message)
        setErrors('')
        setRegionModal({
          ...regionModal,
          show: false
        })
      })
      .catch(error => {
        console.error('error :: ', error)
        if (error.response && error.response.data.errors) {
          let errors = error.response.data.errors
          setErrors(errors)
          return
        }
        setRegionModal({
          ...regionModal,
          show: false
        })
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  const deleteRegion = (regionId) => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/types/regions/${regionId}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        console.log('res :: ', response.data)
        let data = response.data
        setRegions(data.data.items)
        notify('success', 'Success', data.message)
        setDeleteModal({
          ...deleteModal,
          show: false
        })
      })
      .catch(error => {
        console.error('error :: ', error)
        setDeleteModal({
          ...deleteModal,
          show: false
        })
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      })
  }

  return (
    <>
      <CardsHeader name="Regions" parentName="Regions Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Regions</h1>
                    <p className="text-sm mb-0">Regions management</p>
                  </Col>
                  <Col xs={4} className="text-right">                   
                    {hasPermission(permissions, 'create-regions') &&
                      <Button className="w-100" color="success" type="button" onClick={() => {
                        setRegion('')
                        setErrors('')
                        setRegionModal({
                          show: true,
                          header: 'Region details'.toUpperCase(),
                          division: 'create'
                        })
                      }}>Add new</Button>}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom
                  loading={loading}
                  keyField={`id`}
                  searchField={false}
                  items={regions}
                  columns={[
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "country_name",
                      text: "Country",
                      sort: true
                    },
                    {
                      dataField: "",
                      text: "Action",
                      style: {
                        textAlign: 'right'
                      },
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  onClick={() => {
                                    setRegion(row)
                                    setErrors('')
                                    setRegionModal({
                                      ...regionModal,
                                      show: true,
                                      header: 'Edit region details'.toUpperCase(),
                                      division: 'update'
                                    })
                                  }}
                                >
                                  Edit
                                </DropdownItem>
                                {deletee(permissions, row) &&
                                  <DropdownItem
                                    onClick={() => setDeleteModal({
                                      ...deleteModal,
                                      show: true,
                                      header: 'Delete region',
                                      onClick: () => deleteRegion(row.id)
                                    })}
                                  >
                                    Delete
                                </DropdownItem>}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* Add/Edit Lead Source Modal */}
        <Modal
          isOpen={regionModal.show}
          toggle={() => setRegionModal({
            ...regionModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{regionModal.header}</h6>
            <button 
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setRegionModal({
                ...regionModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <InputCustom
              label={`Name`}
              value={region && region.name ? region.name : ''}
              onChange={e => setRegion({
                ...region,
                name: e.target.value,
              })}
              invalid={errors && errors.name ? true : false}
              errorMessage={errors.name}
            />
            <InputArraySelect 
              label={`Country`}
              options={countries}
              value={region && region.country_id ? region.country_id : ''}
              onChange={e => setRegion({
                ...region,
                country_id: e.target.value
              })}
              valueKey={`id`}
              labelKey={`name`}
              defaultOption={true}
              invalid={errors && errors.country_id ? true : false}
              errorMessage={errors.country_id}
            />
            <InputCustom
              type={`number`}
              label={`Sort ( position )`}
              value={region && region.sort ? region.sort : ''}
              onChange={e => setRegion({
                ...region,
                sort: e.target.value,
              })}
              invalid={errors && errors.sort ? true : false}
              errorMessage={errors.sort}
            />
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => regionModal.division == 'create' ? addRegion() : updateRegion()}
            >
              {`Save`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setRegionModal({
                ...regionModal,
                show: false
              })}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
        {/* Delete source */}
        <Modal
          isOpen={deleteModal.show}
          toggle={() => setDeleteModal({
            ...deleteModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{deleteModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {`Are you sure you want to delete this region?`}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={deleteModal.onClick}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setDeleteModal({
                ...deleteModal,
                show: false
              })}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}