import React from 'react';
import Pagination from 'react-js-pagination';

import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';
import {
  Row,
  Col
} from 'reactstrap';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: blue;
`;

export default function TableCustom({ loading = true, delay = true, items = [], columns = [], pagination, onChange}) {
  return (
    <React.Fragment>
      <div className="py-4 table-responsive">
        {!loading && delay && items.length > 0 ?
          <div className="react-bootstrap-table">
            <table className="table">
              <thead>
                <tr>
                  {
                    columns && columns.length > 0 &&
                    columns.map((column, index) => {
                      return <th tabIndex="0" key={index} style={column.headerStyle ? column.headerStyle : {}}>{column.text}</th>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  items && items.length > 0 &&
                  items.map((item, index) => {
                    return <tr key={index}>
                      {
                        columns && columns.length > 0 &&
                        columns.map((column, index2) => {
                          return <td key={index2} style={column.style ? column.style : {}}>
                            {column.formatter ? column.formatter('', item) : item[column.dataField]}
                          </td>
                        })
                      }
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div> :
          <ClipLoader
            css={override}
            size={40}
            color={`#7B61E4`}
            loading={loading}
          />}
      </div>
      {pagination && items.length > 0 && 
      <Row className="react-bootstrap-table-pagination">
        <Col lg={6}>
          <p className="text-sm text-black">
            {`Showing rows ${(pagination.current_page - 1) * pagination.per_page + 1} to ${pagination.per_page * pagination.current_page > pagination.total ? pagination.total : pagination.per_page * pagination.current_page} of ${pagination.total}`}
          </p>
        </Col>
        <Col lg={6} className="react-bootstrap-table-pagination-list">
          <Pagination
            activePage={pagination.current_page}
            totalItemsCount={pagination.total}
            itemsCountPerPage={pagination.per_page}
            onChange={onChange}
            itemClass="page-item"
            linkClass="page-link"
            innerClass="pagination react-bootstrap-table-page-btns-ul"
          />
        </Col>
      </Row>}
    </React.Fragment>
  )
}