import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import axios from 'axios';
import Cookies from 'js-cookie';

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';
import InputCustom from 'views/pages/components/InputCustom';
import {
  InputKeySelect,
} from 'views/pages/components/InputSelect';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from 'reactstrap';

export default function FinancialHistory() {
  const { id } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();
  
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [intervals, setIntervals] = useState('');
  const [histories, setHistories] = useState([]);
  const [vehicle, setVehicle] = useState('');

  const [termModal, setTermModal] = useState({
    show: false,
    header: '',
    division: '',
  });
  const [term, setTerm] = useState('');

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState('');

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/vehicle/${id}/financial-history`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        
        setIntervals(data.data.intervals)
        setHistories(data.data.items)
        setVehicle(data.data.vehicle)
        
        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])
  
  const goList = () => {
    history.push({
      pathname: `/admin/vehicle/${id}/edit`
    })
  }

  const addTerm = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/vehicle/${id}/financial-history`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...term,
        id: null,
        to: term.to? term.to: null,
        otr: term.otr? term.otr: null,
        finance_rate: term.finance_rate? term.finance_rate: null,
        total_interest: term.total_interest? term.total_interest: null,
        total_deposit: term.total_deposit? term.total_deposit: null,
        non_refundable_deposit: term.non_refundable_deposit? term.non_refundable_deposit: null,
        end_payment: term.end_payment? term.end_payment: null,
      }
    })
      .then(response => {
        setTermModal({
          ...termModal,
          show: false
        })
        setTerm('')
        setErrors('')
        console.log('res :: ', response.data)
        const data = response.data
        notify('success', 'Success', data.message)
        setHistories(data.data)
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors)
          return
        }
        setTermModal({
          ...termModal,
          show: false
        })
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  const updateTerm = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/vehicle/${id}/financial-history`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: term
    })
      .then(response => {
        setTermModal({
          ...termModal,
          show: false
        })
        setErrors('')
        setTerm('')
        console.log('res :: ', response.data)
        const data = response.data
        notify('success', 'Success', data.message)
        setHistories(data.data)
      })
      .catch(error => {
        setErrors('')
        console.error('error :: ', error)
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors)
          return
        }
        setTermModal({
          ...termModal,
          show: false
        })
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  const deleteTerm = () => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/vehicle/${id}/financial-history/delete/${selectedTerm.id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        setDeleteModal(false)
        console.log('res :: ', response.data)
        let data = response.data
        notify('success', 'Success', data.message)
        setHistories(data.data)
      })
      .catch(error => {
        console.error('error :: ', error)
        setDeleteModal(false)
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred!')
      })
  }

  return (
    <>
      <CardsHeader name="Vehicles" parentName={`Vehicle - ${vehicle.vehicle_make && vehicle.vehicle_make.name}, ${vehicle.vehicle_model && vehicle.vehicle_model.name}, ${vehicle.stock_no}`} />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="title">Financial changes</h1>
                    <h3>Do not leave gaps between multiple intervals.</h3>
                    <p className="text-sm mb-0">{`From 2019-01-01 - To 2020-01-01.`}</p>
                    <p className="text-sm mb-0">{`From 2020-01-01 - To - (null for on going interval )`}</p>
                  </Col>
                  <Col xs={2} className="text-right">
                    <Button className="w-100" color="success" style={{margin: 5}} onClick={() => setTermModal({
                      ...termModal,
                      show: true,
                      header: 'Add Financial Term',
                      division: 'create'
                    })}>Add Term</Button>
                  </Col>
                  <Col xs={2} className="text-right">
                    <Button className="w-100" color="success" style={{margin: 5}} onClick={() => goList()}>Back to vehicle</Button>
                  </Col>
                </Row>
              </CardHeader>			  
              <CardBody>
                <TableCustom 
                  loading={loading}
                  items={histories}
                  keyField={`id`}
                  searchField={false}
                  columns={[
                    {
                      dataField: "monthly_cost",
                      text: "Monthly Cost",
                      sort: true
                    },
                    {
                      dataField: "term",
                      text: "Term",
                      sort: true
                    },
                    {
                      dataField: "interval",
                      text: "Interval",
                      sort: true
                    },
                    {
                      dataField: "from",
                      text: "From",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.from? constants.formatDate(row.from): '-'
                      }
                    },
                    {
                      dataField: "to",
                      text: "To",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.to? constants.formatDate(row.to): '-'
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  onClick={() => {
                                    setTerm({
                                      ...row,
                                      from: row.from? constants.formatDate(row.from): '',
                                      to: row.to? constants.formatDate(row.to): '',
                                    })
                                    setTermModal({
                                      ...termModal,
                                      show: true,
                                      header: 'Edit Financial Term',
                                      division: 'update'
                                    })
                                  }}
                                >
                                  <i className="fas fa-user-edit"></i>Edit
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    setSelectedTerm(row)
                                    setDeleteModal(true)
                                  }}
                                >
                                  <i className="fas fa-trash"></i>Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={termModal.show}
          toggle={() => setTermModal({
            ...termModal,
            show: false
          })}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{termModal.header}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setTermModal({
                ...termModal,
                show: false
              })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col md={6} sm={12}>
                <InputCustom 
                  type={`number`}
                  label={`Monthly Cost`}
                  value={term && term.monthly_cost? term.monthly_cost: ''}
                  onChange={e => setTerm({
                    ...term,
                    monthly_cost: e.target.value,
                  })}
                  invalid={errors && errors.monthly_cost? true: false}
                  errorMessage={errors.monthly_cost}
                />
                <InputCustom 
                  type={`number`}
                  label={`OTR`}
                  value={term && term.otr? term.otr: ''}
                  onChange={e => setTerm({
                    ...term,
                    otr: e.target.value,
                  })}
                  invalid={errors && errors.otr? true: false}
                  errorMessage={errors.otr}
                />
                <InputCustom 
                  type={`number`}
                  label={`VAT deposit`}
                  value={term && term.total_deposit? term.total_deposit: ''}
                  onChange={e => setTerm({
                    ...term,
                    total_deposit: e.target.value,
                  })}
                  invalid={errors && errors.total_deposit? true: false}
                  errorMessage={errors.total_deposit}
                />
                <InputCustom 
                  type={`number`}
                  label={`End payment`}
                  value={term && term.end_payment? term.end_payment: ''}
                  onChange={e => setTerm({
                    ...term,
                    end_payment: e.target.value,
                  })}
                  invalid={errors && errors.end_payment? true: false}
                  errorMessage={errors.end_payment}
                />
                <InputKeySelect
                  label={`Interval`}
                  value={term && term.interval? term.interval: ''}
                  options={intervals}
                  onChange={e => setTerm({
                    ...term,
                    interval: e.target.value,
                  })}
                  defaultOption={true}
                  invalid={errors && errors.interval? true: false}
                  errorMessage={errors.interval}
                />
                <InputCustom 
                  type={`date`}
                  label={`To`}
                  value={term && term.to? term.to: ''}
                  onChange={e => setTerm({
                    ...term,
                    to: e.target.value,
                  })}
                  invalid={errors && errors.to? true: false}
                  errorMessage={errors.to}
                />
              </Col>
              <Col md={6} sm={12}>
                <InputCustom 
                  type={`number`}
                  label={`Finance rate`}
                  value={term && term.finance_rate? term.finance_rate: ''}
                  onChange={e => setTerm({
                    ...term,
                    finance_rate: e.target.value,
                  })}
                  invalid={errors && errors.finance_rate? true: false}
                  errorMessage={errors.finance_rate}
                />
                <InputCustom 
                  type={`number`}
                  label={`Total interest`}
                  value={term && term.total_interest? term.total_interest: ''}
                  onChange={e => setTerm({
                    ...term,
                    total_interest: e.target.value,
                  })}
                  invalid={errors && errors.total_interest? true: false}
                  errorMessage={errors.total_interest}
                />
                <InputCustom 
                  type={`number`}
                  label={`Non Refundable deposit`}
                  value={term && term.non_refundable_deposit? term.non_refundable_deposit: ''}
                  onChange={e => setTerm({
                    ...term,
                    non_refundable_deposit: e.target.value,
                  })}
                  invalid={errors && errors.non_refundable_deposit? true: false}
                  errorMessage={errors.non_refundable_deposit}
                />
                <InputCustom 
                  type={`number`}
                  label={`Term`}
                  value={term && term.term? term.term: ''}
                  onChange={e => setTerm({
                    ...term,
                    term: e.target.value,
                  })}
                  invalid={errors && errors.term? true: false}
                  errorMessage={errors.term}
                />
                <InputCustom 
                  type={`date`}
                  label={`From`}
                  value={term && term.from? term.from: ''}
                  onChange={e => setTerm({
                    ...term,
                    from: e.target.value,
                  })}
                  invalid={errors && errors.from? true: false}
                  errorMessage={errors.from}
                />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => termModal.division == 'create'? addTerm(): updateTerm()}
            >
              {`Save`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setTermModal({
                ...termModal,
                show: false
              })}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={deleteModal}
          toggle={() => setDeleteModal(false)}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h6 className="modal-title">{`Delete Financial Term`}</h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setDeleteModal(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            {`Are you sure you want to delete this financial term?`}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={() => deleteTerm()}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setDeleteModal(false)}
            >
			        {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}