import React, { useContext } from "react";
import { LeadsContext } from "../context";

import { Row, Col, Card, CardBody, Button } from "reactstrap";

import {
  InputKeySelect,
  InputArraySelect,
} from "views/pages/components/InputSelect";

import InputCustom from "views/pages/components/InputCustom";

export default function DetailsSection() {
  const leadsContext = useContext(LeadsContext);
  const {
    markWonLead,
    markLostLead,
    leadsStatuses,
    lead,
    setLead,
    leadErrors,
    setModels,
    updateLead,
    makes,
    makesAndModels,
    models,
    leadSources,
    leadTypes,
    dealerMembers,
  } = leadsContext;
  return (
    <Card>
      <CardBody>
        <Row className="justify-content-md-center">
          <Col md={4}>
            <Row>
              <Col md={6} sm={6} xs={6}>
                <Button
                  className="w-100"
                  color="success"
                  type="button"
                  onClick={() => markWonLead()}
                >
                  Won Lead
                </Button>
              </Col>
              <Col md={6} sm={6} xs={6}>
                <Button
                  className="w-100"
                  color="danger"
                  type="button"
                  onClick={() => markLostLead()}
                >
                  Lost Lead
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2 className="text-center mt-5">
              Lead Details -{" "}
              <strong>
                {lead.vehicle
                  ? lead.vehicle.purchase_price
                    ? "Used Van"
                    : "Rental Deal"
                  : leadsStatuses &&
                    lead.vehicle === null &&
                    lead &&
                    lead.status
                  ? leadsStatuses[lead.status]
                  : ""}
              </strong>
            </h2>
            {lead && lead.vehicle && (
              <h2 className="text-center mt-3">
                Customer applied to{" "}
                <strong>
                  {lead.vehicle.vehicleMake && lead.vehicle.vehicleMake.name}{" "}
                  {lead.vehicle.vehicleModel && lead.vehicle.vehicleModel.name}{" "}
                  {lead.vehicle.derivative}
                </strong>
              </h2>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={12}>
            <InputCustom
              label={`Name`}
              value={lead && lead.name ? lead.name : ""}
              onChange={(e) =>
                setLead({
                  ...lead,
                  name: e.target.value,
                })
              }
              invalid={leadErrors && leadErrors.name ? true : false}
              errorMessage={leadErrors.name}
            />
          </Col>
          <Col md={4} sm={12}>
            <InputCustom
              label={`Mobile No`}
              value={lead && lead.phone ? lead.phone : ""}
              onChange={(e) =>
                setLead({
                  ...lead,
                  phone: e.target.value,
                })
              }
              invalid={leadErrors && leadErrors.phone ? true : false}
              errorMessage={leadErrors.phone}
            />
          </Col>
          <Col md={4} sm={12}>
            <InputCustom
              label={`Email`}
              value={lead && lead.email ? lead.email : ""}
              onChange={(e) =>
                setLead({
                  ...lead,
                  email: e.target.value,
                })
              }
              invalid={leadErrors && leadErrors.email ? true : false}
              errorMessage={leadErrors.email}
            />
          </Col>
          <Col md={4} sm={12}>
            <InputCustom
              label={`Address`}
              value={lead && lead.address ? lead.address : ""}
              onChange={(e) =>
                setLead({
                  ...lead,
                  address: e.target.value,
                })
              }
              invalid={leadErrors && leadErrors.address ? true : false}
              errorMessage={leadErrors.address}
            />
          </Col>
          <Col md={4} sm={12}>
            <InputCustom
              label={`Driver No.`}
              value={
                lead && lead.driver_license_number
                  ? lead.driver_license_number
                  : ""
              }
              onChange={(e) =>
                setLead({
                  ...lead,
                  driver_license_number: e.target.value,
                })
              }
              invalid={
                leadErrors && leadErrors.driver_license_number ? true : false
              }
              errorMessage={leadErrors.driver_license_number}
            />
          </Col>
          <Col md={4} sm={12}>
            <InputCustom
              label={`Postal Code`}
              value={lead && lead.post_code ? lead.post_code : ""}
              onChange={(e) =>
                setLead({
                  ...lead,
                  post_code: e.target.value,
                })
              }
              invalid={leadErrors && leadErrors.post_code ? true : false}
              errorMessage={leadErrors.post_code}
            />
          </Col>
        </Row>
        <Row>
          <h2>Vehicle Info</h2>
        </Row>
        <Row>
          <Col md={3} sm={12}>
            <InputArraySelect
              label={`Makes`}
              value={lead && lead.lead_make ? lead.lead_make : ""}
              options={makes}
              onChange={(e) => {
                setModels(
                  e.target.value != "-" ? makesAndModels[e.target.value] : []
                );
                setLead({
                  ...lead,
                  lead_make: e.target.value,
                  vehicle_model_id:
                    makesAndModels[e.target.value] &&
                    makesAndModels[e.target.value].length > 0
                      ? makesAndModels[e.target.value][0].id
                      : "",
                });
              }}
              valueKey={`id`}
              labelKey={`name`}
              defaultOption={true}
              invalid={leadErrors && leadErrors.lead_make ? true : false}
              errorMessage={leadErrors.lead_make}
            />
          </Col>
          <Col md={3}>
            <InputArraySelect
              label={`Model`}
              value={lead && lead.vehicle_model_id ? lead.vehicle_model_id : ""}
              options={models}
              onChange={(e) =>
                setLead({
                  ...lead,
                  vehicle_model_id: e.target.value,
                })
              }
              valueKey={`id`}
              labelKey={`name`}
              invalid={leadErrors && leadErrors.vehicle_model_id ? true : false}
              errorMessage={leadErrors.vehicle_model_id}
            />
          </Col>
          <Col md={6}>
            <InputCustom
              label={`Drivative`}
              value={lead && lead.derivative ? lead.derivative : ""}
              onChange={(e) =>
                setLead({
                  ...lead,
                  derivative: e.target.value,
                })
              }
              invalid={leadErrors && leadErrors.derivative ? true : false}
              errorMessage={leadErrors.derivative}
            />
          </Col>
          <Col md={4}>
            <InputArraySelect
              label={`Source`}
              value={lead && lead.lead_source_id ? lead.lead_source_id : ""}
              options={leadSources}
              onChange={(e) =>
                setLead({
                  ...lead,
                  lead_source_id: e.target.value,
                })
              }
              valueKey={`id`}
              labelKey={`name`}
              defaultOption={true}
              invalid={leadErrors && leadErrors.lead_source_id ? true : false}
              errorMessage={leadErrors.lead_source_id}
            />
          </Col>
          <Col md={4}>
            <InputKeySelect
              label={`Lead Type`}
              value={lead && lead.lead_type ? lead.lead_type : ""}
              options={leadTypes}
              onChange={(e) =>
                setLead({
                  ...lead,
                  lead_type: e.target.value,
                })
              }
              defaultOption={true}
              invalid={leadErrors && leadErrors.lead_type ? true : false}
              errorMessage={leadErrors.lead_type}
            />
          </Col>
          <Col md={4}>
            <InputArraySelect
              label={`Assignee`}
              value={lead && lead.assigned_to ? lead.assigned_to : ""}
              options={dealerMembers}
              onChange={(e) =>
                setLead({
                  ...lead,
                  assigned_to: e.target.value,
                })
              }
              valueKey={`id`}
              labelKey={`name`}
              defaultOption={true}
              invalid={leadErrors && leadErrors.assigned_to ? true : false}
              errorMessage={leadErrors.assigned_to}
            />
          </Col>
        </Row>
        <Col md={6} sm={12}>
          <InputCustom
            type={`textarea`}
            label={`Additional vehicle information`}
            rows={5}
            value={
              lead && lead.additional_information
                ? lead.additional_information
                : ""
            }
            onChange={(e) =>
              setLead({
                ...lead,
                additional_information: e.target.value,
              })
            }
          />
        </Col>
        <div className="d-flex flex-row justify-content-center">
          <Button
            className="w-100"
            color="success"
            onClick={() => updateLead()}
          >
            Update
          </Button>
        </div>
      </CardBody>
    </Card>
  );
}
