import React, { Component } from 'react';

import UnplannedGrid from 'views/pages/fleet/scheduler/lib/UnplannedGrid';
import Task from 'views/pages/fleet/scheduler/lib/Task';

class UnplannedGridComponent extends Component {

  componentDidMount() {
    this.unplannedGrid = new UnplannedGrid({
      appendTo: 'unplannedContainer',
      eventStore: this.props.eventStore,
      store: {
        modelClass: Task,
        autoLoad: true
      },
      data: this.props.data,
    });
  }

  componentDidUpdate() {
    this.unplannedGrid.eventStore = this.props.eventStore
    this.unplannedGrid.data = this.props.data
  }

  render() {
    return (<div id="unplannedContainer"></div>);
  }
}

export default UnplannedGridComponent;
