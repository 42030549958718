import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import InputCustom from 'views/pages/components/InputCustom'
import {
  InputArraySelect,
} from 'views/pages/components/InputSelect'
import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

import { hasPermission } from 'views/policies/FleetPolicy';

export default function VehicleInStock() {
  const { category } = useParams()
  const notificationAlert = useRef(null)
  const history = useHistory()

  const permissions = useSelector(state => state.getState).permissions

  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState('')
  const [pagination, setPagination] = useState('')
  const [vehicleStatuses, setVehicleStatuses] = useState('')
  const [makes, setMakes] = useState([])
  const [makesAndModels, setMakesAndModels] = useState('')
  const [models, setModels] = useState([])
  const [filter, setFilter] = useState('')

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, 1, grs_token, '')

    return () => {
      source.cancel()
    }
  }, [])  

  const fetchData = async (source, pageNumber, token, filter) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/fleet-management/vehicle-in-stock`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          page: pageNumber,
          category: category? category: null,
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data
      setItems(data.data.items.data)
      setPagination(data.data.items)
      setVehicleStatuses(data.data.vehicleStatuses)
      setMakes(data.data.makes)
      setMakesAndModels(data.data.makesAndModels)
      setLoading(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
      }
    }
  }

  const filterVehicle = () => {
    const source = axios.CancelToken.source()
    fetchData(source, 1, token, filter)
  }

  const resetVehicle = () => {
    setFilter('')
    const source = axios.CancelToken.source()
    fetchData(source, 1, token, '')
  }

  const addVechile = () => {
    history.push({
      pathname: '/admin/fleet-job/create'
    })
  }

  const inspectVehicle = (vehicleId) => {
    history.push({
      pathname: `/admin/fleet-job/${vehicleId}/inspect`,
      state: {
        category: category? category: null,
      }
    })
  }

  const makeHeader = (category) => {
    return `${category.slice(0, 1).toUpperCase()}${category.slice(1, category.length).replace('_', ' ').toLowerCase()}`
  }
  
  const archiveVehicle = async (vehicleId) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/fleet-management/${vehicleId}/archive`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          page: 1,
        },
      })
      console.log('response :: ', response.data)
      const data = response.data
      setItems(data.data.items.data)
      setPagination(data.data.items)
      notify('success', 'Success', data.message)
    } catch (error) {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
    }
  }

  return (
    <>
      <CardsHeader name="Fleets" parentName="Vehicle In Stock" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">{category? `${makeHeader(category)} Vehicles`: 'Vehicle In Stock'}</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    {!category && (hasPermission(permissions, 'create-body-shop') || hasPermission(permissions, 'create-valeting') || hasPermission(permissions, 'create-mechanical')) && 
                    <Button className="w-100" color="success" type="button" onClick={() => addVechile()}>Add Vehicle</Button>}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Container>
                  <Row>
                    <Col md={4} sm={12} xs={12}>
                      <InputArraySelect 
                        label={`Make`}
                        options={makes}
                        value={filter && filter.make_id? filter.make_id: ''}
                        onChange={e => {
                          setModels(makesAndModels[e.target.value])
                          setFilter({
                            ...filter,
                            make_id: e.target.value
                          })
                        }}
                        defaultOption={true}
                        valueKey={`id`}
                        labelKey={`name`}
                      />
                    </Col>
                    <Col md={4} sm={12} xs={12}>
                      <InputArraySelect 
                        label={`Model`}
                        options={models}
                        value={filter && filter.model_id? filter.model_id: ''}
                        onChange={e => {
                          setFilter({
                            ...filter,
                            model_id: e.target.value
                          })
                        }}
                        defaultOption={true}
                        valueKey={`id`}
                        labelKey={`name`}
                      />
                    </Col>
                    <Col md={4} sm={12} xs={12}>
                      <InputCustom 
                        label={`Reg No`}
                        value={filter && filter.reg_no? filter.reg_no: ''}
                        onChange={e => setFilter({
                          ...filter,
                          reg_no: e.target.value
                        })}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={12}>
                      <Button className="w-100" color="success" style={{margin: 5}} type="button" onClick={() => filterVehicle()}>Filter</Button>
                    </Col>
                    <Col md={6} sm={12}>
                      <Button className="w-100" color="warning" style={{margin: 5}} type="button" onClick={() => resetVehicle()}>Reset</Button>
                    </Col>
                  </Row>
                </Container>
                <TableCustom 
                  loading={loading}
                  items={items}
                  pagination={pagination}
                  onChange={pageNumber => fetchData(axios.CancelToken.source(), pageNumber, token, filter)}
                  columns={[
                    {
                      dataField: "vehicle",
                      text: "Vehicle",
                      formatter: (rowContent, row) => {
                        return `${row.vehicle_make.name} ${row.vehicle_model.name}`
                      }
                    },
                    {
                      dataField: "stock_no",
                      text: "Stock No"
                    },
                    {
                      dataField: "status",
                      text: "Status"
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  onClick={() => inspectVehicle(row.id)}
                                >
                                  {category? `Jobs`: `Inspect`}
                                </DropdownItem>
                                {hasPermission(permissions, 'update-fleet-management') && row.status === 'Ready To Go' && 
                                <DropdownItem
                                  onClick={() => archiveVehicle(row.id)}
                                >
                                  {`Archive`}
                                </DropdownItem>}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}