import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import NotificationAlert from "react-notification-alert"
import axios from 'axios'
import moment from 'moment'
import Cookies from 'js-cookie'
import * as constants from 'constants/index.js'

import CardsHeader from 'layouts/Headers/CardsHeader.js'
import InputCustom from 'views/pages/components/InputCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap'

export default function GeneralOverview() {
  const notificationAlert = useRef(null)
  const history = useHistory()

  const [token, setToken] = useState('')
  const [items, setItems] = useState([])
  const [vehicleStatuses, setVehicleStatuses] = useState('')

  const [devices, setDevices] = useState([])
  const [selectedDeviceId, setSelectedDeviceId] = useState('')
  const [selectedDate, setSelectedDate] = useState({
    from: moment().format('YYYY-MM-DDT00:00:00'),
    to: moment().format('YYYY-MM-DDT23:59:59')
  })

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {

    window.addEventListener('message', function (event) {
      if (typeof event.data === 'string' && event.data.indexOf('MapView|') === 0) {
        console.log(event.data)
      }
    })

    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    fetchData(source, grs_token)

    return () => {
      source.cancel()
    }
  }, [])

  useEffect(() => {
    if (items && items.length > 0) {

      window.addEventListener('message', function (event) {
        if (typeof event.data === 'string' && event.data.indexOf('MapView|') === 0) {
          console.log(event.data)

          let markers = ''
          let i = 0
          items.map((item, index) => {
            if (item.location) {
              if (i == 0) {
                markers += `"markername${index}": { "title": "${item.name}", "appendpoints": [[${item.location.latitude}, ${item.location.longitude}]], "label": "${item.name}", "color": "#f0f" }`
              } else {
                markers += `, "markername${index}": { "title": "${item.name}", "appendpoints": [[${item.location.latitude}, ${item.location.longitude}]], "label": "${item.name}", "color": "#f0f" }`
              }
              i++
            }
          })
    
          const namedmarkers = JSON.parse(`{ ${markers} }`)
          const cmd = JSON.stringify({ "clear": "all", ruler: true, "namedmarkers": namedmarkers })
          
          if (document.getElementById('map')) {
            document.getElementById('map').contentWindow.postMessage(`MapView|cmd: ${cmd}`, '*')
          }
        }
      })
    }
  }, [items])

  const fetchData = async (source, token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/tracking/device/location/index`,
        headers: {
          Authorization: `Bearer ${token}`
        },
        cancelToken: source.token,
      })
      console.log('response :: ', response.data)
      const data = response.data

      const _items = data.data.items
      setItems(_items)
      setVehicleStatuses(data.data.vehicleStatuses)

      if (_items.length > 0) {

        let markers = ''
        let i = 0
        _items.map((item, index) => {
          if (item.location) {
            if (i == 0) {
              markers += `"markername${index}": { "title": "${item.name}", "appendpoints": [[${item.location.latitude}, ${item.location.longitude}]], "label": "${item.name}", "color": "#f0f" }`
            } else {
              markers += `, "markername${index}": { "title": "${item.name}", "appendpoints": [[${item.location.latitude}, ${item.location.longitude}]], "label": "${item.name}", "color": "#f0f" }`
            }
            i++
          }
        })

        const namedmarkers = JSON.parse(`{ ${markers} }`)
        const cmd = JSON.stringify({ "clear": "all", ruler: true, "namedmarkers": namedmarkers })
    
        document.getElementById('map').contentWindow.postMessage(`MapView|cmd: ${cmd}`, '*')
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled :: ', error)
      } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
      }
    }
  }

  const DeviceItem = ({device}) => {
    return (
      <Row className={`device-item ${device.id == selectedDeviceId ? `selected-device-item` : ``} `} onClick={() => selectDevice(device.id, parseInt(moment(selectedDate.from).format('X')), parseInt(moment(selectedDate.to).format('X')))}>
        <Col md={4} style={{ color: '#FFFFFF', textAlign: 'center' }}>
          <i className="fa fa-microchip" aria-hidden="true"></i>
          <div>
            <small>{`# ${device.id}`}</small>
          </div>
        </Col>
        <Col md={8} style={{ color: '#FFFFFF', }}>
          <div>
            {device.name}
          </div>
          <div>
            <small><i className="fa fa-plug" aria-hidden="true"></i> {device.identifier ? device.identifier : `<no ident>`}</small>
          </div>
          <div>
            <small><i className="fa fa-phone" aria-hidden="true"></i> {device.phoneNumber ? device.phoneNumber : `<no phone>`}</small>
          </div>
        </Col>
      </Row>
    )
  }

  const selectDevice = async (deviceId, from_date, to_date) => {
    setSelectedDeviceId(deviceId)

    try {
      const response = await axios({
        method: 'GET',
        url: `${constants.API_URL}/tracking/device/${deviceId}/messages/show`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          from: from_date,
          to: to_date, 
          fields: "timestamp,position.latitude,position.longitude"
        }
      })
      console.log('response :: ', response.data)
      const data = response.data
      const result = data.data.messages.result

      let points = ''
      result.map((position, index) => {
        if (position['position.latitude'] && position['position.longitude']) {
          if (index === 0) {
            points += `[${position['position.latitude']},${position['position.longitude']}]`
          } else {
            points += `,[${position['position.latitude']},${position['position.longitude']}]`
          }
        }
      })
      document.getElementById('map').contentWindow.postMessage(`MapView|cmd:{"clear": "all"}`, '*')
      document.getElementById('map').contentWindow.postMessage(`MapView|cmd:{"appendpoints": [${points}]}`, '*')
    } catch (error) {
      console.error('error :: ', error)
      notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
    }
  }

  return (
    <>
      <CardsHeader name="Dashboard Management" parentName="Tracking Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title">Tracker Dashboard</h1>
                    <span className="text-sm mb-0">See all tracked vehicles here.</span>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <h1>Map Displaying all vehicle tracker locations</h1>
                <Row className="mt-3 pl-3">
                  <Col md={3} sm={12} style={{backgroundColor: '#424242'}}>
                    <h2 className="pt-3" style={{ color: '#FFFFFF' }}>{`Devices`}</h2>
                    <Row>
                      <Col md={12} sm={12}>
                        <InputCustom
                          type={`datetime-local`}
                          label={`From`}
                          labelClassName={`white-label`}
                          value={selectedDate && selectedDate.from ? selectedDate.from : ''}
                          max={selectedDate.to}
                          onChange={e => {
                            setSelectedDate({
                              ...selectedDate,
                              from: e.target.value
                            })
                            if (selectedDeviceId) {
                              selectDevice(selectedDeviceId, parseInt(moment(e.target.value).format('X')), parseInt(moment(selectedDate.to).format('X')))
                            }
                          }}
                        />
                      </Col>
                      <Col md={12} sm={12}>
                        <InputCustom
                          type={`datetime-local`}
                          label={`To`}
                          labelClassName={`white-label`}
                          value={selectedDate && selectedDate.to ? selectedDate.to : ''}
                          min={selectedDate.from}
                          onChange={e => {
                            setSelectedDate({
                              ...selectedDate,
                              to: e.target.value
                            })
                            if (selectedDeviceId) {
                              selectDevice(selectedDeviceId, parseInt(moment(selectedDate.from).format('X')), parseInt(moment(e.target.value).format('X')))
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <div style={{overflowX: 'hidden', overflowY: 'auto', height: 640}}>
                      {items.length > 0 &&
                        items.map((device, index) => {
                          return <DeviceItem device={device} key={index} />
                        })
                      }
                    </div>
                  </Col>
                  <Col md={9} sm={12}>
                    <iframe id="map" src="https://flespi.io/mapview/" width="100%" height="800"></iframe>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}