import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import Cookies from "js-cookie";

import * as constants from "constants/index.js";
import CardsHeader from "layouts/Headers/CardsHeader.js";

import InputCustom from "views/pages/components/InputCustom";
import InputSideCustom from "views/pages/components/InputSideCustom";
import InputFile from "views/pages/components/InputFile";
import {
  InputKeySelect,
  InputArraySelect,
  InputKeySideSelect,
  InputArraySideSelect,
} from "views/pages/components/InputSelect";
import InputToggle from "views/pages/components/InputToggle";
import TableCustom from "views/pages/components/TableCustom";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";

export default function Edit() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState("");
  const [items, setItems] = useState([]);
  const [typeOfRent, setTypeOfRent] = useState("");
  const [extra, setExtra] = useState("");

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get("grs_token");
    setToken(grs_token);

    const source = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const response = await axios({
          method: "GET",
          url: `${constants.API_URL}/customer/daily-deals/extras`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        });
        console.log("response :: ", response.data);
        const data = response.data;

        setItems(data.data.items);
        setTypeOfRent(data.data.typeOfRent);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled :: ", error);
        } else {
          console.error("error :: ", error);
          notify(
            "warning",
            "Warning",
            error.response && error.response.data
              ? error.response.data.message
              : error.response
              ? error.response.statusText
              : "Error Occurred!"
          );
        }
      }
    };

    fetchData();

    return () => {
      source.cancel();
    };
  }, []);

  const goList = () => {
    history.push({
      pathname: `/admin/vehicle-model`,
    });
  };

  const updateExtras = async () => {
    let formData = new FormData()

    if (items.length > 0) {
      items.map(group => {
        if (group.extras.length > 0) {
          group.extras.map(extra => {
            formData.append(`extra[${extra.id}][price]`, extra?.dealer_extras.length > 0 && extra.dealer_extras[0].price ? extra?.dealer_extras[0].price : '')
            formData.append(`extra[${extra.id}][type_of_rent]`, extra?.dealer_extras.length > 0 &&  extra?.dealer_extras[0].type_of_rent ? extra?.dealer_extras[0].type_of_rent : '')
          })
        }
      })
    }
    formData.append('_method', 'PUT')

    try {
      const response = await axios({
        method: "POST",
        url: `${constants.API_URL}/customer/daily-extra-deals/extras`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: formData
      });
      console.log("response :: ", response.data);
      const data = response.data;

      notify(
        "success",
        "Success",
        data.message
      );
    } catch (error) {
      console.error("error :: ", error);
      notify(
        "warning",
        "Warning",
        error.response && error.response.data
          ? error.response.data.message
          : error.response
          ? error.response.statusText
          : "Error Occurred!"
      );
    }
  }

  return (
    <>
      <CardsHeader
        name="Leads"
        parentName="Leads Management"
        currentName="Create"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Extras Management</h1>
                    <p className="text-sm mb-0">
                      Only those extras providing both Price and Apply On will
                      be shown on frontend.
                    </p>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button
                      className="w-100"
                      color="success"
                      type="button"
                      onClick={() => goList()}
                    >
                      Back to list
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {items &&
                  items?.length > 0 &&
                  items.map((group, index) => {
                    return (
                      <div className="pl-lg-4" key={index}>
                        <h2 className="heading mb-4">{group.name}</h2>
                        {group?.extras &&
                          group?.extras.length > 0 &&
                          group?.extras?.map((extra, _index) => {
                            return (
                              <Row key={_index}>
                                <Col md={12}>
                                  <h2 className="text-muted mb-1">
                                    {extra.name}
                                  </h2>
                                  <h3 className="text-muted mb-1">
                                    {extra.description}
                                  </h3>
                                </Col>
                                <Col md={6} sm={12}>
                                  <InputCustom
                                    label={`Price`}
                                    value={
                                      extra?.dealer_extras?.length > 0
                                        ? extra?.dealer_extras[0].price
                                        : ""
                                    }
                                    onChange={(e) => {
                                      setItems([
                                        ...items.map((item, __idx) => {
                                          if (index !== __idx)
                                            return { ...item };
                                          if (
                                            item.extras[_index].dealer_extras
                                              .length > 0 &&
                                            item.extras[_index].dealer_extras[0]
                                              .type_of_rent
                                          ) {
                                            item.extras[_index].dealer_extras =
                                              [
                                                {
                                                  price: e.target.value,
                                                  type_of_rent:
                                                    item.extras[_index]
                                                      .dealer_extras[0]
                                                      .type_of_rent,
                                                },
                                              ];
                                          } else if (
                                            item.extras[_index].dealer_extras
                                              .length > 0 &&
                                            item.extras[_index].dealer_extras[0]
                                              .price
                                          ) {
                                            item.extras[
                                              _index
                                            ].dealer_extras[0].price =
                                              e.target.value;
                                          } else {
                                            item.extras[_index].dealer_extras =
                                              [
                                                {
                                                  price: e.target.value,
                                                },
                                              ];
                                          }
                                          return item;
                                        }),
                                      ]);
                                    }}
                                  />
                                </Col>
                                <Col md={6} sm={12}>
                                  <InputKeySelect
                                    label={`Apply on`}
                                    options={typeOfRent}
                                    value={
                                      extra?.dealer_extras?.length > 0
                                        ? extra?.dealer_extras[0].type_of_rent
                                        : ""
                                    }
                                    onChange={(e) => {
                                      setItems([
                                        ...items.map((item, __idx) => {
                                          if (index !== __idx)
                                            return { ...item };
                                          if (
                                            item.extras[_index].dealer_extras
                                              .length > 0 &&
                                            item.extras[_index].dealer_extras[0]
                                              .price &&
                                            !item.extras[_index]
                                              .dealer_extras[0].type_of_rent
                                          ) {
                                            item.extras[_index].dealer_extras =
                                              [
                                                {
                                                  price:
                                                    item.extras[_index]
                                                      .dealer_extras[0].price,
                                                  type_of_rent: e.target.value,
                                                },
                                              ];
                                          } else if (
                                            item.extras[_index].dealer_extras
                                              .length > 0 &&
                                            item.extras[_index].dealer_extras[0]
                                              .price &&
                                            item.extras[_index].dealer_extras[0]
                                              .type_of_rent
                                          ) {
                                            item.extras[
                                              _index
                                            ].dealer_extras[0].type_of_rent =
                                              e.target.value;
                                          } else {
                                            item.extras[_index].dealer_extras =
                                              [
                                                {
                                                  type_of_rent: e.target.value,
                                                },
                                              ];
                                          }
                                          return item;
                                        }),
                                      ]);
                                    }}
                                    defaultOption={true}
                                  />
                                </Col>
                              </Row>
                            );
                          })}
                      </div>
                    );
                  })}
                <Row>
                  <Col md={12}>
                    <Button
                      className="w-100"
                      color="success"
                      onClick={() => updateExtras()}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
