import React, { useEffect, useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from "js-cookie";
import axios from 'axios';

import actions from 'actions';
import * as constants from 'constants/index.js';

export const Context = createContext({});

export const Provider = props => {
  const { id, notify, children } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  
  const profilePermissions = useSelector((state) => state.getState).permissions;
  const csrfToken = useSelector((state) => state.getState).csrfToken;
  const profileData = useSelector((state) => state.getState).profileData;

  const [token, setToken] = useState('');
  const [tab, setTab] = useState('info');

  const [siteCode, setSiteCode] = useState('');
  const [authUserTeam, setAuthUserTeam] = useState('');
  const [allowEdit, setAllowEdit] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [contractTypes, setContractTypes] = useState('');
  const [dealChecksheet, setDealChecksheet] = useState('');
  const [dealStatuses, setDealStatuses] = useState('');
  const [dealStatusesLabels, setDealStatusesLabels] = useState('');
  const [dealTaskStatuses, setDealTaskStatuses] = useState('');
  const [dealTracking, setDealTracking] = useState('');
  const [dealerMembers, setDealerMembers] = useState([]);
  const [dealerProfile, setDealerProfile] = useState('');
  const [documents, setDocuments] = useState([]);
  const [hasAccessToDocumentModule, setHasAccessToDocumentModule] = useState('');
  const [hasPaymentSchedule, setHasPaymentSchedule] = useState(false);
  const [intervals, setIntervals] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [deal, setDeal] = useState('');
  const [paymentIntervals, setPaymentIntervals] = useState('');
  const [company, setCompany] = useState('');
  const [deliveredStatuses, setDeliveredStatuses] = useState([]);
  const [defaultedStatus, setDefaultedStatus] = useState('');
  const [dealExpiredStatus, setDealExpiredStatus] = useState('');
  const [canAccessPaymentsDetails, setCanAccessPaymentsDetails] = useState('');
  const [isAbleToGetPayments, setIsAbleToGetPayments] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [returnedStatus, setReturnedStatus] = useState('');
  const [deliveredStatus, setDeliveredStatus] = useState('');
  const [paidOutStatus, setPaidOutStatus] = useState('');
  const [earlyTerminatedStatus, setEarlyTerminatedStatus] = useState('');

  const [customer, setCustomer] = useState('');
  const [vehicle, setVehicle] = useState('');

  const [checkSheetCount, setCheckSheetCount] = useState('');

  const [dealErrors, setDealErrors] = useState('');
  const [documentErrors, setDocumentErrors] = useState('');
  const [taskErrors, setTaskErrors] = useState('');

  const [documentModal, setDocumentModal] = useState(false);
  const [document, setDocument] = useState('');

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null,
  })

  const [fileLists, setFileLists] = useState([]);

  const [addNoteModal, setAddNoteModal] = useState(false);
  const [noteDelay, setNoteDelay] = useState(false);
  const [note, setNote] = useState('');

  const [taskDelay, setTaskDelay] = useState('');
  const [task, setTask] = useState('');
  const [addTaskModal, setAddTaskModal] = useState(false);

  const [selectedRecurrentTask, setSelectedRecurrentTask] = useState('');
  const [editRecurrentTaskModal, setEditRecurrentTaskModal] = useState(false);

  const [transitModal, setTransitModal] = useState({
    show: false,
    header: '',
    body: '',
    onClick: null
  })

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/deal/${id}/edit`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data

        setSiteCode(data.data.siteCode);
        setAuthUserTeam(data.data.authUserTeam);
        setAllowEdit(data.data.allowEdit);
        setBusinessTypes(data.data.businessTypes);
        setCompanyTypes(data.data.companyTypes);
        setContractTypes(data.data.contractTypes);
        setDealChecksheet(data.data.dealChecksheet);
        setDealStatuses(data.data.dealStatuses);
        setDealStatusesLabels(data.data.dealStatusesLabels);
        setDealTaskStatuses(data.data.dealTaskStatuses);
        setDealTracking(data.data.dealTracking);

        let dealer_members = data.data.dealerMembers;
        dealer_members.map(dealer => {
          dealer.text = dealer.name;
        });
        setDealerMembers(dealer_members);

        setDealerProfile(data.data.dealerProfile);
        setDocuments(data.data.documents);
        setHasAccessToDocumentModule(data.data.hasAccessToDocumentModule);
        setHasPaymentSchedule(data.data.hasPaymentSchedule);
        setIntervals(data.data.intervals);
        setIsAuthorized(data.data.isAuthorized);
        setDeal({
          ...data.data.item,
          deal_date: data.data.item.deal_date ? formatDate(data.data.item.deal_date) : '',
          delivery_date: data.data.item.delivery_date ? formatDate(data.data.item.delivery_date) : '',
          canceled_at: data.data.item.canceled_at ? formatDate(data.data.item.canceled_at) : '',
        });
        setPaymentIntervals(data.data.paymentIntervals);
        setCompany(data.data.item.company);
        setDeliveredStatuses(data.data.deliveredStatuses);
        setDefaultedStatus(data.data.defaultedStatus);
        setDealExpiredStatus(data.data.dealExpiredStatus);
        setCanAccessPaymentsDetails(data.data.canAccessPaymentsDetails);
        setIsAbleToGetPayments(data.data.isAbleToGetPayments);
        setIsEditable(data.data.isEditable);
        setReturnedStatus(data.data.returnedStatus);
        setDeliveredStatus(data.data.deliveredStatus);
        setPaidOutStatus(data.data.paidOutStatus);
        setEarlyTerminatedStatus(data.data.earlyTerminatedStatus);

        setCustomer({
          ...customer,
          id: data.data.item.customer_id,
          first_name: data.data.item.first_name,
          full_name: data.data.item.full_name,
          address: data.data.item.address,
          email: data.data.item.email,
          mobile_number: data.data.item.mobile_number,
          land_line_number: data.data.item.land_line_number,
          postal_code: data.data.item.postal_code,
          national_insurance_number: data.data.item.national_insurance_number,
          driver_license_number: data.data.item.driver_license_number,
        })

        setVehicle({
          ...vehicle,
          id: data.data.item.vehicle_id,
          stock_no: data.data.item.stock_no,
          colour: data.data.item.colour,
          derivative: data.data.item.derivative,
          current_mileage: data.data.item.current_mileage,
        })

        setCheckSheetCount(Object.keys(data.data.dealChecksheet).length)
        dispatch(actions.getNotifications(grs_token));
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  useEffect(() => {
    switch (tab) {
      case 'notes':
        setTimeout(() => {
          setNoteDelay(true);
        }, 1000);        
        break;

      case 'tasks':
        setTimeout(() => {
          setTaskDelay(true);
        }, 1000);        
        break;
    
      default:
        break;
    }
  }, [tab])

  const updateDeal = () => {
    axios({
      method: 'PUT',
      url: constants.API_URL + '/deal/' + id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        deal_status: deal && deal.deal_status ? deal.deal_status : '',
        deal_date: deal && deal.deal_date ? deal.deal_date : '',
        delivery_date: deal && deal.delivery_date ? deal.delivery_date : '',
        canceled_at: deal && deal.canceled_at ? deal.canceled_at : '',
        customer: {
          ...customer,
          company: {
            ...company,
            business_address: company && company.address ? company.address : '',
            business_phone_number: company && company.phone_number ? company.phone_number : '',
            company_name: company && company.name ? company.name : '',
          }
        },
        checksheet: deal.checksheet,
        vehicle: vehicle,
        extras: deal.extras,
        payment_frequency: deal.payment_frequency,
        payment_frequency_interval: deal.payment_frequency_interval,
        initial_payment: deal.initial_payment,
        monthly_payment: deal.monthly_payment,
        expected_profit: deal.expected_profit,
        end_payment: deal.end_payment,
        term: deal.term,
        interval: deal.interval,
        annual_mileage: deal.annual_mileage ? deal.annual_mileage : 0,
        delivery_fee: deal.delivery_fee,
        vehicle_id: vehicle.id,
        customer_id: customer.id,
      }
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setDealErrors('');
        console.error('error :: ', error.response);
        if (error.response.status == 422) {
          setDealErrors(error.response.data.errors);
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const sendDocument = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/doc',
      headers: {
        Authorization: 'Bearer ' + token
      },
      params: document,
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          notify('success', 'Success', data.message);
          setDocuments(data.data.documents);
          setDocumentModal(false);
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.data.message? error.response.data.message: error.response.statusText);
      })
  }

  const viewDocument = (row) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/document/' + row.id,
      headers: {
        Authorization: 'Bearer ' + token
      },
      responseType: 'blob'
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          const disposition = response.request.getResponseHeader('Content-Disposition');
          let fileName = "";
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
          const blob = new Blob([data])
          const downloadUrl = URL.createObjectURL(blob)
          let a = window.document.createElement("a");
          a.href = downloadUrl;
          a.download = fileName;
          window.document.body.appendChild(a);
          a.click();
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.data.message? error.response.data.message: error.response.statusText);
      })
  }

  const deleteDocument = (documentId) => {
    console.log('documentId :: ', documentId);
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/remove/' + documentId,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDeal({
            ...deal,
            document_files: data.data,
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.data.message? error.response.data.message: error.response.statusText);
      })
  }

  const uploadDocument = () => {
    let formData = new FormData();
    for (let index = 0; index < fileLists.length; index++) {
      const element = fileLists[index];
      formData.append(`file[${index}]`, element);
    }

    axios({
      method: 'POST',
      url: constants.API_URL + '/deal/' + id + '/general-documents',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: formData
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDeal({
            ...deal,
            document_files: data.data,
          })
          setFileLists([]);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setDocumentErrors('');
        console.error('error :: ', error);
        if (error.response && error.response.status == 422) {
          let errors = error.response.data.errors;
          setDocumentErrors(errors);
        }
        notify('warning', 'Warning', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const formatDate = (d) => {
    if (d) {
      let date = new Date(d);
      let ye = Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      let mo = Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
      let da = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

      return `${ye}-${mo}-${da}`
    }
  }

  const formatDateTask = (d) => {
    if (d) {
      let date = new Date(d);
      let ye = Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      let mo = Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
      let da = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

      return `${da}/${mo}/${ye}`
    }
  }

  const formatDateNote = (d) => {
    if (d) {
      let date = new Date(d);

      let options = {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: 'numeric', minute: 'numeric', second: '2-digit',
        hour12: false
      }
      
      let da = Intl.DateTimeFormat('en-BG', options).format(date);

      return da
    }
  }

  const addNote = () => {
    if (note == '') {
      notify('info', 'Information', 'Please fill note.')
      return
    }
    axios({
      method: 'POST',
      url: `${constants.API_URL}/deal/${id}/note`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        task: note
      }
    })
      .then(response => {
        setAddNoteModal(false);
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDeal({
            ...deal,
            notes: data.data,
          })
          setNote('');
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setAddNoteModal(false);
        console.error('error :: ', error);
        notify('warning', 'Warning', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const deleteNote = (noteId) => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/deal/${id}/note/${noteId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDeal({
            ...deal,
            notes: data.data,
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        console.error('error :: ', error);
        notify('warning', 'Warning', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const addTask = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/deal/${id}/task`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: task,
    })
      .then(response => {
        setAddTaskModal(false);
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDeal({
            ...deal,
            tasks: data.data.tasks,
            recurrentTasks: data.data.recurrentTasks,
          })
          setTask('');
          dispatch(actions.getNotifications(token));
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setTaskErrors('');
        console.error('error :: ', error.response);
        if (error.response && error.response.status == 422) {
          setTaskErrors(error.response.data.errors)
          return
        }
        setAddTaskModal(false);
        notify('warning', 'Warning', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const taskEdit = (row) => {
    history.push({
      pathname: '/admin/deals/' + id + '/task/' + row.uuid,
    })
  }

  const deleteTask = (taskId) => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/deal/${id}/task/${taskId}`,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDeal({
            ...deal,
            tasks: data.data.tasks,
            recurrentTasks: data.data.recurrentTasks,
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const recurrentTaskUpdate = () => {
    axios({
      method: 'POST',
      url: `${constants.API_URL}/deal/${id}/recurrent-tasks/${selectedRecurrentTask.id}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        ...selectedRecurrentTask,
        task_assignee: selectedRecurrentTask.assignee,
        next_schedule_at: formatDate(selectedRecurrentTask.next_schedule_at),
      },
    })
      .then(response => {
        setTaskErrors('');
        setEditRecurrentTaskModal(false);
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDeal({
            ...deal,
            tasks: data.data.tasks,
            recurrentTasks: data.data.recurrentTasks,
          })
          setSelectedRecurrentTask('');
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setTaskErrors('');
        console.error('error :: ', error.response);
        if (error.response && error.response.status == 422) {
          setTaskErrors(error.response.data.errors)
          return
        }
        setEditRecurrentTaskModal(false);
        notify('warning', 'Warning', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const deleteRecurrentTask = (taskId) => {
    axios({
      method: 'DELETE',
      url: `${constants.API_URL}/deal/${id}/recurrent-tasks/${taskId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setDeal({
            ...deal,
            tasks: data.data.tasks,
            recurrentTasks: data.data.recurrentTasks,
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setDeleteModal({
          ...deleteModal,
          show: false,
        });
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const goPayment = () => {
    history.push({
      pathname: '/admin/deals/' + id + '/payment',
    })
  }

  const getDocument = (docId) => {
    axios({
      method: 'GET',
      url: `${constants.API_URL}/deal/${id}/contract/${docId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob'
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          const disposition = response.request.getResponseHeader('Content-Disposition');
          let fileName = "";
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
          const blob = new Blob([data])
          const downloadUrl = URL.createObjectURL(blob)
          let a = window.document.createElement("a");
          a.href = downloadUrl;
          a.download = fileName;
          window.document.body.appendChild(a);
          a.click();
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.data.message? error.response.data.message: error.response.statusText);
      })
  }

  const viewSigning = (docId) => {
    window.open(constants.BASE_URL + '/deal/' + id + '/' + docId + '/documents/web', '_blank');
  }

  const checkSignedDocument = (docId) => {
    axios({
      method: 'GET',
      url: `${constants.API_URL}/deal/${id}/signed-document/${docId}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob'
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          const disposition = response.request.getResponseHeader('Content-Disposition');
          let fileName = "";
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
          const blob = new Blob([data])
          const downloadUrl = URL.createObjectURL(blob)
          let a = window.document.createElement("a");
          a.href = downloadUrl;
          a.download = fileName;
          window.document.body.appendChild(a);
          a.click();
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.data.message? error.response.data.message: error.response.statusText);
      })
  }

  const defaulter = () => {
    axios({
      method: 'GET',
      url: `${constants.API_URL}/deal/${id}/transit/${defaultedStatus}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setTransitModal({
            ...transitModal,
            show: false
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const expired = () => {
    axios({
      method: 'GET',
      url: `${constants.API_URL}/deal/${id}/transit/${dealExpiredStatus}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const repossessed = () => {
    axios({
      method: 'GET',
      url: `${constants.API_URL}/deal/${id}/transit/${earlyTerminatedStatus}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setTransitModal({
            ...transitModal,
            show: false
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const returned = () => {
    axios({
      method: 'GET',
      url: `${constants.API_URL}/deal/${id}/transit/${returnedStatus}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setTransitModal({
            ...transitModal,
            show: false
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const paidOut = () => {
    axios({
      method: 'GET',
      url: `${constants.API_URL}/deal/${id}/transit/${paidOutStatus}`,
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setTransitModal({
            ...transitModal,
            show: false
          })
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error.response);
        notify('warning', 'Warning', error.response && error.response.statusText? error.response.statusText: 'Error occur.');
      })
  }

  const dealContext = {
    id,
    notify,
    history,
    dispatch,
    profilePermissions,
    csrfToken,
    profileData,
    token, setToken,
    tab, setTab,
    siteCode, setSiteCode,
    authUserTeam, setAuthUserTeam,
    allowEdit, setAllowEdit,
    businessTypes, setBusinessTypes,
    companyTypes, setCompanyTypes,
    contractTypes, setContractTypes,
    dealChecksheet, setDealChecksheet,
    dealStatuses, setDealStatuses,
    dealStatusesLabels, setDealStatusesLabels,
    dealTaskStatuses, setDealTaskStatuses,
    dealTracking, setDealTracking,
    dealerMembers, setDealerMembers,
    dealerProfile, setDealerProfile,
    documents, setDocuments,
    hasAccessToDocumentModule, setHasAccessToDocumentModule,
    hasPaymentSchedule, setHasPaymentSchedule,
    intervals, setIntervals,
    isAuthorized, setIsAuthorized,
    deal, setDeal,
    paymentIntervals, setPaymentIntervals,
    company, setCompany,
    deliveredStatuses, setDeliveredStatuses,
    defaultedStatus, setDefaultedStatus,
    dealExpiredStatus, setDealExpiredStatus,
    returnedStatus, setReturnedStatus,
    deliveredStatus, setDeliveredStatus,
    paidOutStatus, setPaidOutStatus,
    earlyTerminatedStatus, setEarlyTerminatedStatus,
    canAccessPaymentsDetails, setCanAccessPaymentsDetails,
    isAbleToGetPayments, setIsAbleToGetPayments,
    isEditable, setIsEditable,
    customer, setCustomer,
    vehicle, setVehicle,
    checkSheetCount, setCheckSheetCount,
    dealErrors, setDealErrors,
    documentErrors, setDocumentErrors,
    taskErrors, setTaskErrors,
    documentModal, setDocumentModal,
    document, setDocument,
    deleteModal, setDeleteModal,
    fileLists, setFileLists,
    addNoteModal, setAddNoteModal,
    noteDelay, setNoteDelay,
    note, setNote,
    taskDelay, setTaskDelay,
    task, setTask,
    addTaskModal, setAddTaskModal,
    selectedRecurrentTask, setSelectedRecurrentTask,
    editRecurrentTaskModal, setEditRecurrentTaskModal,
    transitModal, setTransitModal,
    updateDeal,
    sendDocument,
    viewDocument,
    deleteDocument,
    uploadDocument,
    formatDate,
    formatDateTask,
    formatDateNote,
    addNote,
    deleteNote,
    addTask,
    taskEdit,
    deleteTask,
    recurrentTaskUpdate,
    deleteRecurrentTask,
    goPayment,
    getDocument,
    viewSigning,
    checkSignedDocument,
    defaulter,
    expired,
    repossessed,
    returned,
    paidOut,
  }

  return <Context.Provider value={dealContext}>{children}</Context.Provider>
}

export const { Consumer } = Context;