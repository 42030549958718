import { takeEvery, put } from 'redux-saga/effects';
import axios from 'axios';
import * as actionTypes from 'reducers/action-types.js';
import * as constants from 'constants/index.js';

export default function* watcherSaga() {
  yield takeEvery(actionTypes.LOGIN, login);
  yield takeEvery(actionTypes.EMPTY_STATE, emptyState);
  yield takeEvery(actionTypes.GET_PROFILE_DATA, getProfileData);
  yield takeEvery(actionTypes.GET_NOTIFICATIONS, getNotifications);
}

/**
 * Login, get passport token to access apis
 */
function* login(action) {
  try {
    let formData = new FormData();

    formData.append('email', action.email);
    formData.append('password', action.password);

    let payload;
    yield axios({
      method: 'POST',
      url: constants.API_URL + '/login',
      data: formData
    })
      .then(response => payload = response.data);
    
    yield put({ type: actionTypes.SET_LOGIN_DATA, payload });
  } catch (error) {
    let payload = error.response.data;
    yield put({ type: actionTypes.SET_LOGIN_DATA, payload });
  }
}

/**
 * Get profile data
 */
function* getProfileData(action) {
  try {
    let user;
    let roles;
    let permissions;
    let authUserTeam;
    let routeCan;
    let csrfToken;
    yield axios({
      method: 'POST',
      url: constants.API_URL + '/detail',
      headers: {
        Authorization: 'Bearer ' + action.token
      }
    })
      .then(response => {
        let data = response.data.data;
        user = data.user;
        roles = data.roles;
        permissions = data.permissions;
        authUserTeam = data.authUserTeam;
        routeCan = data.routeCan;
        csrfToken = data.csrfToken;
      });
    
    yield put({ type: actionTypes.SET_PROFILE_DATA, user });
    yield put({ type: actionTypes.SET_ROLES_DATA, roles });
    yield put({ type: actionTypes.SET_PERMISSIONS_DATA, permissions });
    yield put({ type: actionTypes.SET_AUTH_USER_TEAM, authUserTeam });
    yield put({ type: actionTypes.SET_ROUTE_CAN, routeCan });
    yield put({ type: actionTypes.SET_CSRF_TOKEN, csrfToken });
  } catch (error) {
  }
}

/**
 * Get profile data
 */
function* getNotifications(action) {
  try {
    let actionNotifications;
    let generalNotifications;
    yield axios({
      method: 'GET',
      url: constants.API_URL + '/notifications',
      headers: {
        Authorization: 'Bearer ' + action.token
      }
    })
      .then(response => {
        let data = response.data.data;
        actionNotifications = data.actionNotifications;
        generalNotifications = data.generalNotifications;
      });
    
    yield put({ type: actionTypes.SET_ACTION_NOTIFICATIONS, actionNotifications });
    yield put({ type: actionTypes.SET_GENERAL_NOTIFICATIONS, generalNotifications });
  } catch (error) {
  }
}

/**
 * Initialize all data for logout
 */
function* emptyState() {
  try {
    let payload = {};
    yield put({ type: actionTypes.SET_EMPTY_STATE, payload });
  } catch (error) {
  }
}