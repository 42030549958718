import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Cookies from "js-cookie";
import NotificationAlert from "react-notification-alert";

import * as constants from 'constants/index.js';
import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';
import InputCustom from 'views/pages/components/InputCustom';

import {
  Button,
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
} from "reactstrap";

import {
  create,
  update
} from 'views/policies/RolePolicy';

export default function Role() {
  const history = useHistory();
  const notificationAlert = useRef(null);

  const permissions = useSelector(state => state.getState).permissions;
  const authUserTeam = useSelector(state => state.getState).authUserTeam;
  const roles = useSelector(state => state.getState).roles;
  
  const [token, setToken] = useState('');
  const [data, setData] = useState([]);
  const [superadminRole, setSuperadminRole] = useState('');
  const [addModal, setAddModal] = useState(false);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  
  const [loading, setLoading] = useState(true);

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/role`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        setData(data.data.roles)
        setSuperadminRole(data.data.superadmin_role)

        setTimeout(() => {
          setLoading(false)
        }, 500);
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const editRole = (row) => {
    history.push({
      pathname: '/admin/role/' + row.id + '/edit',
      state: {
        role: row
      }
    });
  }

  const viewRole = (row) => {
    history.push({
      pathname: '/admin/role/' + row.id + '/show',
      state: {
        role: row
      }
    });
  }

  const addRole = () => {
    let data = {
      name: name,
      description: description
    }
    axios({
      method: 'POST',
      url: constants.API_URL + '/role',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: data
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('add data :: ', data);
          notify('success', 'Success', data.message);
          setAddModal(false);

          setTimeout(() => {
            history.push({
              pathname: '/admin/role/' + data.data.id + '/edit',
              state: {
                role: data.data
              }
            });
          }, 1500);
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error);
        if (error.response && error.response.status == 422) {
          setErrors(error.response.data.errors);
          return
        }
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  const formatDate = (date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return da + '/' + mo + '/' + ye;
  }

  return (
    <>
      <CardsHeader name="Admin" parentName="Role Management" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Roles</h1>
                    <p className="text-sm mb-0">System Roles Management.</p>
                  </Col>
                  <Col xs={4} className="text-right">
                    {create(permissions) &&
                      <Button className="w-100" color="success" type="button" onClick={() => setAddModal(true)}>Add role</Button>}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  delay={true}
                  items={data}
                  columns={[
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "description",
                      text: "Description",
                      sort: true
                    },
                    {
                      dataField: "created_at",
                      text: "Creation date",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return formatDate(row.created_at)
                      }
                    },
                    {
                      dataField: "dealer",
                      text: "Belongs to",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.dealer? row.dealer.name: ''
                      }
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                {update(permissions, row, superadminRole, roles, authUserTeam) && 
                                <DropdownItem
                                  onClick={e => editRole(row)}
                                >
                                  Edit
                                </DropdownItem>}
                                <DropdownItem
                                  onClick={e => viewRole(row)}
                                >
                                  View
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </>
                        )
                      }
                    }
                  ]}
                  keyField={`name`}
                  searchField={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={addModal}
          toggle={() => setAddModal(false)}
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body">
            <form className="new-event--form">
              <InputCustom 
                label={`Name`}
                placeholder={`Name`}
                onChange={(e) => setName(e.target.value)}
                invalid={errors && errors.name? true: false}
                errorMessage={errors.name}
              />
              <InputCustom 
                type={`textarea`}
                label={`Description`}
                rows={5}
                placeholder={`Description`}
                onChange={(e) => setDescription(e.target.value)}
                invalid={errors && errors.description? true: false}
                errorMessage={errors.description}
              />
            </form>
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => addRole()}
            >
              Save
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setAddModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  );
}