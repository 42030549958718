import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import TableCustom from 'views/pages/components/TableCustom'

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from 'reactstrap'

export default function Subscription() {
  const notificationAlert = useRef(null)
  const history = useHistory()

  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true);

  const [subscriptions, setSubscriptions] = useState([])
  const [totals, setTotals] = useState('')

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/tracking/subscriptions`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data

        setSubscriptions(data.data.subscriptions)
        setTotals(data.data.totals)

        setTimeout(() => {
          setLoading(false)
        }, 500);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const goList = () => {
    history.push({
      pathname: `/admin/tracking/index`
    })
  }

  return (
    <>
      <CardsHeader
        name="Leads"
        parentName="Leads Management"
        currentName="Create"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Subscriptions</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button
                      className="w-100"
                      color="success"
                      type="button"
                      onClick={() => goList()}
                    >
                      Back to list
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="text-center">
                  <Col md={4} sm={12}>
                    <p
                      className="mb-0"
                      style={{ color: "#000", fontWeight: 700 }}
                    >{`Available Credits`}</p>
                    <h4 className="mb-0">
                      {totals?.total_remaining ? totals?.total_remaining : 0}
                    </h4>
                  </Col>
                  <Col md={4} sm={12}>
                    <p
                      className="mb-0"
                      style={{ color: "#000", fontWeight: 700 }}
                    >{`Used`}</p>
                    <h4 className="mb-0">
                      {totals?.total_used ? totals?.total_used : 0}
                    </h4>
                  </Col>
                  <Col md={4} sm={12}>
                    <p
                      className="mb-0"
                      style={{ color: "#000", fontWeight: 700 }}
                    >{`Total Credits`}</p>
                    <h4 className="mb-0">
                      {totals?.total_value ? totals?.total_value : 0}
                    </h4>
                  </Col>
                </Row>                
                <TableCustom
                  loading={loading}
                  items={subscriptions}
                  columns={[
                    {
                      dataField: "createdAt",
                      text: "Created at",
                      sort: true,
                    },
                    {
                      dataField: "available",
                      text: "Available Credits",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.totalFeatureRemained !== null ? row.totalFeatureRemained : row.totalFeatureValue
                      }
                    },
                    {
                      dataField: "totalFeatureUsed",
                      text: "Used",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.totalFeatureUsed ? row.totalFeatureUsed : 0
                      }
                    },
                    {
                      dataField: "totalFeatureValue",
                      text: "Total Credits",
                      sort: true
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}