import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import Cookies from "js-cookie";

import * as constants from "constants/index.js";
import CardsHeader from "layouts/Headers/CardsHeader.js";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";

export default function Confirmation() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [orderId, setOrderId] = useState("");

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const pOrder = params.get("order");
  const pStatus = params.get("status");

  const [token, setToken] = useState("");

  const notify = (type, header, message) => {
    const options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get("grs_token");
    setToken(grs_token);

    const source = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const response = await axios({
          method: "GET",
          url: `${constants.API_URL}/tracking/credits/confirmation`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
          params: {
            order: pOrder,
            status: pStatus,
          },
        });
        console.log("response :: ", response.data);
        const data = response.data;

        setMessage(data.data.message);
        setStatus(data.data.status);
        setOrderId(data.data.orderId);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled :: ", error);
        } else {
          console.error("error :: ", error);
          notify(
            "warning",
            "Warning",
            error.response && error.response.data
              ? error.response.data.message
              : error.response
              ? error.response.statusText
              : "Error Occurred!"
          );
        }
      }
    };

    fetchData();

    return () => {
      source.cancel();
    };
  }, []);

  const goList = () => {
    history.push({
      pathname: `/admin/tracking/index`,
    });
  };

  return (
    <>
      <CardsHeader
        name="Confirmation"
        parentName="Place subscription credits order"
        currentName="Tracking Dashboard"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Credits order confirmation</h1>
                  </Col>
                  <Col xs={4} className="text-right">
                    <Button
                      className="w-100"
                      color="success"
                      type="button"
                      onClick={() => goList()}
                    >
                      Back to list
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    {status && status === 1 ? (
                      <>
                        <p>
                          <strong>
                            {" "}
                            Thank you for your order, your credits are now
                            available and ready to be used !
                          </strong>
                        </p>
                        <hr />
                        <p>ID : #{orderId}</p>
                        <hr />
                      </>
                    ) : (
                      <>
                        <p>
                          <strong>
                            {" "}
                            Something went wrong processing your request !
                          </strong>
                        </p>
                        <hr />
                      </>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
