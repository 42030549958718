import React from "react";
import { connect } from 'react-redux';
import classnames from "classnames";

import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interaction from "@fullcalendar/interaction";

import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";

import InputCustom from 'views/pages/components/InputCustom';

import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Modal,
  Container,
  Row,
  Col
} from "reactstrap";

import Select2 from "react-select2-wrapper";

import axios from 'axios';
import * as constants from 'constants/index.js';
import Cookies from "js-cookie";

import Pusher from 'pusher-js'

let calendar;

class CalendarView extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    events: [],
    assignees: [],
    alert: null,
    token: '',
    errors: '',
  };
  componentDidMount() {
    if (Cookies.get('grs_token')) {
      let token = Cookies.get('grs_token');
      this.setState({
        token: token
      });

      this.fetchData(token)
    }
  }
  fetchData = (token) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/events',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          let team_users = data.data.auth_team_users;
          team_users.map(user => {
            user.text = user.name;
            delete user.name;
          });
          this.setState({
            events: Array.isArray(data.data.events)? data.data.events: data.data.events.data,
            assignees: team_users
          }, function() {
            this.createCalendar();
          });
        }
      })
      .catch(error => {
        console.error('error :: ', error);
      })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.profileData !== this.props.profileData && this.state.token) {
      const pusher = new Pusher(constants.PUSHER_APP_KEY, {
        cluster: constants.PUSHER_APP_CLUSTER,
        authEndpoint: `${constants.API_URL}/broadcast/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${this.state.token}`
          }
        },
      })

      const that = this
  
      // Document signed
      const channelDealEvents = pusher.subscribe(`private-app.calendar.${that.props.profileData.id}`)
      channelDealEvents.bind(`stuff.calendar.update`, function (data) {
        that.fetchData(that.state.token)
        if (data.notification) {
          that.notify('info', 'Information', data.message)
        }
      })
    }
  }
  createCalendar = () => {
    calendar = new Calendar(this.refs.calendar, {
      navLinks: true,
      plugins: [interaction, dayGridPlugin],
      defaultView: "dayGridMonth",
      selectable: true,
      selectHelper: true,
      editable: true,
      events: this.state.events,
      // Add new event
      dateClick: info => {
        this.setState({
          modalAdd: true,
          startDate: info.dateStr,
          endDate: info.dateStr,
          radios: "bg-info",
          eventAssignee: undefined,
          eventTitle: undefined,
          eventDuration: undefined,
          eventDescription: undefined,
          eventEditable: true,
          errors: '',
        });
      },
      // Edit calendar event action
      eventClick: ({ event }) => {
        this.setState({
          modalChange: true,
          eventId: event.id,
          eventTitle: event.title,
          eventDuration: event.extendedProps.duration,
          eventAssignee: event.extendedProps.assignees,
          eventDescription: event.extendedProps.description,
          eventGoto: event.extendedProps.go_to,
          eventEditable: event.extendedProps.is_editable,
          radios: event.classNames[0],
          event: event,
          errors: ''
        });
      }
    });
    calendar.render();
    this.setState({
      currentDate: calendar.view.title
    });
  };
  changeView = newView => {
    calendar.changeView(newView);
    this.setState({
      currentDate: calendar.view.title,
      gridMode: newView
    });
  };
  addNewEvent = () => {
    let data = {
      event_title: this.state.eventTitle,
      start_date: this.state.startDate,
      duration: this.state.eventDuration,
      description: this.state.eventDescription,
      assignees: this.state.eventAssignee,
      priority: this.state.radios,
      is_all_day: 0
    }

    axios({
      method: 'POST',
      url: constants.API_URL + '/events',
      headers: {
        Authorization: 'Bearer ' + this.state.token
      },
      data: data
    })
      .then(response => {
        if (response.status == 200) {
          this.notify('success', 'Success', 'New event was added successfully.');
          let data = response.data.data;
          console.log('response data :: ', response);

          var newEvents = this.state.events;
          newEvents.push({
            title: this.state.eventTitle,
            start: this.state.startDate,
            end: this.state.endDate,
            className: this.state.radios,
            id: data.id,
            extendedProps: {
              duration: this.state.eventDuration,
              assignees: this.state.eventAssignee,
              description: this.state.eventDescription,
              is_editable: this.state.eventEditable
            }
          });
          calendar.addEvent({
            title: this.state.eventTitle,
            start: this.state.startDate,
            end: this.state.endDate,
            className: this.state.radios,
            id: data.id,
            extendedProps: {
              duration: this.state.eventDuration,
              assignees: this.state.eventAssignee,
              description: this.state.eventDescription,
              is_editable: this.state.eventEditable
            }
          });
          this.setState({
            modalAdd: false,
            events: newEvents,
            startDate: undefined,
            endDate: undefined,
            radios: "bg-info",
            eventTitle: undefined,
            eventAssignee: undefined,
            eventEditable: false
          });
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status == 422) {
          this.setState({
            errors: error.response.data.errors
          })
          return
        }
        this.setState({
          modalAdd: false
        })
        this.notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.')
      })
  };
  changeEvent = () => {
    let data = {
      event_title: this.state.eventTitle,
      start_date: this.state.startDate,
      duration: this.state.eventDuration,
      description: this.state.eventDescription,
      assignees: this.state.eventAssignee,
      priority: this.state.radios,
      is_all_day: 0
    }

    axios({
      method: 'PUT',
      url: constants.API_URL + '/events/' + this.state.eventId,
      headers: {
        Authorization: 'Bearer ' + this.state.token
      },
      data: data
    })
      .then(response => {
        if (response.status == 200) {
          this.notify('success', 'Success', 'Event was updated successfully.');
          let data = response.data.data;
          console.log('response data :: ', data);
    
          var newEvents = this.state.events.map((prop, key) => {
            if (prop.id + "" == this.state.eventId + "") {
              // this.state.event.remove();
              // calendar.addEvent({
              //   ...prop,
              //   title: this.state.eventTitle,
              //   className: this.state.radios,
              //   extendedProps: {
              //     duration: this.state.eventDuration,
              //     assignees: this.state.eventAssignee,
              //     description: this.state.eventDescription,
              //     is_editable: this.state.eventEditable
              //   }
              // });
              return {
                ...prop,
                title: this.state.eventTitle,
                className: this.state.radios,
                extendedProps: {
                  duration: this.state.eventDuration,
                  assignees: this.state.eventAssignee,
                  description: this.state.eventDescription,
                  is_editable: this.state.eventEditable
                }
              };
            } else {
              return prop;
            }
          });
          this.setState({
            modalChange: false,
            events: newEvents,
            radios: "bg-info",
            eventTitle: undefined,
            eventDescription: undefined,
            eventAssignee: undefined,
            eventEditable: false,
            eventId: undefined,
            event: undefined
          });
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status == 422) {
          this.setState({
            errors: error.response.data.errors
          })
          return
        }
        this.setState({
          modalChange: false
        })
        this.notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.')
      })
  };
  deleteEventSweetAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Are you sure?"
          onConfirm={() =>
            this.setState({
              alert: false,
              radios: "bg-info",
              eventTitle: undefined,
              eventDescription: undefined,
              eventDuration: undefined,
              eventAssignee: undefined,
              eventId: undefined
            })
          }
          onCancel={() => this.deleteEvent()}
          confirmBtnCssClass="btn-secondary"
          cancelBtnBsStyle="danger"
          confirmBtnText="Cancel"
          cancelBtnText="Yes, delete it"
          showCancel
          btnSize=""
        >
          You won't be able to revert this!
        </ReactBSAlert>
      )
    });
  };
  deleteEvent = () => {
    axios({
      method: 'DELETE',
      url: constants.API_URL + '/events/' + this.state.eventId,
      headers: {
        Authorization: 'Bearer ' + this.state.token
      },
      data: {}
    })
      .then(response => {
        if (response.status == 200) {    
          var newEvents = this.state.events.filter(
            prop => prop.id + "" !== this.state.eventId
          );
          this.state.event.remove();
          this.setState({
            alert: (
              <ReactBSAlert
                success
                style={{ display: "block" }}
                title="Success"
                onConfirm={() => this.setState({ alert: null })}
                onCancel={() => this.setState({ alert: null })}
                confirmBtnBsStyle="primary"
                confirmBtnText="Ok"
                btnSize=""
              >
                Event was deleted successfully!
              </ReactBSAlert>
            ),
            modalChange: false,
            events: newEvents,
            radios: "bg-info",
            eventTitle: undefined,
            eventDescription: undefined,
            eventAssignee: undefined,
            eventDuration: undefined,
            eventId: undefined,
            event: undefined
          });
        }
      })
      .catch(error => {
        console.error('error :: ', error);
      })
  };
  notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleAssigneeChange = (e) => {
    let value = Array.from(e.target.selectedOptions, option => option.value);
    this.setState({eventAssignee: value});
  }

  checkResource = (go_to) => {
    this.props.history.push(go_to)
  }

  render() {
    return (
      <>
        {this.state.alert}        
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="header header-dark content__title content__title--calendar">
          <Container fluid className="px-0">
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="fullcalendar-title h2 text-black d-inline-block mb-0 mr-1">
                    {this.state.currentDate}
                  </h6>
                </Col>
                <Col className="mt-3 mt-md-0 text-md-right" lg="6">
                  <Button
                    className="fullcalendar-btn-prev btn-neutral"
                    color="default"
                    onClick={() => {
                      calendar.prev();
                    }}
                    size="sm"
                  >
                    <i className="fas fa-angle-left" />
                  </Button>
                  <Button
                    className="fullcalendar-btn-next btn-neutral"
                    color="default"
                    onClick={() => {
                      calendar.next();
                    }}
                    size="sm"
                  >
                    <i className="fas fa-angle-right" />
                  </Button>
                  <Button
                    className="btn-neutral"
                    color="default"
                    data-calendar-view="month"
                    onClick={() => this.changeView("dayGridMonth")}
                    size="sm"
                  >
                    Month
                  </Button>
                  <Button
                    className="btn-neutral"
                    color="default"
                    data-calendar-view="basicWeek"
                    onClick={() => this.changeView("dayGridWeek")}
                    size="sm"
                  >
                    Week
                  </Button>
                  <Button
                    className="btn-neutral"
                    color="default"
                    data-calendar-view="basicDay"
                    onClick={() => this.changeView("dayGridDay")}
                    size="sm"
                  >
                    Day
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--6" fluid className="px-0">
          <Row>
            <div className="col">
              <Card className="card-calendar">
                <CardHeader>
                  <h5 className="h3 mb-0">Calendar</h5>
                </CardHeader>
                <CardBody className="p-0">
                  <div
                    className="calendar"
                    data-toggle="calendar"
                    id="calendar"
                    ref="calendar"
                  />
                </CardBody>
              </Card>
              <Modal
                isOpen={this.state.modalAdd}
                toggle={() => this.setState({ modalAdd: false })}
                className="modal-dialog-centered modal-secondary"
              >
                <div className="modal-body">
                  <form className="new-event--form">
                    <InputCustom 
                      label={`Event title`}
                      placeholder={`Event title`}
                      onChange={e =>
                        this.setState({ eventTitle: e.target.value })
                      }
                      invalid={this.state.errors && this.state.errors.event_title? true: false}
                      errorMessage={this.state.errors.event_title}
                    />
                    <InputCustom 
                      type={`number`}
                      label={`Duration (minutes for hourly events or days for all day events)`}
                      placeholder={`Durations`}
                      onChange={e =>
                        this.setState({ eventDuration: e.target.value })
                      }
                      invalid={this.state.errors && this.state.errors.duration? true: false}
                      errorMessage={this.state.errors.duration}
                    />
                    <InputCustom 
                      type={`textarea`}
                      rows={5}
                      label={`Description`}
                      placeholder={`Event Desctiption`}
                      onChange={e =>
                        this.setState({ eventDescription: e.target.value })
                      }
                    />
                    <FormGroup>
                      <label className="form-control-label">Assignee</label>
                      <Select2
                        className="form-control"
                        multiple
                        value={this.state.eventAssignee}
                        data={this.state.assignees}
                        onChange={(e) => this.handleAssigneeChange(e)}
                      />
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <label className="form-control-label d-block mb-3">
                        Status color
                      </label>
                      <ButtonGroup
                        className="btn-group-toggle btn-group-colors event-tag"
                        data-toggle="buttons"
                      >
                        <Button
                          className={classnames("bg-info", {
                            active: this.state.radios === "bg-info"
                          })}
                          color=""
                          type="button"
                          onClick={() => this.setState({ radios: "bg-info" })}
                        />
                        <Button
                          className={classnames("bg-warning", {
                            active: this.state.radios === "bg-warning"
                          })}
                          color=""
                          type="button"
                          onClick={() =>
                            this.setState({ radios: "bg-warning" })
                          }
                        />
                        <Button
                          className={classnames("bg-danger", {
                            active: this.state.radios === "bg-danger"
                          })}
                          color=""
                          type="button"
                          onClick={() => this.setState({ radios: "bg-danger" })}
                        />
                        <Button
                          className={classnames("bg-success", {
                            active: this.state.radios === "bg-success"
                          })}
                          color=""
                          type="button"
                          onClick={() =>
                            this.setState({ radios: "bg-success" })
                          }
                        />
                        <Button
                          className={classnames("bg-default", {
                            active: this.state.radios === "bg-default"
                          })}
                          color=""
                          type="button"
                          onClick={() =>
                            this.setState({ radios: "bg-default" })
                          }
                        />
                        <Button
                          className={classnames("bg-primary", {
                            active: this.state.radios === "bg-primary"
                          })}
                          color=""
                          type="button"
                          onClick={() => {
                            this.setState({ radios: "bg-primary" });
                          }}
                        />
                      </ButtonGroup>
                    </FormGroup>
                  </form>
                </div>
                <div className="modal-footer">
                  <Button
                    className="new-event--add"
                    color="primary"
                    type="button"
                    onClick={this.addNewEvent}
                  >
                    Add event
                  </Button>
                  <Button
                    className="ml-auto"
                    color="link"
                    type="button"
                    onClick={() => this.setState({ modalAdd: false })}
                  >
                    Close
                  </Button>
                </div>
              </Modal>
              <Modal
                isOpen={this.state.modalChange}
                toggle={() => this.setState({ modalChange: false })}
                className="modal-dialog-centered modal-secondary"
              >
                <div className="modal-body">
                  <Form className="edit-event--form">
                    <InputCustom 
                      label={`Event title`}
                      placeholder={`Event Title`}
                      value={this.state.eventTitle? this.state.eventTitle: ''}
                      onChange={e =>
                        this.setState({ eventTitle: e.target.value })
                      }
                      invalid={this.state.errors && this.state.errors.event_title? true: false}
                      errorMessage={this.state.errors.event_title}
                    />
                    <InputCustom 
                      type={`number`}
                      label={`Duration (minutes for hourly events or days for all day events)`}
                      placeholder={`Durations`}
                      value={this.state.eventDuration? this.state.eventDuration: ''}
                      onChange={e =>
                        this.setState({ eventDuration: e.target.value })
                      }
                      invalid={this.state.errors && this.state.errors.duration? true: false}
                      errorMessage={this.state.errors.duration}
                    />
                    <InputCustom 
                      type={`textarea`}
                      rows={5}
                      label={`Description`}
                      placeholder={`Event Desctiption`}
                      value={this.state.eventDescription? this.state.eventDescription: ''}
                      onChange={e =>
                        this.setState({ eventDescription: e.target.value })
                      }
                    />
                    <FormGroup>
                      <label className="form-control-label">Assignee</label>
                      <Select2
                        className="form-control"
                        multiple
                        value={this.state.eventAssignee}
                        data={this.state.assignees}
                        onChange={(e) => this.handleAssigneeChange(e)}
                      />
                    </FormGroup>
                    {this.state.eventEditable && 
                    <FormGroup>
                      <label className="form-control-label d-block mb-3">
                        Status color
                      </label>
                      <ButtonGroup
                        className="btn-group-toggle btn-group-colors event-tag mb-0"
                        data-toggle="buttons"
                      >
                        <Button
                          className={classnames("bg-info", {
                            active: this.state.radios === "bg-info"
                          })}
                          color=""
                          type="button"
                          onClick={() => this.setState({ radios: "bg-info" })}
                        />
                        <Button
                          className={classnames("bg-warning", {
                            active: this.state.radios === "bg-warning"
                          })}
                          color=""
                          type="button"
                          onClick={() =>
                            this.setState({ radios: "bg-warning" })
                          }
                        />
                        <Button
                          className={classnames("bg-danger", {
                            active: this.state.radios === "bg-danger"
                          })}
                          color=""
                          type="button"
                          onClick={() => this.setState({ radios: "bg-danger" })}
                        />
                        <Button
                          className={classnames("bg-success", {
                            active: this.state.radios === "bg-success"
                          })}
                          color=""
                          type="button"
                          onClick={() =>
                            this.setState({ radios: "bg-success" })
                          }
                        />
                        <Button
                          className={classnames("bg-default", {
                            active: this.state.radios === "bg-default"
                          })}
                          color=""
                          type="button"
                          onClick={() =>
                            this.setState({ radios: "bg-default" })
                          }
                        />
                        <Button
                          className={classnames("bg-primary", {
                            active: this.state.radios === "bg-primary"
                          })}
                          color=""
                          type="button"
                          onClick={() => {
                            this.setState({ radios: "bg-primary" });
                          }}
                        />
                      </ButtonGroup>
                    </FormGroup>}
                    <input className="edit-event--id" type="hidden" />
                  </Form>
                </div>
                <div className="modal-footer">
                  {this.state.eventEditable && 
                  <Button color="primary" onClick={this.changeEvent}>
                    Update
                  </Button>}
                  {this.state.eventEditable && 
                  <Button
                    color="danger"
                    onClick={() =>
                      this.setState({ modalChange: false }, () =>
                        this.deleteEventSweetAlert()
                      )
                    }
                  >
                    Delete
                  </Button>}
                  {this.state.eventGoto && 
                  <Button color="danger" onClick={() => this.checkResource(this.state.eventGoto)}>
                    Check Resource
                  </Button>}
                  <Button
                    className="ml-auto"
                    color="link"
                    onClick={() => this.setState({ modalChange: false })}
                  >
                    Close
                  </Button>
                </div>
              </Modal>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    authUserTeam: state.getState.authUserTeam,
    profileData: state.getState.profileData,
  }
}

export default connect(
  mapStateToProps
)(CalendarView)