import React, { useEffect, useState, useRef } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import actions from "actions/index.js";
import * as constants from 'constants/index.js';
import NotificationAlert from "react-notification-alert"
import moment from 'moment'

import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  Nav,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Badge,
} from "reactstrap";

import Pusher from 'pusher-js'

export default function AdminNavbar(props) {
  const profileData = useSelector((state) => state.getState).profileData;
  const actionNotifications = useSelector((state) => state.getState).actionNotifications;
  const generalNotifications = useSelector((state) => state.getState).generalNotifications;
  const dispatch = useDispatch();
  const history = useHistory();
  const notificationAlert = useRef(null)

  const [token, setToken] = useState('')

  const data = profileData? profileData: { data: {name: '', email: '', picture: ''} };

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    }
    notificationAlert.current.notificationAlert(options)
  }
  
  const pullAsyncData = () => {
    if (Cookies.get('grs_token')) {
      const grs_token = Cookies.get('grs_token');
      setToken(grs_token)
      dispatch(actions.getProfileData(grs_token));
      dispatch(actions.getNotifications(grs_token));
    }
  }

  useEffect(() => {
    pullAsyncData()
  }, [])

  /**
   * PUSHER
   */
  useEffect(() => {
    if (profileData && profileData.id && token) {
      const pusher = new Pusher(constants.PUSHER_APP_KEY, {
        cluster: constants.PUSHER_APP_CLUSTER,
        authEndpoint: `${constants.API_URL}/broadcast/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        },
      })
  
      // Document signed
      const channelDealEvents = pusher.subscribe(`private-deal-events.${profileData.id}`)
      channelDealEvents.bind(`app.deal.document`, function (data) {
        dispatch(actions.getNotifications(token));
        notify('info', 'Information', data.message)
      })
      
      // Receive Application
      const channelApplication = pusher.subscribe(`private-app.notifications.dealer.${profileData.id}`)
      channelApplication.bind(`dealer.notification.lead`, function (data) {
        dispatch(actions.getNotifications(token));
        notify('info', 'Information', data.message)
      })
      
      // Receive short Application
      const channelShortApplication = pusher.subscribe(`private-app.short.notifications.dealer.${profileData.id}`)
      channelShortApplication.bind(`dealer.short.notification.lead`, function (data) {
        dispatch(actions.getNotifications(token));
        notify('info', 'Information', data.message)
      })
    }
  }, [profileData, token])

  const logout = () => {
    dispatch(actions.emptyState());
    Cookies.remove('loggedin');
    Cookies.remove('grs_token');
    history.push('/');
  }

  const goProfile = () => {
    history.push('/admin/profile');
  }

  const goViewAll = () => {
    history.push({
      pathname: '/admin/all-notifications'
    })
  }

  const goView = (link) => {
    history.push({
      pathname: link
    })
  }

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-info": props.theme === "dark" },
          { "navbar-light bg-secondary": props.theme === "light" }
        )}
      >
        <div className="rna-wrapper">
          <NotificationAlert ref={notificationAlert} />
        </div>
        <Container fluid>
          <Collapse navbar isOpen={true}>

            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: props.sidenavOpen },
                    { "sidenav-toggler-dark": props.theme === "dark" }
                  )}
                  onClick={props.toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link cursor-pointer" color="" tag="a">
                  <i className="ni ni-bell-55" />
                  {generalNotifications && generalNotifications.count > 0 && 
                  <Badge color="danger" className="badge-md badge-circle badge-floating border-white">{generalNotifications.count}</Badge>}
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-xl py-0 overflow-hidden"
                  right
                >
                  <div className="px-3 py-3">
                    <h6 className="text-sm text-muted m-0">
                      You have <strong className="text-info">{generalNotifications && generalNotifications.count}</strong>{" "}
                      notifications.
                    </h6>
                  </div>
                  <ListGroup flush>
                    {generalNotifications && generalNotifications.items && 
                      generalNotifications.items.map((notification, index) => {
                        return (
                          <ListGroupItem
                            className="list-group-item-action cursor-pointer"
                            href=""
                            onClick={() => goView(notification.link)}
                            key={index}
                          >
                            <Row className="align-items-center px-4">
                              <div className="col">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h4 className="mb-0 text-sm">{notification.type}</h4>
                                  </div>
                                  <div>
                                    <span className="mb-0 text-sm">{notification.date ? moment(notification.date).format('DD/MM/YYYY HH:mm:ss') : ''}</span>
                                  </div>
                                </div>
                                <p className="text-sm mb-0">
                                  {notification.message}
                                </p>
                              </div>
                            </Row>
                          </ListGroupItem>
                        )
                      })
                    }
                  </ListGroup>
                  <DropdownItem
                    className="text-center text-info font-weight-bold py-3"
                    onClick={e => goViewAll()}
                  >
                    View all
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link cursor-pointer" color="" tag="a">
                  <i className="ni ni-bullet-list-67" />
                  {actionNotifications && actionNotifications.length > 0 && 
                  <Badge color="danger" className="badge-md badge-circle badge-floating border-white">{actionNotifications.length}</Badge>}
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-xl py-0 overflow-hidden"
                  right
                >
                  <div className="px-3 py-3">
                    <h6 className="text-sm text-muted m-0">
                      You have <strong className="text-info">{actionNotifications && actionNotifications.length}</strong>{" "}
                    notifications.
                  </h6>
                  </div>
                  <ListGroup flush>
                    {actionNotifications &&
                      actionNotifications.map((notification, index) => {
                        return (
                          <ListGroupItem
                            className="list-group-item-action cursor-pointer"
                            href=""
                            onClick={() => goView(notification.link)}
                            key={index}
                          >
                            <Row className="align-items-center px-4">
                              <div className="col">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h4 className="mb-0 text-sm">{notification.type}</h4>
                                  </div>
                                </div>
                                <p className="text-sm mb-0">
                                  {notification.message}
                                </p>
                              </div>
                            </Row>
                          </ListGroupItem>
                        )
                      })
                    }
                  </ListGroup>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a" style={{cursor: 'pointer'}}>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      {data.picture? (<img
                        alt={data.name? data.name.slice(0, 2): '' }
                        src={ constants.STORAGE_URL + data.picture}
                      />)
                      : (data.name? data.name.slice(0, 2): '')}
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {data.name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => goProfile()}
                  >
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={() => logout()}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark"
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"])
};