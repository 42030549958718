import React, { useState, useRef, useEffect } from 'react';

import Cookies from 'js-cookie';
import axios from 'axios';
import * as constants from 'constants/index.js';
import NotificationAlert from "react-notification-alert";

import CardsHeader from 'layouts/Headers/CardsHeader.js';

import TableCustom from 'views/pages/components/TableCustom';
import InputCustom from 'views/pages/components/InputCustom';

import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Button,
  Row,
  Col
} from "reactstrap";

export default function RuleOf78Calculator() {
  const notificationAlert = useRef(null);

  const [token, setToken] = useState('');

  const [amount, setAmount] = useState('');
  const [rate, setRate] = useState('');
  const [years, setYears] = useState('');

  const [data, setData] = useState('');
  const [detailedTable, setDetailedTable] = useState([]);

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {    
    if (Cookies.get('grs_token')) {
      let token = Cookies.get('grs_token');
      setToken(token);
    }
  })

  const calculate = () => {    
    let data = {
      amount: amount,
      rate: rate,
      years: years
    }

    axios({
      method: 'POST',
      url: constants.API_URL + '/ruleof78calculate',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: data
    })
      .then(response => {
        if (response.status == 200) {
          setErrors('');
          let data = response.data;
          console.log('response data :: ', data);
          setData(data.data);
          setDetailedTable(data.data.detailedTable);
          
          notify('success', 'Success', 'Calculated successfully.');
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response.status = 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
          return
        }
        notify('warning', 'Error', error.response.statusText);
      })
  }

  return (
    <>
      <CardsHeader name="Admin" parentName="Rule of 78 calcultor" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title mb-0">{`Rule of 78 calculator`}</h1>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader>
                <h1 className="title mb-0 text-center">{`Rule of 78 estimation based on: loan amount, rate, and number of years`}</h1>
              </CardHeader>
              <CardBody>
                <Row className="justify-content-center">
                  <h2 className="title mb-0 text-center">{`Inputs`}</h2>
                </Row>
                <Row className="mt-5">
                  <Col md="4">
                    <InputCustom 
                      type={`number`}
                      label={`Loan Amount`}
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      invalid={errors && errors.amount? true: false}
                      errorMessage={errors.amount}
                    />
                  </Col>
                  <Col md="4">
                    <InputCustom 
                      type={`number`}
                      label={`Rate`}
                      value={rate}
                      onChange={(e) => setRate(e.target.value)}
                      invalid={errors && errors.rate? true: false}
                      errorMessage={errors.rate}
                    />
                  </Col>
                  <Col md="4">
                    <InputCustom 
                      type={`number`}
                      label={`No of years`}
                      value={years}
                      onChange={(e) => setYears(e.target.value)}
                      invalid={errors && errors.years? true: false}
                      errorMessage={errors.rate}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md="12" className="d-flex justify-content-center">
                    <Button block color="success" type="button" onClick={() => calculate()} style={{ width: '200px' }}>
                      Calculate
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {detailedTable.length > 0 && <Card>
              <CardHeader>
                <h1 className="mb-0">Vehicle Cost</h1>
                <div className="d-flex justify-content-around">
                  <div className="w-20 p-3 text-center">
                    <h2 className="mb-0">Vehicle Cost</h2>
                    <span className="mb-0">${data.totalPayment}</span>
                  </div>
                  <div className="w-20 p-3 text-center">
                    <h2 className="mb-0">Total Interest</h2>
                    <span className="mb-0">${data.totalInterest}</span>
                  </div>
                  <div className="w-20 p-3 text-center">
                    <h2 className="mb-0">Total Monthly Payments</h2>
                    <span className="mb-0">{data.numberOfPayments}</span>
                  </div>
                  <div className="w-20 p-3 text-center">
                    <h2 className="mb-0">Monthly Payment</h2>
                    <span className="mb-0">${data.monthlyPayment}</span>
                  </div>
                  <div className="w-20 p-3 text-center">
                    <h2 className="mb-0">End Payment</h2>
                    <span className="mb-0">${data.endPayment?? 0}</span>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={false}
                  items={detailedTable}
                  keyField={`month`}
                  searchField={true}
                  columns={[
                    {
                      dataField: "month",
                      text: "Payment Month",
                      sort: true
                    },
                    {
                      dataField: "beginning_balance",
                      text: "Beginning Balance",
                      sort: true
                    },
                    {
                      dataField: "interest_payment",
                      text: "Interest Payment",
                      sort: true
                    },
                    {
                      dataField: "paid_off_vehicle",
                      text: "Paid off Vehicle",
                      sort: true
                    },
                    {
                      dataField: "current_balance",
                      text: "Current Balance",
                      sort: true
                    },
                    {
                      dataField: "interest_paid_to_date",
                      text: "Interest Paid Todate",
                      sort: true
                    },
                    {
                      dataField: "total_amount_paid",
                      text: "Total Amount Paid",
                      sort: true
                    }
                  ]}
                />
              </CardBody>
            </Card>}
          </Col>
        </Row>
      </Container>
    </>
  );
}