import React, { useContext, useCallback, useMemo } from 'react';
import { useDropzone } from "react-dropzone";

import { DealContext } from '../context';

import TableCustom from 'views/pages/components/TableCustom';

import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out"
};

const activeStyle = {
  borderColor: "#2196f3"
};

const acceptStyle = {
  borderColor: "#00e676"
};

const rejectStyle = {
  borderColor: "#ff1744"
};

export default function UploadTab() {
  const dealContext = useContext(DealContext);
  const {
    deal,
    deleteModal, setDeleteModal,
    fileLists, setFileLists,
    viewDocument,
    deleteDocument,
    uploadDocument,
  } = dealContext;

  const onDrop = useCallback(acceptedFiles => {
    setFileLists(prev => [...prev, ...acceptedFiles]);
  })
  
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({onDrop});

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <CardBody>
      <Card>
        <CardHeader>
          <h2>Documents</h2>
        </CardHeader>
        <CardBody>
          <div className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p>Select or drop the documents, then match each of them to the right type</p>
            </div>
            <aside>
              {fileLists.map((item, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: '1px solid #CCC',
                      alignItems: 'center',
                      padding: 20,
                    }}
                    key={index}
                  >
                    <Col md={3} sm={6}>
                      <span>{item.name}</span>
                    </Col>
                    <Col md={3} sm={6}>
                      <span>{`${item.size} bytes`}</span>
                    </Col>
                  </div>
                )
              })}
            </aside>
          </div>
          <div className="d-flex flex-row justify-content-center mt-3">
            <Button className="w-25" color="success" onClick={() => uploadDocument()}>Save</Button>
          </div>
          <h2 className="text-center mt-5">Uploaded documents</h2>
          {deal.document_files &&
            <TableCustom
              loading={false}
              items={deal.document_files}
              keyField={`name`}
              searchField={true}
              columns={[
                {
                  dataField: "name",
                  text: "Name",
                  sort: true,
                  formatter: (rowContent, row) => {
                    return `Document - ${row.name}`
                  }
                },
                {
                  dataField: "",
                  text: "Action",
                  formatter: (rowContent, row) => {
                    return (
                      <>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            color=""
                            role="button"
                            size="sm"
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              onClick={() => viewDocument(row)}
                            >
                              {`View`}
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                setDeleteModal({
                                  ...deleteModal,
                                  show: true,
                                  header: 'Delete document',
                                  body: 'Are you sure to delete this document?',
                                  onClick: () => deleteDocument(row.id)
                                })
                              }}
                            >
                              {`Delete`}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </>
                    )
                  }
                }
              ]}
            />}
        </CardBody>
      </Card>
    </CardBody>
  );
}