import React, { useRef, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import Cookies from 'js-cookie';
import axios from 'axios';
import * as constants from 'constants/index.js';
import moment from 'moment';

import CardsHeader from 'layouts/Headers/CardsHeader.js';

import InputCustom from 'views/pages/components/InputCustom';
import { InputKeySelect } from 'views/pages/components/InputSelect';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  UncontrolledAlert,
  FormGroup,
} from "reactstrap";

export default function Payment() {
  const { id } = useParams();
  const notificationAlert = useRef(null);
  const history = useHistory();

  const [token, setToken] = useState('');
  
  const [deal, setDeal] = useState('');
  const [hasBeenGenerated, setHasBeenGenerated] = useState(true);
  const [isPaymentActive, setIsPaymentActive] = useState(true);
  const [payments, setPayments] = useState([]);
  const [minDate, setMinDate] = useState('');
  const [paymentDescriptors, setPaymentDescriptors] = useState('');
  const [paymentIntervals, setPaymentIntervals] = useState('');
  const [paymentProcessTypes, setPaymentProcessTypes] = useState('');
  const [paymentStatuses, setPaymentStatuses] = useState('');
  const [paymentStatusesGroup, setPaymentStatusesGroup] = useState('');
  const [totalCollected, setTotalCollected] = useState('');
  const [totalPayments, setTotalPayments] = useState('');
  const [isAbleToCreateDeals, setIsAbleToCreateDeals] = useState(false);

  const [paymentChangesHistory, setPaymentChangesHistory] = useState([]);
  const [preferences, setPreferences] = useState('');

  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState(false);

  const [saveButtonDisable, setSaveButtonDisable] = useState(true);
  const [confirmRescheduleModal, setConfirmRescheduleModal] = useState(false);

  const [flag, setFlag] = useState(false);

  const [errors, setErrors] = useState('');

  const [message, setMessage] = useState('');
  const [changeMinDate, setChangeMinDate] = useState('');
  const [viewChangeDetail, setViewChangeDetail] = useState(false);

  const [paymentDetailModal, setPaymentDetailModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState('');
  const [paymentStatusPaidOut, setPaymentStatusPaidOut] = useState('');
  const [paymentStatusFailed, setPaymentStatusFailed] = useState('');
  const [paymentStatusNew, setPaymentStatusNew] = useState('');
  const [paymentStatusCreated, setPaymentStatusCreated] = useState('');
  const [processTypeDirectDebit, setProcessTypeDirectDebit] = useState('');
  const [paymentEdit, setPaymentEdit] = useState('');

  const [savePaymentErrors, setSavePaymentErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    let grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/deal/${id}/payments`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data        

        setDeal({
          ...data.data.deal,
          next_payment: data.data.deal.next_payment ? constants.formatDate(data.data.deal.next_payment) : '',
        });
        setHasBeenGenerated(data.data.hasBeenGenerated);
        setIsPaymentActive(data.data.isPaymentActive);
        setPayments(data.data.items);
        setMinDate(data.data.minDate);
        setPaymentDescriptors(data.data.paymentDescriptors);
        setPaymentIntervals(data.data.paymentIntervals);
        setPaymentProcessTypes(data.data.paymentProcessTypes);
        setPaymentStatuses(data.data.paymentStatuses);
        setPaymentStatusesGroup(data.data.paymentStatusesGroup);
        setTotalCollected(data.data.totalCollected);
        setTotalPayments(data.data.totalPayments);
        setIsAbleToCreateDeals(data.data.isAbleToCreateDeals);

        setTimeout(() => {
          setLoading(false);
          setDelay(true);
        }, 1000);
      } catch (error) {
        setLoading(false);
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
        console.error('error :: ', error)
        notify('warning', 'Warning', error.response && error.response.data ? error.response.data.message : error.response ? error.response.statusText : 'Error Occurred!')
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const goDeal = () => {
    history.push({
      pathname: '/admin/deals/' + id + '/edit',
    })
  }

  const getHistory = () => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/payments-history',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);

          setPaymentChangesHistory(data.data.items);
          setPreferences(data.data.preferences);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const regeneratePaymentSchedule = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/deal/' + id + '/update-payment',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        payment_frequency: String(deal.payment_frequency),
        payment_frequency_interval: deal.payment_frequency_interval,
        term: String(deal.term),
        interval: deal.interval,
        monthly_payment: String(deal.monthly_payment),
        next_payment: deal.next_payment,
        last_payment: deal.last_payment,
        consent: deal.consent,
      },
    })
      .then(response => {
        setErrors('');
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setPayments(data.data.items);
          setTotalCollected(data.data.totalCollected);
          setTotalPayments(data.data.totalPayments);
          setIsPaymentActive(data.data.isPaymentActive);
          setHasBeenGenerated(data.data.hasBeenGenerated);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error.response);
        if (error.response.status == 422) {
          setErrors(error.response.data.errors);
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const resetPaymentSchedule = () => {
    history.push({
      pathname: '/admin/deals/' + id + '/payment',
    })
  }

  useEffect(() => {
    if (flag) {
      let body = {
        payment_frequency: deal.payment_frequency,
        payment_frequency_interval: deal.payment_frequency_interval,
        monthly_payment: deal.monthly_payment,
        term: deal.term,
        interval: deal.interval,
        next_payment: deal.next_payment,
      }
      axios({
        method: 'POST',
        url: constants.API_URL + '/deal/' + id + '/edit-calculator',
        headers: {
          Authorization: 'Bearer ' + token
        },
        data: body,
      })
        .then(response => {
          if (response.status == 200) {
            setErrors('');
            let data = response.data;
            console.log('response data :: ', data);

            setMessage(data.data.message);
            setChangeMinDate(data.data.minDate);
            setDeal({
              ...deal,
              next_payment: constants.formatDate(data.data.nextPayment),
              last_payment: constants.formatDate(data.data.lastPayment)
            })

            setTimeout(() => {
              setViewChangeDetail(true);
              setConfirmRescheduleModal(true);
            }, 2000);
          }
        })
        .catch(error => {
          setErrors('');
          console.error('error :: ', error.response);
          if (error.response.status == 422) {
            setErrors(error.response.data.errors);
          }
          notify('warning', 'Warning', error.response.data.message? error.response.data.message: error.response.statusText);
        })
    }
  }, [
    deal.payment_frequency,
    deal.payment_frequency_interval,
    deal.term,
    deal.interval,
    deal.monthly_payment,
    deal.next_payment,
  ])

  useEffect(() => {
    if (deal.consent == 1) {
      setSaveButtonDisable(false)
    }
  }, [deal.consent])

  const paymentShow = (row) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/payments/' + row.id,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        setErrors('');
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setSelectedPayment(data.data.item);
          setPaymentStatuses(data.data.paymentStatuses);
          setPaymentProcessTypes(data.data.paymentProcessTypes);
          setPaymentStatusPaidOut(data.data.paymentStatusPaidOut);
          setPaymentStatusFailed(data.data.paymentStatusFailed);
          setPaymentStatusNew(data.data.paymentStatusNew);
          setPaymentStatusCreated(data.data.paymentStatusCreated);
          setProcessTypeDirectDebit(data.data.processTypeDirectDebit);

          setPaymentEdit({
            ...paymentEdit,
            scheduled_at: data.data.item && data.data.item.reference? constants.formatDate(data.data.item.reference.charge_date): constants.formatDate(data.data.item.scheduled_at),
            amount: data.data.item && data.data.item.reference? data.data.item.reference.amount: data.data.item.amount,
            process_type: data.data.item && data.data.item.reference? data.data.item.reference.process_type: '',
            payment_reference_id: data.data.item? data.data.item.id: '',
            collect_now: 0,
          })

          setPaymentDetailModal(true);
        }
      })
      .catch(error => {
        setErrors('');
        console.error('error :: ', error.response);
        if (error.response.status == 422) {
          setErrors(error.response.data.errors);
        }
        notify('warning', 'Warning', error.response.statusText);
      })
  }

  const savePayment = () => {
    axios({
      method: 'POST',
      url: constants.API_URL + '/deal/' + id + '/save-reference',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: paymentEdit,
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setPaymentEdit('');
          setSelectedPayment('');
          setPaymentDetailModal(false);
          setPayments(data.data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status == 422) {
          let errors = error.response.data.errors;
          let err = '';
          Object.keys(errors).map(key => {
            err += errors[key];
          })
          setSavePaymentErrors(err);
        } else if (error.response && error.response.status == 406) {
          let errors = error.response.data.errors;
          let err = errors.message;
          setSavePaymentErrors(err);
        }
      }) 
  }

  const cancelPayment = (referenceId) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/cancel/' + referenceId,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setPaymentEdit('');
          setSelectedPayment('');
          setPaymentDetailModal(false);
          setPayments(data.data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status == 422) {
          let errors = error.response.data.errors;
          let err = '';
          Object.keys(errors).map(key => {
            err += errors[key];
          })
          setSavePaymentErrors(err);
        } else if (error.response && error.response.status == 406) {
          let errors = error.response.data.errors;
          let err = errors.message;
          setSavePaymentErrors(err);
        }
      }) 
  }

  const retryPayment = (referenceId) => {
    axios({
      method: 'GET',
      url: constants.API_URL + '/deal/' + id + '/retry/' + referenceId,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
      .then(response => {
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          setPaymentEdit('');
          setSelectedPayment('');
          setPaymentDetailModal(false);
          setPayments(data.data);
          notify('success', 'Success', data.message);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response && error.response.status == 422) {
          let errors = error.response.data.errors;
          let err = '';
          Object.keys(errors).map(key => {
            err += errors[key];
          })
          setSavePaymentErrors(err);
        } else if (error.response && error.response.status == 406) {
          let errors = error.response.data.errors;
          let err = errors.message;
          setSavePaymentErrors(err);
        }
      }) 
  }

  return (
    <>
      <CardsHeader
        name="Deal"
        parentName="Payment management"
        currentName="List"
      />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--5 admin-main-body" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="title mb-0">{`All payments associated to current deal`}</h1>
                  </div>
                  <Button
                    className="h-50"
                    color="success"
                    type="button"
                    onClick={() => goDeal()}
                  >
                    Deal
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <Row className="py-3">
                  <Col md={3}>
                    <h2>Contract Interval</h2>
                  </Col>
                  <Col md={9}>
                    <h3 className="text-danger">
                      {deal.delivery_date
                        ? `${constants.formatDateShortGB(deal.delivery_date)} - 
                         ${constants.formatDateShortGB(
                           moment(deal.delivery_date).add(
                             deal.term,
                             deal.interval + "s"
                           )
                         )}`
                        : ""}
                    </h3>
                  </Col>
                </Row>
                <Row className="py-3">
                  <Col md={3}>
                    <small className="text-uppercase font-weight-bold">
                      Term
                    </small>
                  </Col>
                  <Col md={9}>
                    <h3 className="heading">
                      {deal.term &&
                        deal.interval &&
                        `${deal.term} ${deal.interval}s`}
                    </h3>
                  </Col>
                </Row>
                <Row className="py-3">
                  <Col md={3}>
                    <small className="text-uppercase font-weight-bold">
                      Payment Start Date
                    </small>
                  </Col>
                  <Col md={9}>
                    <h3 className="heading">
                      {deal.delivery_date &&
                        constants.formatDateShortGB(deal.delivery_date)}
                    </h3>
                  </Col>
                </Row>
                <Row className="py-3">
                  <Col md={12} sm={12}>
                    <h3
                      className="text-uppercase font-weight-bold"
                      style={{ cursor: "pointer" }}
                      onClick={() => getHistory()}
                    >
                      View history
                    </h3>
                  </Col>
                  <Col md={12} sm={12}>
                    <TableCustom
                      loading={false}
                      items={paymentChangesHistory}
                      keyField={`payment_amount`}
                      searchField={false}
                      columns={[
                        {
                          dataField: "payment_amount",
                          text: "Amount",
                          sort: true,
                        },
                        {
                          dataField: "payment_term",
                          text: "Term",
                          sort: true,
                          formatter: (rowContent, row) => {
                            return `${row.payment_term} ${row.payment_term_interval}`;
                          },
                        },
                        {
                          dataField: "payment_frequency",
                          text: "Frequency",
                          sort: true,
                          formatter: (rowContent, row) => {
                            return `${row.payment_frequency} ${row.payment_frequency_interval}`;
                          },
                        },
                        {
                          dataField: "payment_preference",
                          text: "Preference",
                          sort: true,
                          formatter: (rowContent, row) => {
                            return row.payment_preference
                              ? preferences[row.payment_preference]
                              : "-";
                          },
                        },
                        {
                          dataField: "owner",
                          text: "Created By",
                          sort: true,
                          formatter: (rowContent, row) => {
                            return row.owner.name ? row.owner.name : "-";
                          },
                        },
                        {
                          dataField: "applying_from",
                          text: "Apply From(Next Payment)",
                          sort: true,
                          formatter: (rowContent, row) => {
                            return row.applying_from
                              ? constants.formatDateShortGB(row.applying_from)
                              : "";
                          },
                        },
                        {
                          dataField: "created_at",
                          text: "Created At",
                          sort: true,
                          formatter: (rowContent, row) => {
                            return row.created_at
                              ? constants.formatDateShortGB(row.created_at)
                              : "";
                          },
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </CardBody>
              {deal && deal.deal_status == "delivered" && (
                <CardBody>
                  <Row>
                    <Col md={4} sm={6}>
                      <InputCustom
                        type={`number`}
                        label={`Payment frequency`}
                        value={
                          deal && deal.payment_frequency
                            ? deal.payment_frequency
                            : ""
                        }
                        onChange={(e) => {
                          setDeal({
                            ...deal,
                            payment_frequency: e.target.value,
                          });
                          setFlag(true);
                        }}
                      />
                    </Col>
                    <Col md={4} sm={6}>
                      <InputKeySelect
                        label={`Payment Interval`}
                        value={
                          deal && deal.payment_frequency_interval
                            ? deal.payment_frequency_interval
                            : ""
                        }
                        options={paymentIntervals}
                        onChange={(e) => {
                          setDeal({
                            ...deal,
                            payment_frequency_interval: e.target.value,
                          });
                          setFlag(true);
                        }}
                        defaultOption={true}
                      />
                    </Col>
                    <Col md={4} sm={6}>
                      <InputCustom
                        type={`number`}
                        label={`Term`}
                        value={deal && deal.term ? deal.term : ""}
                        onChange={(e) => {
                          setDeal({
                            ...deal,
                            term: e.target.value,
                          });
                          setFlag(true);
                        }}
                      />
                    </Col>
                    <Col md={4} sm={6}>
                      <InputKeySelect
                        label={`Term Interval`}
                        value={deal && deal.interval ? deal.interval : ""}
                        options={{ week: "Week(s)" }}
                        onChange={(e) => {
                          setDeal({
                            ...deal,
                            interval: e.target.value,
                          });
                          setFlag(true);
                        }}
                        defaultOption={true}
                      />
                    </Col>
                    <Col md={4} sm={6}>
                      <InputCustom
                        type={`number`}
                        label={`Unit payment`}
                        value={
                          deal && deal.monthly_payment
                            ? deal.monthly_payment
                            : ""
                        }
                        onChange={(e) => {
                          setDeal({
                            ...deal,
                            monthly_payment: e.target.value,
                          });
                          setFlag(true);
                        }}
                      />
                    </Col>
                    <Col md={4} sm={6}>
                      <InputCustom
                        type={`date`}
                        label={`Next payment`}
                        value={
                          deal && deal.next_payment ? deal.next_payment : ""
                        }
                        min={minDate ? constants.formatDate(minDate) : ""}
                        onChange={(e) => {
                          setDeal({
                            ...deal,
                            next_payment: e.target.value,
                          });
                          setFlag(true);
                        }}
                        invalid={errors && errors.next_payment ? true : false}
                        errorMessage={
                          errors && errors.next_payment
                            ? errors.next_payment
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                  {isAbleToCreateDeals && (
                    <>
                      {viewChangeDetail && (
                        <Row>
                          <Col md={12}>
                            {message && (
                              <Row className="py-3">
                                <Col md={3}>
                                  <small className="text-uppercase font-weight-bold">
                                    Notice
                                  </small>
                                </Col>
                                <Col md={9}>
                                  <h3 class="text-warning heading mb-0">
                                    {message}
                                  </h3>
                                </Col>
                              </Row>
                            )}
                            <Row className="py-3">
                              <Col md={3}>
                                <small className="text-uppercase font-weight-bold">
                                  Next payment
                                </small>
                              </Col>
                              <Col md={9}>
                                <h3 className="text-warning heading mb-0">
                                  {constants.formatDateShortGB(
                                    deal.next_payment
                                  )}
                                </h3>
                              </Col>
                              <Col md={3}>
                                <small className="text-uppercase font-weight-bold">
                                  Min date you can set
                                </small>
                              </Col>
                              <Col md={9}>
                                <h3 className="text-warning heading mb-0">
                                  {constants.formatDateShortGB(changeMinDate)}
                                </h3>
                              </Col>
                            </Row>
                            <Row className="py-3">
                              <Col md={12}>
                                <label
                                  className="form-control-label"
                                  htmlFor="consent"
                                >
                                  {`I understand and want to proceed further with payments regeneration!`}
                                </label>
                                <div>
                                  <label className="custom-toggle">
                                    <input
                                      type="checkbox"
                                      id="consent"
                                      checked={deal.consent == 1 ? true : false}
                                      onChange={() =>
                                        setDeal({
                                          ...deal,
                                          consent:
                                            deal.consent == 1 ? undefined : 1,
                                        })
                                      }
                                    />
                                    <span
                                      className="custom-toggle-slider rounded-circle"
                                      data-label-off="No"
                                      data-label-on="Yes"
                                    />
                                  </label>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md={12}>
                          <h3 className="text-info">
                            Click save to regenerate the payment schedule
                          </h3>
                          <div>
                            <Button
                              className="w-100"
                              color="success"
                              type="button"
                              disabled={saveButtonDisable}
                              onClick={() => regeneratePaymentSchedule()}
                            >
                              Save
                            </Button>
                          </div>
                          <div className="mt-3">
                            <Button
                              className="w-100"
                              color="white"
                              type="button"
                              onClick={() => resetPaymentSchedule()}
                            >
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              )}
              {isPaymentActive && hasBeenGenerated && (
                <CardBody className="py-0">
                  <Row>
                    <Col md={3} sm={4}>
                      <h3>Total Collected</h3>
                    </Col>
                    <Col md={9} sm={8}>
                      <h4>{totalCollected}</h4>
                    </Col>
                  </Row>
                </CardBody>
              )}
              {!isPaymentActive && (
                <CardBody className="py-0">
                  <Row>
                    <Col md={12}>
                      <div className="alert alert-danger alert-dismissible">
                        {`Warning`}!{" "}
                        {`Payments can not be generated until payment frequency and delivery date are both set`}
                        !
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              )}
              {!hasBeenGenerated && (
                <CardBody className="py-0">
                  <Row>
                    <Col md={12}>
                      <div className="alert alert-danger alert-dismissible">
                        {`Payments for this deal are not generated yet`}!
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              )}
              <CardBody>
                <TableCustom
                  loading={loading}
                  delay={delay}
                  items={payments}
                  keyField={`payment_no`}
                  searchField={false}
                  columns={[
                    {
                      dataField: "payment_no",
                      text: "#",
                      sort: true,
                    },
                    {
                      dataField: "amount",
                      text: "Amount",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.payment_reference
                          ? row.payment_reference.amount
                          : row.amount;
                      },
                    },
                    {
                      dataField: "charge_at",
                      text: "Charge At",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.payment_reference
                          ? constants.formatDateShortGB(
                              row.payment_reference.charge_date
                            )
                          : constants.formatDateShortGB(row.scheduled_at);
                      },
                    },
                    {
                      dataField: "payment_status",
                      text: "Payment status",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return row.payment_reference
                          ? paymentStatuses[
                              row.payment_reference.payment_status
                            ]
                          : "-";
                      },
                    },
                    {
                      dataField: "payment_descriptor",
                      text: "Payment descriptor",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return paymentDescriptors[row.payment_descriptor];
                      },
                    },
                    {
                      dataField: "",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {row.id && row.scheduled_at && (
                              <i
                                className="fab fa-amazon-pay"
                                style={{ cursor: "pointer" }}
                                onClick={() => paymentShow(row)}
                              ></i>
                            )}
                          </>
                        );
                      },
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={confirmRescheduleModal}
          toggle={() => setConfirmRescheduleModal(false)}
          className="modal-dialog-centered"
        >
          <div className="modal-body my-2">
            <h1 className="text-center my-5">{`Payment Schedule Change`}</h1>
            <h4 className="text-center text-info">
              Check the table below to see how are the changes applying in real
              time
            </h4>
          </div>
          <div className="text-center my-4">
            <Button
              className="w-25"
              color="primary"
              type="button"
              onClick={() => {
                setConfirmRescheduleModal(false);
              }}
            >
              {`OK!`}
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={paymentDetailModal}
          toggle={() => setPaymentDetailModal(false)}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h2>{`Payment reference editor`}</h2>
          </div>
          <div className="modal-body">
            <h1 className="my-1">{`Notes`}</h1>
            <ul className="list-group mt-3 mb-5">
              <li className="list-group-item">
                Past payments can't be edited for direct debit processing
              </li>
              <li className="list-group-item">
                Direct debit payments are automatically processed
              </li>
              <li className="list-group-item">
                Cash/Card are automatically set as paid
              </li>
            </ul>
            {savePaymentErrors && (
              <UncontrolledAlert color="warning" fade={false}>
                <span className="alert-inner--text">
                  <strong>Warning!</strong> {savePaymentErrors}
                </span>
              </UncontrolledAlert>
            )}
            {selectedPayment &&
              selectedPayment.reference &&
              selectedPayment.reference.payment_status ==
                paymentStatusPaidOut && (
                <Row>
                  <Col sm={12}>
                    <h3 className="text-info heading mb-0">{`Payment successfully collected!`}</h3>
                  </Col>
                </Row>
              )}
            {selectedPayment &&
              selectedPayment.reference &&
              selectedPayment.reference.process_type ==
                processTypeDirectDebit && (
                <Row>
                  <Col sm={3}>
                    <small className="text-uppercase font-weight-bold">
                      Payment Reference
                    </small>
                  </Col>
                  <Col sm={9}>
                    <h3 className="heading mb-0">
                      {selectedPayment.reference.payment_gateway_reference_id}
                    </h3>
                  </Col>
                </Row>
              )}
            {selectedPayment &&
              selectedPayment.reference &&
              selectedPayment.reference.payment_status ==
                paymentStatusFailed && (
                <Row>
                  <Col sm={12}>
                    <h3 className="text-danger heading mb-0">{`Payment failed! You can take future actions by retrying the payment!`}</h3>
                  </Col>
                </Row>
              )}
            <InputCustom
              type={`date`}
              label={`Charge At`}
              value={
                paymentEdit && paymentEdit.scheduled_at
                  ? paymentEdit.scheduled_at
                  : ""
              }
              onChange={(e) =>
                setPaymentEdit({
                  ...paymentEdit,
                  scheduled_at: e.target.value,
                })
              }
            />
            <InputCustom
              type={`number`}
              label={`Amount`}
              value={
                paymentEdit && paymentEdit.amount ? paymentEdit.amount : ""
              }
              onChange={(e) =>
                setPaymentEdit({
                  ...paymentEdit,
                  amount: e.target.value,
                })
              }
            />
            <InputKeySelect
              label={`Process type`}
              value={
                paymentEdit && paymentEdit.process_type
                  ? paymentEdit.process_type
                  : ""
              }
              options={paymentProcessTypes}
              onChange={(e) =>
                setPaymentEdit({
                  ...paymentEdit,
                  process_type: e.target.value,
                })
              }
              defaultOption={true}
            />
            {selectedPayment.scheduled_at >= moment().format() &&
              (!selectedPayment.reference ||
                (selectedPayment.reference &&
                  selectedPayment.reference.process_type ==
                    processTypeDirectDebit &&
                  selectedPayment.reference.payment_status ==
                    paymentStatusNew)) && (
                <FormGroup>
                  <label className="form-control-label">{`Collect payment now (only for direct debit payments)`}</label>
                  <div>
                    <label className="custom-toggle">
                      <input
                        type="checkbox"
                        checked={paymentEdit.collect_now == 1 ? true : false}
                        onChange={() =>
                          setPaymentEdit({
                            ...paymentEdit,
                            collect_now: paymentEdit.collect_now == 1 ? 0 : 1,
                          })
                        }
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                      />
                    </label>
                  </div>
                </FormGroup>
              )}
          </div>
          <div className="modal-footer mb-4">
            {selectedPayment.reference &&
              selectedPayment.reference.process_type ===
                processTypeDirectDebit &&
              selectedPayment.reference.payment_status ==
                paymentStatusCreated && (
                <Button
                  className="new-event--add"
                  color="primary"
                  type="button"
                  onClick={() => cancelPayment(selectedPayment.reference.id)}
                >
                  {`Cancel Payment`}
                </Button>
              )}
            {selectedPayment.reference &&
              selectedPayment.reference.process_type ===
                processTypeDirectDebit &&
              selectedPayment.reference.payment_status ==
                paymentStatusFailed && (
                <Button
                  className="new-event--add"
                  color="primary"
                  type="button"
                  onClick={() => retryPayment(selectedPayment.reference.id)}
                >
                  {`Retry`}
                </Button>
              )}
            {!(
              selectedPayment.reference &&
              selectedPayment.reference.process_type ===
                processTypeDirectDebit &&
              selectedPayment.reference.payment_status != paymentStatusNew
            ) && (
              <Button
                className="new-event--add"
                color="primary"
                type="button"
                onClick={() => savePayment()}
              >
                {`Save`}
              </Button>
            )}
            <Button
              className="ml-auto"
              color="danger"
              type="button"
              onClick={() => {
                setSavePaymentErrors("");
                setPaymentDetailModal(false);
              }}
            >
              {`Close`}
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  );
}