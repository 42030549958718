import React, { useState, useEffect } from 'react';

import InputSideCustom from 'views/pages/components/InputSideCustom';
import {
  InputKeySideSelect,
  InputArraySideSelect
} from 'views/pages/components/InputSelect';

import {
  Container,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Label,
  Row,
  Col
} from "reactstrap";

import CardsHeader from 'layouts/Headers/CardsHeader.js';

const financeTerms = [
  {
    value: '12',
    name: '1 Year'
  },
  {
    value: '24',
    name: '2 Year'
  },
  {
    value: '36',
    name: '3 Year'
  },
  {
    value: '48',
    name: '4 Year'
  },
  {
    value: '60',
    name: '5 Year'
  },
  {
    value: '11',
    name: '1 Year + Balloon'
  },
  {
    value: '23',
    name: '2 Year + Balloon'
  },
  {
    value: '35',
    name: '3 Year + Balloon'
  },
  {
    value: '47',
    name: '4 Year + Balloon'
  },
  {
    value: '59',
    name: '5 Year + Balloon'
  }
];

const financeTermsDays = [
  {
    value: '365',
    name: '1 Year'
  },
  {
    value: '730',
    name: '2 Year'
  },
  {
    value: '1095',
    name: '3 Year'
  },
  {
    value: '1460',
    name: '4 Year'
  },
  {
    value: '1825',
    name: '5 Year'
  },
  {
    value: '335',
    name: '1 Year + Balloon'
  },
  {
    value: '700',
    name: '2 Year + Balloon'
  },
  {
    value: '1065',
    name: '3 Year + Balloon'
  },
  {
    value: '1430',
    name: '4 Year + Balloon'
  },
  {
    value: '1795',
    name: '5 Year + Balloon'
  }
];

const months = [];
for (let index = 0; index < 64; index++) {
  const element = {
    value: index + 1,
    name: 'Month ' + (index + 1)
  };
  months.push(element);
}

export default function RentalCalculator() {
  const [division, setDivision] = useState(true);

  // Monthly
  const [vehicleCost, setVehicleCost] = useState('');
  const [rfl, setRfl] = useState(305);
  const [warranty, setWarranty] = useState(165);
  const [tracker, setTracker] = useState(210);
  const [fundingAmount, setFundingAmount] = useState('');
  const [financeTerm, setFinanceTerm] = useState(12);
  const [fundingRate, setFundingRate] = useState(4);
  const [balloon, setBalloon] = useState('');

  const [totalCost, setTotalCost] = useState('');
  const [monthlyPayment, setMonthlyPayment] = useState('');
  const [monthSettled, setMonthSettled] = useState(1);
  const [monthsRemaining, setMonthsRemaining] = useState('');
  const [amountPaidOff, setAmountPaidOff] = useState('');
  const [capitalOwed, setCapitalOwed] = useState('');
  const [costOfSettlement, setCostOfSettlement] = useState('');

  const [fourWeeklyRental, setFourWeeklyRental] = useState('');
  const [weeklyRental, setWeeklyRental] = useState('');
  const [rentalTerm, setRentalTerm] = useState(1);
  const [initialPayment, setInitialPayment] = useState('');
  const [rentalProfit, setRentalProfit] = useState('');
  const [endPayment, setEndPayment] = useState('');
  const [estimatedVansWorth, setEstimatedVansWorth] = useState('');
  const [totalProfit, setTotalProfit] = useState('');

  // Daily
  const [dVehicleCost, setDVehicleCost] = useState('');
  const [dRfl, setDRfl] = useState(0);
  const [dWarranty, setDWarranty] = useState(0);
  const [dTracker, setDTracker] = useState(0);
  const [dFundingAmount, setDFundingAmount] = useState('');
  const [dFinanceTerm, setDFinanceTerm] = useState(365);
  const [dFundingRate, setDFundingRate] = useState(4);
  const [dBalloon, setDBalloon] = useState('');

  const [dTotalCost, setDTotalCost] = useState('');
  const [dDailyCost, setDDailyCost] = useState('');
  const [dDaysOnRent, setDDaysOnRent] = useState('');
  const [dDaysRemaining, setDDaysRemaining] = useState('');
  const [dAmountPaidOff, setDAmountPaidOff] = useState('');
  const [dCapitalOwed, setDCapitalOwed] = useState('');
  const [dCostOfSettlement, setDCostOfSettlement] = useState('');

  const [dDailyRental, setDDailyRental] = useState('');
  const [dRentalTerm, setDRentalTerm] = useState('');
  const [dRentalProfit, setDRentalProfit] = useState('');
  const [dEstimatedVansWorth, setDEstimatedVansWorth] = useState('');
  const [dTotalProfit, setDTotalProfit] = useState('');

  useEffect(() => {
    // Funding Amount
    setFundingAmount( Number(vehicleCost) != 0? Number(vehicleCost) + Number(rfl) + Number(warranty) + Number(tracker): '' );

    // Total Cost
    let calc_totalCost = Number(fundingAmount) + ( Number(financeTerm) / 12 * Number(fundingRate) * Number(fundingAmount) / 100 );
    setTotalCost( Math.round( calc_totalCost * 100 ) / 100 != 0? Math.round( calc_totalCost * 100 ) / 100 : '');

    // Monthly payment
    let calc_monthlyPayment = ( Number(totalCost) - Number(balloon) ) / Number( financeTerm );
    setMonthlyPayment( Math.round(calc_monthlyPayment * 100)/100 != 0? Math.round(calc_monthlyPayment * 100)/100 : '');

    // Months Remaining
    let calc_monthsRemaining = Number(financeTerm) - Number(monthSettled);
    setMonthsRemaining( Number(vehicleCost) != 0 && Math.round(calc_monthsRemaining * 100)/100 != 0? Math.round(calc_monthsRemaining * 100)/100 : '' );

    // Amount Paid Off
    let calc_amountPaidOff = Number(monthlyPayment) * Number(monthSettled);
    setAmountPaidOff( Math.round(calc_amountPaidOff * 100)/100 != 0? Math.round(calc_amountPaidOff * 100)/100 : '' );

    // Capital Owed
    let calc_capitalOwed = Number(monthSettled) /12 * Number(fundingRate) * Number(fundingAmount) / 100 + Number(fundingAmount) - Number(amountPaidOff);
    setCapitalOwed( Math.round(calc_capitalOwed * 100)/100 != 0? Math.round(calc_capitalOwed * 100)/100 : '');

    // Cost Of Settlement
    let calc_costOfSettlement = Number(capitalOwed) * Number(fundingRate) / 100 + Number(capitalOwed);
    setCostOfSettlement( Math.round(calc_costOfSettlement) != 0? Math.round(calc_costOfSettlement) : '' );

    // Weekly Rental
    let calc_weeklyRental = Number(fourWeeklyRental) / 4;
    setWeeklyRental( Math.round(calc_weeklyRental) != 0? Math.round(calc_weeklyRental) : '' );

    // Rental profit
    let calc_rentalProfit = Number(fourWeeklyRental) * Number(rentalTerm) + Number(initialPayment) - Number(amountPaidOff);
    setRentalProfit( Math.round(calc_rentalProfit * 100)/100 != 0? Math.round(calc_rentalProfit * 100)/100 : '' );

    // Total Profit
    let calc_totalProfit = (Number(fourWeeklyRental) * Number(rentalTerm) + Number(initialPayment)) - (Number(calc_amountPaidOff) + Number(calc_costOfSettlement)) + Number(endPayment) + Number(estimatedVansWorth);
    setTotalProfit( Math.round(calc_totalProfit * 100)/100 != 0? Math.round(calc_totalProfit * 100)/100 : '' );
  }, [
    vehicleCost, 
    rfl, 
    warranty, 
    tracker, 
    financeTerm,
    fundingRate, 
    balloon, 
    monthSettled, 
    fourWeeklyRental, 
    rentalTerm, 
    initialPayment, 
    endPayment, 
    estimatedVansWorth,
    fundingAmount,
    totalCost,
    monthlyPayment,
    amountPaidOff,
    capitalOwed, 
    costOfSettlement,
    rentalProfit
  ])

  useEffect(() => {
    // Vehicle Cost
    setDFundingAmount( Number(dVehicleCost) != 0? Number(dVehicleCost) + Number(dRfl) + Number(dWarranty) + Number(dTracker) : '' );

    // Total Cost
    let calc_dTotalCost = Number(dFundingAmount) + ( Number(dFinanceTerm) / 365 * Number(dFundingRate) * Number(dFundingAmount) / 100 );
    setDTotalCost( Math.round(calc_dTotalCost * 100)/100 != 0? Math.round(calc_dTotalCost * 100)/100 : '' );

    // Daily cost
    let calc_dDailyCost = ( Number(dTotalCost) - Number(dBalloon) ) / Number(dFinanceTerm);
    setDDailyCost( Math.round(calc_dDailyCost * 100)/100 != 0? Math.round(calc_dDailyCost * 100)/100 : '' );

    // Days Remaining
    let calc_dDaysRemaining = parseInt( Number(dFinanceTerm) - Number(dDaysOnRent) );
    setDDaysRemaining( (Number(dVehicleCost) != 0 || Number(dDaysOnRent) != 0) && Math.round(calc_dDaysRemaining * 100)/100 != 0? Math.round(calc_dDaysRemaining * 100)/100 : '' );

    // Amount Paid Off
    let calc_dAmountPaidOff = Number(calc_dDailyCost) * Number(dDaysOnRent);
    setDAmountPaidOff( Math.round(calc_dAmountPaidOff * 100)/100 != 0? Math.round(calc_dAmountPaidOff * 100)/100 : '' );

    // Capital Owed
    let calc_dCapitalOwed = Number(dDaysOnRent) / 365 * Number(dFundingRate) * Number(dFundingAmount) / 100 + Number(dFundingAmount) - Number(calc_dAmountPaidOff);
    setDCapitalOwed( Math.round(calc_dCapitalOwed * 100)/100 != 0? Math.round(calc_dCapitalOwed * 100)/100 : '' );

    // Cost Of Settlement
    let calc_dCostOfSettlement = Number(calc_dCapitalOwed) * Number(dFundingRate) / 100 + Number(calc_dCapitalOwed);
    setDCostOfSettlement( Math.round(calc_dCostOfSettlement) != 0? Math.round(calc_dCostOfSettlement) : '' );

    // Rental profit
    let calc_dRentalProfit = Number(dDailyRental) * Number(dRentalTerm) - Number(calc_dAmountPaidOff);
    setDRentalProfit( Math.round(calc_dRentalProfit * 100)/100 != 0? Math.round(calc_dRentalProfit * 100)/100 : '' );

    // Total Profit
    let calc_dTotalProfit = Number(dDailyRental) * Number(dRentalTerm) - ( Number(calc_dAmountPaidOff) + Number(calc_dCostOfSettlement) ) + Number(dEstimatedVansWorth);
    setDTotalProfit( Math.round(calc_dTotalProfit * 100)/100 != 0? Math.round(calc_dTotalProfit * 100)/100 : '' );
  }, [
    dVehicleCost,
    dRfl,
    dWarranty,
    dTracker,
    dFinanceTerm,
    dFundingRate,
    dBalloon,
    dDaysOnRent,
    dDailyRental,
    dRentalTerm,
    dRentalProfit,
    dEstimatedVansWorth,
    dFundingAmount,
    dTotalCost,
    dDailyCost,
    dAmountPaidOff,
    dCapitalOwed,
    dCostOfSettlement,
    dRentalProfit
  ])

  return (
    <>
      <CardsHeader name="Admin" parentName="Rental Calculator" />
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h1 className="title mb-0">Rental calculator</h1>
              </CardHeader>
            </Card>
            <Card>
              <CardHeader>
                <h1 className="title mb-0 text-center">{`Flexi Lease & Rent 2 Buy Calculator`}</h1>
              </CardHeader>
              <CardHeader className="d-flex justify-content-around">
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    className="custom-control-input"
                    id="customRadioInline1"
                    name="customRadioInline1"
                    type="radio"
                    checked={division}
                    onChange={() => setDivision(true)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadioInline1"
                    onClick={() => setDivision(true)}
                  >
                    Monthly Rental Calculator
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    className="custom-control-input"
                    id="customRadioInline2"
                    name="customRadioInline1"
                    type="radio"
                    checked={!division}
                    onChange={() => setDivision(false)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadioInline2"
                    onClick={() => setDivision(false)}
                  >
                    Daily Rental Calculator
                  </label>
                </div>
              </CardHeader>
              <CardBody>
                {division && (<Row className="justify-content-center">
                  <Col md="4" style={{borderRight: '1px solid #1B4B72'}}>
                    <div className="text-center mb-5">
                      <h1 className="title mb-0">{`Vehicle & Finance`}</h1>
                    </div>
                    <InputSideCustom 
                      type={`number`}
                      label={`Vehicle Cost`}
                      value={vehicleCost}
                      onChange={(e) => setVehicleCost(e.target.value)}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`RFL`}
                      value={rfl}
                      onChange={(e) => setRfl(e.target.value)}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Warranty`}
                      value={warranty}
                      onChange={(e) => setWarranty(e.target.value)}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Tracker`}
                      value={tracker}
                      onChange={(e) => setTracker(e.target.value)}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Funding Amount`}
                      disabled={true}
                      value={fundingAmount}
                    />
                    <InputArraySideSelect 
                      label={`Finance Term`}
                      value={financeTerm}
                      onChange={(e) => setFinanceTerm(e.target.value)}
                      options={financeTerms}
                      valueKey={`value`}
                      labelKey={`name`}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Funding Rate %`}
                      value={fundingRate}
                      onChange={(e) => setFundingRate(e.target.value)}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Balloon`}
                      value={balloon}
                      onChange={(e) => setBalloon(e.target.value)}
                    />
                  </Col>
                  <Col md="4" style={{borderRight: '1px solid #1B4B72'}}>
                    <div className="text-center mb-5">
                      <h1 className="title mb-0">{`Finance Cost`}</h1>
                    </div>
                    <InputSideCustom 
                      type={`number`}
                      label={`Total Cost`}
                      disabled={true}
                      value={totalCost}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Monthly payment`}
                      disabled={true}
                      value={monthlyPayment}
                    />
                    <InputArraySideSelect
                      label={`Month Settled`}
                      options={months}
                      value={monthSettled}
                      onChange={(e) => setMonthSettled(e.target.value)}
                      valueKey={`value`}
                      labelKey={`name`}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Months Remaining`}
                      disabled={true}
                      value={monthsRemaining}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Amount Paid Off`}
                      disabled={true}
                      value={amountPaidOff}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Capital Owed`}
                      disabled={true}
                      value={capitalOwed}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Cost Of Settlement`}
                      disabled={true}
                      value={costOfSettlement}
                    />
                  </Col>
                  <Col md="4">
                    <div className="text-center mb-5">
                      <h1 className="title mb-0">{`Rental Quote`}</h1>
                    </div>
                    <InputSideCustom 
                      type={`number`}
                      label={`4 Weekly Rental`}
                      value={fourWeeklyRental}
                      onChange={(e) => setFourWeeklyRental(e.target.value)}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Weekly Rental`}
                      value={weeklyRental}
                      disabled={true}
                    />
                    <InputArraySideSelect
                      label={`Rental Term`}
                      options={months}
                      value={rentalTerm}
                      onChange={(e) => setRentalTerm(e.target.value)}
                      valueKey={`value`}
                      labelKey={`name`}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Initial payment`}
                      value={initialPayment}
                      onChange={(e) => setInitialPayment(e.target.value)}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Rental profit`}
                      value={rentalProfit}
                      disabled={true}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`End Payment`}
                      value={endPayment}
                      onChange={(e) => setEndPayment(e.target.value)}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Estimated Vans Worth`}
                      value={estimatedVansWorth}
                      onChange={(e) => setEstimatedVansWorth(e.target.value)}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Total Profit`}
                      disabled={true}
                      value={totalProfit}
                    />
                  </Col>
                </Row>)}
                {!division && (<Row className="justify-content-center">
                  <Col md="4" style={{borderRight: '1px solid #1B4B72'}}>
                    <div className="text-center mb-5">
                      <h1 className="title mb-0">{`Vehicle & Finance`}</h1>
                    </div>
                    <InputSideCustom 
                      type={`number`}
                      label={`Vehicle Cost`}
                      value={dVehicleCost}
                      onChange={(e) => setDVehicleCost(e.target.value)}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`RFL`}
                      value={dRfl}
                      onChange={(e) => setDRfl(e.target.value)}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Warranty`}
                      value={dWarranty}
                      onChange={(e) => setDWarranty(e.target.value)}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Tracker`}
                      value={dTracker}
                      onChange={(e) => setDTracker(e.target.value)}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Funding Amount`}
                      disabled={true}
                      value={dFundingAmount}
                    />
                    <InputArraySideSelect
                      label={`Finance Term`}
                      options={financeTermsDays}
                      onChange={(e) => setDFinanceTerm(e.target.value)}
                      valueKey={`value`}
                      labelKey={`name`}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Funding Rate %`}
                      value={dFundingRate}
                      onChange={(e) => setDFundingRate(e.target.value)}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Balloon`}
                      value={dBalloon}
                      onChange={(e) => setDBalloon(e.target.value)}
                    />
                  </Col>
                  <Col md="4" style={{borderRight: '1px solid #1B4B72'}}>
                    <div className="text-center mb-5">
                      <h1 className="title mb-0">{`Finance Cost`}</h1>
                    </div>
                    <InputSideCustom 
                      type={`number`}
                      label={`Total Cost`}
                      disabled={true}
                      value={dTotalCost}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Daily cost`}
                      disabled={true}
                      value={dDailyCost}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Days on Rent`}
                      value={dDaysOnRent}
                      onChange={(e) => setDDaysOnRent(e.target.value)}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Days Remaining`}
                      disabled={true}
                      value={dDaysRemaining}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Amount Paid Off`}
                      disabled={true}
                      value={dAmountPaidOff}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Capital Owed`}
                      disabled={true}
                      value={dCapitalOwed}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Cost Of Settlement`}
                      disabled={true}
                      value={dCostOfSettlement}
                    />
                  </Col>
                  <Col md="4">
                    <div className="text-center mb-5">
                      <h1 className="title mb-0">{`Rental Quote`}</h1>
                    </div>
                    <InputSideCustom 
                      type={`number`}
                      label={`Daily Rental`}
                      value={dDailyRental}
                      onChange={(e) => setDDailyRental(e.target.value)}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Rental Term`}
                      value={dRentalTerm}
                      onChange={(e) => setDRentalTerm(e.target.value)}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Rental profit`}
                      value={dRentalProfit}
                      disabled={true}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Estimated Vans Worth`}
                      value={dEstimatedVansWorth}
                      onChange={(e) => setDEstimatedVansWorth(e.target.value)}
                    />
                    <InputSideCustom 
                      type={`number`}
                      label={`Total Profit`}
                      disabled={true}
                      value={dTotalProfit}
                    />
                  </Col>
                </Row>)}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}