import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationAlert from "react-notification-alert";
import Cookies from 'js-cookie';
import axios from 'axios';

import CardsHeader from 'layouts/Headers/CardsHeader.js';
import * as constants from 'constants/index.js';

import InputCustom from 'views/pages/components/InputCustom';
import TableCustom from 'views/pages/components/TableCustom';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import {
  deletee,
  hasPermission,
} from 'views/policies/MessagePolicy';

export default function Message() {
  const notificationAlert = useRef(null);
  const history = useHistory();

  const permissions = useSelector(state => state.getState).permissions;

  const [token, setToken] = useState('');
  const [messages, setMessages] = useState([]);

  const [confirmShow, setConfirmShow] = useState(false);
  const [selectedId, setSelectedId] = useState('');

  const [addModal, setAddModal] = useState(false);
  const [message, setMessage] = useState({
    title: '',
    message: '',
    sort: '',
    active_from: '',
    active_to: '',
  });

  const [loading, setLoading] = useState(true);

  const [errors, setErrors] = useState('');

  const notify = (type, header, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {header}
          </span>
          <span data-notify="message">
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    const grs_token = Cookies.get('grs_token')
    setToken(grs_token)

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      try {
        const response = await axios({
          method: 'GET',
          url: `${constants.API_URL}/settings/messages`,
          headers: {
            Authorization: `Bearer ${grs_token}`,
          },
          cancelToken: source.token,
        })
        console.log('response :: ', response.data)
        const data = response.data
        setMessages(data.data)

        setTimeout(() => {
          setLoading(false)
        }, 500);
      } catch (error) {
        setLoading(false)
        if (axios.isCancel(error)) {
          console.log('Request canceled :: ', error)
        } else {
          console.error('error :: ', error)
          notify('warning', 'Warning', error.response.data.message ? error.response.data.message : error.response.statusText)
        }
      }
    }

    fetchData()

    return () => {
      source.cancel()
    }
  }, [])

  const formatDate = (d) => {
    let options = {
      year: 'numeric', month: '2-digit', day: '2-digit'
    }
    const date = new Date(d);
    const formatedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
    return formatedDate;
  }

  const addMessage = () => {

    axios({
      method: 'POST',
      url: constants.API_URL + '/settings/messages',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        ...message,
        sort: message.sort? message.sort: 0,
      }
    })
      .then(response => {
        setAddModal(false);
        if (response.status == 200) {
          setErrors('');
          let data = response.data;
          console.log('response data :: ', data);
          notify('success', 'Success', data.message);
          setMessages(data.data);
        }
      })
      .catch(error => {
        console.error('error :: ', error);
        if (error.response.status == 422) {
          let errors = error.response.data.errors;
          setErrors(errors);
          return
        }
        setAddModal(false);
        notify('warning', 'Warning', error.response? error.response.statusText: 'Error occurred.');
      })
  }

  const editMessage = (row) => {
    history.push({
      pathname: '/admin/message/' + row.uuid + '/edit'
    });
  }

  const deleteMessage = () => {
    axios({
      method: 'DELETE',
      url: constants.API_URL + '/settings/messages/' + selectedId,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        setConfirmShow(false);
        if (response.status == 200) {
          let data = response.data;
          console.log('response data :: ', data);
          notify('success', 'Success', data.message);
          setMessages(data.data);
        }
      })
      .catch(error => {
        setConfirmShow(false);
        console.error('error :: ', error);
        notify('error', 'Error', error.response.statusText);
      })
  }

  return (
    <>
      <CardsHeader name="Admin" parentName="Messages Management" currentName="List" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container className="mt--6 admin-main-body" fluid >
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs={8}>
                    <h1 className="mb-0">Messages</h1>
                    <p className="text-sm mb-0">Messages management.</p>
                  </Col>
                  <Col xs={4} className="text-right">
                    {hasPermission(permissions, 'create-messages') &&
                      <Button className="w-100" color="success" type="button" onClick={() => {
                        setErrors('')
                        setAddModal(true)
                      }}>Add new</Button>}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <TableCustom 
                  loading={loading}
                  keyField={`title`}
                  searchField={true}
                  items={messages}
                  columns={[
                    {
                      dataField: "title",
                      text: "Title",
                      sort: true
                    },
                    {
                      dataField: "message",
                      text: "Message",
                      sort: true
                    },
                    {
                      dataField: "active_from",
                      text: "Active from",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return formatDate(row.active_from);
                      }
                    },
                    {
                      dataField: "active_to",
                      text: "Active to",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return formatDate(row.active_to);
                      }
                    },
                    {
                      dataField: "action",
                      text: "Action",
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                color=""
                                role="button"
                                size="sm"
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  onClick={e => editMessage(row)}
                                >
                                  Edit
                                </DropdownItem>
                                {deletee(permissions, row) && 
                                <DropdownItem
                                  onClick={e => {
                                    setConfirmShow(true);
                                    setSelectedId(row.uuid);
                                  }}
                                >
                                  Delete
                                </DropdownItem>}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </>
                        )
                      }
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={addModal}
          toggle={() => setAddModal(false)}
          size="lg"
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-header">
            <h2 className="surtitle">Message details</h2>
          </div>
          <div className="modal-body">
            <InputCustom 
              label={`Title`}
              placeholder={`Title`}
              onChange={(e) => setMessage({
                ...message,
                title: e.target.value
              })}
              invalid={errors && errors.title? true: false}
              errorMessage={errors.title}
            />
            <InputCustom 
              type={`textarea`}
              label={`Message`}
              placeholder={`Message`}
              rows={8}
              onChange={(e) => setMessage({
                ...message,
                message: e.target.value
              })}
              invalid={errors && errors.message? true: false}
              errorMessage={errors.message}
            />
            <InputCustom 
              type={`number`}
              label={`Sort`}
              rows={8}
              onChange={(e) => setMessage({
                ...message,
                sort: e.target.value
              })}
              invalid={errors && errors.sort? true: false}
              errorMessage={errors.sort}
            />
            <InputCustom 
              type={`date`}
              label={`Active From`}
              onChange={(e) => setMessage({
                ...message,
                active_from: e.target.value
              })}
              invalid={errors && errors.active_from? true: false}
              errorMessage={errors.active_from}
            />
            <InputCustom 
              type={`date`}
              label={`Active To(blank for no expiration)`}
              onChange={(e) => setMessage({
                ...message,
                active_to: e.target.value
              })}
              invalid={errors && errors.active_to? true: false}
              errorMessage={errors.active_to}
            />
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="primary"
              type="button"
              onClick={() => addMessage()}
            >
              Save
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setAddModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={confirmShow}
          toggle={() => setConfirmShow(false)}
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body">
            {`Are you sure you want to delete this message?`}
          </div>
          <div className="modal-footer">
            <Button
              className="new-event--add"
              color="danger"
              type="button"
              onClick={() => deleteMessage()}
            >
              {`Delete`}
            </Button>
            <Button
              className="ml-auto"
              color="link"
              type="button"
              onClick={() => setConfirmShow(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  )
}