/**
 * Login
 */
export const LOGIN            = "LOGIN";
export const SET_LOGIN_DATA   = 'SET_LOGIN_DATA';

/**
 * Logout
 */
export const EMPTY_STATE      = 'EMPTY_STATE';
export const SET_EMPTY_STATE  = 'SET_EMPTY_STATE';

/**
 * Profile
 */
export const GET_PROFILE_DATA     = 'GET_PROFILE_DATA';
export const SET_PROFILE_DATA     = 'SET_PROFILE_DATA';
export const SET_ROLES_DATA       = 'SET_ROLES_DATA';
export const SET_PERMISSIONS_DATA = 'SET_PERMISSIONS_DATA';
export const SET_AUTH_USER_TEAM   = 'SET_AUTH_USER_TEAM';
export const SET_ROUTE_CAN        = 'SET_ROUTE_CAN';
export const SET_CSRF_TOKEN       = 'SET_CSRF_TOKEN';

/**
 * Notifications
 */
export const GET_NOTIFICATIONS         = 'GET_NOTIFICATIONS';
export const SET_ACTION_NOTIFICATIONS  = 'SET_ACTION_NOTIFICATIONS';
export const SET_GENERAL_NOTIFICATIONS = 'SET_GENERAL_NOTIFICATIONS';